/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import axios from 'axios';
import ReactSelect from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { BaseURL, UIURL } from '../../../Constant';
import { format } from 'url';
import { Redirect, withRouter } from 'react-router';
import $ from 'jquery';
import { AgGridLocale } from '../../../Utils/AgGridLocale';


var MetricsData = []

function CreateEditBox() {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = '<span><img src="images/icon-edit-active.png"></span>';
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class Metrics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            MetricsGridData: [],
            isEdit: 1,
            errors: {},
            selectedRow: {},
        };
        this.ChangeIsActive = this.ChangeIsActive.bind(this);
        this.onChange = this.OnCancel.bind(this);
    }

    componentWillMount() {
        try {
            this.setState({ MetricsGridData: MetricsData });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == "Edit") {
                this.refs.MetricName.value = e.data.MetricName;
                this.refs.MetricsDefinition.value = e.data.MetricsDefinition;
                this.setState({
                    isEdit: 2,
                    selectedRow: e.data
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {
        this.setState({ IsActive: event.target.checked });
    }

    OnCancel(event) {
        try {
            this.setState({
                isEdit: 1
            });
            this.refs.MetricsName.value = '';
            this.refs.Description.value = '';
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {

    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: 'Edit', width: 50, field: '', cellRenderer: CreateEditBox },
            { headerName: 'MetricId', field: 'MetricId', hide: true },
            { headerName: 'MetricName', field: 'MetricName' },
            { headerName: 'MetricsDefinition', field: 'MetricsDefinition' },
            { headerName: 'Units', field: 'Units' },
            { headerName: 'CreatedBy', field: 'CreatedBy' },
            { headerName: 'CreatedDate', field: 'CreatedDate' },
            { headerName: 'UpdatedBy', field: 'UpdatedBy' },
            { headerName: 'UpdatedDate', field: 'UpdatedDate' },
            { headerName: 'IsActive', field: 'IsActive', width: 100 },
            { headerName: 'IsDeleted', field: 'IsDeleted', width: 100 },
            { headerName: 'SCId', field: 'SCId', width: 100 },
        ];

        var MetricsGridData = this.state.MetricsGridData;

        var MetricsDataGrid = (
            <div style={{ height: '250px' }} className="ag-fresh padding5">
                <AgGridReact enableSorting="true" id="grd_MetricsData"
                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs}
                    rowData={this.state.MetricsGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='true'
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )
        return (
            <div>
                <div className="box">
                    <div className="box-in">
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_metricsName" for=" ">Metrics Name:</label>
                                <div className="col-sm-8">
                                    <input ref="MetricName" id="txt_metName" name="MetricName" type="text" className="form-control" placeholder="" />
                                </div>
                            </div>
                            <div className="btn-group-md">
                                <a type="button" className="btn btn-success">Save</a>
                                <a type="button" className="btn btn-danger" onClick={this.OnCancel.bind(this)} >Cancel</a>
                            </div>
                        </div>
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-2" id="lbl_metricsDefinition" for=" "> Metrics Definition:</label>
                                <div className="col-sm-8">
                                    <textarea ref="MetricsDefinition" id="txtArea_metDef" name="MetricsDefinition" rows="1" className="form-control" placeholder="" type="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-2" id="lbl_isActive" for="">Is Active </label>
                                <div className="col-sm-8">
                                    <div className="commoncheckbox">
                                        <input ref="IsActive" id="chk_isActiveMetrics" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} defaultChecked={true} onChange={this.ChangeIsActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4>List Details </h4>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {MetricsDataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Metrics);
