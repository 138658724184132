
/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import request from '../services/Service';
import i18n from 'i18next';
import langKeys from '../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../Utils/AgGridLocale';


class BenchmarkPopup extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show, BenchmarkGridData: []
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.clear = this.clear.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show
            });
            window.addEventListener('resize', this.reSizeGrid, true);
            var DealId = this.props.dealId;
            var MetricName = this.props.selectedMetric;
            let omidData = {
                "MetricsName": MetricName,
                "DealId": DealId,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/Common/GetBenchmarkDetails", 'POST', omidData)
                .then(response => {
                    this.setState({ BenchmarkGridData: response.Table });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.benchmark_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    closePopUp(eve) {
        try {
            this.setState({
                show: false
            });

            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    clear() {

    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];


        columnDefs = [
            { headerName: '', field: 'Region', hide: false, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('CrossIndustry'), field: 'CrossIndustry', tooltipField: 'CrossIndustry', suppressMovable: true, suppressResize: true },
            { headerValueGetter: (params) => { return this.props.industry == i18n.t('standard') ? i18n.t('global') : this.props.industry }, field: 'Industry', tooltipField: 'Chemicals and Natural Resources', suppressMovable: true, suppressResize: true }

        ];

        var BenchmarkGridData = this.state.BenchmarkGridData;


        var BenchmarkDataGrid = (
            <div style={{ height: '350px' }} className="ag-theme-material">
                <AgGridReact ref="benchmark_grid" id="benchmark_grid"
                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs}
                    rowData={BenchmarkGridData}
                    localeText={AgGridLocale()}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"

                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )
        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('RegionWiseInternalBenchmark')} :  <span className="infoHeaderContent">{this.props.selectedMetric}</span></b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="box-plain pmm-asmt-tbl BOI-div">
                            {BenchmarkDataGrid}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_closeBenchmark" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
		languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        }
}

BenchmarkPopup = connect(mapStateToProps)(withRouter(BenchmarkPopup))
export default BenchmarkPopup