/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from './../services/Service';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class NavigationConfirmation extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: props.show,
                url: props.url,
                navigationPage: props.navigationPage,
                isEditAcitve: props.isEditAcitve,
                dropValue: props.dropValue == undefined ? null : props.dropValue[0].text
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.handleResponse = this.handleResponse.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {
        try {
            this.setState({
                show: this.props.show,
                url: this.props.url,
                navigationPage: this.props.navigationPage,
                isEditAcitve: this.props.isEditAcitve,
                dropValue: this.props.dropValue == undefined ? null : this.getItemprops.dropValue[0].text
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {

        try {
            this.setState({
                show: this.props.show,
                url: this.props.url,
                navigationPage: this.props.navigationPage,
                isEditAcitve: this.props.isEditAcitve,
                dropValue: this.props.dropValue == undefined ? null : this.props.dropValue[0].text
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleResponse(eve) {
        try {
            let targetText = eve.target.value;
            if (targetText == i18n.t('yes')) {
                if (!this.state.url) {
                    this.props.closeCallback();
                    this.props.setDropValCallback(this.state.dropValue);

                } else {
                    var currentURL = window.location.href;
                    var arrPageName = currentURL.split("/");
                    var pageName = arrPageName[arrPageName.length - 1];
                    if (pageName == "BOI" ||
                        pageName == "LeadingIndicator" ||
                        pageName == "PMMAssessment" ||
                        pageName == "StoryBoard" ||
                        pageName == "PotentialProject" ||
                        pageName == "ProjectPrioritization") {
                        var data = {
                            LoggedInUserId: this.props.enterpriseId,
                            PMMProcessDraftID: this.props.propPMMProcessDraftID,
                            FlagId: 2
                        };
                        request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" +this.props.languageId, 'POST', data)
                        .then(res => {
                                $("#loader-div").hide();
                            })
                            .catch(error => {
                                console.log("Error Encountered");
                                $("#loader-div").hide();
                            });

                    }
                    if (pageName == "StoryBoard") {
                        this.props.history.push({
                            pathname: this.state.url,
                            state: { asToVal: this.props.asToVal }
                        });
                    }
                    else {
                        this.props.history.push(this.state.url);
                    }

                }
            } else {
                this.setState({
                    show: false
                });
                this.props.closeCallback();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        return (
            <div>
                <Modal show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('navigateConfirmation')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.isEditAcitve == true ?
                            <span>
                                <p>{i18n.t('youHave')}<b style={{ 'color': 'red' }}>{i18n.t('unsavedChanges')}</b>{i18n.t('onThisPage')}</p>
                                <p>{i18n.t('areYouSure')}<b>{i18n.t('navigate')}</b>{i18n.t('backTo')}<b>{this.state.navigationPage}</b>{i18n.t('page')}<b style={{ 'color': 'red' }}>{i18n.t('withoutSaving')}</b>{i18n.t('currentChanges')}</p>
                            </span>
                            :
                            <span>
                                <p>{i18n.t('allChangesIfAny')}<b style={{ 'color': 'green' }}>{i18n.t('alreadySaved')}</b> .</p>
                                <p>{i18n.t('areYouSure')}<b>{i18n.t('navigate')}</b>{i18n.t('backTo')}<b>{this.state.navigationPage}</b>{i18n.t('page')} ?  </p>
                            </span>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_yesNavConfirm" className="btn btn-success" value={i18n.t('yes')} onClick={this.handleResponse} />
                        <input type="button" id="btn_noNavConfirm" className="btn btn-danger" value={i18n.t('no')} onClick={this.handleResponse} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
		languageId:state.languageId,
        enterpriseId:state.enterpriseId,
    }
}


NavigationConfirmation = connect(mapStateToProps)(withRouter(withTranslation()(NavigationConfirmation)))
export default NavigationConfirmation