import { combineReducers } from "redux";
import { languageIdReducer as languageId } from "./reducer";
import { enterpriseIdReducer as enterpriseId } from "./reducer";
import { groupIdReducer as groupId } from "./reducer";
import { roleNameReducer as roleName } from "./reducer";
import { pageNameReducer as pageName } from "./reducer";
import { groupNameReducer as groupName } from "./reducer";
import { dropDownsValueReducer as dropDownsValue } from "./reducer";
const rootReducer = combineReducers({
  languageId,
  enterpriseId,
  groupId,
  roleName,
  pageName,
  groupName,
  dropDownsValue,
});

export default rootReducer;
