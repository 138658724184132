/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from '../../services/Service';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'

class ConfirmSubmit extends React.Component {
    constructor(props) {
        super(props)
        this.state = { show: this.props.show };
        this.handleClose = this.handleClose.bind(this);
    };
    componentWillReceiveProps() {
        this.setState({ show: this.props.show })

    }

    handleClose() {
        this.setState({ show: false });
    };

    handleResult(event) {
        $("#loader-div").show();
        let PMMAllDetails = this.props.PMMAllDetails;
        if (this.props.reqSrc == i18n.t('SubmitFinalAssessment')) {
            request("/PotentialProjects/UpsertSubmitAssessment?LanguageID=" + this.props.languageId, 'POST', PMMAllDetails)
            .then(res => {
                    this.setState({ show: false });
                    alert(i18n.t("AssessmentSubmittedSuccessfully"));
                    this.props.history.push("/")
                    $("#loader-div").hide();
                })
                .catch(error => {
                    alert(error.statusText);
                    $("#loader-div").hide();
                });
        }
        else {
            $("#loader-div").hide();
            this.setState({ show: false });
            this.props.history.push("/");
        }
    }

    render() {


        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h2>{i18n.t('confirm')}</h2>
                </Modal.Header>
                <Modal.Body>
                    {this.props.reqSrc == i18n.t("SubmitFinalAssessment") ? <div> <b>{i18n.t('yescoma')}</b> {i18n.t('toSUBMITtheassessment')}. <b><i> {i18n.t('Onceassessmentissubmitteducannotmakeanychange')}.</i></b><p>{i18n.t('press')} <b>{i18n.t('nocomma')}</b> {i18n.t('toremainonthecurrentscreen')}.</p></div> : null}
                    {this.props.reqSrc == i18n.t('SaveasDraft') ? <div>{i18n.t('press')} <b>{i18n.t('yescoma')}</b> {i18n.t('tosaveandreturntohomescreen')}.<p>{i18n.t('press')} <b>{i18n.t('nocomma')}</b> {i18n.t('toremainonthecurrentscreen')}.</p></div> : null}
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button id="btn_yes" bsStyle="success" bsSize="large" onClick={this.handleResult.bind(this)}>{i18n.t('yes')}</Button>
                        <Button id="btn_no" bsStyle="danger" bsSize="large" onClick={this.handleClose}>{i18n.t('no')}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>)
    }
}

function mapStateToProps(state){
    return{
		languageId:state.languageId,
        }
}

ConfirmSubmit = connect(mapStateToProps)(withRouter(ConfirmSubmit))
export default ConfirmSubmit;