import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import { BaseURL } from '../../../../Constant';
import { AgGridReact } from 'ag-grid-react';
import request from '../../../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../../Utils/AgGridLocale';
import {connect} from 'react-redux';

function CreateUploadButton(params) {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = '<input type="button" class="btn btn-success" value='+i18n.t('upload')+' />';
        eDiv.className = "form-group";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

function CreateDeleteButton(params) {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = '<span class="glyphicon glyphicon-trash"></span>';
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        if (params.data.Filename == "") {
            eDiv.style = "color:lightgray";
        } else {
            eDiv.className = "col-md-12 text-dark";
        }
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class SingleSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            MaturityValue: [],
            MaturityOptions: this.props.data.MaturityElement,
            selectedMaturity: ""
        };
        this.onMaturityChange = this.onMaturityChange.bind(this);
        this.populateMaturitys = this.populateMaturitys.bind(this);
    }

    componentDidMount() {
        this.populateMaturitys();
    }

    populateMaturitys() {
        if (this.props.data.MaturitySelectedTypes && this.props.data.MaturitySelectedTypes != null && this.props.data.MaturitySelectedTypes.length > 0) {
            this.onMaturityChange(this.props.data.MaturitySelectedTypes);
        }
    }

    onMaturityChange(value) {
        let selectedMaturitys = "", MaturityNames = "", containsGeneral = false, replaceGeneral = false, generalItem = [];
        this.setState({
            MaturityValue: value
        });
        value.map((item, index) => {
            if (value.length == 1) {
                selectedMaturitys = item.value.trim();
                MaturityNames = item.label.trim();
            }
            else {
                if (index == 0) {
                    if (item.label == i18n.t('generalDocument')) {
                        selectedMaturitys = ""
                        MaturityNames = ""
                        replaceGeneral = true;
                        containsGeneral = true;
                    }
                    else {
                        selectedMaturitys = item.value.trim();
                        MaturityNames = item.label.trim();
                    }
                } else {
                    if (item.label == i18n.t('generalDocument')) {
                        selectedMaturitys = item.value.trim();
                        MaturityNames = item.label.trim();
                        containsGeneral = true;
                        generalItem = item;
                    }
                    else {
                        if (replaceGeneral) {
                            generalItem.push(item)
                        }
                        selectedMaturitys = selectedMaturitys + "," + item.value.trim();
                        MaturityNames = MaturityNames + "^" + item.label.trim();
                    }
                }
            }
        });

        this.props.data["MaturitySelectedNames"] = MaturityNames;
        if (containsGeneral == true) {
            this.setState({
                MaturityValue: generalItem
            });
            this.props.data["MaturitySelectedTypes"] = generalItem;
        }
        else {
            this.setState({
                MaturityValue: value
            });
            this.props.data["MaturitySelectedTypes"] = value;
        }
    }

    render() {
        return (
            <div>
                {this.props.node.lastChild ?
                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="boi_select" id="rs_maturityChange" labelKey="label" onChange={this.onMaturityChange} options={this.state.MaturityOptions} selected={this.state.MaturityValue} menuContainerStyle={{ top: 'auto', bottom: '100%' }} multiple clearButton />
                    :
                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="boi_select" id="rs_maturityChange" labelKey="label" onChange={this.onMaturityChange} options={this.state.MaturityOptions} selected={this.state.MaturityValue} multiple clearButton />
                }
            </div>
        );
    }
}

class DocumentUploadPopup extends Component {
    constructor(props) {
        super(props);
        try {
            let PMMReferenceArr = new Array(20);
            this.state = {
                show: this.props.show,
                MaturityElements: [{ value: i18n.t('generalDocument'), label: i18n.t('generalDocument') }],
                PMMReferenceGridData: PMMReferenceArr,
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.fileUploaded = this.fileUploaded.bind(this);
            this.loadMaturityElement = this.loadMaturityElement.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.CreateUploadField = this.CreateUploadField.bind(this);
            this.loadMaturityElement = this.loadMaturityElement.bind(this);

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {

        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {

        try {
            this.setState({
                show: this.props.show,
                SCID: this.props.SCID
            });
            window.addEventListener('resize', this.reSizeGrid, true);
            this.loadMaturityElement(this.props.SCID);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    loadMaturityElement(serviceID) {
        try {
            let referenceDocumentData = {
                SCID:serviceID,
                MaturityElement:"All",
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
            }
            let maturityElementData = {
                SCId:serviceID,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
            }
            
            $("#loader-div").show();
            Promise.all([request("/PMM/GetReferenceDocumentNames", 'POST', referenceDocumentData),
            request("/PMM/GetMaturityElementPerServiceComponent", 'POST', maturityElementData)])
                .then((response) => {
                    let MaturityElementArr = [];
                    if (response[1] != "") {
                        MaturityElementArr = response[1];
                        MaturityElementArr.push({ value: i18n.t('generalDocument'), label: i18n.t('generalDocument') });
                    } else {
                        MaturityElementArr.push({ value: i18n.t('generalDocument'), label: i18n.t('generalDocument') });
                    }
                    if (response[0]) {
                        let PMMReferenceArr = new Array(20);
                        let counter = 20;
                        for (var i = 0; i < counter; i++) {
                            if (i < response[0].length) {
                                let MaturitySelectedArr = response[0][i].MaturityElement.split('^');
                                let MaturityElementArrObj = [];
                                MaturitySelectedArr.map(item => MaturityElementArrObj.push({ "label": item, "value": item }));
                                let PMM = {
                                    File: {},
                                    Filename: response[0][i].Filename,
                                    MaturityElement: MaturityElementArr,
                                    MaturitySelectedTypes: MaturityElementArrObj,
                                    isFileEmpty: true,
                                    DocumentID: response[0][i].ID
                                }
                                PMMReferenceArr[i] = PMM;
                            }
                            else {
                                let PMM = {
                                    File: {},
                                    Filename: '',
                                    MaturityElement: MaturityElementArr,
                                    MaturitySelectedTypes: [],
                                    isFileEmpty: true,
                                    DocumentID: 0
                                }
                                PMMReferenceArr[i] = PMM;
                            }
                        }
                        this.setState({
                            PMMReferenceGridData: PMMReferenceArr,
                            MaturityElements: MaturityElementArr
                        });
                    } else {
                        let PMMReferenceArr = new Array(20);
                        for (var i = 0; i < PMMReferenceArr.length; i++) {
                            let PMM = {
                                File: {},
                                Filename: '',
                                MaturityElement: MaturityElementArr,
                                MaturitySelectedTypes: [],
                                isFileEmpty: true,
                                DocumentID: 0
                            }
                            PMMReferenceArr[i] = PMM;
                        }
                        this.setState({
                            PMMReferenceGridData: PMMReferenceArr,
                            MaturityElements: MaturityElementArr
                        });
                    }
                    this.refresh();
                    $("#loader-div").hide();
                }).catch((error) => {
                    $("#loader-div").hide();
                    console.log("Error in Get User", error)
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    groupBy = (arrObject, property) => {
        return arrObject.reduce((accumulator, object) => {
            let common = accumulator.find(obj => obj.File.name == object.Filename)
            if (common != undefined) {
                accumulator.forEach((element, index) => {
                    if (element.File.name === object.Filename) {
                        if (object.MaturityElement == i18n.t('generalDocument')) {

                            const blobAsFile = new File([object.FileData], object.Filename, { type: object.ContentType });
                            let newObject = {
                                File: blobAsFile,
                                MaturitySelectedTypes: [{ value: object.MaturityElement, label: object.MaturityElement }]
                            }
                            accumulator.push(newObject);
                        }
                        else {
                            accumulator[index].MaturitySelectedTypes.push({ value: object.MaturityElement, label: object.MaturityElement });
                        }
                    }
                });
            }
            else {
                const blobAsFile = new File([object.FileData], object.Filename, { type: object.ContentType });
                let newObject = {
                    File: blobAsFile,
                    MaturitySelectedTypes: [{ value: object.MaturityElement, label: object.MaturityElement }]
                }
                accumulator.push(newObject);
            }
            return accumulator;
        }, []);
    };

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
            params.api.resetRowHeights();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('upload')) {
                this.clearUploadedFile(e);
                this.UploadReferenceFiles(e.rowIndex);
            }
            if (e.colDef.headerName == i18n.t('uploadedFileName')) {
                this.refresh();
            }
            if (e.colDef.headerName != i18n.t('maturityElement')) {
                e.colDef.cellStyle = function () {
                    return {
                        overflow: 'hidden !important',
                        border: 'none !important'
                    };
                }
            }
            if (e.colDef.headerName == i18n.t('delete')) {
                if (e.data.Filename != "") {
                    if (window.confirm(i18n.t('deleteConfirmation'))) {
                        let PMMConfigDetails = this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.rowData;
                        let rowDataToBeDeleted = PMMConfigDetails.find((item, index) => index == e.rowIndex);
                        PMMConfigDetails.splice(e.rowIndex, 1);
                        this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.api.setRowData(PMMConfigDetails);
                        this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.api.sizeColumnsToFit();
                        this.RemoveReferenceFiles(rowDataToBeDeleted);
                    }
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    refresh() {
        try {
            let objGridDetails = this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.rowData;
            this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.api.setRowData(objGridDetails);
            this.setState({
                PMMReferenceGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.api.refreshCells(params);
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CreateUploadField(params) {
        try {
            this.eGui = document.createElement("div");
            this.eGui.className = "form-group form-horizontal";
            this.eGui.width = "100%";

            var index = (params.rowIndex + 1);
            var labelElement = document.createElement("label");
            labelElement.className = "control-label pull-left";
            labelElement.textContent = index;

            var divElement = document.createElement("div");
            divElement.className = "col-sm-8 btn-group-md";
            var fileElement = document.createElement("input");
            fileElement.type = "file";
            fileElement.ref = "file";
            fileElement.accept = "application/pdf,application/vnd.ms-excel";
            if (params.data.File.name != undefined) {
                let list = new DataTransfer();
                let file = params.data.File;
                list.items.add(file);
                fileElement.files = list.files;
            }
            fileElement.addEventListener('change', (event) => {
                this.fileUploaded(event, params.rowIndex);
            });

            divElement.appendChild(fileElement)

            this.eGui.appendChild(labelElement);
            this.eGui.appendChild(divElement);
            return this.eGui;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    clearUploadedFile=(e)=> {
        try {
           e.target.value='';
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    UploadReferenceFiles(currentRowIndex) {
        try {
            var fileData = new FormData();
            let objGridDetails = this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.rowData;
            var emptyfiles = [];
            emptyfiles = objGridDetails.find((row, index) => index == currentRowIndex && row.isFileEmpty);
            var replacedFiles = objGridDetails.find((row, index) => index == currentRowIndex && row.IsReplacement);
            var duplicateFiles = objGridDetails.find((row, index) => index == currentRowIndex && row.IsDuplicate);
            var emptyMaturity = objGridDetails.find((row, index) => index == currentRowIndex && (row.MaturitySelectedNames == undefined || row.MaturitySelectedNames == ""));
            if (emptyfiles !== undefined && emptyfiles.isFileEmpty) {
                alert(i18n.t('selectFileToUpload'));
            } else if (emptyMaturity !== undefined) {
                alert(i18n.t('selectMaturityElement'));
            } else {
                if (replacedFiles !== undefined) {
                    if (window.confirm(i18n.t('replaceExistingFile'))) {
                        //window.tfo_event("PMM-D-7", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                fileData.append("File", row.File);
                                fileData.append('ReplaceFlagId', 1);
                                fileData.append('MaturityElement', row.MaturitySelectedNames);
                                fileData.append('SCId', this.state.SCID);
                                fileData.append("DocumentID", row.DocumentID);
                                fileData.append("fileToReplace", row.OldFilename);
                                fileData.append("LanguageID",this.props.languageId
                                 );
                                return true;
                            }
                        });
                        $("#loader-div").show();

                        fetch(BaseURL + "/Common/UploadReferenceDocuments", {
                            method: 'POST',
                            headers: {
                                "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                            },
                            body: fileData
                        }).then(r => r.json())
                            .then(data => {
                                alert(data.outputmessage);
                                objGridDetails.map((row, index) => {
                                    if (index == currentRowIndex) {
                                        row.Filename = row.File.name;
                                        row.File = {};
                                        row.isFileEmpty = true;
                                        return false;
                                    }
                                })
                                $("#loader-div").hide();
                                this.refresh();
                            })
                            .catch(error => {
                                console.log("Error Encountered");
                            })
                    }
                    else {
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                row.Filename = row.OldFilename;
                                row.File = {};
                                row.isFileEmpty = true;
                                return false;
                            }
                        })
                    }
                }
                else if (duplicateFiles !== undefined) {
                    if (window.confirm(i18n.t('replaceExistingFile'))) {
                        //window.tfo_event("PMM-D-7", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                fileData.append("File", row.File);
                                fileData.append('ReplaceFlagId', 1);
                                fileData.append('MaturityElement', row.MaturitySelectedNames);
                                fileData.append('SCId', this.state.SCID);
                                fileData.append("DocumentID", row.DocumentID);
                                fileData.append("fileToReplace", row.File.name);
                                fileData.append("LanguageID", this.props.languageId
                                );
                                return true;
                            }
                        });
                        $("#loader-div").show();


                        fetch(BaseURL + "/Common/UploadReferenceDocuments", {
                            method: 'POST',
                            headers: {
                                "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                            },
                            body: fileData
                        }).then(r => r.json())
                            .then(data => {
                                alert(data.outputmessage);
                                objGridDetails.map((row, index) => {
                                    if (index == currentRowIndex) {
                                        row.Filename = row.File.name;
                                        row.File = {};
                                        row.isFileEmpty = true;
                                        return false;
                                    }
                                })
                                this.refresh();
                                $("#loader-div").hide();
                            })
                            .catch(error => {
                                console.log("Error Encountered");
                            })
                    }
                    else {
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                row.File = {};
                                row.isFileEmpty = true;
                                return false;
                            }
                        })
                    }
                }
                else{
                    //window.tfo_event("PMM-D-7", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
                    objGridDetails.map((row, index) => {
                        if (index == currentRowIndex) {
                            fileData.append("File", row.File);
                            fileData.append('ReplaceFlagId', 0);
                            fileData.append('MaturityElement', row.MaturitySelectedNames);
                            fileData.append('SCId', this.state.SCID);
                            fileData.append("DocumentID", row.DocumentID);
                            fileData.append("fileToReplace", row.File.name);
                            fileData.append("LanguageID", this.props.languageId
                            );
                            return true;
                        }
                    });
                    $("#loader-div").show();

                    fetch(BaseURL + "/Common/UploadReferenceDocuments", {
                        method: 'POST',
                        headers: {
                            "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                        },
                        body: fileData
                    }).then(r => r.json())
                        .then(data => {
                            alert(data.outputmessage);
                            objGridDetails.map((row, index) => {
                                if (index == currentRowIndex) {
                                    row.Filename = row.File.name;
                                    row.File = {};
                                    row.isFileEmpty = true;
                                    return false;
                                }
                            })
                            this.refresh();
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            $("#loader-div").hide();
                            console.log("Error Encountered");
                        })
                }
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    RemoveReferenceFiles(rowData) {
        try {
            $("#loader-div").show();
            var MaturityElement = rowData.MaturitySelectedNames;
            //MaturityElement = encodeURIComponent(MaturityElement);
            var Filename = rowData.Filename;
            Filename = encodeURIComponent(Filename);
            let referenceDocumentsData = {
                SCID:this.state.SCID,
                maturityElement:MaturityElement,
                fileName:Filename,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
            }
            request("/PMM/RemoveReferenceDocuments", 'POST', referenceDocumentsData)
                .then(res => {
                    alert(res.outputmessage);
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        } catch (error) {
            console.log("Error Encountered");
            $("#loader-div").hide();
        }
    }

    fileUploaded(e, currentRowIndex) {
        try {
            let objGridDetails = this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.rowData;
            if (e.target.value != "") {
                var fileExtension = ['.xls', '.xlsx', '.xlsm', '.pdf'];
                var fileName = e.target.files[0].name.split('\\').pop();
                var fileExt = fileName.substring(fileName.lastIndexOf('.'));
                var file = e.target.files[0];
                if(
                    file !== undefined && file.name !== undefined && file.name !== "")
                    {
                        console.log(file,"filee")
                    const regex = /\..+\./;     
                    if (regex.test(file.name)) {      
                        alert(i18n.t('validFileExtensions') + fileExtension.toString() + i18n.t('types_PMMConfig'));
                        e.target.value = "";
                        return false;
                      } }

                if (fileExtension.indexOf(fileExt) < 0) {
                    alert(i18n.t('validFileExtensions') + fileExtension.toString() + i18n.t('types_PMMConfig'));
                    e.target.value = "";
                    return false;
                } else {
                    objGridDetails.map((row, index) => {
                        if (index == currentRowIndex) {
                            if (row.Filename == e.target.files[0].name) {
                                row.IsDuplicate = true;
                            } else if (row.Filename != "" && row.Filename != e.target.files[0].name) {
                                row.IsReplacement = true;
                                row.OldFilename = row.Filename;
                            }
                            row.File = e.target.files[0];
                            row.isFileEmpty = false;
                        }
                        else {
                            if (row.File.name == e.target.files[0].name || row.Filename == e.target.files[0].name) {
                                alert(i18n.t('duplicateFiles'))
                                e.target.value = "";
                                return false;
                            }
                        }
                    });
                }
            }
            else {
                objGridDetails.map((row, index) => {
                    if (index == currentRowIndex) {
                        row.File = {};
                        row.isFileEmpty = true;
                    }
                });
            }
            this.refs.PMM_Config_ReferenceDocuments_Grid.gridOptions.api.setRowData(objGridDetails);
            this.setState({
                PMMReferenceGridData: objGridDetails
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        let { t } = this.props;
        var pmmReferenceDocumentsGrid = this.state.PMMReferenceGridData;

        columnDefs = [
            { headerName: t('document#'), suppressMovable: true, suppressResize: true, width: 300, cellRenderer: this.CreateUploadField },
            { headerName: t('uploadedFileName'), field: 'Filename', tooltipField: 'Filename', suppressMovable: true, suppressResize: true, autoHeight: true, width: 180 },
            { headerName: t('maturityElement'), field: 'MaturityElement', cellRendererFramework: SingleSelect, suppressMenu: true, suppressSorting: true, suppressMovable: true, autoHeight: true, width: 280, cellStyle: { "white-space": "normal" } },
            { headerName: t('upload'), suppressMovable: true, suppressResize: true, width: 100, cellRenderer: CreateUploadButton },
            { headerName: t('delete'), suppressMovable: true, suppressResize: true, width: 100, cellRenderer: CreateDeleteButton }
        ];

        var PMMReferenceDocumentsGrid = (
            <div className="ag-theme-material grid-div custom-select">
                <AgGridReact ref="PMM_Config_ReferenceDocuments_Grid" id="grid_PMM_Config_ReferenceDocuments"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    onGridSizeChanged={this.reSizeGrid.bind(this)}
                    rowData={pmmReferenceDocumentsGrid}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    singleClickEdit={true}
                    onCellClicked={this.onCellClicked.bind(this)}
                    suppressScrollOnNewData={true}
                    localeText={AgGridLocale()}
                />
            </div>
        )

        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('documentUpload')}</b></h5>
                    </Modal.Header>
                    <Modal.Body >
                        {PMMReferenceDocumentsGrid}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-group-md">
                            <input id="txt_close" type="button" className="btn btn-info" value={i18n.t('close')} onClick={this.closePopUp} />
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}
DocumentUploadPopup = connect(mapStateToProps)(withTranslation()(DocumentUploadPopup));
export default withRouter(DocumentUploadPopup)