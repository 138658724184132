/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import ReactSelect from 'react-select';
import { BaseURL } from '../../Constant';
import PMMAssessmentGrid from './PMMUploadGrid';
import $ from 'jquery';


var Lever = [{ "label": "Policy", "text": "Policy" },
{ "label": "Practice", "text": "Practice" },
{ "label": "Platform", "text": "Platform" }];

var KPA = [{ "label": "Service Level", "text": "Service Level" },
{ "label": "Control & Risk", "text": "Control & Risk" },
{ "label": "Efficiency", "text": "Efficiency" }];

var MetricsImpacted = [{ "label": "% Rejected Invoices", "text": "% Rejected Invoices" },
{ "label": "% On-Hold Invoices", "text": "% On-Hold Invoices" },
{ "label": "% Urgent Payment Invoices", "text": "% Urgent Payment Invoices" },
{ "label": "% Duplicate Payment by volume ", "text": "% Duplicate Payment by volume " },
{ "label": "% Payment on Time", "text": "% Payment on Time" },
{ "label": "Invoice Processing Cycle Time(days)", "text": "Invoice Processing Cycle Time(days)" },
{ "label": "% Payment on Time", "text": "% Payment on Time" }];

var MaturityElement = [{ "label": "Digital vs Handwritten", "text": "Digital vs Handwritten" },
{ "label": "No PO-No Pay Policy", "text": "No PO-No Pay Policy" },
{ "label": "PO-Invoice Mapping", "text": "PO-Invoice Mapping" },
{ "label": "Timely GR Creation", "text": "Timely GR Creation" },
{ "label": "Segregation of Critical Vendors", "text": "Segregation of Critical Vendors" },
{ "label": "Segregation of Urgent Invoices", "text": "Segregation of Urgent Invoices" },
{ "label": "Duplicate payment check", "text": "Duplicate payment check" },
{ "label": "Computation of payment terms/due dates", "text": "Computation of payment terms/due dates" },
{ "label": "Tolerance for price/rate discrepancy", "text": "Tolerance for price/rate discrepancy" },
{ "label": "QC Framework", "text": "QC Framework" },
{ "label": "Direct Receipt Paper Invoices", "text": "Direct Receipt Paper Invoices" }];

export default class PMMUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = { PMMGridData: [] };
    }

    componentDidMount() {

    }

    fileUploaded(e) {
        try {
            
            var fileName = e.currentTarget.files[0].name.split('\\').pop();
            fileName = fileName.substr(0, fileName.lastIndexOf('.'));
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    clearUploadedFile(e) {
        try {
           e.target.value='';
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    uploadFile(obj) {
        try {
           
            var fileData = new FormData();
           
            fileData.append("File", this.refs.file.files[0]);
            var uploadedFile = this.refs.file.files[0];

            var that = this;
            var reader = new FileReader();
            reader.readAsDataURL(uploadedFile);

            
            fetch(BaseURL+"//Common/ValidateSAPUpload?LanguageID=" + sessionStorage.getItem('LanguageID'), {
                method: 'POST',
                headers: {
                  "Authorization":"Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body:fileData
            }).then(r => r.json())
                .then(res => {
                    that.setState({
                        PMMGridData: fileData
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        return (
            <div className="content bgbrightgrey">
                <div className="content-body ">
                    <div className="row">
                        <div className="col-xs-12 ">
                            <div>
                                <div className="form-group">
                                    <label id="lbl_lever" className="col-md-2 col-form-label">Lever</label>
                                    <div className="col-md-2">
                                        <ReactSelect ref="ddlLever" id="ddl_leverPMM" name="form-field-name" options={Lever} clearable={false} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label id="lbl_kpa" className="col-md-2 col-form-label">KPA</label>
                                    <div className="col-md-2">
                                        <ReactSelect ref="ddlKPA" id="ddl_kpaPMM" name="form-field-name" options={KPA} clearable={false} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label id="lbl_metricsImpacted" className="col-md-2 col-form-label">Metrics Impacted</label>
                                    <div className="col-md-2">
                                        <ReactSelect ref="ddlMetricsImpacted" id="ddl_metricsImpacted" name="form-field-name" options={MetricsImpacted} clearable={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 ">
                            <div>
                                <div className="form-group">
                                    <label id="lbl_maturityElement" className="col-md-2 col-form-label">Maturity Element</label>
                                    <div className="col-md-2">
                                        <ReactSelect ref="ddlMaturityElem" id="ddl_maturityElement" name="form-field-name" options={MaturityElement} clearable={false} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label id="lbl_selectFile" className="col-md-2 col-form-label">Select File</label>
                                    <div className="col-md-2">
                                        <input type="file" ref="file" name="PMMFileUpload" id="fUpload" onClick={this.clearUploadedFile} onChange={this.fileUploaded} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <button id="btnUpload" type="button" className="btn btn-primary pull-right" ref="button" onClick={this.uploadFile.bind(this)}>Upload Excel</button>
                            </div>
                        </div>
                    </div>
                    <div className="row rowGap">
                        <div className="col-xs-12 ">
                            {this.state.PMMGridData.length > 0 ? <PMMAssessmentGrid propsPMMGridData={this.state.PMMGridData} /> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
