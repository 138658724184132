/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Chart } from 'chart.js';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

class StandardizationScore_vs_PMMScore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show
        }
        this.closePopUp = this.closePopUp.bind(this);
        this.scatterChart = this.scatterChart.bind(this);

    }

    componentWillReceiveProps(props) {
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show
            });
            this.scatterChart();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    generateToolTipLabel() {
        try {
            var label = ['As-Is']
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    scatterChart() {
        try {
            let asLocalval, asLocalvalArr;
            if (this.props.asToViewLocal) {
                if (sessionStorage.getItem("asToValView")) {
                    asLocalval = sessionStorage.getItem("asToValView")
                    asLocalvalArr = JSON.parse(asLocalval);
                }
            } else {
                if (sessionStorage.getItem("asToVal")) {
                    asLocalval = sessionStorage.getItem("asToVal")
                    asLocalvalArr = asLocalval.split(',');
                }
            }
            setTimeout(() => {
                var ctx = document.getElementById('asToChart').getContext('2d');
                window.asToChart = new Chart(ctx, {
                    type: 'scatter',
                    data: {
                        label: i18n.t('ScatterDataset'),
                        datasets: [{
                            data: [{
                                x: ': ( STD.: ' + this.props.asToViewLocal ? (sessionStorage.getItem("asToValView") ? asLocalvalArr[0] : this.props.asToValData.split(',')[0]) : (sessionStorage.getItem("asToVal") ? ': ( STD.: ' + asLocalvalArr[0] : this.props.asToValData.split(',')[0]),
                                y: this.props.asToViewLocal ? (sessionStorage.getItem("asToValView") ? asLocalvalArr[1] : this.props.asToValData[1]) : (sessionStorage.getItem("asToVal") ? asLocalvalArr[1] : this.props.asToValData[1])
                            }],
                            label: i18n.t('As-Is'),
                            pointRadius: 15,
                            backgroundColor: 'rgba(255, 0, 0, 0.5)',
                        },
                        {
                            data: [{
                                x: this.props.asToViewLocal ? (sessionStorage.getItem("asToValView") ? asLocalvalArr[2] : this.props.asToValData[2]) : (sessionStorage.getItem("asToVal") ? asLocalvalArr[2] : this.props.asToValData[2]),
                                y: this.props.asToViewLocal ? (sessionStorage.getItem("asToValView") ? asLocalvalArr[3] : this.props.asToValData[3]) : (sessionStorage.getItem("asToVal") ? asLocalvalArr[3] : this.props.asToValData[3])
                            }],
                            label: i18n.t('To-Be'),
                            pointRadius: 15,
                            backgroundColor: 'rgba(0, 150, 220, 20)'
                        }]
                    },
                    options: {
                        layout: {
                            padding: {
                                left: 50,
                                right: 50,
                                top: 0,
                                bottom: 0
                            }
                        },
                        tooltips: {
                            enabled: true,
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = data.datasets[tooltipItem.datasetIndex].label
                                    return '( STD.: ' + tooltipItem.xLabel + ', PMM: ' + tooltipItem.yLabel + ')';

                                }
                            }
                        },
                        legend: {
                            display: true,
                        },
                        showLines: false,
                        scales: {
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString:i18n.t('standardizationScore'),
                                    fontSize: 16
                                },
                                type: 'linear',
                                position: 'bottom',
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1,
                                    min: 0,
                                    max: 4
                                },
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: i18n.t('overAllPMMScore'),
                                    fontSize: 16
                                },
                                type: 'linear',
                                position: 'bottom',
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1,
                                    min: 0,
                                    max: 4
                                },
                            }]
                        },
                    }
                });
            }, 1);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp(eve) {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {
        return (
            <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>

                <Modal.Header closeButton>
                    <h5 align='center'><b>{i18n.t('StandardizationScoreOverAllPMMScore')} <span className="infoHeaderContent"></span></b></h5>
                </Modal.Header>
                <Modal.Body>
                    <canvas id="asToChart" width="800" height="350"></canvas>
                </Modal.Body>
                <Modal.Footer>
                    <input id="txt_close"type="button" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                </Modal.Footer>

            </Modal>

        );
    }
}

StandardizationScore_vs_PMMScore = withTranslation()(StandardizationScore_vs_PMMScore)
export default withRouter(StandardizationScore_vs_PMMScore)