import React from 'react';
import { APP_LOGOUTURL } from './Constant';
import 'bootstrap/dist/js/bootstrap.js';
import ModalPopup from './ModalPopup';
import Support from './Support';
import AboutPMM from './AboutPMM';
import $ from 'jquery';
import pmmHeader from './images/pmm-logo-inner.PNG';
import emailIcon from './images/icon-email.ico';
import infoIcon from './images/icon-info.ico';
import userMenuArrowIcon from './images/user-menu-arrow.png';
import loderIcon from './images/loader-strokes.gif';
import request from './services/Service';
import i18n from 'i18next';
import { connect } from 'react-redux';


var pageName = "";
class Header extends React.Component {

    constructor(props) {

        super(props);
        try {
            this.state = {
                MenuList: [], modalOpen: false, NotificationMsg: "", RoleName: "", popUpDisplay: false, popUpAbout: false, showSwitchAccountsBtn: false,
                roleAssosciatedUserData: []
            };
            this.onSupportClick = this.onSupportClick.bind(this);
            this.onAboutClick = this.onAboutClick.bind(this);
            this.closeSupportPopUp = this.closeSupportPopUp.bind(this);
            this.closeAboutPopUp = this.closeAboutPopUp.bind(this);
            this.handleLogout = this.handleLogout.bind(this);
            this.GetRoleByLoggedinUser = this.GetRoleByLoggedinUser.bind(this);
            this.switchGroupPopup = this.switchGroupPopup.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillMount() {
        var path = window.location.href;
        var pageArr = [];
        pageArr = path.split("/");
        pageName = pageArr.length > 5 ? pageArr[pageArr.length - 2] : pageArr[pageArr.length - 1];
        let roleName = this.props.roleName
        let adminPageNames = ["BOIConfiguration", "PMMConfiguration", "LIConfiguration", "PMMConfig", "DealManagement", "UserManagement", "DealMapping", "DealRegionMapping", "UnlockAssessment"]
        let assessmentPages = ["CreateNewAssessment", "BOI", "LeadingIndicator", "PMMAssessment", "StoryBoard", "PotentialProject", "ProjectPrioritization"]
        if (roleName == i18n.t('assessor') || roleName == i18n.t('viewOnly') || roleName == i18n.t('leadership')) {
            if (adminPageNames.includes(pageName)) {
                window.location = window.location.origin;

            }
        }
        if (roleName == i18n.t('viewOnly') && assessmentPages.includes(pageName)) {
            window.location = window.location.origin;
        }
    }

    componentDidMount() {

        this.GetRoleByLoggedinUser();
    }

    GetMenuDetails() {
        try {
            var obj = this;

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ValidateGDPR() {

        try {
            var obj = this;

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    _openModal() {
        try {

            this.setState({ modalOpen: true });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    _closeModal() {
        try {

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSupportClick(eve) {

        try {
            this.setState({
                popUpDisplay: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeSupportPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onAboutClick(eve) {

        try {
            this.setState({
                popUpAbout: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeAboutPopUp() {
        try {
            this.setState({
                popUpAbout: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    // handleLogout() {
    //     try {
    //         var PMMProcessDraftID = localStorage.getItem("PMMProcessDraftID");
    //         var data = {
    //             LoggedInUserId: this.props.enterpriseId,

    //             PMMProcessDraftID: PMMProcessDraftID,
    //             FlagId: 2
    //         };
    //         if (PMMProcessDraftID != undefined && PMMProcessDraftID != null) {
    //             request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
    //                 .then(res => {

    //                     $("#loader-div").hide();
    //                 })
    //                 .catch(error => {
    //                     console.log("Error Encountered");
    //                     $("#loader-div").hide();
    //                 });
    //         }
    //         localStorage.removeItem('UserName');
    //         localStorage.removeItem('PMMProcessDraftID');
    //         localStorage.removeItem('access_token_api');
    //         localStorage.removeItem('access_token');
    //         localStorage.removeItem("adal.idtoken");
    //         localStorage.clear();
    //         window.location = APP_LOGOUTURL;
    //     }
    //     catch (error) {
    //         console.log("Error Encountered");
    //     }
    // }

    async handleLogout() {

        try {
            var PMMProcessDraftID = sessionStorage.getItem("PMMProcessDraftID");
            var data = {
                LoggedInUserId: this.props.enterpriseId,
                PMMProcessDraftID: PMMProcessDraftID,
                FlagId: 2
            };
            if (PMMProcessDraftID != undefined && PMMProcessDraftID != null) {
                request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                    .then(res => {

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            await request("/Common/UpdateIsActiveToken", 'POST')
                .then(res => {

                    localStorage.removeItem('UserName');
                    localStorage.removeItem('PMMProcessDraftID');
                    localStorage.removeItem('access_token_api');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem("adal.idtoken");
                    localStorage.clear();
                    window.location = APP_LOGOUTURL;
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    async GetRoleByLoggedinUser() {
        try {


            let userData = {
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId,
            }
            await request("/Common/GetRoleAssociatedWithUser", 'POST', userData)
                .then(res => {
                    if (res != null && res.length > 0) {

                        res.map((obj) => {
                            if (obj.GroupID == this.props.groupId
                                && obj.IsActive == true) {
                                this.setState({ RoleName: obj.RoleName, pageNames: obj.ScreenName.split(','), roleAssosciatedUserData: res });
                            }
                        })
                        if (res.length > 1) {
                            this.setState({ showSwitchAccountsBtn: true })
                        }
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    switchGroupPopup = (groupId) => {
        this.props.closeGroupSwitchPopup(groupId);

    }
    render() {

        var path = window.location.href;
        var pageArr = path.split("/");
        pageName = pageArr[pageArr.length - 1];
        const { modalOpen } = this.state;
        return (
            <div>
                <header>
                    <div className="pmm-logo">
                        <img src={pmmHeader} />

                    </div>
                    <div className="pull-right contact"><img title="Support" className="contactHomeImg" src={emailIcon} onClick={this.onSupportClick} /></div>
                    {pageName == "Home" || pageName == "CreateNewAssessment"
                        || pageName == "BOI" || pageName == "BOIView" || pageName == "LeadingIndicator"
                        || pageName == "LIView" || pageName == "PMMAssessment" || pageName == "PMMAssessmentView"
                        || pageName == "PotentialProject" || pageName == "PotentialProjectView" || pageName == "StoryBoard"
                        || pageName == "StoryBoardView" || pageName == "ProjectPrioritization" || pageName == "ProjectPrioritizationView"
                        || pageName == "ViewPreviousAssessmentHome"
                        ? <div className="pull-right contactHomeinnerpage"><img title="info" className="contactHomeImg" src={infoIcon} onClick={this.onAboutClick} /></div>
                        : null}
                    <ul className="hd-menu">
                        <li className="user dropdown">
                            <a href="#" data-toggle="dropdown">

                                <span>{this.props.enterpriseId
                                }
                                    <b>
                                        {this.state.roleAssosciatedUserData.length > 1 ? this.props.roleName + " - " + this.props.groupName : this.props.roleName

                                        }
                                    </b> </span>
                                <img src={userMenuArrowIcon} />
                            </a>

                            <ul className="dropdown-menu">

                                <li><a className="mousePointer" onClick={this.handleLogout.bind(this)}>{i18n.t('logout')}</a></li>

                            </ul>

                        </li>

                    </ul>
                </header>
                <div id="loader-div">
                    <img src={loderIcon} id="loader-img" />
                </div>
                {modalOpen ? <ModalPopup showModal={modalOpen} closeModal={this._closeModal.bind(this)} ModalTitle={i18n.t('privacyStatement')} NotificationMsg={this.state.NotificationMsg} /> : null}
                {this.state.popUpDisplay && <Support show={this.state.popUpDisplay} closeCallback={this.closeSupportPopUp} />}
                {this.state.popUpAbout && <AboutPMM show={this.state.popUpAbout} closeCallback={this.closeAboutPopUp} />}
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName
    }
}
Header = connect(mapStateToProps)(Header);

export default Header;
