/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import LandscapeGraph from './LandscapeGraph';
import request from '../../services/Service';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';


function createCircelText() {
    try {
        this.eGui = document.createElement('div');
        this.eGui.className = "row"
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.init = function (params) {
    try {
        let spanValue = document.createElement('span');
        spanValue.className = "AsIsToBeLabel";
        let circle = document.createElement('div');
        circle.className = "circleDivCompare";
        spanValue.textContent = params.value;
        if (params.data.RegionName.trim() != i18n.t('benchmark_CompareScreen')) {
            if (params.value != "" && params.value >= 0 && params.value < 2) {
                circle.style.backgroundColor = "#E57373"
            }
            else if (params.value != "" && params.value >= 2 && params.value < 3) {
                circle.style.backgroundColor = "#FFB74D"
            }
            else if (params.value != "" && params.value >= 3 && params.value < 3.5) {
                circle.style.backgroundColor = "#FFEB3B"
            }
            else if (params.value != "" && params.value >= 3.5 && params.value <= 4) {
                circle.style.backgroundColor = "#81C784"
            }
            if (params.value != null) {
                this.eGui.appendChild(circle);
                this.eGui.appendChild(spanValue);
            }
        }
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.getGui = function (params) {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class CompareRegionGrid extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                comparisionGridData: [],
                popUpLandscapeGraph: false,
                overallval: [],
                standardizationval: [],
                GraphArray: [],
                RegionArray: [],
                DeliveryLocationArray: [],
                DealName: "",
                ServiceComponentName: "",
                nonEmptyLeverNames: []
            };
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.closepopUpLandscapeGraph = this.closepopUpLandscapeGraph.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {            
            // if(!this.props.Assessmentdetails.Deal[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-16", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.Deal[0].label)
            // }
            window.addEventListener('resize', this.reSizeGrid, true);
            $("#loader-div").show();
           
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_Comparision.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun = (eve) => {
        try {

            eve.api.showLoadingOverlay();

            let objThis = this;
            var DealID = objThis.props.Assessmentdetails.Deal != [] ? objThis.props.Assessmentdetails.Deal[0].value : 0;
            var SCID = objThis.props.ServiceComponent != [] ? objThis.props.Assessmentdetails.ServiceComponent[0].value : 0;
            var DeliveryLocationId = objThis.props.Assessmentdetails.DeliveryLocation != "" ? objThis.props.Assessmentdetails.DeliveryLocation.value : 0;

            var SearchedString = "";
            let viewRegionalData = {
                DealID: DealID,
                RegionID: 0,
                DeliveryLocationId: DeliveryLocationId,
                SCID: SCID,
                SearchedString: SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			     LanguageID:this.props.languageId
            }

            Promise.all([request("/PMM/ViewRegionalComparison", 'POST', viewRegionalData),
            request("/MasterScreens/GetLever?LeverID=0&GroupID=" + localStorage.getItem("GroupID") + "&SearchedString=" + SearchedString + "&LanguageID=" + sessionStorage.getItem("LanguageID"), 'GET')])
                .then((response) => {
                    if (response.length !== 0) {

                        eve.api.hideOverlay();

                        if (response[0].length!==0) {
                            if (response[1].length!==0) {
                                var emptyLevers = [];
                                response[1].map((lever, leverindex) => {
                                    var levername = lever.LeverName;
                                    var empty = response[0].Table.filter(item => item[levername] !== undefined && item[levername] != null && item[levername] != "")
                                    if (empty.length == 0) {
                                        emptyLevers.push(levername);
                                    }
                                })
                                var nonEmptyLevers = response[1].filter(item => !(emptyLevers.includes(item.LeverName)));
                                if(response[0].Table[0].OutputCode ==1 ){
                                this.setState({
                                    comparisionGridData: null,
                                    GraphArray: null,
                                    nonEmptyLeverNames: nonEmptyLevers
                                });
                            }                            
                            else{
                                this.setState({
                                    comparisionGridData: response[0].Table,
                                    GraphArray: response[0].Table.filter((i) => {
                                        if (i.RegionName != i18n.t('benchmark_CompareScreen')) {
                                            return i;
                                        }
                                    }),
                                    nonEmptyLeverNames: nonEmptyLevers
                                });
                            }
                            }
                            else {
                                this.setState({
                                    comparisionGridData: response[0].Table,
                                    GraphArray: response[0].Table.filter((i) => {
                                        if (i.RegionName != i18n.t('benchmark_CompareScreen')) {
                                            return i;
                                        }
                                    }),
                                    nonEmptyLeverNames: []
                                });
                            }
                        }
                    }
                    else{
                        eve.api.showNoRowsOverlay();
                    }
                }).catch((error) => {
                    console.log("Error Encountered")
                });

            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == "") {
                var details = {
                    RegionName: e.data.RegionName,
                    DLName: e.data.DeliveryLocationName,
                    ReportType: i18n.t('individual')
                };
                this.props.propsSetSelectedTrendReportDetails(details);
                this.props.history.push("/TrendReport");
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    showOverallTrendReport(e) {
        try {
            var details = {
                RegionName: "",
                DLName: "",
                ReportType: i18n.t('overall')
            };
            this.props.propsSetSelectedTrendReportDetails(details);
            this.props.history.push("/TrendReport");
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    ShowLandscapegraph(e) {
        try {
            var overallScore = [];
            var standardizationscore = [];
            var Regionval = [];
            var DeliveryLocationval = [];

            // if(!this.props.Assessmentdetails.Deal[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-19", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.Deal[0].label)
            // }
            if(this.state.GraphArray != null){
            
            this.state.GraphArray.forEach((i) => {
                return (overallScore.push(i.OverAll));
            });
            
            this.state.GraphArray.forEach((i) => {
                return (standardizationscore.push(i.Standardization));
            });
            
            this.state.GraphArray.forEach((i) => {
                return (Regionval.push(i.RegionName));
            });
            
            this.state.GraphArray.forEach((i) => {
                return (DeliveryLocationval.push(i.DeliveryLocationName));
            });
            this.setState({
                overallval: overallScore,
                standardizationval: standardizationscore,
                RegionArray: Regionval,
                DeliveryLocationArray: DeliveryLocationval,
                popUpLandscapeGraph: true,
            })
        }
        else{
            this.setState({
                overallval: overallScore,
                standardizationval: standardizationscore,
                RegionArray: Regionval,
                DeliveryLocationArray: DeliveryLocationval,
                popUpLandscapeGraph: true,
            })
        }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    closepopUpLandscapeGraph() {
        try {
            this.setState({
                popUpLandscapeGraph: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {
        var columnDefs = [];

        var RegionColDef = {
            headerName: i18n.t('regions'), field: 'RegionName', tooltipField: 'RegionName', suppressMovable: true, suppressResize: true, autoHeight: true, cellStyle: function (params) {
                if (params.node.data.RegionName.trim() == i18n.t('benchmark_CompareScreen')) {
                    let backgroundVal = "rgba(255, 183, 76, 1)"
                    return { background: backgroundVal, 'font-size': '16px', 'font-weight': 'bold' }
                }
            }
        }
        var DeliveryLocationColDef = { headerName: i18n.t('delivery_Location'), field: 'DeliveryLocationName', tooltipField: 'DeliveryLocationName', suppressMovable: true, suppressResize: true, autoHeight: true }
        var OverAllColDef = { headerName: i18n.t('overall'), field: 'OverAll', tooltipField: 'OverAll', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var PolicyColDef = { headerName: i18n.t('policy'), field: 'Policy', tooltipField: 'Policy', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var PracticeColDef = { headerName: i18n.t('practice'), field: 'Practice', tooltipField: 'Practice', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var PlatformColDef = { headerName: i18n.t('platform'), field: 'Platform', tooltipField: 'Platform', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var StandardizationColDef = { headerName: i18n.t('standardization'), field: 'Standardization', tooltipField: 'Standardization', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var SubmittedOnColDef = { headerName: i18n.t('SubmittedOn'), field: 'DateOfAssessment', tooltipField: 'DateOfAssessment', suppressMovable: true, suppressResize: true, autoHeight: true }
        var SubmittedByColDef = { headerName: i18n.t('submittedBy'), field: 'CreatedBy', tooltipField: 'CreatedBy', suppressMovable: true, suppressResize: true, autoHeight: true }
        var RegionalTrendColDef = {
            headerName: '', cellRenderer: function (params) {
                if (params.node.data.ProjectId != 0) {
                    return `<a style="cursor:pointer">${i18n.t('RegionalTrend')}</a>`
                }

            }, suppressMovable: true, suppressResize: true, autoHeight: true
        }

        if (this.state.comparisionGridData) {
            if (this.state.nonEmptyLeverNames.length > 0) {
                var LeverColDefs = [];
                this.state.nonEmptyLeverNames.forEach((item, index) => {
                    var LeverColDef = { headerName: item.LeverName, field: item.LeverName, tooltipField: item.LeverName, suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
                    return (LeverColDefs.push(LeverColDef));
                })
                columnDefs.push(RegionColDef, DeliveryLocationColDef, OverAllColDef)
                LeverColDefs.forEach((item, index) => { return(columnDefs.push(item)); })
                columnDefs.push(StandardizationColDef, SubmittedOnColDef, SubmittedByColDef, RegionalTrendColDef)
            }
            else {
                columnDefs = [
                    RegionColDef,
                    DeliveryLocationColDef,
                    OverAllColDef,
                    PolicyColDef,
                    PracticeColDef,
                    PlatformColDef,
                    StandardizationColDef,
                    SubmittedOnColDef,
                    SubmittedByColDef,
                    RegionalTrendColDef
                ];
            }
        } else {
            columnDefs = [
                RegionColDef,
                DeliveryLocationColDef,
                OverAllColDef,
                PolicyColDef,
                PracticeColDef,
                PlatformColDef,
                StandardizationColDef,
                SubmittedOnColDef,
                SubmittedByColDef,
                RegionalTrendColDef
            ];
        }

        var comparisionGridData = this.state.comparisionGridData;

        var ComparisonDataGrid = (
            <div style={{ height: '450px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_Comparision" id="grd_Comparision"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={comparisionGridData}
                    headerHeight="48"
                    gridAutoHeight={true}
                    onGridReady={this.gridReadyFun}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <Link className="menu2" to="/Home">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} />{i18n.t('bACKTOHOME')}</a>
                    </Link>
                    <Link className="menu2 pull-left" to="/ViewPreviousAssessmentHome">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} /> {i18n.t('BackToViewPreviousAssessment')}</a>
                    </Link>
                    <Link className="menu2 pull-left" to="/ComparativeReport">
                        <a type="button" className="btn btn-info btn-back">
                            {i18n.t('ShowComparativeReport')}</a>
                    </Link>
                    <a type="button" onClick={this.showOverallTrendReport.bind(this)} className="btn btn-info btn-back">
                        {i18n.t('TrendReport')}</a>
                    <a type="button" onClick={this.ShowLandscapegraph.bind(this)} className="btn btn-info btn-back">
                        {i18n.t('StandardizationScoreComparison')}</a>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <h4><div className="pull-left">{i18n.t('ComparisonofPMMScores')}</div>
                                <div className="row compareHeader pull-right">
                                    <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.Assessmentdetails.Deal[0].label}>{this.props.Assessmentdetails.Deal[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.Assessmentdetails.Region[0].label}> {this.props.Assessmentdetails.Region[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.Country.label}>{this.props.Assessmentdetails.Country.label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('delivery_Location') + ': ' + this.props.Assessmentdetails.DeliveryLocation.label}>{this.props.Assessmentdetails.DeliveryLocation.label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.Capability[0].label}>{this.props.Assessmentdetails.Capability[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.Tower[0].label}>{this.props.Assessmentdetails.Tower[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.ServiceComponent[0].label}>{this.props.Assessmentdetails.ServiceComponent[0].label} </span>
                                </div>
                            </h4>

                            <div className="AsmtData viewprevassmt">
                                {ComparisonDataGrid}
                            </div>
                            <div className="infoCompare">{i18n.t('BenchmarkValuesarerefreshedEvery')}</div>
                            {this.state.popUpLandscapeGraph && <LandscapeGraph show={this.state.popUpLandscapeGraph} closeCallback={this.closepopUpLandscapeGraph} asToValX={this.state.overallval} asToValY={this.state.standardizationval} XLabel={this.state.RegionArray} YLabel={this.state.DeliveryLocationArray} DealName={this.props.Assessmentdetails.Deal.label} ServiceComponentName={this.props.Assessmentdetails.ServiceComponent.label} />}
                        </div>
                    </div>
                </section>
            </section>
        );
    }
};

function mapStateToProps(state){
    return{
		dropDownsValue:state.dropDownsValue,
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

CompareRegionGrid=connect(mapStateToProps)(withRouter(CompareRegionGrid))

export default CompareRegionGrid;