/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as DOMPurify from 'dompurify';

class GDPRNotification extends React.Component {

    constructor(props) {

        super(props);
    }

    componentDidMount() {

    }

    render() {
        let cleanedNotificationMessage = DOMPurify.sanitize(this.props.NotificationMsg);
        return (
            <div>
                <div className="row gdpr">
                    <div dangerouslySetInnerHTML={{ __html: cleanedNotificationMessage }}>   
                    </div>
                    <div className="row alignCenter">
                        <button type="button" id="btn_close" className="btn btn-primary" onClick={this.props.CloseModal.bind(this)} >CLOSE</button>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(GDPRNotification);
