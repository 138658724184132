import React, { PropTypes } from 'react';
import MainRouter from './MainRouter';
import langKeys from './Utils/LangKeys'
import i18n from 'i18next';
class App extends React.Component {

    constructor(props) {
        super(props);
        try {
            this.unloadEvent = this.unloadEvent.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
   
    unloadEvent(e) {
        try {
            let confirmationMessage = "Leaving this page will result in loss of unsaved data. Are you sure you want to leave?";
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome etc.
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    componentWillUnmount() {
        try {
            window.removeEventListener("beforeunload", this.unloadEvent);
            i18n.off('languageChanged', (lng) => {
                console.log("Language change detected", lng, this);
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        return (
            <div>
                {this.props.userName != "" ? <MainRouter closeGroupSwitchPopup={this.props.closeGroupSwitchPopup} pageNames={this.props.pageNames} /> : null}
            </div>
        );
    }
}

export default App;