
// Validating links and html tags for input fields
export const inputValidator = (value) => {

    let check_Links_Tags = /(https?:\/\/[^\s]+|<[^>]*>)/igm
    let chec_SecialChar = /[`|^\\]/gm
    let allowInput = check_Links_Tags.test(value)
    let allowInputsc =  chec_SecialChar.test(value)
    if (allowInput||allowInputsc) {
        return true;
    } 
    else {
        return false;
    }
    
}