import i18next from 'i18next';
/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import { connect } from 'react-redux';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';

class UpdateOMIDAndFTEsConfirm extends React.Component {
    constructor(props) {
        super(props)
        this.state = { show: this.props.show };
        this.handleClose = this.handleClose.bind(this);
    };
    componentWillReceiveProps() {
        this.setState({ show: this.props.show })
    }
    handleClose() {
        this.setState({ show: false });
    };

    render() {
        
        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h2>{i18next.t('confirm')}</h2>
                </Modal.Header>
                <Modal.Body >
                     {(this.props.groupId == "1")?
                        <div>
                            <p>{i18n.t('thereisno')} <b>{i18n.t('omid')}</b> & <b>{i18n.t('FTEs')}</b> {i18n.t('presentforthisassessment')}.</p>
                            <p>{i18n.t('pleaseupdatethe')} <b>{i18n.t('omid')}</b> & <b>{i18n.t('FTEs')}</b> {i18n.t('byclickingontheupdatelinkfrom')} '<b>{i18n.t('omid')} & {i18n.t('FTEs')}</b>' {i18n.t('column')}</p>
                        </div>
                    :
                        <div>
                            <p>{i18n.t('thereisno')} <b>{i18n.t('mMSIDs')}</b> & <b>{i18n.t('FTEs')}</b> {i18n.t('presentforthisassessment')}.</p>
                            <p>{i18n.t('pleaseupdatethe')} <b>{i18n.t('mMSID')}</b> & <b>{i18n.t('FTEs')}</b> {i18n.t('byclickingontheupdatelinkfrom')} '<b>{i18n.t('mMSIDs')} & {i18n.t('FTEs')}</b>' {i18n.t('column')}</p>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btn_ok" bsStyle="success" bsSize="large" onClick={this.handleClose}>{i18n.t('ok')}</Button>
                </Modal.Footer>
            </Modal>
        </div>)
    }
}

function mapStateToProps(state){
    return{
       groupId:state.groupId,
        
    }
}
UpdateOMIDAndFTEsConfirm = connect(mapStateToProps)(withRouter(UpdateOMIDAndFTEsConfirm))
export default UpdateOMIDAndFTEsConfirm;