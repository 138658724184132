/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import { Table } from 'react-bootstrap'
import $, { param } from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import downloadGreenIcon from '../../images/icon-download-green.png';
import MetricImpactedDetail from './MetricImpactedDetail';
import ReferenceDocumentsDetail from './ReferenceDocumentsDetail';
import request from '../../services/Service';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { BaseURL } from '../../Constant';

class PMMAssessmentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickFilterText: null,
            policyAvg: 0, practiceAvg: 0, platformAvg: 0, ToBePMM: 0,
            PMMAssessmentGrdDta: [],
            popUpMetricImpacted: false,
            dynamicLeverData: [],
            SCID: this.props.Assessmentdetails.ServiceComponentID,
            popUpReferenceDocuments: false,
        };
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.DownloadExcel = this.DownloadExcel.bind(this);
        this.scatterChartData = this.scatterChartData.bind(this);
        this.closeMetricImpactedPopUp = this.closeMetricImpactedPopUp.bind(this);
        this.closeReferenceDocumentsPopUp = this.closeReferenceDocumentsPopUp.bind(this);
        this.showDownloadReferencePopup = this.showDownloadReferencePopup.bind(this);
        this.dynamicLeverDataCalculation = this.dynamicLeverDataCalculation.bind(this);
    }

    dynamicLeverDataCalculation = (response) => {
        try {
            var objThis = this;
            var leverWithValue = [];
            var sumForOverAllAsIs = 0;
            var sumForOverAllToBe = 0;
            let standardizationSum = 0, standardizationCount = 0;
            let standardizationToBeSum = 0, standardizationToBeCount = 0;
            var pmmData = response[0];
            var asisAvgcount=0;
            var tobeAvgcount=0;
            var distinctLevers = [...new Set(pmmData.map(x => x.LeverName))]

            for (var i = 0; i < distinctLevers.length; i++) {

                var data = pmmData.filter(x => x.LeverName == distinctLevers[i])
                var asisSum = 0;
                var tobeSum = 0;
                var asisCount = 0;
                var tobeCount = 0;

                data.map((eachdata) => {

                    asisSum += Number(eachdata.AsIsPMMScore === -1 ? 0 : eachdata.AsIsPMMScore), 
                    asisCount += Number(eachdata.AsIsPMMScore === -1 ? 0 : 1),
                    tobeSum += Number(eachdata.TobePMMScore === -1 ? 0 : eachdata.TobePMMScore), 
                    tobeCount += Number(eachdata.TobePMMScore === -1 ? 0 : 1)

                    if (eachdata.IsStandardization == true) {

                        standardizationSum += Number(eachdata.AsIsPMMScore === -1 || eachdata.AsIsPMMScore === "NA" ? 0 : eachdata.AsIsPMMScore), 
                        standardizationCount += Number(eachdata.AsIsPMMScore === -1 || eachdata.AsIsPMMScore === "NA" ? 0 : 1)
                        standardizationToBeSum += Number(eachdata.TobePMMScore === -1 || eachdata.TobePMMScore === "NA" ?  0 : eachdata.TobePMMScore), 
                        standardizationToBeCount +=  Number(eachdata.TobePMMScore === -1  || eachdata.TobePMMScore === "NA" ? 0 : 1)
                    }
                })
                leverWithValue.push(
                    {
                        'LeverName': distinctLevers[i],
                        'AsIsSum': asisSum,
                        'ToBeSum': tobeSum,
                        'AsIsAvg': (asisSum / asisCount).toFixed(2),
                        'ToBeAvg': (tobeSum / tobeCount).toFixed(2)
                    }
                )
                // var asisAvg = (asisSum === 0 && asisCount === 0) ? 0 : (asisSum / asisCount).toFixed(2);
                // var tobeAvg = (tobeSum === 0 && tobeCount === 0)? 0: (tobeSum / tobeCount).toFixed(2);
                
                // sumForOverAllAsIs = parseFloat(sumForOverAllAsIs) + parseFloat(asisAvg)
                // sumForOverAllToBe = parseFloat(sumForOverAllToBe) + parseFloat(tobeAvg)
                asisAvgcount += (asisCount === 0) ? 0 : (asisCount);
                tobeAvgcount += (tobeCount === 0) ? 0 : (tobeCount);
                sumForOverAllAsIs +=  parseFloat(asisSum)
                sumForOverAllToBe +=  parseFloat(tobeSum)

            }
            let standardAvg = (standardizationSum / standardizationCount).toFixed(2);
            let standardToBeAvg = (standardizationToBeSum / standardizationToBeCount).toFixed(2);

             // to find distinct levers which do not have AsIsAvg as Nan(do not have all values as NA)
            //  let distinctCount = leverWithValue.filter(function(item){
            //     return item.AsIsAvg !== "NaN"
            // }).length

          

            document.getElementById("AsIsPMMScore").textContent = Number(sumForOverAllAsIs / asisAvgcount).toFixed(2);
            document.getElementById("idStandardAsIsAvg").textContent = isNaN(standardAvg) == true ? i18n.t('na') : standardAvg;

            document.getElementById("ToBePMMScore").textContent = Number(sumForOverAllToBe / tobeAvgcount).toFixed(2);
            document.getElementById("idStandardToBeAvg").textContent = isNaN(standardToBeAvg) == true ? i18n.t('na') : standardToBeAvg;
            objThis.setState({ dynamicLeverData: leverWithValue })

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            window.addEventListener('resize', this.reSizeGrid, true);
            $("#loader-div").show();
           
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);


            let grdData = this.refs.grd_PMMAssessment.gridOptions.rowData;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.grd_PMMAssessment.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun=(eve)=> {
        try {

            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {                
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-4", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)  
                // }
                var SearchedString = "";
                let PMMDetailsData = {
                    PMMProcessID:PMMProcessID,
                    LeverID:0,
                    SearchedString:SearchedString,
                    UserName:this.props.enterpriseId,				
        			GroupID: this.props.groupId,
		        	LanguageID:this.props.languageId
                };
                let referenceDocumentData = {
                    SCID:this.state.SCID,
                    MaturityElement:"All",
                    GroupID: this.props.groupId,
			        LanguageID:this.props.languageId
                };
                eve.api.showLoadingOverlay();
                Promise.all([request("/PMM/ViewPMMDetails", 'POST', PMMDetailsData),
                request("/PMM/GetReferenceDocumentNames", 'POST', referenceDocumentData)])
                    .then((response) => {
                        if(response.length!==0){
                            eve.api.hideOverlay();
                
                        this.dynamicLeverDataCalculation(response);
                        response[0].map((eachdata, index) => {

                            if (response[1]) {
                                var emptyMaturity = response[1].filter((item) => item.MaturityElement.split('^').includes(eachdata.MaturityElement.trim()));
                                if (emptyMaturity !== undefined && emptyMaturity.length > 0) {
                                    eachdata["ContainsReferenceDocuments"] = true;
                                }
                                else {
                                    eachdata["ContainsReferenceDocuments"] = false;
                                }
                            }
                            else {
                                eachdata["ContainsReferenceDocuments"] = false;
                            }
                        })
                        this.setState({ PMMAssessmentGrdDta: response[0] });

                        $("#loader-div").hide();
                    }
                        else{
                            eve.api.showNoRowsOverlay(); 
                        }
                    }).catch((error) => {
                        $("#loader-div").hide();
                        console.log("Error Encountered");
                    });
                }
               
            

            if (PMMProcessDraftID > 0) {                
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName) 
                // }
                var SearchedString = "";
                let getPMMDetailsData ={
                    PMMProcessDraftID:this.props.Assessmentdetails.PMMProcessDraftID,
                    IndustryID:this.props.Assessmentdetails.IndustryId,
                    SCID:this.props.Assessmentdetails.ServiceComponentID,
                    LeverID:0,
                    SearchedString:SearchedString,
                    UserName:this.props.enterpriseId,				
			        GroupID: this.props.groupId,
			        LanguageID:this.props.languageId
                };
                let referenceDocumentNamesData = {
                    SCID:this.state.SCID,
                    MaturityElement:"All",
                    LanguageID:this.props.languageId
                };
                eve.api.showLoadingOverlay();
                Promise.all([request("/PMM/GetPMMDetails", 'POST', getPMMDetailsData),
                request("/PMM/GetReferenceDocumentNames", 'POST', referenceDocumentNamesData)])
                    .then((response) => {
                        if(response.length!==0){
                            eve.api.hideOverlay();
                            $("#loader-div").hide();
                        this.dynamicLeverDataCalculation(response);
                        response[0].map((eachdata, index) => {

                            if (response[1]) {
                                var emptyMaturity = response[1].filter((item) => item.MaturityElement.split('^').includes(eachdata.MaturityElement.trim()));
                                if (emptyMaturity !== undefined && emptyMaturity.length > 0) {
                                    eachdata["ContainsReferenceDocuments"] = true;
                                }
                                else {
                                    eachdata["ContainsReferenceDocuments"] = false;
                                }
                            }
                            else {
                                eachdata["ContainsReferenceDocuments"] = false;
                            }
                        })
                        this.setState({ PMMAssessmentGrdDta: response[0] });

                        
                    }
                    else{
                        eve.api.showNoRowsOverlay();
                    }
                    }).catch((error) => {
                        $("#loader-div").hide();
                        console.log("Error Encountered");
                    });
            }




            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(params) {
        if (params.colDef.field == "MetricImpacted") {
            this.setState({
                popUpMetricImpacted: true,
                selectedMetric: params.data
            })
        }
        if (params.colDef.field == "ReferenceDocuments") {
            if (params.data.ContainsReferenceDocuments == true) {
                this.setState({
                    popUpReferenceDocuments: true,
                    selectedMaturity: params.data.MaturityElement
                })
            }
        }

    }

    closeReferenceDocumentsPopUp() {
        this.setState({
            popUpReferenceDocuments: false
        });
        $("#loader-div").hide();
    }

    showDownloadReferencePopup(maturityType) {
        this.setState({
            popUpReferenceDocuments: true,
            selectedMaturity: maturityType
        })
    }

    CreateDownload(params) {
        try {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = '<span class="glyphicon glyphicon-download-alt"></span>';
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            if (params.data.ContainsReferenceDocuments == false) {
                eDiv.style = "color:lightgray";
            } else {
                eDiv.style = "color: deepskyblue";
            }
            return eDiv;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeMetricImpactedPopUp() {
        this.setState({
            popUpMetricImpacted: false
        });
        $("#loader-div").hide();
    }

    onQuickFilterText(event) {
        try {
            this.setState({ quickFilterText: event.target.value });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    async DownloadExcel() {
        try {
            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-D-25", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                let excelData = {
                    "PMMProcessID": this.props.Assessmentdetails.PMMProcessID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                let jsonexcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelTransaction", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonexcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Transaction.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
                
                // await request("/Common/DownloadtoExcelTransaction", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;

                //     })
                //     .catch(error => {
                //         console.log("Error Encountered");
                //     });
            }

            if (PMMProcessDraftID > 0) {
                var objThis = this;
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-D-21", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                let excelData = {
                    "PMMProcessDraftID": PMMProcessDraftID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                let jsonExcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelDraft", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonExcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Draft.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
            
                // await request("/Common/DownloadtoExcelDraft", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;
                //     })
                //     .catch(error => {
                //         window.addEventListener("beforeunload", objThis.unloadEvent);
                //         console.log("Error Encountered");
                //     });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    scatterChartData() {
        let graphStd = this.state.PMMAssessmentGrdDta.map((eachdata, index) => {
            return eachdata.IsStandardization
        });
        var yAsIsPMMScore, xAsIsStdScore, yToBePMMScore, xToBeStdScore;
        yAsIsPMMScore = document.getElementById("AsIsPMMScore").innerText;
        xAsIsStdScore = document.getElementById("idStandardAsIsAvg").innerText;
        yToBePMMScore = document.getElementById("ToBePMMScore").innerText;
        xToBeStdScore = document.getElementById("idStandardToBeAvg").innerText;
        let asTovalArrView = [xAsIsStdScore, yAsIsPMMScore, xToBeStdScore, yToBePMMScore];
        if (yAsIsPMMScore != undefined && xAsIsStdScore != undefined && yToBePMMScore != undefined && xToBeStdScore != undefined) {
            sessionStorage.setItem('asToValView', JSON.stringify(asTovalArrView));
            sessionStorage.setItem('asToValGraphView', JSON.stringify(graphStd));
            this.props.history.push({
                pathname: '/StoryBoardView',
                state: { asToVal: [xAsIsStdScore, yAsIsPMMScore, xToBeStdScore, yToBePMMScore, graphStd] }
            });
        }
    }

    // to display as-is score as NA in grid , if as-is score is -1
    asIsCellRenderer = (params) => {
       return params.data.AsIsPMMScore === -1 ?  'NA' :  params.data.AsIsPMMScore
    }

    // to display to-be score as NA in grid , if to-be score is -1
    toBeCellRenderer = (params) => {
        return params.data.TobePMMScore === -1 ? 'NA' : params.data.TobePMMScore
    }

    render() {
        var columnDefs = [];
        let { t } = this.props;
        columnDefs = [
            { headerName: i18n.t('lever'), field: 'LeverName', suppressMovable: true, suppressResize: true, tooltipField: 'LeverName', autoHeight: true, width: 80 },
            { headerName: i18n.t('kpa'), field: 'KPA', suppressMovable: true, suppressResize: true, tooltipField: 'KPA', autoHeight: true, width: 100, hide: false },
            { headerName: i18n.t('metricImpacted'), field: 'MetricImpacted', suppressMovable: true, suppressResize: true, tooltipField: 'MetricImpacted', autoHeight: true, width: 150 },
            {
                headerName: i18n.t('maturityElement'), field: 'MaturityElement',
                cellStyle: function (params) {

                    var background = "";
                    var textColor = "";
                    if (params.node.data.IsStandardization == 1) {

                        textColor = "#000000"
                    }
                    return {
                        background: background,
                        color: textColor
                    };
                }, suppressMovable: true, suppressResize: true, tooltipField: 'MaturityElement', autoHeight: true
            },

            { headerName: i18n.t('BaseScore1'), field: 'Base', suppressMovable: true, suppressResize: true, tooltipField: 'Base', autoHeight: true },
            { headerName: i18n.t('EnhancedScore2'), field: 'Enhanced', suppressMovable: true, suppressResize: true, tooltipField: 'Enhanced', autoHeight: true },
            { headerName: i18n.t('AboveAverageScore3'), field: 'AboveAverage', suppressMovable: true, suppressResize: true, tooltipField: 'AboveAverage', autoHeight: true },
            { headerName: i18n.t('HighPerformanceScore4'), field: 'HighPerformance', suppressMovable: true, suppressResize: true, tooltipField: 'HighPerformance', autoHeight: true },
            { headerName: i18n.t('CurrentMetricValue'), field: 'CurrentMetricValue', suppressMovable: true, suppressResize: true, tooltipField: 'CurrentMetricValue', autoHeight: true, width: 80, hide: true },
            { headerName: i18n.t('asIsPMMScore'), field: 'AsIsPMMScore', suppressMovable: true, suppressResize: true, tooltipField: 'AsIsPMMScore', autoHeight: true, width: 100 , cellRenderer: this.asIsCellRenderer}, //, cellRenderer: CreateDropdown
            { headerName: i18n.t('FutureStateMetricValue'), field: 'FutureStateMetricValue', suppressMovable: true, suppressResize: true, tooltipField: 'FutureStateMetricValue', autoHeight: true, width: 120, hide: true },
            { headerName: i18n.t('toBePMMScore'), field: 'TobePMMScore', suppressMovable: true, suppressResize: true, tooltipField: 'TobePMMScore', autoHeight: true, width: 100, cellRenderer: this.toBeCellRenderer}, //, cellRenderer: CreateDropdown
            { headerName: i18n.t('Remarks'), field: 'Remarks', suppressMovable: true, suppressResize: true, tooltipField: 'Remarks', autoHeight: true, width: 100 },
            {
                headerName: i18n.t('ReferenceDocuments'), field: 'ReferenceDocuments', wisth: 80, suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: this.CreateDownload,
                cellStyle: function () {
                    return {
                        textDecoration: "underline",
                        cursor: "pointer"
                    };
                }
                , tooltip: function (params) {
                    return i18n.t('viewReferenceDocs');
                }
            }
        ];



        var PMMAssessmentData = this.state.PMMAssessmentGrdDta;

        var PMMAssessmentDtaGrd = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_PMMAssessment" id="grd_PMMAssess"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={PMMAssessmentData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.gridReadyFun}
                    onCellClicked={this.onCellClicked.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <div>
                <section className="content">
                    <div className="innerpage-hd"><Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back"><img src={backArrowIcon} />{i18n.t('bACKTOHOME')}</a></Link>
                        <ul className="pagecounter"  >
                            <li><span title={i18n.t("vIEWPreviousassesssment")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page"><Link to="/ViewPreviousAssessmentHome"></Link></span></li>
                            <li><span title={i18n.t("BOIKeyBusinessMetrics")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><Link to="/BOIView"></Link></span></li>
                            <li><span title={i18n.t("lEADINGINDICATOR")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator"><Link to="/LIView"></Link></span></li>
                            <li><span title={i18n.t("pMMASSESSMENT")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">...</span></li>
                            <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                            <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                            <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                        </ul>
                    </div>
                    <section className="bodydata">
                        <div className="bodydata-full">
                            <h4><div className="pull-left">{i18n.t('PMMASSESSMENTVIEW')}</div>

                                <div className="pull-right">
                                    <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.Assessmentdetails.DealName}>{this.props.Assessmentdetails.DealName}
                                    </span> &nbsp;&nbsp;||<span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.Assessmentdetails.RegionName}>{this.props.Assessmentdetails.RegionName}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.CountryName}>{this.props.Assessmentdetails.CountryName}
                                    {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.Assessmentdetails.OGName}>{this.props.Assessmentdetails.OGName} */}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ':' + this.props.Assessmentdetails.IndustryName}>{this.props.Assessmentdetails.IndustryName}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.CapabilityName}>{this.props.Assessmentdetails.CapabilityName}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.TowerName}>{this.props.Assessmentdetails.TowerName}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.SCName}>{this.props.Assessmentdetails.SCName}</span>
                                </div>
                            </h4>

                            <div className="box-plain">
                                <h4>
                                    <span className="col-md-4 infoblockfilter ">
                                        <label className="col-md-3" id="lbl_searchPMMAssess" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                        <input type="text" id="txt_searchText" className="col-md-9 searchText" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />
                                    </span>
                                    <div className="col-md-6">
                                        <ul className="grid-legend ulStyle">
                                            <li className="liStyle">
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="btn-group btn-group-pmmasmt">
                                        <button type="button" id="btn_downloadSavedScreens" title={i18n.t("DownloadSavedScreensforthisAssessment")} className="btn btn-default btn-downld" onClick={this.DownloadExcel}>
                                            <img src={downloadIcon} />
                                        </button>
                                        <button type="button" id="btn_downloadRefDocs" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default btn-downld" onClick={this.showDownloadReferencePopup.bind(this, i18n.t('all'))} style={{ "marginLeft": "2px" }}>
                                            <img src={downloadGreenIcon} />
                                        </button>
                                    </div>
                                </h4>
                                <div className="box-plain pmm-asmt-tbl">
                                    {PMMAssessmentDtaGrd}

                                </div>

                                <div className="btn-group-md row">
                                    <div className="col-md-2 btn-group-md">
                                        <button type="button" id="btn_viewGap" className="btn btn-success" onClick={this.scatterChartData}>{i18n.t('ViewGapAssessment')}</button>
                                    </div>
                                    <div style={{ backgroundColor: "#fff" }}>
                                        <div className="col-md-5"> <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th></th>

                                                    {
                                                        this.state.dynamicLeverData.map((l) => {
                                                            return <th>{l.LeverName}</th>
                                                        })
                                                    }
                                                    <th>{i18n.t('overall')}</th>
                                                    <th>{i18n.t('standardization')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {i18n.t('As-Is')}
                                                    </td>

                                                    {
                                                        this.state.dynamicLeverData.map((l) => {
                                                            return <td>
                                                                <span className="greentext">{l.AsIsAvg === "NaN" ? "NA" : l.AsIsAvg}</span>
                                                            </td>
                                                        })
                                                    }
                                                    <td>
                                                        <span id="AsIsPMMScore" className="greentext first">{this.state.AsIsPMM}</span>
                                                    </td>
                                                    <td>
                                                        <span id="idStandardAsIsAvg" className="greentext first">{this.state.ToBePMM}</span>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                        </div>
                                        <div className="col-md-5">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th></th>

                                                        {
                                                            this.state.dynamicLeverData.map((l) => {
                                                                return <th>{l.LeverName}</th>
                                                            })
                                                        }
                                                        <th>{i18n.t('overall')}</th>
                                                        <th>{i18n.t('standardization')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{i18n.t('To-Be')}</td>
                                                        {
                                                            this.state.dynamicLeverData.map((l) => {
                                                                return <td>
                                                                    <span className="greentext">{l.ToBeAvg === "NaN" ? "NA" : l.ToBeAvg}</span>
                                                                </td>
                                                            })
                                                        }
                                                        <td>
                                                            <span id="ToBePMMScore" className="greentext first">{this.state.ToBePMM}</span>
                                                        </td>
                                                        <td>
                                                            <span id="idStandardToBeAvg" className="greentext first">{this.state.ToBePMM}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                                {this.state.popUpMetricImpacted && <MetricImpactedDetail show={this.state.popUpMetricImpacted} closeCallback={this.closeMetricImpactedPopUp} selectedMetric={this.state.selectedMetric} />}
                                {this.state.popUpReferenceDocuments && <ReferenceDocumentsDetail show={this.state.popUpReferenceDocuments} closeCallback={this.closeReferenceDocumentsPopUp} selectedMaturity={this.state.selectedMaturity} SCID={this.state.SCID} />}
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        );
    }
};

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        groupName: state.groupName
      }
}

PMMAssessmentView = connect(mapStateToProps)(withRouter(withTranslation()(PMMAssessmentView)));
export default PMMAssessmentView;