/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import EditConfirmation from '../../Master/PMMConfiguration/EditConfirmation';
import NavigationConfirmation from '../../PMM/NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import editIcon from '../../images/icon-edit-active.png';
import AccessTransferPopup from './AccessTransferPopup';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import {connect} from 'react-redux';
class DealMapping extends Component {

    constructor(props) {
        super(props);
        try {
            this.onCapabilityChange = this.onCapabilityChange.bind(this);
            this.onTowerChange = this.onTowerChange.bind(this);

            this.saveEditDeal = this.saveEditDeal.bind(this);
            this.onDealChange = this.onDealChange.bind(this);
            this.createEditCell = this.createEditCell.bind(this);
            this.loadDropDownValues = this.loadDropDownValues.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.loadDeal = this.loadDeal.bind(this);
            this.loadUsers = this.loadUsers.bind(this);
            this.onInputChange = this.onInputChange.bind(this);
            this.onUserChange = this.onUserChange.bind(this);
            this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
            this.onEditCell = this.onEditCell.bind(this);
            this.clearInputs = this.clearInputs.bind(this);
            this.formatActive = this.formatActive.bind(this);
            this.populateValues = this.populateValues.bind(this);
            this.onNavigationClick = this.onNavigationClick.bind(this);
            this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
            this.closeEditPopUp = this.closeEditPopUp.bind(this);
            this.passValue = this.passValue.bind(this);
            this.onPrimaryFilterChange = this.onPrimaryFilterChange.bind(this);
            this.onOGChange = this.onOGChange.bind(this);
            this.onSubmittedChk = this.onSubmittedChk.bind(this);
            this.onSavedChk = this.onSavedChk.bind(this);
            this.onTakeAssessmentChk = this.onTakeAssessmentChk.bind(this);
            this.onReportChk = this.onReportChk.bind(this);
            this.accessTransfer = this.accessTransfer.bind(this);
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);

            this.state = {
                IsActive: true,
                dealSelected: [],
                capabilitySelected: [],
                towerSelected: [],
                userSelected: [],
                userList: [],
                IsEditActive: false,
                isDealSelected: false,
                searchDeal: "",
                dealGridData: [],
                capabilityOptions: [],
                towerOptions: [],
                dealOptions: [],
                dealList: [],
                isEditPopUp: false,
                isUpdateExisting: false,
                isUnSaved: false,
                popUpDisplay: false,
                toURL: '',
                navigationPage: '',
                lstDlvryLoc: [],
                DlvryLocValue: [],
                RoleName: "",
                RoleID: "",
                pageName: [],
                primaryFilterOptions: [],
                primaryFilterValue: [],
                OGOptions: [],
                OGValue: [],
                isSubmitted: true,
                isSaved: false,
                isTakeAssessment: false,
                isSubmittedEnable: false,
                isSavedEnable: false,
                isTakeAssessmentEnable: false,
                viewStatus: 1,
                UserDealmappingId: 0,
                isReport: false,
                popUpAccessTransfer: false,
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillMount() {
        try {
            this.setState({ pageNames:this.props.pageName
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-MC-13", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            window.addEventListener('resize', this.reSizeGrid, true);
            this.loadUsers();

            this.loadDropDownValues();
   
            let dealData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "DealID": 0,
                "PrimaryFilter": 1,
                "OGID": 0,
                "DLID": 0,
            }
            request("/Common/GetDealForUserMapping", 'POST', dealData)
                .then(response => {

                    this.setState({
                        dealList: response
                    });

                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {

        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {

        try {
            this.refs.deal_user_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    //Fetches Users
    loadUsers() {

        try {
            $("#loader-div").show();
            let userData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "UserID": 0,
                "RoleID": 0,
            }
            request("/Common/GetUserDetails", 'POST', userData)
                .then(response => {

                    $("#loader-div").hide();
                    let fullData = response;
                    let userArr = [];

                    fullData.map((user) => {
                        if (user.IsActive) {
                            let userObj = {}
                            userObj = {
                                label: user.EnterpriseID,
                                value: user.UserID,
                                roleId: user.RoleId,
                                roleName: user.RoleName
                            }
                            userArr.push(userObj);

                        }
                    })

                    this.setState({
                        userList: userArr,
                        lstDlvryLoc: [],
                        DlvryLocValue: []
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    //Fetch Deals
    loadDeal(userName) {

        try {
            $("#loader-div").show();

            let userData = {
                "UserName": userName,
              
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
            }
            request("/MasterScreens/GetUSerDealDetails", 'POST', userData)
            
                .then((response) => {
                    $("#loader-div").hide();

                    if (response == "Data not there") {
                        this.setState({
                            dealGridData: []
                        });
                    }
                    else {
                        this.setState({
                            dealGridData: response
                        });
                    }
                }).catch((error) => {
                    $("#loader-div").hide();
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    loadDropDownValues() {

        try {
            $("#loader-div").show();
            let dealData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "PrimaryFilterId": 0,
            }
            request("/Common/GetPrimaryFilterUserMapping", 'POST', dealData)
                .then(response => {

                    this.setState({ primaryFilterOptions: response });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCapabilityChange(value) {
        try {

            if (this.state.primaryFilterValue != "" && value.length > 0) {
                if (this.state.primaryFilterValue[0].label == i18n.t('capability') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all')) {
                    alert(i18n.t('cannotSelectPrimaryFilterCapability'));
                    return false;
                }
                else if (this.state.primaryFilterValue[0].label == i18n.t('capability') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) != i18n.t('all')) {
                    this.setState({ capabilitySelected: [] });
                    let selectedValue = value.filter((item, index) => {
                        return item.label == value[value.length - 1].label;
                    })
                    this.setState({

                        capabilitySelected: selectedValue,
                        isUnSaved: true
                    });

                }
                else {
                    var selValue = [];
                    selValue = value.filter((item, index) => {
                        return item.label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all');
                    });

                    if (selValue.length == 0) {
                        this.setState({
                            capabilitySelected: value,

                            isUnSaved: true
                        });
                    }
                    else {
                        this.setState({
                            capabilitySelected: selValue,

                            isUnSaved: true
                        });
                    }

                }

            }
            else {
                this.setState({ capabilitySelected: [] });
            }

            let capIds;
            if (value.length > 1) {

                value.map((capId, index) => {

                    if (index == 0) {
                        capIds = capId.value
                    } else {
                        capIds = capIds + ',' + capId.value;
                    }
                })
            } else {
                capIds = value[0].value;
            }


            let towerData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerId": 0,
                "CapabilityID": capIds,
            }
            request("/Common/GetTowerForUserMapping", 'POST', towerData)
                .then(response => {

                    this.setState({
                        towerOptions: response
                    });

                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    getTower(capabilityIds, towersSel) {
        try {
            $("#loader-div").show();            
            let towerBasedData = {
                CapabilityIds:capabilityIds,
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
            }
            request("/MasterScreens/GetTowerBasedOnCapabilityIds", 'POST', towerBasedData)
                .then(response => {
                    $("#loader-div").hide();
                    let towers = [];

                    if (towersSel) {
                        towersSel.map((towerId, index) => {
                            response.map(tower => {
                                if (tower.TowerId == towerId) {
                                    towers.push(tower);
                                }
                            });
                        });
                    }

                    if (towersSel[0] == "0") {
                        towers.push({ "label": i18n.t('all'), "value": "0" });
                    }
                    this.setState({
                        towerOptions: response,
                        towerSelected: towers
                    })
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onTowerChange(value) {

        try {
            if (this.state.primaryFilterValue != "" && value.length > 0) {
                if (this.state.primaryFilterValue[0].label == i18n.t('tower') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all')) {
                    alert(i18n.t('cannotSelectPrimatyFilterTower'));
                    return false;
                }
                else if (this.state.primaryFilterValue[0].label == i18n.t('tower') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) != i18n.t('all')) {
                    if(this.state.IsEditActive != true){
                    this.setState({ dealSelected: [] });
                    }

                    let selectedValue = value.filter((item, index) => {
                        return item.label == value[value.length - 1].label;
                    })
                    this.setState({

                        capabilitySelected: [],
                        towerSelected: selectedValue,
                        isUnSaved: true
                    });

                }
                else {
                    var selValue = [];
                    selValue = value.filter((item, index) => {
                        return item.label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all');
                    });

                    if (selValue.length == 0) {
                        this.setState({
                            towerSelected: value,

                            isUnSaved: true
                        });
                    }
                    else {
                        this.setState({
                            towerSelected: selValue,

                            isUnSaved: true
                        });
                    }

                }
            }
            else {
                this.setState({ towerSelected: [] });
            }

            if (this.state.primaryFilterValue[0].value == 5) {

                let capData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "TowerId": value[value.length - 1].value,
                    "CapabilityID": 0,
                }
                request("/Common/GetCapabilityForUserMapping", 'POST', capData)
                    .then(response => {

                        this.setState({
                            capabilityOptions: response,

                        });

                        $("#loader-div").hide();

                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onUserChange(value) {

        try {
            this.setState({ RoleName: "" });
            if(value.length>0)
            {
            if (value != undefined) {
                if (value[0].roleId != 1 && value[0].roleId != 2) {
                    this.loadDeal(value[0].label);
                    this.setState({
                        userSelected: value,
                        isUnSaved: true,
                        dealSelected: [],
                        capabilitySelected: [],
                        towerSelected: [],
                        RoleName: value[0].roleName,
                        RoleID: value[0].roleId,
                        OGValue: [],
                        DlvryLocValue: [],
                        primaryFilterValue: []
                    });

                    if (value[0].roleId == 3 || value[0].roleId == 4) {
                        this.setState({
                            isSavedEnable: false,
                            isTakeAssessmentEnable: false
                        });
                    }
                    else if (value[0].roleId == 5) {
                        this.setState({
                            isSavedEnable: false,
                            isTakeAssessmentEnable: true,
                            isTakeAssessment: false
                        });
                    }
                } else {

                    alert(i18n.t('userRoleIsAdminSuperAdmin'));
                }
            }
            else {
                

            }

        }

        else{
            this.setState({
                userSelected:[],
                dealGridData:[] })
        }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onDealChange(value) {

        try {
            if (this.state.primaryFilterValue != "" && value.length > 0) {
                if (this.state.primaryFilterValue[0].label == i18n.t('deal') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all')) {
                    alert(i18n.t('cannotSelectPrimaryFilterDeal'));
                    return false;
                }
                else if (this.state.primaryFilterValue[0].label == i18n.t('deal') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) != i18n.t('all')) {
                    this.setState({ dealSelected: [] });
                    let selectedValue = value.filter((item, index) => {
                        return item.label == value[value.length - 1].label;
                    })
                    this.setState({
                        dealSelected: selectedValue,
                        DlvryLocValue: [],
                        OGValue: [],
                        isUnSaved: true
                    });

                }
                else if (this.state.primaryFilterValue[0].label == i18n.t('delivery_location') || this.state.primaryFilterValue[0].label == i18n.t('oG') || this.state.primaryFilterValue[0].label == i18n.t('capability') || this.state.primaryFilterValue[0].label == i18n.t('tower')) {
                    this.setState({ dealSelected: [] });

                    var selValue = [];
                    selValue = value.filter((item, index) => {
                        return item.label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all');
                    });

                    if (selValue.length == 0) {
                        this.setState({
                            dealSelected: value,

                            isUnSaved: true
                        });
                    }
                    else {
                        this.setState({
                            dealSelected: selValue,

                            isUnSaved: true
                        });
                    }
                }
                else {
                    this.setState({ dealSelected: value });
                }
            }
            else {
                this.setState({ dealSelected: [] });
            }

            if (this.state.primaryFilterValue[0].label != i18n.t('oG') && value.length == 0) {
                this.setState({ OGValue: [], OGOptions: [] });
            }

            let paramVal = "";
            let paramLabel = "";
            if (this.state.primaryFilterValue[0].value == 1) {
                paramVal = value[value.length - 1].value;
                paramLabel = value[value.length - 1].label;
            }
            else {
                let isALLExist = value.filter((item, index) => {
                    return item.value == 0;
                })
                if (isALLExist.length > 0) {
                    paramVal = "0";
                    paramLabel = "All";
                }
                else {
                    value.map((val, index) => {
                        if (index == 0) {
                            paramVal = val.value;
                            paramLabel = val.label;
                        } else {
                            paramVal = paramVal + ',' + val.value;
                            paramLabel = paramLabel + ',' + val.label;
                        }
                    });
                }
            }

            if (paramVal != "") {
                let ogData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "OGID": 0,
                    "PrimaryFilter": this.state.primaryFilterValue[0].value,
                    "DealID": paramVal,
                }
                request("/Common/GetOGForUserMapping", 'POST', ogData)
                    .then(response => {

                        this.setState({

                            OGOptions: response,

                        });

                        $("#loader-div").hide();

                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });

                let dlData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "DeliveryLocationID": 0,
                    "PrimaryFilter": this.state.primaryFilterValue[0].value,
                    "DealID": paramVal,
                    "OGID": 0,
                }
                request("/Common/GetDLForUserMapping", 'POST', dlData)
                    .then(response => {

                        this.setState({

                            lstDlvryLoc: response,

                        });

                        $("#loader-div").hide();

                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onDlvryLocChange(value) {

        try {
            if (this.state.primaryFilterValue != "" && value.length > 0) {
                if (this.state.primaryFilterValue[0].label == i18n.t('delivery_Location') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all')) {
                    alert(i18n.t('cannotSelectPrimaryFilterDeliveryLocation'));
                    return false;
                }
                else if (this.state.primaryFilterValue[0].label == i18n.t('delivery_Location') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) != i18n.t('all')) {
                    this.setState({ DlvryLocValue: [] });
                    let selectedValue = value.filter((item, index) => {
                        return item.label == value[value.length - 1].label;
                    })
                    this.setState({
                        dealSelected: [],
                        DlvryLocValue: selectedValue,
                        OGValue: [],
                        isUnSaved: true
                    });

                }
                else {
                    var selValue = [];
                    selValue = value.filter((item, index) => {
                        return item.label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all');
                    });

                    if (selValue.length == 0) {
                        this.setState({ DlvryLocValue: value });
                    }
                    else {
                        this.setState({ DlvryLocValue: selValue });
                    }
                }
            }
            else {
                this.setState({ DlvryLocValue: [] });
            }

            let paramVal = "";
            let paramLabel = "";
            value.map((val, index) => {
                if (index == 0) {
                    paramVal = val.value;
                    paramLabel = val.label;
                } else {
                    paramVal = paramVal + ',' + val.value;
                    paramLabel = paramLabel + ',' + val.label;
                }
            });
            if (this.state.primaryFilterValue[0].value == 3 && paramVal != "") {
                let dealData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "DealID": 0,
                    "PrimaryFilter": this.state.primaryFilterValue[0].value,
                    "OGID": 0,
                    "DLID": paramVal,
                }
                request("/Common/GetDealForUserMapping", 'POST', dealData)
                    .then(response => {

                        this.setState({

                            dealOptions: response

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onPrimaryFilterChange(val) {

        try {
            if(val.length>0 && this.state.userSelected!=""){
             if (this.state.dealGridData != null) {
                if (this.state.dealGridData.length > 0) {
                    var isPrimaryFilterExist = [];
                    isPrimaryFilterExist = this.state.dealGridData.filter((item, index) => {
                        return item.PrimaryFilter != val[0].value && item.IsActive == true;
                    });
                    if (isPrimaryFilterExist.length > 0) {
                        this.setState({
                            primaryFilterValue: []
                        });
                        if (!confirm(i18n.t('wantToChangePrimaryFilter') + "\n" + i18n.t('deactivateExistingPrimaryFilter'))) {
                            return false;
                        }

                    }
                }
            }

            this.setState({
                primaryFilterValue: val,
                dealSelected: [],
                OGValue: [],
                DlvryLocValue: [],
                capabilitySelected: [],
                towerSelected: []
            });

            let urls = [];

            //Deal
            if (val[0].value == 1) {
                let dealData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "DealID": 0,
                    "PrimaryFilter": 1,
                    "OGID": 0,
                    "DLID": 0,
                }
                request("/Common/GetDealForUserMapping", 'POST', dealData)
                    .then(response => {

                        this.setState({
                            towerOptions: [],
                            lstDlvryLoc: [],
                            OGOptions: [],
                            dealOptions: response

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
                let capData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "TowerId": 0,
                    "CapabilityID": 0,
                }
                request("/Common/GetCapabilityForUserMapping", 'POST', capData)
                    .then(response => {

                        this.setState({
                            capabilityOptions: response,
                            towerOptions: [],
                            lstDlvryLoc: [],
                            OGOptions: []

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            // OG
            else if (val[0].value == 2) {
                let ogData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "OGID": 0,
                    "PrimaryFilter": 2,
                    "DealID": 0,
                }
                request("/Common/GetOGForUserMapping", 'POST', ogData)
                    .then(response => {
                        this.setState({
                            towerOptions: [],
                            lstDlvryLoc: [],
                            OGOptions: response

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
                let capData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "TowerId": 0,
                    "CapabilityID": 0,
                }
                request("/Common/GetCapabilityForUserMapping", 'POST', capData)
                    .then(response => {

                        this.setState({
                            capabilityOptions: response,
                            towerOptions: [],
                            lstDlvryLoc: []

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });

            }
            // Delivery Location
            else if (val[0].value == 3) {
                let dlData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "DeliveryLocationID": 0,
                    "PrimaryFilter": 3,
                    "DealID": 0,
                    "OGID": 0,
                }
                request("/Common/GetDLForUserMapping", 'POST', dlData)
                    .then(response => {

                        this.setState({
                            towerOptions: [],
                            lstDlvryLoc: response,
                            OGOptions: []

                        });

                        if (this.state.IsEditActive != true) {
                            this.setState({ dealOptions: [] });
                        }

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
                let capData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "TowerId": 0,
                    "CapabilityID": 0,
                }
                request("/Common/GetCapabilityForUserMapping", 'POST', capData)
                    .then(response => {

                        this.setState({
                            capabilityOptions: response,
                            towerOptions: [],
                            OGOptions: []

                        });

                        if (this.state.IsEditActive != true) {
                            this.setState({ dealOptions: [] });
                        }

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            // Capability
            else if (val[0].value == 4) {
                let dealData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "DealID": 0,
                    "PrimaryFilter": 1,
                    "OGID": 0,
                    "DLID": 0,
                }
                request("/Common/GetDealForUserMapping", 'POST', dealData)
                    .then(response => {

                        this.setState({
                            towerOptions: [],
                            lstDlvryLoc: [],
                            OGOptions: [],
                            dealOptions: response

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
                let capData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "TowerId": 0,
                    "CapabilityID": 0,
                }
                request("/Common/GetCapabilityForUserMapping", 'POST', capData)
                    .then(response => {

                        this.setState({
                            capabilityOptions: response,
                            towerOptions: [],
                            lstDlvryLoc: [],
                            OGOptions: [],

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            // Tower
            else if (val[0].value == 5) {
                let dealData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "DealID": 0,
                    "PrimaryFilter": 1,
                    "OGID": 0,
                    "DLID": 0,
                }
                request("/Common/GetDealForUserMapping", 'POST', dealData)
                    .then(response => {

                        this.setState({
                            capabilityOptions: [],
                            lstDlvryLoc: [],
                            OGOptions: [],
                            dealOptions: response

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
                let towerData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "TowerId": 0,
                    "CapabilityID": 0,
                }
                request("/Common/GetTowerForUserMapping", 'POST', towerData)
                    .then(response => {

                        this.setState({
                            capabilityOptions: [],
                            towerOptions: response,
                            lstDlvryLoc: [],
                            OGOptions: []

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
                this.setState({
                    dealOptions: [],
                    capabilityOptions: [],
                    towerOptions: [],
                    lstDlvryLoc: [],
                    OGOptions: []
                });
            }       
        }
           else{
           this.setState({
            primaryFilterValue:[]
           }) 
           }
      }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onOGChange(value) {

        try {
            if (this.state.primaryFilterValue != "" && value.length > 0) {
                if (this.state.primaryFilterValue[0].label == i18n.t('oG') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all')) {
                    alert(i18n.t('cannotSelectPrimaryFilterOG'));
                    return false;
                }
                else if (this.state.primaryFilterValue[0].label == i18n.t('oG') && value[value.length - 1].label.replace(i18n.t('all'), i18n.t('all')) != i18n.t('all')) {
                    this.setState({ OGValue: [] });
                    let selectedValue = value.filter((item, index) => {
                        return item.label == value[value.length - 1].label;
                    })
                    this.setState({
                        dealSelected: [],
                        DlvryLocValue: [],
                        OGValue: selectedValue,
                        isUnSaved: true
                    });

                }
                else {
                    var selValue = [];
                    selValue = value.filter((item, index) => {
                        return item.label.replace(i18n.t('all'), i18n.t('all')) == i18n.t('all');
                    });

                    if (selValue.length == 0) {
                        this.setState({ OGValue: value });
                    }
                    else {
                        this.setState({ OGValue: selValue });
                    }

                    let paramDealVal = "";
                    let paramDealLabel = "";
                    this.state.dealSelected.map((val, index) => {
                        if (index == 0) {
                            paramDealVal = val[0].value;
                            paramDealLabel = val[0].label;
                        } else {
                            paramDealVal = paramDealVal + ',' + val[0].value;
                            paramDealLabel = paramDealLabel + ',' + val[0].label;
                        }
                    });

                    let paramOGVal = "";
                    let paramOGLabel = "";
                    value.map((val, index) => {
                        if (index == 0) {
                            paramOGVal = val.value;
                            paramOGLabel = val.label;
                        } else {
                            paramOGVal = paramOGVal + ',' + val.value;
                            paramOGLabel = paramOGLabel + ',' + val.label;
                        }
                    });
                    let dlData = {
                        "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                        "DeliveryLocationID": 0,
                        "PrimaryFilter": this.state.primaryFilterValue[0].value,
                        "DealID": paramDealVal,
                        "OGID": paramOGVal,
                    }
                    request("/Common/GetDLForUserMapping", 'POST', dlData)
                        .then(response => {

                            this.setState({

                                lstDlvryLoc: response,


                            });

                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                            $("#loader-div").hide();
                        });
                }
            }
            else {
                this.setState({ OGValue: [] });
            }

            var paramOGVal = "";
            var paramOGLabel = "";
            value.map((val, index) => {
                if (index == 0) {
                    paramOGVal = val.value;
                    paramOGLabel = val.label;
                } else {
                    paramOGVal = paramOGVal + ',' + val.value;
                    paramOGLabel = paramOGLabel + ',' + val.label;
                }
            });

            if (this.state.primaryFilterValue[0].value == 2) {
                let dealData = {
                    "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                    "DealID": 0,
                    "PrimaryFilter": this.state.primaryFilterValue[0].value,
                    "OGID": value[value.length - 1].value,
                    "DLID": 0,
                }
                request("/Common/GetDealForUserMapping", 'POST', dealData)
                    .then(response => {

                        this.setState({

                            dealOptions: response

                        });

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }


        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveEditDeal(eve) {

        try {
            let userName = this.state.userSelected;

            let deal, dealNames;
            let OG, OGNames;
            let capability, capNames;
            let tower, towerNames;
            let deleveryLoc, deleveryLocNames;
            let operation = this.state.IsEditActive ? 2 : 1;
            let ogArr = [];
            let towerArr = [];
            let delvLocArr = [];
            let capNotIn = [];
            let ogNotIn = [];

            if (this.state.dealSelected == undefined || this.state.dealSelected.length <= 0 || this.state.capabilitySelected == undefined || this.state.capabilitySelected.length <= 0 || this.state.towerSelected == undefined || this.state.towerSelected.length <= 0 || this.state.OGValue == undefined || this.state.OGValue.length <= 0 || this.state.DlvryLocValue == undefined || this.state.DlvryLocValue.length <= 0) {
                alert(i18n.t('fillToSaveUserAccess'));
            }

            else {
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && this.state.dealSelected.length > 0) {
                    this.setState({
                        isEditPopUp: true
                    })
                    return;
                }
                this.state.dealSelected.map((dealVal, index) => {
                    if (dealVal.label.replace(i18n.t('all'), i18n.t('all')) != i18n.t('all')) {
                        let ogValFromDealOption = this.state.dealOptions.filter((item, index) => {
                            return item.label == dealVal.label;
                        })

                        ogArr.push(ogValFromDealOption[0].OGID);

                    }

                    if (index == 0) {
                        deal = dealVal.value;
                        dealNames = dealVal.label;
                    } else {
                        deal = deal + ',' + dealVal.value;
                        dealNames = dealNames + ',' + dealVal.label;
                    }
                });

                this.state.OGValue.map((OGVal, index) => {
                    if (ogArr.length != 0 && ogArr.indexOf(OGVal.value) == -1) {
                        if(OGVal.value != 0){
                            ogNotIn.push(OGVal.label);
                        }
                    }

                    if (index == 0) {
                        OG = OGVal.value;
                        OGNames = OGVal.label;
                    } else {
                        OG = OG + ',' + OGVal.value;
                        OGNames = OGNames + ',' + OGVal.label;
                    }
                });

                this.state.towerSelected.map((towerVal, index) => {
                    if (towerVal.label.replace(i18n.t('all'), i18n.t('all')) != i18n.t('all')) {
                        if (towerVal.CapabilityID == undefined) {
                            towerArr.push(towerVal.CapabilityId);
                        }
                        else {
                            towerArr.push(towerVal.CapabilityID);
                        }

                    }

                    if (index == 0) {
                        tower = towerVal.value;
                        towerNames = towerVal.label;
                    } else {
                        tower = tower + ',' + towerVal.value;
                        towerNames = towerNames + ',' + towerVal.label;
                    }
                });

                this.state.capabilitySelected.map((capVal, index) => {
                    if (towerArr.length != 0 && towerArr.indexOf(capVal.value) == -1) {
                        capNotIn.push(capVal.label);
                    }
                    if (index == 0) {
                        capability = capVal.value;
                        capNames = capVal.label;
                    } else {
                        capability = capability + ',' + capVal.value;
                        capNames = capNames + ',' + capVal.label;
                    }
                });

                var isAllDLSelected = true;

                if (this.state.DlvryLocValue != []) {
                    this.state.DlvryLocValue.map((delvLocVal, index) => {
                        delvLocArr.push(delvLocVal.value);

                        if (index == 0) {
                            deleveryLoc = delvLocVal.value;
                            deleveryLocNames = delvLocVal.label;
                        } else {
                            deleveryLoc = deleveryLoc + ',' + delvLocVal.value;
                            deleveryLocNames = deleveryLocNames + ',' + delvLocVal.label;
                        }
                    });
                }

                if (this.state.dealSelected.length > 0) {
                    if (this.state.dealSelected[0].value != 0) {
                        this.state.dealSelected.map((dealVal, index) => {
                            if (isAllDLSelected == true) {
                                let dlList = [];
                                dlList = dealVal.DeliveryLocationIds.split(",");
                                let selDL = deleveryLoc.toString().split(",");
                                if (deleveryLoc.toString() != "0") {
                                    let found = dlList.some(r => selDL.includes(r));
                                    if (found == false) {
                                        isAllDLSelected = false;
                                    }
                                }
                            }
                        });
                    }
                }

                let selctOG = OG.toString().split(",");
                var isFoundOG = true;
                if (OG.toString() != "0") {
                    for (var i = 0; i < ogArr.length; i++) {
                        isFoundOG = selctOG.includes(ogArr[i].toString());
                        if (isFoundOG == false) {
                            break;
                        }
                    }
                }

                if (isFoundOG == false) {
                    alert(i18n.t('notSelectedOGForAllDeals'));
                    return;
                }

                if (isAllDLSelected == false) {
                    alert(i18n.t('notSelectedDeliveryLocationForAll'));
                    return;
                }

                if (capNotIn.length != 0) {
                    let capNames = capNotIn.map(cap => cap + " ");

                    alert(i18n.t('notSelectedTowerForAll'));
                    return;
                }


                let dealObj = {
                    "UserName": userName[0].label,
                    "PrimaryFilter": this.state.primaryFilterValue[0].value,
                    "DealId": deal.toString().split(/,\s*/).sort(function (a, b) { return parseFloat(a) - parseFloat(b) }).join(",").toString(),
                    "DealName": dealNames.toString(),
                    "OGIds": OG.toString().split(/,\s*/).sort(function (a, b) { return parseFloat(a) - parseFloat(b) }).join(",").toString(),
                    "OGName": OGNames.toString(),
                    "TowerIds": tower.toString().split(/,\s*/).sort(function (a, b) { return parseFloat(a) - parseFloat(b) }).join(",").toString(),
                    "TowerName": towerNames.toString(),
                    "CapabilityId": capability.toString().split(/,\s*/).sort(function (a, b) { return parseFloat(a) - parseFloat(b) }).join(",").toString(),
                    "CapabilityName": capNames.toString(),
                    "DeliveryLocationIds": deleveryLoc == undefined ? "" : deleveryLoc.toString().split(/,\s*/).sort(function (a, b) { return parseFloat(a) - parseFloat(b) }).join(",").toString(),
                    "DeliveryLocationName": "",
                    "Operation": operation,
                    "IsActive": this.state.IsActive,
                    "PermissionLevel": this.state.viewStatus,
                    "UserDealmappingId": this.state.UserDealmappingId,
                    "IsReport": this.state.isReport,
                    "UserId":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId
                }
                this.dealUpdateCall(dealObj);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    //Calls the API to save the user deal mapping
    dealUpdateCall(dealObj) {
        try {
            let dealData = dealObj;

            $("#loader-div").show();
            request("/MasterScreens/UpSertUserDealMapping", 'POST', dealData)
                .then(res => {

                    $("#loader-div").hide();
                    if (res.Outputcode == -1) {
                        alert(res.outputmessage)
                    } else {
                        this.clearInputs();
                        this.loadDeal(this.state.userSelected.label);
                        alert(res.outputmessage);
                    }
                    this.setState({ UserDealmappingId: 0 });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                    alert(error.statusText)
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    createEditCell() {
        try {
            let editCell = document.createElement("div");
            editCell.innerHTML = `<span><img src=${editIcon}></span>`;
            editCell.className = "col-md-12 editIcon";
            editCell.width = "100%";
            return editCell;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onEditCell(eve) {
        try {
            if (eve.colDef.headerName == i18n.t('editDeal')) {
                this.populateValues(eve.data);
            }
            window.scrollTo(0, 0);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(userDealObj) {

        try {
            let userName = [{
                label: userDealObj.UserName,
                value: parseInt(userDealObj.UserId)
            }];
            let primaryFilter = [{
                label: userDealObj.PrimaryFilterName,
                value: parseInt(userDealObj.PrimaryFilter),

            }]

            var populateStatus = true;

            populateStatus = this.onPrimaryFilterChange(primaryFilter);
            if (populateStatus == false) {
                return false;
            }




            let deal = [];
            let dealIds = userDealObj.DealId.split(",");
            let dealNames = userDealObj.DealName.split(",");
            let capability = [];
            let currCap = userDealObj.CapabilityId.split(",");
            let currCapNames = userDealObj.CapabilityName.split(",");
            let towers = [];
            let currTower = userDealObj.TowerIds.split(",");
            let curTowerNames = userDealObj.TowerName.split(",");
            let OG = [];
            let OGIds = userDealObj.OGIds.split(",");
            let OGName = userDealObj.OGName.split(",");
            this.getTower(userDealObj.CapabilityId, currTower);

            let delvLocs = [];
            let currDelvLoc = "";
            if (userDealObj.DeliveryLocationIds != null && userDealObj.DeliveryLocationIds != "") {
                currDelvLoc = userDealObj.DeliveryLocationIds.split(",");
            }

            let curDelvLocNames = "";
            if (userDealObj.DeliveryLocationName != null && userDealObj.DeliveryLocationName != "") {
                curDelvLocNames = userDealObj.DeliveryLocationName.split(",");
            }

            dealIds.map((dealId, index) => {
                let result = this.state.dealList.filter((item, index) => {
                    if (item.value == dealId) {
                        return item.DeliveryLocationIds;
                    }
                })

                let dealObj = {
                    label: dealNames[index],
                    value: parseInt(dealId),
                    CapabilityID: null,
                    DeliveryLocationIds: result[0].DeliveryLocationIds,
                    OGID: userDealObj.OGIds
                };
                deal.push(dealObj);
            });

            currCap.map((capId, index) => {
                let capObj = {
                    label: currCapNames[index],
                    value: parseInt(capId)
                };
                capability.push(capObj);
            });

            OGIds.map((ogId, index) => {
                let ogObj = {
                    label: OGName[index],
                    value: parseInt(ogId),
                    CapabilityID: null,
                    DeliveryLocationIds: userDealObj.DeliveryLocationIds,
                    OGID: userDealObj.OGIds
                };
                OG.push(ogObj);
            });

            currTower.map((towerId, index) => {
                let towerObj = {
                    label: curTowerNames[index],
                    value: parseInt(towerId)
                };
                towers.push(towerObj);
            });

            if (userDealObj.DeliveryLocationIds != null && userDealObj.DeliveryLocationIds != "") {
                currDelvLoc.map((delLocId, index) => {
                    let delvObj = {
                        label: curDelvLocNames[index],
                        value: parseInt(delLocId)
                    };
                    delvLocs.push(delvObj);
                });
            }

            if (userDealObj.PrimaryFilter == 1) {
                this.onDealChange(deal);
                this.onCapabilityChange(capability);
                this.onTowerChange(towers);
            }
            else if (userDealObj.PrimaryFilter == 2) {
                this.onOGChange(OG);
                this.onDealChange(deal);
                this.onCapabilityChange(capability);
                this.onTowerChange(towers);
            }
            else if (userDealObj.PrimaryFilter == 3) {
                this.onDlvryLocChange(delvLocs);
                this.onDealChange(deal);
                this.onCapabilityChange(capability);
                this.onTowerChange(towers);

            }
            else if (userDealObj.PrimaryFilter == 4) {
                this.onDealChange(deal);
                this.onCapabilityChange(capability);
            }
            else if (userDealObj.PrimaryFilter == 5) {
                this.onDealChange(deal);
            }

            this.setState({
                userSelected: userName,
                RoleName: userDealObj.RoleName,
                dealSelected: deal,
                capabilitySelected: capability,

                DlvryLocValue: delvLocs,
                IsActive: userDealObj.IsActive,
                isReport: userDealObj.IsReport,
                IsEditActive: true,
                isUnSaved: true,
                isDealSelected: true,
                primaryFilterValue: primaryFilter,
                OGValue: OG,
                UserDealmappingId: userDealObj.UserDealmappingId,
                viewStatus: userDealObj.PermissionLevel
            })

            if (userDealObj.PermissionLevel == 1) {
                this.setState({ isSubmitted: true, isSaved: false, isTakeAssessment: false });
            }
            else if (userDealObj.PermissionLevel == 2) {
                this.setState({ isSubmitted: true, isSaved: true, isTakeAssessment: false });
            }
            else if (userDealObj.PermissionLevel == 3) {
                this.setState({ isSubmitted: true, isSaved: true, isTakeAssessment: true });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCheckBoxChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.checked,
                isUnSaved: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun(eve) {

        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    //On Change of user inputs
    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    //Resets the form
    clearInputs() {
        try {
            this.setState({
                userSelected: [],
                dealSelected: [],
                lstDlvryLoc: [],
                DlvryLocValue: [],
                capabilitySelected: [],
                towerSelected: [],
                IsActive: true,
                isDealSelected: false,
                IsEditActive: false,
                isUnSaved: false,
                isUpdateExisting: false,
                dealGridData: [],
                RoleName: "",
                RoleID: "",
                primaryFilterValue: [],
                OGValue: [],
                dealOptions: [],
                OGOptions: [],
                capabilityOptions: [],
                towerOptions: [],
                isSubmitted: true,
                isSaved: false,
                isTakeAssessment: false,
                isSubmittedEnable: false,
                isSavedEnable: false,
                isTakeAssessmentEnable: false,
                viewStatus: 1,
                isReport: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                DealID: value ? this.state.UserName : 0
            });
            if (value) {
                this.saveEditDeal(value);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the Edit popup */
    closeEditPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the Navigation popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /* Function formats the active column data in the grid */
    formatActive(params) {
        try {
            return params.value ? i18n.t('yes') : i18n.t('no');
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function prompts a pop up in case on unsaved data*/
    onNavigationClick(eve) {

        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == i18n.t('userManagement')){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('dealManagement')){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('pMMConfiguration')){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (this.state.isUnSaved) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    accessTransfer() {

        try {            
            //window.tfo_event("PMM-MC-14", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            this.setState({
                popUpAccessTransfer: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeAccessTransferPopUp = (value) => {
        if (value >= 1) {
            this.setState({
                arrval: true
            });
        }

        try {
            this.setState({
                popUpAccessTransfer: false

            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSubmittedChk() {
        try {
            if(this.state.isSubmitted == false){
                this.setState({ isSubmitted: true })
            } else {
                this.setState({ isSubmitted: true });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSavedChk() {

        try {
            this.state.isSaved == false ? this.setState({ isSaved: true }) : this.setState({ isSaved: false });
            if (this.state.isSaved == false && this.state.isTakeAssessment == false) {
                this.setState({ viewStatus: 2, isTakeAssessment: false });
            }
            else if (this.state.isSaved == true && this.state.isTakeAssessment == true) {
                this.setState({ viewStatus: 1, isTakeAssessment: false });
            }
            else if (this.state.isSaved == true && this.state.isTakeAssessment == false) {
                this.setState({ viewStatus: 1, isTakeAssessment: false });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onTakeAssessmentChk() {

        try {
            this.state.isTakeAssessment == false ? this.setState({ isTakeAssessment: true }) : this.setState({ isTakeAssessment: false });
            if (this.state.isTakeAssessment == false) {
                this.setState({ viewStatus: 3, isSaved: true });
            }
            else if (this.state.isTakeAssessment == false && this.state.isSaved == false) {
                this.setState({ viewStatus: 2 });
            }
            else if (this.state.isTakeAssessment == true) {
                this.setState({ viewStatus: 1, isSaved: false })
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onReportChk() {

        try {
            if (this.state.isReport == false) {
                this.setState({ isReport: true });
            }
            else {
                this.setState({ isReport: false });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        var { t } = this.props
        columnDefs = [
            { headerName: t('editDeal'), cellRenderer: this.createEditCell, suppressResize: true },
            { headerName: t('userName'), field: 'UserName', suppressMovable: true, suppressResize: true, tooltipField: 'User ID' },
            {
                headerName: t('accessType'),
                cellRenderer: function (params) {

                    if (params.data.PermissionLevel == "1") {
                        return t('viewSubmittedAssessment');
                    }
                    else if (params.data.PermissionLevel == "2") {
                        return t('viewSubmittedSavedAssessment');
                    }
                    else if (params.data.PermissionLevel == "3") {
                        return t('takeAssessments');
                    }
                },
                suppressMovable: true, suppressResize: true, autoHeight: true
            },
            { headerName: t('primaryFilter'), field: 'PrimaryFilterName', suppressMovable: true, suppressResize: true, tooltipField: 'PrimaryFilterName' },
            { headerName: t('deal'), field: 'DealName', suppressMovable: true, suppressResize: true, tooltipField: 'DealName', autoHeight: true },
            { headerName: t('capability'), field: 'CapabilityName', suppressMovable: true, suppressResize: true, tooltipField: 'CapabilityName', autoHeight: true },
            { headerName: t('tower'), field: 'TowerName', suppressMovable: true, suppressResize: true, tooltipField: 'TowerName', autoHeight: true },
            { headerName: t('oG'), field: 'OGName', suppressMovable: true, suppressResize: true, tooltipField: 'OGName', autoHeight: true },
            { headerName: t('delivery_Location'), field: 'DeliveryLocationName', suppressMovable: true, suppressResize: true, tooltipField: 'DeliveryLocationName', autoHeight: true },
            { headerName: t('active'), field: 'IsActive', valueFormatter: this.formatActive, suppressResize: true, tooltipField: 'Active', headerTooltip: 'Active' }
        ];
        var dealGrid = (
            <div className="ag-theme-material grid-div">
                <AgGridReact ref="deal_user_grid" id="grid_deal_user"
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={this.state.dealGridData}
                    enableFilter={true}
                    rowmodeltype="pagination"
                    onCellClicked={this.onEditCell}
                    onGridReady={this.gridReadyFun}
                    quickFilterText={this.state.searchDeal}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        );
        return (
            <section className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" />{i18n.t('bACKTOHOME')}</a>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li className="active">
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            <li>
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}
                    </ul>
                </div>
                <section className="multiple-tabs-section">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className="" data-content="CreateUser" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('createUser')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="active" data-content="Deal Mapping" data-url="/DealMapping" onClick={this.onNavigationClick}>{i18n.t('userAccessMapping')}</a>
                        </li>
                        <div className="col-md-8 col-md-offset-1">
                            <input id="txt_viewSubmittedAssessment" type="checkbox" className="form-checkbox" checked={this.state.isSubmitted} onClick={this.onSubmittedChk} disabled={this.state.isSubmittedEnable} />{i18n.t('viewSubmittedAssessment')}
                            <input id="txt_viewDraft/SavedAssessment" type="checkbox" className="form-checkbox" checked={this.state.isSaved} onClick={this.onSavedChk} disabled={this.state.isSavedEnable} />{i18n.t('viewDraftSavedAssessment')}
                            <input id="txt_takeAssessments" type="checkbox" className="form-checkbox" checked={this.state.isTakeAssessment} onClick={this.onTakeAssessmentChk} disabled={this.state.isTakeAssessmentEnable} />{i18n.t('takeAssessments')}
                            <input id="txt_reports" type="checkbox" className="form-checkbox" checked={this.state.isReport} onClick={this.onReportChk} />{i18n.t('reports')}
                        </div>
                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                        navigationPage={this.state.navigationPage} isEditAcitve={this.state.isUnSaved}
                        url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                    {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={"User Deal"}
                        editValue={this.state.dealSelected[0].label}
                        passValue={this.passValue} closeCallback={this.closeEditPopUp} />}
                    <div className="box">
                        <div id="userCreate" className="box-in">
                            <div className="row">
                                <div className="col-lg-4 form-horizontal">
                                    <div className="form-group">
                                        <label id="lbl_enterpriseID" className="control-label col-sm-4" >{i18n.t('enterpriseID')}:<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-6">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="UserName" id="ddl_UserName" name="form-field-name" labelKey="label" onChange={this.onUserChange.bind(this)} options={this.state.userList} selected={this.state.userSelected} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                        </div>
                                        <div className="col-sm-2 form-horizontal">
                                            <div className="form-group link">
                                                <Link to="/UserManagement">{i18n.t('createUser')}</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label id="lbl_primaryFilter" className="control-label col-sm-4" >{i18n.t('primaryFilter')}:<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-8">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlPrimaryFilter" id="ddl_primaryFilter" name="form-field-name" labelKey="label" onChange={this.onPrimaryFilterChange.bind(this)} options={this.state.primaryFilterOptions} selected={this.state.primaryFilterValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('selectPlace')} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label id="lbl_isActive" className="control-label col-sm-4">{i18n.t('isActive')}</label>
                                        <div className="col-sm-1">
                                            <input id="txt_checkBoxChange" type="checkbox" ref="isActiveDeal" data-field="IsActive" checked={this.state.IsActive} onChange={this.onCheckBoxChange} />
                                        </div>
                                        <label id="lbl_userRole" className="control-label col-sm-3">{i18n.t('userRole')}:</label>
                                        <label id="lbl_roleName" className="control-label col-sm-4 infoRole">{this.state.RoleName}</label>

                                    </div>
                                </div>

                                <div className="col-lg-4 form-horizontal">
                                    <div className="form-group">
                                        <label id="lbl_deal" className="control-label col-sm-4" >{i18n.t('deal')}:<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-6">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="deal" id="ddl_deal" name="form-field-name" labelKey="label" onChange={this.onDealChange.bind(this)} options={this.state.dealOptions} selected={this.state.dealSelected} multiple placeholder={i18n.t('selectPlace')} />
                                        </div>
                                        <div className="col-sm-2 form-horizontal">
                                            <div className="form-group link">
                                                <Link to="/DealManagement">{i18n.t('createDeal')}</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label id="lbl_OG" className="control-label col-sm-4">{i18n.t('oG')}:<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-8">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlOG" id="ddl_OG" name="form-field-name" labelKey="label" onChange={this.onOGChange.bind(this)} options={this.state.OGOptions} selected={this.state.OGValue} multiple placeholder={i18n.t('selectPlace')} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label id="lbl_deliveryLocation" className="control-label col-sm-4">{i18n.t('delivery_Location')}:</label>
                                        <div className="col-sm-8">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlDlvryLoc" id="ddl_dlvryLoc" name="form-field-name" labelKey="label" onChange={this.onDlvryLocChange.bind(this)} multiple options={this.state.lstDlvryLoc} selected={this.state.DlvryLocValue} placeholder={i18n.t('selectPlace')} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 form-horizontal">
                                    <div className="form-group">
                                        <label id="lbl_capability" className="control-label col-sm-4" >{i18n.t('capability')}:<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-8">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="capability" id="ddl_capability" name="form-field-name" labelKey="label" onChange={this.onCapabilityChange.bind(this)} options={this.state.capabilityOptions} selected={this.state.capabilitySelected} multiple placeholder={i18n.t('selectPlace')} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label id="lbl_tower" className="control-label col-sm-4" >{i18n.t('tower')}:<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-8">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="tower" id="ddl_tower" name="form-field-name" labelKey="label" onChange={this.onTowerChange.bind(this)} options={this.state.towerOptions} selected={this.state.towerSelected} multiple placeholder={i18n.t('selectPlace')} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="saveDiv row">
                        <div className="btn-group-md">
                            <input id="txt_accessTransfer" type="button" className="btn btn-info" value={i18n.t('accessTransfer')} onClick={this.accessTransfer} />
                            <input id="txt_save" type="button" className="btn btn-success" value={i18n.t('save')} onClick={this.saveEditDeal} />
                            <input id="txt_Clear" type="button" className="btn btn-danger" value={i18n.t('clear')} onClick={this.clearInputs} />
                        </div>
                    </div>
                    <div id="dealDetailsDiv">
                        <div><h6 className="fleft">{i18n.t('userAccessDetails')}</h6><input id="txt_inputChange" type="text" placeholder={i18n.t('search')} className="fright searchBox" data-field="searchDeal" onChange={this.onInputChange} /></div>
                        <div className="box-plain pmm-asmt-tbl">
                            {dealGrid}
                        </div>
                    </div>
                </section>
                <div>
                    {this.state.popUpAccessTransfer && <AccessTransferPopup arr={this.state.arrval} show={this.state.popUpAccessTransfer} closeAccessTransferPopUp={(val) => this.closeAccessTransferPopUp(val)} />}
                </div>
            </section>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}

DealMapping = connect(mapStateToProps)(withTranslation()(DealMapping));
export default withRouter(DealMapping);