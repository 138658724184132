import React from 'react';
import { Modal } from 'react-bootstrap';
import GDPRNotification from './UserManagement/Component/GDPRNotification';
import i18n from 'i18next';

export default class ModalPopup extends React.Component {

    render() {
        let style;
        let modalBody;
        let modalClose;
        let titleAlign;

        if (this.props.ModalTitle == i18n.t('privacyStatement')) {
            modalBody = <GDPRNotification CloseModal={this.props.closeModal} NotificationMsg={this.props.NotificationMsg} />;
            style = {};
            titleAlign = { 'text-align': 'center' };
            modalClose = true;
        }
        else {
            style = {};
        }

        return (
            <div className="static-modal">

                <Modal show={this.props.showModal}>

                    <Modal.Header>
                        <Modal.Title>
                            {this.props.ModalTitle != i18n.t('privacyStatement') ? <button type="button" className="close" data-dismiss="modal" aria-label={i18n.t('closeModalPopUp')} onClick={this.props.closeModal}><span aria-hidden="true">×</span></button> : null}
                            <h4 className="modal-title" style={titleAlign} id="myModalLabel">{this.props.ModalTitle}</h4>
                        </Modal.Title>
                        <div>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={style}>
                        {modalBody}
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}
