import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';

class VersionChangePopup extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.handleResponse = this.handleResponse.bind(this);
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {
       
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    handleResponse(eve) {
        try {
            let targetText = eve.target.value;
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    render() {
       
        return (
            <div>
                <Modal show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <h5 className="infoHeaderContent"><b>PMM Version Change</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <span>
                            <p className="red-text">There is a <b>new version</b> of PMM tool available. Please clear your <b>Browser Cache</b>, <b>close the current tab</b> and open the application in <b>new window</b> to get the new changes available.</p>
                           
                        </span>
                    </Modal.Body>
                    
                </Modal>
            </div>
        );
    }
}

export default withRouter(VersionChangePopup)