/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class ConfirmUserAddition extends React.Component {
    constructor(props) {
        super(props)
        try {
            this.state = { show: this.props.show };
            this.handleClose = this.handleClose.bind(this);

        }
        catch (error) {
            console.log("Error Encountered");
        }
    };
    componentWillReceiveProps() {
        this.setState({ show: this.props.show })
    }
    handleClose() {
        this.setState({ show: false });
    };


    render() {
        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h2>{i18n.t('confirm')}</h2>
                </Modal.Header>
                <Modal.Body>
                    {this.props.bodyMessage}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.propsIsLocked == 2 ?
                        <div>
                            <Button id="btn_ok" bsStyle="success" bsSize="large" onClick={this.handleClose}>{i18n.t('ok')}</Button>
                        </div> :
                        <div>
                            <Button id="btn_yes" bsStyle="success" bsSize="large" onClick={this.props.onSelectYes}>{i18n.t('yes')}</Button>
                            <Button id="btn_no" bsStyle="danger" bsSize="large" onClick={this.props.onSelectNo}>{i18n.t('no')}</Button>
                        </div>}
                </Modal.Footer>
            </Modal>
        </div>)
    }
}
ConfirmUserAddition = withTranslation()(ConfirmUserAddition);
export default withRouter(ConfirmUserAddition);