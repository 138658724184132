import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers';
import '../src/css/fonts.css';
import '../src/css/bootstrap.min.css';
import '../src/css/customstyles.css';
import '../node_modules/ag-grid/dist/styles/ag-grid.css';
import '../node_modules/ag-grid/dist/styles/theme-fresh.css';
import '../node_modules/react-datepicker/dist/react-datepicker.css';
import '../node_modules/react-select/dist/react-select.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';
import { runWithAdal } from 'react-adal';
import { authContext } from './config/AdalConfig';
import './i18n';
import '../node_modules/react-bootstrap-typeahead/css/Typeahead.css';


const DO_NOT_LOGIN = false;

// initialized redux store
export const store = createStore(rootReducer)
//COMMENTED CODE IN THIS FILE NEED NOT BE DELETED

 runWithAdal(authContext, () => {  
  debugger;
    ReactDOM.render(
      <Provider store={store}>
        <Routes />
        </Provider>
    , document.getElementById('root'));
  },DO_NOT_LOGIN);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
