import * as types from "./actionTypes";

//action creator functions for redux store

export const setEnterpriseId = (data) => {
  return {
    type: types.SET_ENTERPRISE_ID,
    data: data,
  };
};

export const setLanguageId = (data) => {
  return {
    type: types.SET_LANGUAGE_ID,
    data: data,
  };
};

export const setGroupId = (data) => {
  return {
    type: types.SET_GROUP_ID,
    data: data,
  };
};

export const setRoleName = (data) => {
  return {
    type: types.SET_ROLE_NAME,
    data: data,
  };
};

export const setPageName = (data) => {
  return {
    type: types.SET_PAGE_NAME,
    data: data,
  };
};

export const setGroupName = (data) => {
  return {
    type: types.SET_GROUP_NAME,
    data: data,
  };
};

export const setDropdownValues = (data) => {
  return {
    type: types.SET_DROPDOWN_VALUES,
    data: data,
  };
};
