/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import ReactSelect from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from '../EditConfirmation';
import NavigationConfirmation from '../../../PMM/NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../../images/icon-back-arrow.png';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import addButtonIcon from '../../../images/icon-add-btn.png';
import DealMMSIDMapping from './DealMMSIDMapping';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect} from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.DealName;

const renderSuggestion = suggestion => <span>{suggestion.DealName}</span>;

function CreateEditBox() {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class Deal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DealGridData: [],
            IsActive: true,
            lstOG: [],
            IndustryList: [],
            DelLocsList: [],
            OGValue: [],
            selectedRow: {},
            isEdit: 1,
            DealID: 0,
            DealId: 0,
            OGName: "",
            Industry: [],
            DelLocation: [],
            searchDeal: "",
            IsEditActive: false,
            isEditPopUp: false,
            isUpdateExisting: false,
            isUnSaved: false,
            popUpDisplay: false,
            toURL: '',
            navigationPage: '',
            value: "",
            suggestions: [],
            DealName: '',
            Description: '',
            pageNames: [],
            lstOMID: [],
            OMIDValue: [],
            MMSID: "",
            sameGroup: true,
            showMMSIDPopUp: false,
            MMSIDGridLength: 0,
            MMSIDGridData: []
        };
        this.SelectOG = this.SelectOG.bind(this);
        this.ChangeIsActive = this.ChangeIsActive.bind(this);
        this.onCancel = this.OnCancel.bind(this);
        this.SelectIndustry = this.SelectIndustry.bind(this);
        this.SelectDelLocation = this.SelectDelLocation.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.formatActive = this.formatActive.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.closeEditPopUp = this.closeEditPopUp.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.populateValues = this.populateValues.bind(this);
        this.passValue = this.passValue.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.SelectOMID = this.SelectOMID.bind(this);
        this.addMMSIDPopUp = this.addMMSIDPopUp.bind(this);
        this.closeMMSIDPopUp = this.closeMMSIDPopUp.bind(this);
        this.saveMMSID = this.saveMMSID.bind(this);
        this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
    }

    componentWillMount() {
    }

    componentDidMount() {

        try {            
            //window.tfo_event("PMM-MC-15", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])   
            this.setState({ pageNames:this.props.pageName 
            })
            window.addEventListener('resize', this.reSizeGrid, true);
            this.GetDropDownsValue();
            //this.FetchConnDealGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        if (this.refs.deal_grid != undefined) {
            this.refs.deal_grid.gridOptions.api.sizeColumnsToFit();
        }
    }


    async GetDropDownsValue() {
        try {
            var objThis = this;
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            await request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(res => {
                    objThis.setState({
                        lstOG: res.OperatingGroup,
                        IndustryList: res.Industry,
                        DelLocsList: res.DeliveryLocation,
                        lstOMID: res.OMID
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async FetchConnDealGrid() {

        try {
            var objThis = this;
            var SearchedString = "";
            let getDealData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                DealId:0,
                SearchedString:SearchedString,
            }
            await request("/MasterScreens/GetDeal", 'POST', getDealData) 
                .then(res => {
                    objThis.setState({ DealGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectOG(val) {
        this.setState({
            OGValue: val,
            isUnSaved: true
        });
    }

    SelectOMID(val) {
        try {
            if (this.state.isEdit == 2) {
                let deletedOMID = this.state.OMIDValue.filter(function (x) {
                    return !val.some(function (y) {
                        return x.value === y.value;          // assumes unique id
                    });
                })
                let DealId = this.state.selectedRow.DealId;
                if (deletedOMID.length > 0) {
                    request("/Common/ValidateOMID?UserName="+this.props.enterpriseId + "&DealId=" + DealId + "&OMID=" + deletedOMID[0].value + "&GroupID=" + this.props.groupId + "&LanguageID=" +this.props.languageId,'GET')
                    let userData = {
                        "UserName":this.props.enterpriseId,
                        "GroupID":this.props.groupId,
                        "LanguageID":this.props.languageId,
                        "DealId": DealId,
                        "OMID": deletedOMID[0].value,
                    }
                    request("/Common/ValidateOMID", 'POST', userData)
                        .then(res => {
                            if (res.outputmessage == i18n.t('success')) {
                                this.setState({
                                    OMIDValue: val,
                                    isUnSaved: true
                                });
                            }
                            else {
                                alert(res.outputmessage);
                                this.setState({
                                    OMIDValue: this.state.OMIDValue
                                });
                            }
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                        });
                }
                else {
                    this.setState({
                        OMIDValue: val,
                        isUnSaved: true
                    });
                }

            }
            else {
                this.setState({
                    OMIDValue: val,
                    isUnSaved: true
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectIndustry(val) {

        this.setState({
            Industry: val,
            isUnSaved: true
        });
    }

    SelectDelLocation(val) {

        try {
            if (this.state.isEdit == 2) {
                let deletedDelLoc = this.state.DelLocation.filter(x => !val.includes(x));
                let DealId = this.state.selectedRow.DealId;
                if (deletedDelLoc.length > 0) {
                    let dlData = {
                        "UserName":this.props.enterpriseId,
                        "GroupID":this.props.groupId,
                        "LanguageID":this.props.languageId,
                        "DealId": DealId,
                        "DeliveryLocationId": deletedDelLoc[0].value,
                    }
                    request("/Common/GetUserDetails", 'POST', dlData)
                        .then(res => {
                            if (res.outputmessage == i18n.t('success')) {
                                this.setState({
                                    DelLocation: val,
                                    isUnSaved: true
                                });
                            }
                            else {
                                alert(res.outputmessage);
                            }
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                        });
                }
                else {
                    this.setState({
                        DelLocation: val,
                        isUnSaved: true
                    });
                }

            }
            else {
                this.setState({
                    DelLocation: val,
                    isUnSaved: true
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            $("#loader-div").hide();
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
            var objThis = this;
            var SearchedString = "";
            let getDealData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                DealId:0,
                SearchedString:SearchedString,
            }
            params.api.showLoadingOverlay();
            request("/MasterScreens/GetDeal", 'POST', getDealData) 
                .then(res => {
                    if (res.length !== 0) {
                        params.api.hideOverlay();
                    objThis.setState({ DealGridData: res });
                    $("#loader-div").hide();
                    }
                    else {
                        params.api.showNoRowsOverlay();
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('edit')) {

                this.populateValues(e.data);

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(dealObj) {

        try {

            let industry = dealObj.IndustryIds.split(',');
            let industryNames = dealObj.IndustryName.split(',');

            let indObj
            let delLoc = dealObj.DeliveryLocationIds.split(',');
            let delLocNames = dealObj.DeliveryLocationName.split(',');
            let omId = dealObj.OMID == null ? [] : dealObj.OMID.split(',');
            let omIds = dealObj.OMIDs == null ? [] : dealObj.OMIDs.split(',');
            let delLocArr = [];
            let omIdArr = [];
            industry.map((indId, index) => {
                indObj = [{
                    label: industryNames[index],
                    value: parseInt(indId)
                }];

            });
            delLoc.map((delId, index) => {
                let delObj = {
                    label: delLocNames[index],
                    value: parseInt(delId)
                };
                delLocArr.push(delObj);
            });
            omIds.map((valomId, index) => {
                let omIdObj = {
                    label: valomId,
                    value: parseInt(omId[index])
                };
                omIdArr.push(omIdObj);
            });
            this.setState({
                DealName: dealObj.DealName,
                Description: dealObj.Description,
                OGValue: [{
                    value: dealObj.OGID,
                    label: dealObj.OGName
                }],
                IsActive: dealObj.IsActive,
                isEdit: 2,
                selectedRow: dealObj,
                DealId: dealObj.DealId,
                OGName: dealObj.OGName,
                Industry: indObj,
                DelLocation: delLocArr,
                OMIDValue: omIdArr,
                isUpdateExisting: true,
                isUnSaved: true,
                MMSID: (dealObj.MMSID == "" || dealObj.MMSID == null) ? 0 : dealObj.MMSID,
                sameGroup: (dealObj.GroupID == parseInt(this.props.groupId)
                    ) ? true : false,
                showMMSIDPopUp: false,
                MMSIDGridLength: (dealObj.MMSID == "" || dealObj.MMSID == null) ? 0 : dealObj.MMSID.split(',').length,
                MMSIDGridData: []
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {
        this.setState({
            IsActive: event.target.checked,
            isUnSaved: true
        });
    }

    OnCancel(event) {
        this.setState({
            OGValue: [],
            IsActive: true,
            isEdit: 1,
            DealId: '',
            OGName: '',
            errors: '',
            Industry: [],
            DelLocation: [],
            isUnSaved: false,
            isUpdateExisting: false,
            DealName: '',
            Description: '',
            OMIDValue: [],
            MMSID: "",
            sameGroup: true,
            showMMSIDPopUp: false,
            MMSIDGridLength: 0,
            MMSIDGridData: []
        });
    }

    IsFormValid() {
        try {
            let { DealName, Description, Industry, DelLocation, OGValue, OMIDValue, MMSID, MMSIDGridLength } = this.state;
            let formIsValid = true;
            let errors = {};
            let reg = /^[A-Za-z\d\s]+$/;
            let regForAlphabets = /^[a-zA-Z]+$/;
            if (this.props.groupId== "1") {
                if (DealName.length <= 0 || DealName == undefined || Description == undefined || Description.length <= 0 || OGValue == undefined || OGValue.length <= 0 || Industry == undefined || Industry.length <= 0 || DelLocation == undefined || DelLocation.length <= 0 || OMIDValue == undefined || OMIDValue.length <= 0) {
                    alert(i18n.t('pleaseFillAllValues'));
                    formIsValid = false;
                } else {
                    formIsValid = true;
                }
            } else {
                if (DealName.length <= 0 || DealName == undefined || Description == undefined || Description.length <= 0 || OGValue == undefined || OGValue.length <= 0 || Industry == undefined || Industry.length <= 0 || DelLocation == undefined || DelLocation.length <= 0 || MMSIDGridLength <= 0) {
                    alert(i18n.t('pleaseFillAllValues'));
                    formIsValid = false;
                } else {
                    formIsValid = true;
                }
            }
            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveDealDetails(eve) {

        try {
            const obj = this;
            let industryIds = "", delLocsIds = "", omIds = "";
            let { DealId, DealName, Description, Industry, DelLocation, OMIDValue,
                isEdit, selectedRow, OGValue, IsActive, isUpdateExisting, MMSID } = this.state;
            let dealNameValidation = inputValidator(DealName);
            let descriptionvalidation = inputValidator(Description);
            let specialCharacters = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g
            
            if(specialCharacters.test(DealName)){
                console.log("Inside new SP function" ,DealName);
                alert(i18n.t('Deal name should not contain any Special Characters'));
                return false;
            }
            if (DealName.includes(",")) {
                alert(i18n.t('dealNameShouldNotContain'));
                return false;
            }

            if(dealNameValidation ===false && descriptionvalidation ===false){

            if (obj.IsFormValid()) {
                if (eve && typeof (eve) != "boolean" && isUpdateExisting && DealName != "") {
                    this.setState({
                        isEditPopUp: true
                    })
                    return
                }


                DelLocation.forEach((del, index) => {
                    if (index == 0) {
                        delLocsIds = del.value;
                        return delLocsIds;
                    } else {
                        delLocsIds = delLocsIds + ',' + del.value;
                        return delLocsIds;
                    }
                });

                OMIDValue = OMIDValue == "" ? [] : OMIDValue

                OMIDValue.forEach((om, index) => {
                    if (index == 0) {
                        omIds = om.value;
                        return omIds;
                    } else {
                        omIds = omIds + ',' + om.value;
                        return omIds;
                    }
                });

                const requestData = selectedRow;
                requestData["UserName"] =this.props.enterpriseId;
                requestData["Operation"] = isEdit;
                requestData["DealId"] = DealId == "" ? 0 : DealId;
                requestData["DealName"] = DealName;
                requestData["Description"] = Description
                requestData["OGID"] = OGValue[0].value
                requestData["OGName"] = OGValue[0].label
                requestData["IsActive"] = IsActive

                requestData["IndustryIds"] = Industry[0].value;
                requestData["DeliveryLocationIds"] = delLocsIds.toString().split(/,\s*/).sort(function (a, b) { return parseFloat(a) - parseFloat(b) }).join(",").toString();
                requestData["OMIDs"] = omIds.toString().split(/,\s*/).sort(function (a, b) { return parseFloat(a) - parseFloat(b) }).join(",").toString();
                requestData["MMSID"] = MMSID;
                requestData["GroupID"] =this.props.groupId
                requestData["LanguageID"] =this.props.languageId

                request("/MasterScreens/UpSertDeal", 'POST', requestData)
                    .then(res => {
                        if (res.Outputcode != -1) {
                            if (res.Outputcode == 1) {
                                let DealIDGenerated = res.ScopeIDGenrated;
                                if (this.props.groupId
                                    != 1) {
                                    this.saveMMSID(DealIDGenerated);
                                }
                                else {
                                    alert(res["outputmessage"]);
                                    obj.FetchConnDealGrid();
                                    obj.OnCancel();
                                    let dropdownData = {
                                        "UserName":this.props.enterpriseId,
                                        "GroupID":this.props.groupId,
                                        "LanguageID":this.props.languageId,
                                        "ID": 0,
                                        "DealId": 0,
                                    }
                                    request("/Common/DropDownsValue", 'POST', dropdownData)
                                        .then(res => {
                                            obj.setState({
                                                lstOG: res.OperatingGroup,
                                                IndustryList: res.Industry,
                                                DelLocsList: res.DeliveryLocation,
                                                lstOMID: res.OMID
                                            });
                                        })
                                        .catch(error => {
                                            console.log("Error Encountered");
                                        });
                                }
                            } else {
                                alert(res["outputmessage"]);
                                obj.FetchConnDealGrid();
                                obj.OnCancel();
                                let dropdownData = {
                                    "UserName":this.props.enterpriseId,
                                    "GroupID":this.props.groupId,
                                    "LanguageID":this.props.languageId,
                                    "ID": 0,
                                    "DealId": 0,
                                }
                                request("/Common/DropDownsValue", 'POST', dropdownData)
                                    .then(res => {
                                        obj.setState({
                                            lstOG: res.OperatingGroup,
                                            IndustryList: res.Industry,
                                            DelLocsList: res.DeliveryLocation,
                                            lstOMID: res.OMID
                                        });
                                    })
                                    .catch(error => {
                                        console.log("Error Encountered");
                                    });
                            }
                        }
                        else {
                            alert(res["outputmessage"]);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
            }
        }
        else{
            alert(i18n.t("PleaseEntervalidValuesintheFields"));
        }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveMMSID(dealID) {
        try {
            let obj = this;
            let MMSIDSaveArr = [];
            obj.state.MMSIDGridData.forEach(row => {
                let mmsidObj = {
                    ID: 0,
                    MMSID: row.MMSID,
                    DealID: dealID,
                    IsActive: row.IsActive,
                    IsDeleted: 0,
                    CreatedBy: row.CreatedBy,
                    CreatedDate: row.CreatedDate,
                    UpdatedBy: row.UpdatedBy,
                    UpdatedDate: row.UpdatedDate,
                    UserName:this.props.enterpriseId,                
                    LanguageID:this.props.languageId,

                }
                MMSIDSaveArr.push(mmsidObj);
                return MMSIDSaveArr;
            });

            let MMSIDObj = {
                mmsidDetails: MMSIDSaveArr
            }

            request("/MasterScreens/UpsertMMSID", 'POST', MMSIDObj)
                .then(res => {
                    alert(res["outputmessage"]);
                    obj.FetchConnDealGrid();
                    obj.OnCancel();
                    let dropdownData = {
                        "UserName":this.props.enterpriseId,
                        "GroupID":this.props.groupId,
                        "LanguageID":this.props.languageId,
                        "ID": 0,
                        "DealId": 0,
                    }
                    request("/Common/DropDownsValue", 'POST', dropdownData)
                        .then(res => {
                            obj.setState({
                                lstOG: res.OperatingGroup,
                                IndustryList: res.Industry,
                                DelLocsList: res.DeliveryLocation,
                                lstOMID: res.OMID
                            });
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                        });
                    if (res.Outputcode == 1) {
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function shows the MMSID popup */
    addMMSIDPopUp() {
        try {
            let { DealName, Description, Industry, DelLocation, OGValue } = this.state;
            if (DealName.length <= 0 || DealName == undefined || Description == undefined || Description.length <= 0 || OGValue == undefined || OGValue[0].length <= 0 || Industry == undefined || Industry[0].length <= 0 || DelLocation == undefined || DelLocation.length <= 0) {
                alert(i18n.t('pleaseFillAlltheDetailsBeforeAddingMMSID'))
                this.setState({ showMMSIDPopUp: false });
            } else {
                this.setState({ showMMSIDPopUp: true });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the MMSID popup */
    closeMMSIDPopUp(mmsIDGridLength, lstMMSID, mmsIDGridData, isMMSIDUnSaved) {
        try {
            if (isMMSIDUnSaved) {
                this.setState({
                    showMMSIDPopUp: false,
                    MMSIDGridLength: mmsIDGridLength,
                    MMSID: lstMMSID,
                    MMSIDGridData: mmsIDGridData
                })
            }
            else {
                this.setState({
                    showMMSIDPopUp: false
                })
            }

        } catch (error) {
            console.log("Error Encountered");
        }
    }

    //On Change of user inputs
    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || '',
                IsEditActive: true,
                isUnSaved: true
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        let attr = eve.target.getAttribute("data-field");
        this.setState({
            [attr]: eve.target.value || ''
        });
    }

    /* Function formats the active column data in the grid */
    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }

    passValue(value) {
        try {
            console.log("Pass value is called", value)
            this.setState({
                isUpdateExisting: false,
                DealID: value ? this.state.DealID : 0
            });
            if (value) {
                this.SaveDealDetails(value);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the Edit popup */
    closeEditPopUp() {
        this.setState({
            isEditPopUp: false
        })
    }

    /*Function closes the Navigation popup */
    closeNavigationPopUp() {
        this.setState({
            popUpDisplay: false
        })
    }

    /*Function prompts a pop up in case on unsaved data*/
    onNavigationClick(eve) {
        try {        
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == i18n.t('userManagement')){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('pMMConfiguration')){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (this.state.isUnSaved) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage,
                    showMMSIDPopUp: false
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage,
                        showMMSIDPopUp: false
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {

        try {
            console.log("In getSUggestions", value)
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.DealGridData;
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.DealGridData.filter(Deal => regex.test(Deal.DealName));
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            DealName: newValue,
            isUnSaved: true,
        });
        $(event.target).attr('type', 'new');
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected(event, { suggestion }) {
        try {
            console.log("Value of chosen resgion", suggestion);
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event) {
        try {
            console.log("In on blur" + $(event.target).attr('type'))
            let type = $(event.target).attr('type');
            let value = event.target.value.toLowerCase();
            this.state.DealGridData.forEach(deal => {
                if (deal.DealName.toLowerCase() == value) {
                    this.populateValues(deal);
                }
                return deal;
            });
            if (type == "new") {

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        let { t } = this.props;
        var columnDefs = [];
        let isHidden = (this.props.groupId
            == "1") ? true : false;
        columnDefs = [
            { headerName: t('edit'), field: '', cellRenderer: CreateEditBox, suppressMovable: true },
            { headerName: t('dealID'), field: 'DealId', hide: true, suppressMovable: true },
            { headerName: t('dealName'), field: 'DealName', tooltipField: 'DealName', suppressMovable: true },
            { headerName: t('description'), field: 'Description', tooltipField: 'Description', suppressMovable: true },
            { headerName: t('ogName'), field: 'OGName', tooltipField: 'OGName', suppressMovable: true },
            { headerName: t('industry'), field: 'IndustryName', tooltipField: 'IndustryName', suppressMovable: true },
            { headerName: t('deliverLocation'), field: 'DeliveryLocationName', tooltipField: 'DeliveryLocationName', suppressMovable: true },
            { headerName: t('oGID'), field: 'OGID', hide: true, suppressMovable: true },
            { headerName: t('oMIDs'), field: 'OMIDs', tooltipField: 'OMIDs', suppressMovable: true, hide: !isHidden },
            { headerName: t('mMSIDs'), field: 'MMSID', tooltipField: 'MMSID', suppressMovable: true, hide: isHidden },
            { headerName: t('createdBy'), field: 'CreatedBy', suppressMovable: true },
            { headerName: t('createdDate'), field: 'CreatedDate', suppressMovable: true },
            { headerName: t('updatedBy'), field: 'UpdatedBy', suppressMovable: true },
            { headerName: t('updatedDate'), field: 'UpdatedDate', suppressMovable: true },
            { headerName: t('headerIsActive'), field: 'IsActive', valueFormatter: this.formatActive, suppressMovable: true },
            { headerName: t('group'), field: 'GroupName', suppressMovable: true },

        ];



        var DealDataGrid = (
            <div className="ag-theme-material grid-div">
                <AgGridReact ref="deal_grid" id="grid_deal"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.DealGridData}
                    onGridReady={this.onGridReady}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    quickFilterText={this.state.searchDeal}
                    enableFilter='true'
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )
        let isDisabled = (this.state.isEdit == 2 && !this.state.sameGroup);
        let { suggestions, DealName: value } = this.state;
        console.log("Value of deal name is **********************************" + value);
        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur,
            disabled: isDisabled
        }
        console.log("In render value of input props" + inputProps)
        return (
            <div className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" />{i18n.t('bACKTOHOME')}</a>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li className="active">
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            <li>
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}
                    </ul>
                </div>

                <section className="multiple-tabs-section">
                    <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="active" data-content="Create Deal" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('createDeal')}</a>

                            </li>
                            <li className="nav-item">
                                <a className="" data-content="Deal Region Mapping" data-url="/DealRegionMapping" onClick={this.onNavigationClick}>{i18n.t('dealRegionMapping')}</a>

                            </li>
                        </ul>
                        {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                            navigationPage={this.state.navigationPage} isEditAcitve={this.state.isUnSaved}
                            url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                        {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={i18n.t('deal')}
                            editValue={this.state.value}
                            passValue={this.passValue} closeCallback={this.closeEditPopUp} />}
                        {this.state.showMMSIDPopUp == true ? <DealMMSIDMapping show={this.state.showMMSIDPopUp} gridData={this.state.MMSIDGridData}
                            selectedRow={this.state.selectedRow} DealName={this.state.DealName} DealId={this.state.DealId} closeCallback={this.closeMMSIDPopUp} /> : null}
                        <div className="box">
                            <div className="box-in">
                                <div className="row">
                                    <div className="col-lg-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_dealName" className="control-label col-sm-4">{i18n.t('deal_Name')}:<span className="mandatory-cls">*</span></label>
                                            <div className="col-sm-8">
                                                <Autosuggest ref="DealName"
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    onSuggestionSelected={this.onSuggestionSelected}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_description" className="control-label col-sm-4">{i18n.t('description')}:<span className="mandatory-cls">*</span></label>
                                            <div className="col-sm-8">
                                                <textarea ref="Description" id="txtArea_description" name="Description" data-field="Description" rows="1" className="form-control" placeholder="" value={this.state.Description} type="text" onChange={this.onInputChange} disabled={isDisabled} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_OG" className="control-label col-sm-4" >{i18n.t('oG')}:<span className="mandatory-cls">*</span></label>
                                            <div className="col-sm-8">
                                                <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlOG" id="ddl_OG" name="form-field-name" labelKey="label" options={this.state.lstOG} onChange={this.SelectOG.bind(this)} selected={this.state.OGValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('select')} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_industry" className="control-label col-sm-4" >{i18n.t('industry')}:<span className="mandatory-cls">*</span></label>
                                            <div className="col-sm-8">
                                                <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddIndus" id="dd_Indus" name="form-field-name" labelKey="label" options={this.state.IndustryList} onChange={this.SelectIndustry.bind(this)} selected={this.state.Industry} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('select')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_deliveryLocation" className="control-label col-sm-4" >{i18n.t('delivery_Location')}:<span className="mandatory-cls">*</span></label>
                                            <div className="col-sm-8">
                                                <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddDL" name="form-field-name" labelKey="label" options={this.state.DelLocsList} onChange={this.SelectDelLocation.bind(this)} selected={this.state.DelLocation} clearButton multiple disabled={isDisabled} placeholder={i18n.t('select')} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_isActive" className="control-label col-sm-4">{i18n.t('isActive')} </label>
                                            <div className="col-sm-8">
                                                <div className="commoncheckbox">
                                                    <input id="txt_isActive" ref="IsActive" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} onChange={this.ChangeIsActive} disabled={isDisabled} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5 form-horizontal">
                                        {(this.props.groupId == "1") ? //display OMID only for BPS user
                                            <div className="form-group">
                                                <label id="lbl_OMId" className="control-label col-sm-4" >{i18n.t('omid')}:<span className="mandatory-cls">*</span></label>
                                                <div className="col-sm-8">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_OMID" ref="ddlOMID" name="form-field-name" labelKey="label" options={this.state.lstOMID} onChange={this.SelectOMID.bind(this)} selected={this.state.OMIDValue} multiple placeholder={i18n.t('select')} />
                                                </div>
                                            </div>
                                            :
                                            <div className="form-group">
                                                <label id="lbl_MMSID" className="control-label col-sm-4">{i18n.t('mMSID')}:<span className="mandatory-cls">*</span></label>
                                                <div className="col-sm-8">
                                                    <button id="btn_ADD" type="button" className="btn btn-default pmmConfigAdd  col-sm-3" onClick={this.addMMSIDPopUp}>
                                                        <img src={addButtonIcon} /> {i18n.t('mMSID')}
                                                    </button>

                                                    {this.state.MMSIDGridLength == 0 ?
                                                        <label ref="lbl_MMSIDLength" name="MMSIDLength" className="control-label col-sm-4 mandatory-cls" onChange={this.onInputChange}>{this.state.MMSIDGridLength + " active MMSID"}</label>
                                                        :
                                                        <label ref="lbl_MMSIDLength" name="MMSIDLength" className="control-label col-sm-4" onChange={this.onInputChange}>{this.state.MMSIDGridLength + " active MMSID"}</label>
                                                    }

                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="btn-group-md fright">
                                        <a type="button" className="btn btn-success" onClick={this.SaveDealDetails.bind(this)} >{i18n.t('save')}</a>
                                        <a type="button" className="btn btn-danger" onClick={this.OnCancel.bind(this)} >{i18n.t('clear')}</a>
                                        <a className="" data-content="Deal Region Mapping" data-url="/DealRegionMapping" onClick={this.onNavigationClick}>
                                            <input id="txt_Next" type="button" className="btn btn-info" value={i18n.t('next')} data-content="Deal Region Mapping" data-url="/DealRegionMapping" />
                                        </a>
                                    </div>
                                </div>

                                <div className="search-div">
                                    <h4 className="fleft">{i18n.t('dEALDETAILS')}</h4>
                                    <input id="txt_saerch" type="text" placeholder={i18n.t('searchDeal')} className="fright searchBox" data-field='searchDeal' value={this.state.searchDeal} onChange={this.onSearch} />
                                </div>
                                <div className="AsmtData data-LeadingInd">
                                    <div className="box box-plain">
                                        {DealDataGrid}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}
Deal = connect(mapStateToProps)( withTranslation()(Deal));
export default withRouter(Deal);
