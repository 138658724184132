/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import i18n from 'i18next';

class EditConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            type: this.props.type,
            editValue: this.props.editValue
        }
        this.closePopUp = this.closePopUp.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    componentWillReceiveProps(props) {

        try {
            this.setState({
                show: this.props.show,
                navigationPage: this.props.navigationPage,
                isEditAcitve: this.props.isEditAcitve
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show,
                type: this.props.type,
                editValue: this.props.editValue
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleResponse(eve) {
        try {
            console.log("TExt here", eve.target.value)
            let targetText = eve.target.value;
            if (targetText == i18n.t('yes')) {
                this.setState({
                    show: false
                });
                this.props.passValue(true);
                this.props.closeCallback();
            } else if (targetText == i18n.t('no')) {
                this.setState({
                    show: false
                });
             
                this.props.closeCallback();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        return (
            <div>
                <Modal show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('updateConfirmation')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>

                        <span>
                            <p>{i18n.t('updatetheexisting')} {this.state.type} <strong>{this.state.editValue}</strong> ?</p>
                        </span>

                        <span>
                            <p>{i18n.t('clickon')} <strong>{i18n.t('yes')}</strong> {i18n.t('toupdateexisting')} {this.state.type}</p>
                            
                        </span>

                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_yesEditConf" className="btn btn-success" value={i18n.t('yes')} onClick={this.handleResponse} />
                        <input type="button" id="btn_noEditConf" className="btn btn-danger" value={i18n.t('no')} onClick={this.handleResponse} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(EditConfirmation)