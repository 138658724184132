/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import NavigationConfirmation from '../NavigationConfirmation';
import ConfirmSubmit from './ConfirmSubmit';
import $ from 'jquery';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

class AssessmentEvalAidPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show, headerName: "", innerContent: "",
            PMMAssessmentMetricData: [],
            popUpDisplay: false,
            selectedBOI: this.props.selectedBOI,
            selectedLI: this.props.selectedLI,
            selectedPMMProcessDraftID: this.props.selectedPMMProcessDraftID,
            PMMAllDetails: [], Confirm: false, reqSrc: "",
            datadisplayed: [], background: "", needToRevisit: "",
            percentGreen: 0,
            uploadedReportOutDocs: this.props.countOfReportOutDocuments,
            popUpReportDocuments: false,
            closeAssessmentAid: this.props.onReportOutPopup

        }
        this.closePopUp = this.closePopUp.bind(this);
        this.trueCriticalMetric = this.trueCriticalMetric.bind(this);
        this.SaveFinalAssessment = this.SaveFinalAssessment.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.percentageGreenMetric = this.percentageGreenMetric.bind(this);
        this.displayGreenMetric = this.displayGreenMetric.bind(this);
    }

    componentWillReceiveProps(props) {
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show,
                selectedBOI: this.props.selectedBOI,
                selectedLI: this.props.selectedLI,

            });
            this.trueCriticalMetric();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    asIsThresholdComparison(item) {
        try {
            var datadisplayed = {};
            var metricType = "";
            if (item.BOILIType == 1) {
                metricType = 'BOI'
            }
            else if (item.BOILIType == 2) {
                metricType = 'LI'
            }
            else if (item.BOILIType == 3) {
                metricType = 'BOI/LI'
            }
            if (item.LessThanEqulaTo == false) { 
                if (item.AsIs >= item.ThresholdValue) {
                    return {
                        "BOILIName": item.BOILIName,
                        "MetricType": metricType,
                        "MetricValue": i18n.t('capitalYes')
                    }
                }
                else {
                    return {
                        "BOILIName": item.BOILIName,
                        "MetricType": metricType,
                        "MetricValue": i18n.t('capitalNo')
                    }
                }
            }
            else if (item.LessThanEqulaTo == true) { 
                if (item.AsIs <= item.ThresholdValue) {
                    return {
                        "BOILIName": item.BOILIName,
                        "MetricType": metricType,
                        "MetricValue": i18n.t('capitalYes')
                    }
                }
                else {
                    return {
                        "BOILIName": item.BOILIName,
                        "MetricType": metricType,
                        "MetricValue": i18n.t('capitalNo')
                    }
                }
            }
            else if (item.LessThanEqulaTo == null || item.LessThanEqulaTo == undefined) {
                return {
                    "BOILIName": item.BOILIName,
                    "MetricType": metricType,
                    "MetricValue": i18n.t('dependentMetric')
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    percentageGreenMetric(dataTobeDisplayed) {
        try {
            var checkMetric = [];
            var checkYesNo = [];
            var greenMetricCount = 0;
            var totalMetricCount = 0;
            var percentGreen = 0;
            checkMetric = dataTobeDisplayed.map(data => {
                return (
                    data.MetricValue
                )
            })
            totalMetricCount = checkMetric.length;
            checkYesNo = checkMetric.filter(item => item == i18n.t('capitalYes'))

            greenMetricCount = checkYesNo.length;

            percentGreen = ((greenMetricCount / totalMetricCount) * 100).toFixed(2);
            if (isNaN(percentGreen)) {
                percentGreen = 0;
            }
            return percentGreen;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    displayGreenMetric(dataTobeDisplayed) {
        try {
            var PMMScore = this.props.selectedPMMScore;
            var NeedToRevisit = i18n.t('no');
            var Remark = "The Metric performance and PMM score";
            if (this.percentageGreenMetric(dataTobeDisplayed) < 40 && PMMScore <= 1) {

                this.setState({ background: "#14D614" });
                return (NeedToRevisit);

            }
            else if ((this.percentageGreenMetric(dataTobeDisplayed) < 65 && this.percentageGreenMetric(dataTobeDisplayed) >= 40) && (PMMScore <= 2 && PMMScore > 1)) {

                this.setState({ background: "#14D614" });
                return (NeedToRevisit);

            }
            else if ((this.percentageGreenMetric(dataTobeDisplayed) < 80 && this.percentageGreenMetric(dataTobeDisplayed) >= 65) && (PMMScore <= 3 && PMMScore > 2)) {

                this.setState({ background: "#14D614" });
                return (NeedToRevisit);

            }
            else if (this.percentageGreenMetric(dataTobeDisplayed) >= 80 && PMMScore > 3) {

                this.setState({ background: "#14D614" });
                return (NeedToRevisit);

            }
            else {
                NeedToRevisit = i18n.t('no');
                this.setState({ background: "#FF0000" });
                return (NeedToRevisit);

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    trueCriticalMetric() {
        try {
            var resultBOI = this.state.selectedBOI.filter(item => 
                {
                    return(item.IsCriticalMetric == true && item.IsSelected == true)
                })
            var resultLI = this.state.selectedLI.filter(item => item.IsCriticalMetric == true)

            var totalBOILI = resultBOI.concat(resultLI)
            var dataTobeDisplayed = [];
            var leftoutBOI = [];
            var leftoutLI = [];
            var commonLIBOI = [];
            totalBOILI.map((item) => {
                if (item.BOILIType == 1) {
                    leftoutBOI.push(item);

                    dataTobeDisplayed.push(this.asIsThresholdComparison(item));
                }
                else if (item.BOILIType == 2) {
                    leftoutLI.push(item);
                    dataTobeDisplayed.push(this.asIsThresholdComparison(item));
                }
                else {
                    var repeat = commonLIBOI.filter(r => r.BOILIName == item.BOILIName)
                    if (repeat.length == 0) {
                        commonLIBOI.push(item);

                        dataTobeDisplayed.push(this.asIsThresholdComparison(item));
                    }
                }

            })
            this.setState({
                datadisplayed: dataTobeDisplayed,
                percentGreen: this.percentageGreenMetric(dataTobeDisplayed),
                needToRevisit: this.displayGreenMetric(dataTobeDisplayed)
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveFinalAssessment(e) {
        try {
            let evtSrc = "";
            if (e.target.value == i18n.t('ignoreSuggandProceedToSubmit')) {
                evtSrc = i18n.t('SubmitFinalAssessment');
            }
            else if (e.target.value == i18n.t('ignoreSuggandClose')) {
                evtSrc =i18n.t('SaveasDraft');
            }

            var PMMAllDetails = {
                LoggedInUserId: this.props.enterpriseId
                , PMMProcessDraftID: this.state.selectedPMMProcessDraftID
            };
           

            if (evtSrc == i18n.t('SubmitFinalAssessment')) {
                if (this.state.uploadedReportOutDocs > 0 || this.props.groupId == 1) {
                    this.setState({ popUpReportDocuments: false, Confirm: true, reqSrc: evtSrc, PMMAllDetails: PMMAllDetails });
                }
                else {
                    if(this.state.closeAssessmentAid==true){
                        this.closePopUp();
                    }
                    alert(i18n.t("PleaseuploadReportOutput"))
                }
            } else {
                this.setState({ popUpReportDocuments: false, Confirm: true, reqSrc: evtSrc, PMMAllDetails: PMMAllDetails });
            }

         
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp(eve) {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve == true) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage,
                    popUpReportDocuments: false
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage,
                        Confirm: false,
                        popUpReportDocuments: false
                    })
                }
                else if (navigationPage == "Take New Assessment") {
                    this.setState({popUpReportDocuments: false});
                    var data= {
                                 LoggedInUserId: this.props.enterpriseId,
                                 PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                                 FlagId: 2
                             };
                request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST',data)
                .then(Response => {
                    $("#loader-div").hide();
                })
                .catch(error => {
                    alert(error.statusText);
                            $("#loader-div").hide();
                });
                    this.props.history.push(url);
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        <div style={{ height: '500px' }} className="ag-theme-material padding5">
            <AgGridReact ref="grd_PMMAssessmentDetails" id="PMMAssessmentDetails_grd"
                enableColResize={false}
                paginationPageSize="10"
                headerHeight="45"
                rowmodeltype="pagination"
                quickFilterText={this.state.quickFilterText}
                onGridReady={this.onGridReady.bind(this)}
                localeText={AgGridLocale()}
                overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
        </div>

        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
        <h5 style={{ backgroundColor: "#3074ca", color: "#ffffff", paddingTop: '10px', paddingBottom: '10px', fontSize: '20px', marginTop: '20px' }} align='center'><b>{i18n.t('assessmentEvaluationAid')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Table border className="assessmentaidTableth">
                            <tbody>
                                <tr>
                                    <th className="assessmentaidTableth">{i18n.t('PMMScore')}</th>

                                    <td className="assessmentaidTableth" align='center'>{this.props.selectedPMMScore}</td>
                                </tr>
                                <tr>
                                    <th className="assessmentaidTableth">{i18n.t('%Metricsbetterthanthresholdvalue')}</th>
                                    <td className="assessmentaidTableth" align='center'>{this.state.percentGreen}%</td>
                                </tr>
                                <tr>
                                    <th className="assessmentaidTableth">{i18n.t('Needtorevisitthmetricsandassessment')}?</th>
                                    <td className="assessmentaidTableth" style={{ "backgroundColor": this.state.background, color: "#ffffff", fontSize: '15px', fontWeight: 'bold' }} align='center'>{this.state.needToRevisit}</td>
                                </tr>
                                <tr>
                                    <th className="assessmentaidTableth">{i18n.t('Remark')}</th>
                                    <td className="assessmentaidTableth" align='center'>
                                        {
                                            this.state.needToRevisit == i18n.t('yes') ?
                                                i18n.t('asIsMetricAndPMMScoreAreNotAligned')
                                                :
                                                ""
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <style marginTop='10px' />
                        <Table striped bordered condensed>
                            <thead>
                                <tr>
                                    <th className="assessmentaidTableheader" style={{ backgroundColor: '#BFBEBE' }}>{i18n.t('Metric')}</th>
                                    <th className="assessmentaidTableheader" style={{ width: '269px', backgroundColor: '#BFBEBE' }}>{i18n.t('MetricType')}</th>
                                    <th className="assessmentaidTableheader" style={{ backgroundColor: '#BFBEBE' }}>{i18n.t('AsIsMetricvalueisbetterthanequal')}? </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.datadisplayed.map(data => {
                                    return (
                                        <tr>
                                            <td>{data.BOILIName}</td>
                                            <td align='center'>{data.MetricType}</td>
                                            <td align='center'>{data.MetricValue}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </Modal.Body>
                    <Modal.Footer style={{ textAlign: 'center' }}>

                        <input id="txt_revisitLaterBackToHomeScreen"style={{ fontSize: '15px', borderRadius: '22px' }} type="button" className="btn btn-success" data-content="HOME" data-url="/Home" value={i18n.t('revisitLaterBackToHomeScreen')} onClick={this.onNavigationClick} />
                        <input id="txt_revisitNowGoToBOIScreen"style={{ fontSize: '15px', borderRadius: '22px' }} type="button" className="btn btn-success" data-content="BOI" data-url="/BOI" value={i18n.t('revisitNowGoToBOIScreen')} onClick={this.onNavigationClick} />
                        <input id="txt_ignoreSuggestionAndClose"style={{ fontSize: '15px', borderRadius: '22px' }} type="button" className="btn btn-success" value={i18n.t('ignoreSuggandClose')} onClick={this.SaveFinalAssessment.bind(this)} />
                        <input id="txt_ignoreSuggestionAndProceedToSubmission"style={{ fontSize: '15px', borderRadius: '22px' }} type="button" className="btn btn-success" value={i18n.t('ignoreSuggandProceedToSubmit')} type="submit" onClick={this.SaveFinalAssessment.bind(this)} />

                    </Modal.Footer>
                </Modal>
                {this.state.Confirm ? <ConfirmSubmit show={this.state.Confirm} reqSrc={this.state.reqSrc} PMMAllDetails={this.state.PMMAllDetails} /> : null}
                {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay} url={this.state.toURL} navigationPage={this.state.navigationPage} isEditAcitve={false} closeCallback={this.closeNavigationPopUp} />}
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
	    languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
    }
}

AssessmentEvalAidPopup = connect(mapStateToProps)(withRouter(AssessmentEvalAidPopup))
export default AssessmentEvalAidPopup