/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import langKeys from './Utils/LangKeys'
import { withTranslation } from 'react-i18next';


class AboutPMM extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show, headerName: "", innerContent: ""
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.handleResponse = this.handleResponse.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {

        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {

        try {
            //window.tfo_event("PMM-Home-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            this.setState({
                show: this.props.show
            });
            var path = window.location.href;
            var pageArr = path.split("/");
            var pageName = pageArr[pageArr.length - 1];
            if (pageName == "" || pageName == "Home") {
                this.setState({ headerName: i18n.t("aboutPMM") });
                this.setState({
                    innerContent: <span>
                       <p className="infoHeaderContent" id="canAssessmentBeTaken">1.  {i18n.t('canAssessmentBeTaken')}</p>
                        <p className="infoContent" id="yesAssessmentCanBeTaken">{i18n.t('yesAssessmentCanBeTaken')}</p>
                        <p className="infoHeaderContent" id="howCanIViewTheList">2.	 {i18n.t('howCanIViewTheList')}</p>
                        <p className="infoContent" id="onTakeNewAssesmentScreen">{i18n.t('onTakeNewAssesmentScreen')}</p>
                        <p className="infoHeaderContent" id="howCanIViewTheMultiple">3.  {i18n.t('howCanIViewTheMultiple')}</p>
                        <p className="infoContent" id="theSubmittedAssessmentsWouldBeSaved">{i18n.t('theSubmittedAssessmentsWouldBeSaved')}</p>
                        <p className="infoContent" id="selectRequiredCombinationFrom">{i18n.t('selectRequiredCombinationFrom')}</p>
                        <p className="infoContent" id="clickOnViewIconToView">{i18n.t('clickOnViewIconToView')}</p>
                        <p className="infoContent" id="allTheAssessmentDoneForThat">{i18n.t('allTheAssessmentDoneForThat')}</p>
                        <p className="infoHeaderContent" >4. {i18n.t('canIEmailTheAssessmentResults')}</p>
                        <p className="infoContent">{i18n.t('noTheAssessmentCanBeDownloadedIn')}</p>
                        <p className="infoHeaderContent">5.  {i18n.t('canTheAssessmentScoresBeOverridden')}</p>
                        <p className="infoContent">{i18n.t('yesIfAssesmentIsAlreadySaved')}</p>
                        <p className="infoHeaderContent">6.	{i18n.t('iAmNotAbleToLocateSavedAssesment')}</p>
                        <p className="infoContent">{i18n.t('reviewTheListofSaved')}</p>
                        <p className="infoHeaderContent">7.  {i18n.t('whichBrowseToUseToLogin')}</p>
                        <p className="infoContent">{i18n.t('thisApplicationIsBestViewedIn')} <b>{i18n.t('googleChrome')}</b> {i18n.t('and')} <b>"1600 X 900"</b> {i18n.t('screenResolution')}.</p>
                        <p className="infoHeaderContent">8. {i18n.t('whereDoIBeginToTakeTheAssessment')}</p>
                        <p className="infoContent">{i18n.t("CreateNewasssesallowsUserToInitiateNewAssessment")} </p>
                        <p className="infoHeaderContent">9.  {i18n.t('whoDoContactForAnyQueriesOrSupport')}</p>
                        <p className="infoContent">{i18n.t('contact')} 
                        {/* {localStorage.getItem("GroupID") == 1 ?  */}
                        {this.props.groupId == 1 ? 
                                <b> PMMTool.Support@accenture.com </b>
                                : <b> g.tejwani@accenture.com / soumya.arora@accenture.com </b>}
                        {i18n.t('forAnyQueriesOrSupport')}</p>
                    </span>
                })
            }
            else if (pageName == "CreateNewAssessment") {
                this.setState({ headerName: i18n.t("aboutCreateNewAssessment") });
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent"><b>1.  {i18n.t('howDoIStartAfterClickingOn')}</b></p>
                        <p className="infoContent">{i18n.t('selectThedealnamefromTheDropdown')}</p>
                        <p className="infoContent">{i18n.t('TheOGandIndustryprepopulated')}</p>
                        <p className="infoContent">{i18n.t('SelecttheRegionfromdropdown')}</p>
                        <p className="infoContent">{i18n.t("selectaccentureDeliveryLocation")}</p>
                        <p className="infoContent">{i18n.t('selectCapabilitTowerandServiceComponent')} </p>
                        <p className="infoContent">{i18n.t('click')} <b>{i18n.t('“startAssessment”')}.</b></p>
                    </span>
                });
            }
            else if (pageName == "BOI" || pageName == "BOIView") {
                this.setState({ headerName: i18n.t('aboutBOI')});
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent">1. {i18n.t('whatDoesTheBOI')}</p>
                        <p className="infoContent">{i18n.t('theBOiKeyBusinesMetric')}</p>
                        <p className="infoHeaderContent">2.  {i18n.t('whereDoISeeTheDefinitions')}?</p>
                        <p className="infoContent">{i18n.t('placeTheCursorArrowOnThe')} </p>
                        <p className="infoHeaderContent">3.  {i18n.t('isThereAnyBenchmark')}?</p>
                        <p className="infoContent">{i18n.t('Yesthebenchmarkcolumdisplay')}.</p>
                        <p className="infoHeaderContent">4.  {i18n.t('ineedmakechangestotheboi')}?</p>
                        <p className="infoContent">{i18n.t('onthrtoprightclickontheboi')} <span className="infoTick"></span> {i18n.t('nagetivesymboltogoback')}.</p>
                        <p className="infoHeaderContent">5. {i18n.t('howdoiproceedfurther')}?</p>
                        <p className="infoContent">{i18n.t('usethecheckbxtoselecttheboi')} </p>
                        <p className="infoContent">{i18n.t('youcanupdatethsdisplay')}</p>
                        <p className="infoContent">{i18n.t('updatethe')} <b>{i18n.t('Asis')}</b>{i18n.t('valuefortheboi')}.</p>
                        <p className="infoContent">{i18n.t('clickon')} <b>{i18n.t('generateLeadIndicator')}</b> {i18n.t('button')}.</p>
                        <p className="infoContent">{i18n.t('thetoolshowsapopup')} {i18n.t('bOIKeyBusinessMetric')}. </p>
                        <p className="infoContent">{i18n.t('click')} <b>{i18n.t('"ok"')}</b> {i18n.t('andwilltakeyoutothe')} : {i18n.t('leadIndicator')}.</p>
                        <p className="infoHeaderContent">6.  {i18n.t('afterSelectingtheBOIKey')}?</p>
                        <p className="infoContent">{i18n.t('noTheassessmentsequenchastbe')}.</p>
                    </span>
                });
            }
            else if (pageName == "LeadingIndicator" || pageName == "LIView") {
                this.setState({ headerName: i18n.t("AboutLeadingIndicator") });
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent">1.  {i18n.t('canIselecttheLead')}?</p>
                        <p className="infoContent">{i18n.t('Nothereisnoselectionoption')}. </p>
                        <p className="infoHeaderContent">2.  {i18n.t('iCurrnetlydonotHaveData')}?</p>
                        <p className="infoContent">{i18n.t('NoLeadIndicatorshavetobeenteredwithvalidValues')}.</p>
                        <p className="infoHeaderContent">3. {i18n.t('isthereanybenchmarkVakues')}?</p>
                        <p className="infoContent">{i18n.t('yesthebenchmarkcolumDisplay')}.</p>
                        <p className="infoHeaderContent">4.  {i18n.t('howDiIproccedFurther')}?</p>
                        <p className="infoContent">{i18n.t('TheBlueHighlightedareaistheActioncolumnthatallows')}
                                          </p>
                        <p className="infoContent"> {i18n.t("Buttontosavethevalueskeyed")}</p>
                        <p className="infoContent">{i18n.t("clickontakePMMAssessment")}</p>
                        <p className="infoHeaderContent">5.  {i18n.t('whenIclickontakePMMAssessmentIgetanerrorstating')} </p>
                        <p className="infoContent">{i18n.t('AllfieldsTobeanAsisismandatory')}</p>
                        <p className="infoHeaderContent">6.	{i18n.t('Iwanttochangethe')}</p>
                        <p className="infoContent">{i18n.t('YesThechangescanbemade')}.</p>
                        <p className="infoContent">{i18n.t('OnTheTopRightclickontheLead')} <span className="infoTick"></span> </p>
                        <p className="infoContent"><b>{i18n.t('note')}:</b> {i18n.t('Youcanusethewizard')}.</p>
                        <p className="infoHeaderContent">8.	 {i18n.t('canIaddadditional')}?</p>
                        <p className="infoContent">{i18n.t('Yesyouaddadditionalleadindicatorbyclicking')}.</p>
                    </span>
                });
            }
            else if (pageName == "PMMAssessment" || pageName == "PMMAssessmentView") {
                this.setState({ headerName: i18n.t("AboutPMMAssessment") });
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent">1. {i18n.t('howdoiproceesFurther')}?</p>
                        <p className="infoContent">{i18n.t('thepmmpagecontainsmaturity')}.</p>
                        <p className="infoContent">{i18n.t('OnThisScreenYouComplete')} </p>
                        <p className="infoContent">{i18n.t('ForalltheMaturityelements')}</p>
                        <p className="infoContent">{i18n.t('Thesearchfieldenableyoutofindany')}</p>
                        <p className="infoContent">{i18n.t('thebottomoftheassesmentshow')} </p>
                        <p className="infoContent">{i18n.t('allthematurityelementshouldbe')}.</p>
                <p className="infoHeaderContent">2.  {i18n.t('canIChangeassesment')}<br/>{i18n.t('YesThechangesbemade')}</p>
                        <p className="infoContent">{i18n.t("OnTheTopRightclickonthePMMAssessment")} <span className="infoTick"></span> {i18n.t('NavigateSymboltogoback')}.</p>
                        <p className="infoHeaderContent">3. {i18n.t('fewmaturityelementdonothave')}?</p>
                        <p className="infoContent">{i18n.t('thematuritylevelsarenotavaiable')}.</p>
                    </span>
                });
            }
            else if (pageName == "PotentialProject" || pageName == "PotentialProjectView") {
                this.setState({ headerName: i18n.t("AboutPotentialProject") });
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent">1.  {i18n.t('whatisthectionrequired')}?</p>
                        <p className="infoContent">{i18n.t('inthebluethehighlighted')} <b>{i18n.t('"yes"')}</b> {i18n.t('or')} <b>{i18n.t('"no"')}</b></p>
                        <p className="infoContent">{i18n.t('copytheprojectfromindicative')}</p>
                        <p className="infoContent">{i18n.t('onclickinganyprojectRow')}.</p>
                        <p className="infoContent">{i18n.t('youcaneitherclicksaveandremain')} <b>{i18n.t('gENERATEPROJECTPRIORITIZATION')}</b> {i18n.t('togotonextscreen')}.</p>
                        <p className="infoContent">{i18n.t('therearetwocheckboxes')} <b>{i18n.t('"As-Is"')}</b> {i18n.t('and')} <b>{i18n.t('"To-Be"')}</b> {i18n.t('scoreisequaltofour')}.</p>
                    </span>
                });
            }
            else if (pageName == "StoryBoard" || pageName == "StoryBoardView") {
                this.setState({ headerName: i18n.t("AboutStoryBoard") });
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent">1.  {i18n.t('WhatdoestheSTORYBOARD')}?</p>
                        <p className="infoContent">{i18n.t('thispagegivesabargraph')}</p>
                        <p className="infoContent">{i18n.t('lowerhalfofgapassesment')} <b>{i18n.t('"Asis"')}</b> {i18n.t('and')} <b>{i18n.t('"toBe"')}</b> {i18n.t('scoreforeachofmaturityelement')}.</p>
                        <p className="infoContent">{i18n.t('thereisonlyavisualPresentaion')}.</p>
                        <p className="infoContent">{i18n.t('remainonthesamescreen')} <b>{i18n.t('gENERATEPOTENTIALPROJECTS')}</b> {i18n.t('togotonextscreen')}.</p>
                    </span>
                });
            }
            else if (pageName == "ProjectPrioritization" || pageName == "ProjectPrioritizationView") {
                this.setState({ headerName: i18n.t("AboutProjectPrioritization") });
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent">1.  {i18n.t('WhatdoesPROJECTPRIORITIZATION')}?</p>
                        <p className="infoContent">{i18n.t('thisPagecontainstheList')}.</p>
                        <p className="infoContent">{i18n.t('projectpriorityhasthreesubviews')}.</p>
                        <p className="infoContent">{i18n.t('TheassessmentcanbeSubmitted')} <b>{i18n.t('"submit"')}</b> {i18n.t('button')}.</p>
                        <p className="infoContent">{i18n.t('press')} <b>{i18n.t('“yes”')}</b> {i18n.t('tosubmmittheassesment')}.</p>
                        <p className="infoContent">{i18n.t('press')} <b>{i18n.t('“no”')}</b> {i18n.t('toremainonthecurrentscreen')}.</p>
                        <p className="infoContent">{i18n.t('orPressclosetosave')}.</p>
                    </span>
                });
            }
            else if (pageName == "ViewPreviousAssessmentHome") {

                this.setState({ headerName: i18n.t("AboutViewPreviousAssessment") });
                this.setState({
                    innerContent: <span>
                        <p className="infoHeaderContent">1.  {i18n.t('canIcomparetheresultforaservice')}?</p>
                        <p className="infoContent">{i18n.t('yescomparisionforAPMMassesmentforaService')}.</p>
                        <p className="infoHeaderContent">2.	{i18n.t('HowcanIviewdetailsof')}?</p>
                        <p className="infoContent">{i18n.t('onviewassesmentscreen')}.</p>
                        <p className="infoContent">{i18n.t('Allthrassesmentforselectedwould')}.</p>
                        <p className="infoContent">{i18n.t('ClickViewIcononextramLeft')}.</p>
                    </span>
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleResponse(eve) {
        try {
            let targetText = eve.target.value;
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        return (
            <div>
                <Modal show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{this.state.headerName}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.innerContent}

                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" className="btn btn-info pull-right" value={i18n.t("close")} onClick={this.handleResponse} />

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        groupId:state.groupId,
    }
}

AboutPMM = connect(mapStateToProps)(withRouter(withTranslation()(AboutPMM)))

export default AboutPMM