/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18next from 'i18next';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';

class UpdateOMIDAndFTEs extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show, headerName: "", innerContent: "",
                lstOMID: [], OMIDValue: [], FTEs: "",
                lstMMSID: [], MMSID: "", MMSIDValue: []
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.saveOMIDAndFtes = this.saveOMIDAndFtes.bind(this);
            this.UpdateMMSIDFTE = this.UpdateMMSIDFTE.bind(this);
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {

        try {
            this.setState({
                OMIDValue: this.props.selectedDetails.OMID != [] ? [{ "label": this.props.selectedDetails.OMIDValue, "value": this.props.selectedDetails.OMID }] : [],
                FTEs: this.props.selectedDetails.FTECount != null ? this.props.selectedDetails.FTECount : "",
                MMSIDValue: this.props.selectedDetails.MMSID != [] ? [{ "label": this.props.selectedDetails.MMSID, "value": this.props.selectedDetails.MMSID }] : [],
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show
            });

            this.props.selectedDetails.FTECount != null ? this.setState({ FTEs: this.props.selectedDetails.FTECount }) : this.setState({ FTEs: "" });
            this.props.selectedDetails.OMID != null ? this.setState({ OMIDValue: [{ "label": this.props.selectedDetails.OMIDValue.toString(), "value": this.props.selectedDetails.OMID.toString() }] }) : this.setState({ OMIDValue: [] });
            this.props.selectedDetails.MMSID != null ? this.setState({ MMSIDValue: [{ "label": this.props.selectedDetails.MMSID, "value": this.props.selectedDetails.MMSID }] }) : this.setState({ MMSIDValue: [] });

            let omidData = {
                "UserName": this.props.enterpriseId,	
                "ID": 0,
                "DealID": this.props.selectedDetails.DealName.value,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetOMIDBasedOnDeal", 'POST', omidData)
                .then(response => {

                    response != [] ? this.setState({ lstOMID: response }) : this.setState({ lstOMID: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

            let dealData = {
                "UserName": this.props.enterpriseId,	
                "DealID": this.props.selectedDetails.DealName.value,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetMMSIDBasedOnDeal", 'POST', dealData)
                .then(response => {
                    response != [] ? this.setState({ lstMMSID: response }) : this.setState({ lstMMSID: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveOMIDAndFtes(eve) {
        try {

            if (this.refs.idFTEs.value == "" || this.refs.idFTEs.value == "0") {
                alert(i18n.t('pleaseenterFTEsvalue'));
                return false;
            }
            else if (parseFloat(this.refs.idFTEs.value) < 0) {
                alert(i18n.t('pleaseenterFTEsvalue'));
                return false;
            }
            else if (this.refs.idFTEs.value != "") {
                let ftesNumStatus = true;
                if(!isNaN(parseFloat(this.refs.idFTEs.value)) && isFinite(this.refs.idFTEs.value)){
                    ftesNumStatus = null;
                }
                else{
                    ftesNumStatus = false;
                }
                if (ftesNumStatus == false) {
                    alert(i18n.t('pleaseenterFTEsvaluenumeric'));
                    return false;
                }
            }

            if ( this.props.groupId == "1") {
                if (this.state.OMIDValue[0].length == 0 || this.state.OMIDValue == null) {
                    alert(i18n.t('plsSelectOMID'));
                    return false;
                }

                $("#loader-div").show();
                let OMIDFTEDetails = {
                    LoggedInUserId: this.props.enterpriseId,
                    PMMProcessDraftID: this.props.selectedDetails.PMMProcessDraftID,
                    OMID: this.state.OMIDValue[0].value,
                    MMSID: this.props.selectedDetails.MMSID,
                    FTECount: this.refs.idFTEs.value,
                    GroupID: this.props.selectedDetails.GroupID
                };

                var data = OMIDFTEDetails;
                request("/Common/UpdateOMIDForPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                    .then(res => {

                        if (res.Outputcode == 1) {
                            alert(res.outputmessage);
                            this.props.propshowUpdatedRecord();
                            this.setState({
                                show: false
                            });
                        }
                        else {
                            alert(res.outputmessage);
                        }

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
                this.UpdateMMSIDFTE()
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    UpdateMMSIDFTE() {
        try {
            if (this.state.MMSIDValue == [] || this.state.MMSIDValue == null || this.state.MMSIDValue[0].label == "0") {
                alert(i18n.t('plsSelectMMSID'));
                return false;
            }

            $("#loader-div").show();
            let MMSIDFTEDetails = {
                LoggedInUserId: this.props.enterpriseId,
                PMMProcessDraftID: this.props.selectedDetails.PMMProcessDraftID,
                OMID: this.props.selectedDetails.OMID,
                MMSID: this.state.MMSIDValue[0].label,
                FTECount: this.refs.idFTEs.value,
                GroupID: this.props.selectedDetails.GroupID
            };

            var data = MMSIDFTEDetails;
            request("/Common/UpdateOMIDForPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                .then(res => {

                    if (res.Outputcode == 1) {
                        alert(res.outputmessage);
                        this.props.propshowUpdatedRecord();
                        this.setState({
                            show: false
                        });
                    }
                    else {
                        alert(res.outputmessage);
                    }

                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        } catch (error) {
            console.log("Error Encountered");
            $("#loader-div").hide();
        }
    }

    SelectOMID(val) {
        try {
            this.setState({
                OMIDValue: val
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectMMSID(val) {
        try {
            this.setState({
                MMSIDValue: val
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleFTEsChange(val) {

        try {
            this.setState({ FTEs: val.currentTarget.value, Confirm: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {

        return (
            <div>
                <Modal show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <div className="col-md-12">
                            <h4>

                                <div className="pull-left">
                                    <span className='statustxt-orng-italic' title={'Deal: ' + this.props.selectedDetails.DealName.label + ' || '}> {this.props.selectedDetails.DealName.label}</span>
                                </div>
                            </h4>
                        </div>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-6 form-horizontal">
                                {(this.props.groupId == "1") ?
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" id="lbl_omId" ><span style={{ color: "red" }}>*</span>{i18n.t('omid')}:</label>
                                        <div className="col-sm-8">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_omid" ref="ddlOMId" name="form-field-name" labelKey="label" onChange={this.SelectOMID.bind(this)} options={this.state.lstOMID} selected={this.state.OMIDValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                        </div>
                                    </div>
                                    :
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" id="lbl_mmsId" ><span style={{ color: "red" }}>*</span>{i18n.t('mMSID')}:</label>
                                        <div className="col-sm-8">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_mmsid" ref="ddlMMSId" name="form-field-name" labelKey="value" onChange={this.SelectMMSID.bind(this)} options={this.state.lstMMSID} selected={this.state.MMSIDValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-6 form-horizontal">
                                <div className="form-group">
                                    <label className="control-label col-sm-4" id="lbl_fte" ><span style={{ color: "red" }}>*</span>{i18n.t('FTEs')}:</label>
                                    <div className="col-sm-8">
                                        <input ref="idFTEs" id="txt_idFTEs" className="form-control" value={this.state.FTEs} onChange={(e) => { this.handleFTEsChange(e) }} type="text" maxLength="100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_update" className="btn btn-success" value={i18n.t("update")} onClick={this.saveOMIDAndFtes} />
                        <input type="button" id="btn_cancel" className="btn btn-danger" value={i18n.t("cancel")} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

UpdateOMIDAndFTEs = connect(mapStateToProps)(withRouter(UpdateOMIDAndFTEs))
export default UpdateOMIDAndFTEs;