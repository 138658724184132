/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import $ from 'jquery';
import PptxGenJS from 'pptxgenjs';
import backArrowIcon from '../../images/icon-back-arrow.png';
import powerpointIcon from '../../images/powerpoint-icon.png';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';



class TrendReport extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.checkLength = this.checkLength.bind(this);
            this.downloadPDF = this.downloadPDF.bind(this);
            this.downloadPPT = this.downloadPPT.bind(this);

            this.state = {
                datasetNames: [],
                chartData: [],
                startDate: "",
                endDate: ""
            }

            this.startDateChange = this.startDateChange.bind(this);
            this.endDateChange = this.endDateChange.bind(this);
            this.clear = this.clear.bind(this);
            this.showTrendReport = this.showTrendReport.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        try {            
            // if(!this.props.Assessmentdetails.Deal[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-18", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.Deal[0].label)
            // }
            if (this.props.TrendReportdetails.ReportType == i18n.t('overall')) {
                var fromDate = "";
                var toDate = "";
                let trendData = {
                    "DealId": this.props.Assessmentdetails.Deal[0].value,
                    "SCId": this.props.Assessmentdetails.ServiceComponent[0].value,
                    "fromDate": fromDate,
                    "toDate": toDate,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/TrendGraph", 'POST', trendData)
                    .then(response => {
                        var trendData = response.Table;

                        var keyNames = [];
                        var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                            "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                            "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                            "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];
                        trendData.map((item, index) => {
                            if(index == 0){
                                keyNames = Object.keys(item);
                            }
                        });

                        keyNames.splice(0, 1); // Remove SubmittedOn from the List

                        var labelArr = [];

                        for (var cnt = 0; cnt < trendData.length; cnt++) {
                            labelArr.push(trendData[cnt].SubmittedOn);
                        }

                        var newArr = [];
                        for (var index in keyNames) {
                            if (keyNames[index] != "SubmittedOn") {

                                var valArr = [];
                                for (var i = 0; i < trendData.length; i++) {
                                    trendData[i][keyNames[index].toString()] != null ?
                                        valArr.push(trendData[i][keyNames[index].toString()]) :
                                        valArr.push(NaN);
                                }

                                if (keyNames[index] == "IndustryBenchmark" || keyNames[index] == "OverAllBenchmark") {
                                    if (valArr.length == 1) {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 5,
                                            pointHoverRadius: 5
                                        });
                                    }
                                    else {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 1,
                                            pointHoverRadius: 1
                                        });
                                    }

                                }
                                else {
                                    newArr.push({
                                        label: keyNames[index],
                                        backgroundColor: "transparent",
                                        borderColor: color[index],
                                        pointBackgroundColor: color[index],
                                        data: valArr,
                                        showLine: true,
                                        spanGaps: true,
                                        pointRadius: 5,
                                        pointHoverRadius: 5
                                    });
                                }

                            }
                        }
                        this.setState({ datasetNames: labelArr, chartData: newArr });
                        this.LineChart();
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
            else if (this.props.TrendReportdetails.ReportType == i18n.t('individual')) {
                let trendData = {
                    "DealId": this.props.Assessmentdetails.Deal[0].value,
                    "SCId": this.props.Assessmentdetails.ServiceComponent[0].value,
                    "RegionName": this.props.TrendReportdetails.RegionName,
                    "DLName": this.props.TrendReportdetails.DLName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/TrendGraphByRegionAndDeliveryLocation", 'POST', trendData)
                    .then(response => {
                        var trendData = response.Table;

                        var keyNames = [];
                        var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                            "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                            "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                            "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];
                        trendData.map((item, index) => {
                            if(index == 0){
                                keyNames = Object.keys(item);
                            }
                        });

                        keyNames.splice(0, 1); // Remove SubmittedOn from the List

                        var labelArr = [];

                        for (var cnt = 0; cnt < trendData.length; cnt++) {
                            labelArr.push(trendData[cnt].Createddate);
                        }

                        var newArr = [];
                        for (var index in keyNames) {
                            if (keyNames[index] != "Createddate") {

                                var valArr = [];
                                for (var i = 0; i < trendData.length; i++) {
                                    trendData[i][keyNames[index].toString()] != null ?
                                        valArr.push(trendData[i][keyNames[index].toString()]) :
                                        valArr.push(NaN);
                                }
                                if (keyNames[index] == "IndustryBenchmark" || keyNames[index] == "OverAllBenchmark") {
                                    if (valArr.length == 1) {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 5,
                                            pointHoverRadius: 5,
                                            borderDash: [10, 5]
                                        });
                                    }
                                    else {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 1,
                                            pointHoverRadius: 1,
                                            borderDash: [10, 5]
                                        });
                                    }

                                }
                                else {
                                    newArr.push({
                                        label: keyNames[index],
                                        backgroundColor: "transparent",
                                        borderColor: color[index],
                                        pointBackgroundColor: color[index],
                                        data: valArr,
                                        showLine: true,
                                        spanGaps: true,
                                        pointRadius: 5,
                                        pointHoverRadius: 5
                                    });
                                }

                            }
                        }
                        this.setState({ datasetNames: labelArr, chartData: newArr });
                        this.LineChart();
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    checkLength(arrData) {
        try {
            let arrLen = arrData.length;
            if (arrLen <= 2) {
                arrData.push("*NA");
            }
            if (arrLen <= 1) {
                arrData.push("*NA");
            }
            return arrData;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    LineChart() {
        try {
            Chart.defaults.global.plugins.datalabels.display = false;
            var line_ctx = document.getElementById('line').getContext('2d');

            var lineChartData = {
                labels: this.state.datasetNames,
                datasets: this.state.chartData,
            }

            window.line_chart = new Chart(line_ctx, {
                type: 'line',
                data: lineChartData,
                options: {

                    elements: {
                        line: {
                            tension: 0, // disables bezier curves
                        }
                    },
                    tooltips: {
                        mode: 'point',

                    }, legend: {
                        display: true,
                        labels: {
                            fontFamily: "Comic Sans MS"
                        }
                    }, scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,

                                min: 1,
                                max: 4
                            }
                        }]
                    }

                }
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    downloadPDF() {

    }

    downloadPPT() {
        //For whole page export
        try {
            window.scrollTo(0, 0);
            html2canvas(document.getElementById("trend-report-section")).then(canvas_whole => {
                let wholePag = canvas_whole.toDataURL();
                let wholePagURI = wholePag.split(':');
                var ppt = new PptxGenJS();
                var slides = ppt.addNewSlide();
                slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.5, w: 9.0, h: 5.0 });
                ppt.save(i18n.t('wholeTrendReport'));
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    startDateChange(date) {
        try {
            var dt = new Date(date._d);
            this.setState({ startDate: date });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    endDateChange(date) {
        try {
            var dt = new Date(date._d);
            this.setState({ endDate: date });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    showTrendReport() {
        try {
            var fromdate = this.state.startDate;
            var toDate = this.state.endDate;

            if (this.props.TrendReportdetails.ReportType == i18n.t('overall')) {
                let trendData = {
                    "DealId": this.props.Assessmentdetails.Deal[0].value,
                    "SCId": this.props.Assessmentdetails.ServiceComponent[0].value,
                    "fromDate": fromdate,
                    "toDate": toDate,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/TrendGraph", 'POST', trendData)
                    .then(response => {
                        var trendData = response.Table;
                        var graphData = [];
                        var keyNames = [];
                        var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                            "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                            "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                            "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];
                        trendData.map((item, index) => {
                            if(index == 0){
                                keyNames = Object.keys(item);
                            }
                        });

                        keyNames.splice(0, 1); // Remove SubmittedOn from the List

                        var labelArr = [];

                        for (var cnt = 0; cnt < trendData.length; cnt++) {
                            labelArr.push(trendData[cnt].SubmittedOn);
                        }

                        var newArr = [];
                        for (var index in keyNames) {
                            if (keyNames[index] != "SubmittedOn") {

                                var valArr = [];
                                for (var i = 0; i < trendData.length; i++) {
                                    trendData[i][keyNames[index].toString()] != null ?
                                        valArr.push(trendData[i][keyNames[index].toString()]) :
                                        valArr.push(NaN);
                                }

                                if (keyNames[index] == "IndustryBenchmark" || keyNames[index] == "OverAllBenchmark") {
                                    if (valArr.length == 1) {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 5,
                                            pointHoverRadius: 5
                                        });
                                    }
                                    else {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 1,
                                            pointHoverRadius: 1
                                        });
                                    }

                                }
                                else {
                                    newArr.push({
                                        label: keyNames[index],
                                        backgroundColor: "transparent",
                                        borderColor: color[index],
                                        pointBackgroundColor: color[index],
                                        data: valArr,
                                        showLine: true,
                                        spanGaps: true,
                                        pointRadius: 5,
                                        pointHoverRadius: 5
                                    });
                                }

                            }
                        }
                        this.setState({ datasetNames: labelArr, chartData: newArr });
                        this.LineChart();
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
            else if (this.props.TrendReportdetails.ReportType == i18n.t('individual')) {
                request("/Common/TrendGraphByRegionAndDeliveryLocation?UserName=" + this.props.enterpriseId + "&DealID=" + this.props.Assessmentdetails.Deal[0].value + "&SCID=" + this.props.Assessmentdetails.ServiceComponent[0].value + "&RegionName=" + this.props.TrendReportdetails.RegionName + "&DLNam=" + this.props.TrendReportdetails.DLName + "&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
               
                let trendData = {
                    "DealId": this.props.Assessmentdetails.Deal[0].value,
                    "SCId": this.props.Assessmentdetails.ServiceComponent[0].value,
                    "RegionName": this.props.TrendReportdetails.RegionName,
                    "DLName": this.props.TrendReportdetails.DLName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/TrendGraphByRegionAndDeliveryLocation", 'POST', trendData)
                    .then(response => {
                        var trendData = response.Table;

                        var keyNames = [];
                        var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                            "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                            "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                            "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];
                        trendData.map((item, index) => {
                            if(index == 0){
                                keyNames = Object.keys(item);
                            }
                        });

                        keyNames.splice(0, 1); // Remove SubmittedOn from the List

                        var labelArr = [];


                        for (var cnt = 0; cnt < trendData.length; cnt++) {
                            labelArr.push(trendData[cnt].Createddate);
                        }

                        var newArr = [];
                        for (var index in keyNames) {
                            if (keyNames[index] != "Createddate") {

                                var valArr = [];
                                for (var i = 0; i < trendData.length; i++) {
                                    trendData[i][keyNames[index].toString()] != null ?
                                        valArr.push(trendData[i][keyNames[index].toString()]) :
                                        valArr.push(NaN);
                                }
                                if (keyNames[index] == "IndustryBenchmark" || keyNames[index] == "OverAllBenchmark") {
                                    if (valArr.length == 1) {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 5,
                                            pointHoverRadius: 5
                                        });
                                    }
                                    else {
                                        newArr.push({
                                            label: keyNames[index],
                                            backgroundColor: "transparent",
                                            borderColor: color[index],
                                            pointBackgroundColor: color[index],
                                            data: valArr,
                                            showLine: true,
                                            spanGaps: true,
                                            pointRadius: 1,
                                            pointHoverRadius: 1
                                        });
                                    }

                                }
                                else {
                                    newArr.push({
                                        label: keyNames[index],
                                        backgroundColor: "transparent",
                                        borderColor: color[index],
                                        pointBackgroundColor: color[index],
                                        data: valArr,
                                        showLine: true,
                                        spanGaps: true,
                                        pointRadius: 5,
                                        pointHoverRadius: 5
                                    });
                                }

                            }
                        }
                        this.setState({ datasetNames: labelArr, chartData: newArr });
                        this.LineChart();
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    clear() {
        try {
            this.setState({
                endDate: "",
                startDate: ""
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    render() {
        return (
            <section className="content">
                <div className="innerpage-hd">
                    <Link className="menu2" to="/Home">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} /> {i18n.t('bACKTOHOME')}
                        </a>
                    </Link>
                    <Link className="menu2 pull-left" to="/CompareRegionGrid">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} /> {i18n.t('BackToCompare')}</a>
                    </Link>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <h4><div className="pull-left">{i18n.t('TrendReport')}</div><br />
                            <div className="btn-group-md pull-left">
                                <a type="button" title={i18n.t("downloadgraphsinppt")} className="btn btn-info pull-right" onClick={this.downloadPPT}>
                                    <img src={powerpointIcon} /> {i18n.t('downloadasPPT')}</a>
                            </div>
                            <div className="row compareHeader pull-right">
                                <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.Assessmentdetails.Deal[0].label}>{this.props.Assessmentdetails.Deal[0].label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.Assessmentdetails.Region[0].label}> {this.props.Assessmentdetails.Region[0].label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.Country.label}>{this.props.Assessmentdetails.Country.label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('delivery_Location') + ': ' + this.props.Assessmentdetails.DeliveryLocation.label}>{this.props.Assessmentdetails.DeliveryLocation.label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.Capability[0].label}>{this.props.Assessmentdetails.Capability[0].label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.Tower[0].label}>{this.props.Assessmentdetails.Tower[0].label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.ServiceComponent[0].label}>{this.props.Assessmentdetails.ServiceComponent[0].label} </span>
                            </div>
                        </h4>
                        <section>
                            <div className="box-plain pmm-asmt-tbl">
                                <div id="trend-report-section" className="box">
                                    <div className="row" >
                                        <div className="col-sm-12" style={{ "margin": "auto" }}>
                                            <div id="customLegendDiv"></div>
                                            <canvas id="line" width="600" height="200"> </canvas>
                                        </div>
                                        <div className="">
                                            <ul className="barChartLegend">
                                                <li className="liStyle">
                                                    <span style={{ background: "black" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    <span> {i18n.t('asIsPMMScore')} </span>
                                                </li>
                                                <li className="liStyle">
                                                    <span style={{ background: "#1E88E5" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    <span> {i18n.t('toBePMMScore')} </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </section >
        );
    }
}
function mapStateToProps(state){
    return{
		dropDownsValue:state.dropDownsValue,
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

TrendReport = connect(mapStateToProps)(TrendReport)
export default TrendReport