import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import {pmmDetails} from '../services/Auth.js'

export const adalConfig = {  
  tenant: pmmDetails.tenant,
  clientId: pmmDetails.clientId,
  endpoints: {
    api: pmmDetails.clientId
  },
  'apiUrl': pmmDetails.apiBaseUrl,
  cacheLocation: 'sessionStorage',
  extraQueryParameter : 'prompt=select_account',
  loadFrameTimeout:600000,
  expireOffsetSeconds:300,
  redirectUri: pmmDetails.redirect_uri

};
export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>  
  adalFetch(authContext, adalConfig.endpoints.api, fetch, adalConfig.apiUrl+url, options);

