/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import { BaseURL } from '../../../../Constant';
import NavigationConfirmation from '../../../../PMM/NavigationConfirmation';
import ProductivityPopup from './ProductivityPopup';
import $ from 'jquery';
import backArrowIcon from '../../../../images/icon-back-arrow.png';
import addButtonIcon from '../../../../images/icon-add-btn.png';
import loderIcon from '../../../../images/loader-strokes.gif';
import DocumentUploadPopup from './DocumentUploadPopup';
import request from '../../../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead'
import { AgGridLocale } from '../../../../Utils/AgGridLocale';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { inputValidator } from '../../../../Utils/InputElementValidation';

var Lever = [{ "label": "Policy", "text": "Policy" },
{ "label": "Practice", "text": "Practice" },
{ "label": "Platform", "text": "Platform" }];

var KPA = [{ "label": "Service Level", "text": "Service Level" },
{ "label": "Control & Risk", "text": "Control & Risk" },
{ "label": "Efficiency", "text": "Efficiency" }];

var MetricsImpacted = [
    { "label": "% Rejected Invoices", "text": "% Rejected Invoices" },
    { "label": "% On-Hold Invoices", "text": "% On-Hold Invoices" },
    { "label": "% Urgent Payment Invoices", "text": "% Urgent Payment Invoices" },
    { "label": "% Duplicate Payment by volume ", "text": "% Duplicate Payment by volume " },
    { "label": "% Payment on Time", "text": "% Payment on Time" },
    { "label": "Invoice Processing Cycle Time(days)", "text": "Invoice Processing Cycle Time(days)" },
    { "label": "% Payment on Time", "text": "% Payment on Time" }];

var MaturityElement = [{ "label": "Digital vs Handwritten", "text": "Digital vs Handwritten" },
{ "label": "No PO-No Pay Policy", "text": "No PO-No Pay Policy" },
{ "label": "PO-Invoice Mapping", "text": "PO-Invoice Mapping" },
{ "label": "Timely GR Creation", "text": "Timely GR Creation" },
{ "label": "Segregation of Critical Vendors", "text": "Segregation of Critical Vendors" },
{ "label": "Segregation of Urgent Invoices", "text": "Segregation of Urgent Invoices" },
{ "label": "Duplicate payment check", "text": "Duplicate payment check" },
{ "label": "Computation of payment terms/due dates", "text": "Computation of payment terms/due dates" },
{ "label": "Tolerance for price/rate discrepancy", "text": "Tolerance for price/rate discrepancy" },
{ "label": "QC Framework", "text": "QC Framework" },
{ "label": "Direct Receipt Paper Invoices", "text": "Direct Receipt Paper Invoices" }];


var editActive = false;
var newCount = 1;


function CreateDelete(params) {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = '<span class="glyphicon glyphicon-trash"></span>';
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}


function CreateStandardizationCheckbox() {
    this.eGui = document.createElement("div");
    this.eGui.width = "100%";
}

CreateStandardizationCheckbox.prototype.init = function (params) {

    var checkboxElement = document.createElement("input");
    checkboxElement.type = "checkbox";
    checkboxElement.className = "findColumns";

    checkboxElement.checked = params.data.IsStandardization;
    checkboxElement.addEventListener('change', function (event) {
        params.api.gridOptionsWrapper.gridOptions.rowData.map((eachRow, index) => {

            if (index == params.rowIndex) {
                eachRow["IsStandardization"] = event.currentTarget.checked
            }
        })
    });

    this.eGui.appendChild(checkboxElement);
}

CreateStandardizationCheckbox.prototype.getGui = function getGui() {
    return this.eGui;
}

CreateStandardizationCheckbox.prototype.refresh = function (params) {
    this.eGui.innerHTML = '';
    this.init(params)
}

class SingleSelect extends React.Component {
    constructor(props) {
        super(props);


        try {
            let selVal = [],
                optionsArr = [],
                saveID = "", saveIDLabel = "";
            if (props.colDef.field == "Lever") {
                if(!props.data.RowAdded){
                    selVal.push({
                        label: props.data.LeverName != undefined ? props.data.LeverName : '',
                        value: props.data.LeverID
                    })}
                if(props.data.RowAdded && props.data.LeverID != 0 ){
                    selVal.push({
                        label: props.data.LeverName != undefined ? props.data.LeverName : '',
                        value: props.data.LeverID
                    })}
                optionsArr = props.data.LeverTypes;
                saveID = "LeverID"
                saveIDLabel = "LeverName";
            } else if (props.colDef.field == "KPA") {
                if(!props.data.RowAdded ){
                    selVal.push({
                        label: props.data.KPA != undefined ? props.data.KPA : '',
                        value: props.data.KPAID
                    })}
                if(props.data.RowAdded && props.data.KPAID != 0 ){
                    selVal.push({
                        label: props.data.KPA != undefined ? props.data.KPA : '',
                        value: props.data.KPAID
                    })}
                optionsArr = props.data.KPATypes;
                saveID = "KPAID"
                saveIDLabel = "KPA";
            } else if (props.colDef.field == "MetricImpacted") {
                selVal = props.data.MetricImpactedTypes != null && props.data.MetricImpactedTypes != undefined ?
                    props.data.MetricImpactedTypes.map((item, index) => {
                        var option = {};
                        option["label"] = item.label ? item.label : item.BOILIName;
                        option["value"] = item.value ? item.value : item.BOILIId;
                        return option;
                    })
                    :
                    [{ label: "", value: 0 }]

                optionsArr = props.data.MetricsTypes.map((item, index) => {
                    var option = {};
                    option["label"] = item.label ? item.label : item.BOILIName;
                    option["value"] = item.value ? item.value : item.BOILIId;
                    return option;
                });

                saveID = "BOILIId"
                saveIDLabel = "BOILIName";
            } else if (props.colDef.field == "IndustrySpecific") {   // added for industryspecific               
                selVal = props.data.ConfigureIndustrySpecifics;
                optionsArr = props.data.IndustrySpecific;
                saveID = "value"
                saveIDLabel = "label";
            }

            this.state = {
                selectedValue: selVal,
                options: optionsArr,
                saveID: saveID,
                saveIDLabel: saveIDLabel
            };
            this.onChange = this.onChange.bind(this);
            this.onMetricImpactedChange = this.onMetricImpactedChange.bind(this);
            this.onIndustrySpecificChange = this.onIndustrySpecificChange.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {        
            $("#loader-div").hide();
            editActive = false;
            var grdData = [];
            grdData = this.props.api.gridOptionsWrapper.gridOptions.rowData;

            if (grdData != null) {

                grdData.map((item, index) => {
                    let selectedMetrics = "", MetricNames = "";
                    if (item.MetricImpactedTypes != undefined) {
                        item.MetricImpactedTypes.map((Metrics, index) => {
                            if (index == 0) {
                                selectedMetrics = Metrics.value;
                                MetricNames = Metrics.label;
                            } else {
                                selectedMetrics = selectedMetrics + "," + Metrics.value;
                                MetricNames = MetricNames + "," + Metrics.label;
                            }
                        });
                    }

                    //added for Industry Specific
                    let selectedIndustrySpecifics = "", IndustrySpecificNames = "", containsCrossIndustry = false, replaceCrossIndustry = false, crossIndustryItem = [];
                    if (item.ConfigureIndustrySpecifics && item.ConfigureIndustrySpecifics != undefined) {
                        item.ConfigureIndustrySpecifics.map((industry, index) => {
                            if (index == 0) {
                                selectedIndustrySpecifics = industry.value;
                                IndustrySpecificNames = industry.label;
                            } else {
                                selectedIndustrySpecifics = selectedIndustrySpecifics + "," + industry.value;
                                IndustrySpecificNames = IndustrySpecificNames + "," + industry.label;
                            }
                        })
                    }
                    else {
                        item["ConfigureIndustrySpecifics"] = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                        selectedIndustrySpecifics = 0;
                        IndustrySpecificNames = i18n.t('crossIndustry');
                    }

                    item["IndustrySpecificSelectedTypes"] = selectedIndustrySpecifics;
                    item["IndustrySpecificSelectedNames"] = IndustrySpecificNames;
                    // item["ConfigureIndustrySpecifics"] = item.ConfigureIndustrySpecifics;

                    item["ConfigureMetrics"] = item.MetricImpactedTypes;
                    item["MetricImpacted"] = selectedMetrics != null && selectedMetrics.length > 0 ? selectedMetrics.toString() : selectedMetrics;
                    item["MetricImpactedNames"] = MetricNames;
                    item["MetricImpactedTypes"] = (item.MetricImpactedTypes === null || item.MetricImpactedTypes === undefined) ? [] : item.MetricImpactedTypes;
                });
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(nextProps) {

        try {
            this.onMetricImpactedChange();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange(value) {
        try {
            editActive = true;
            let selVal = [];
            if (value.length > 0) {
                selVal.push({
                    label: value[0].label,
                    value: value[0].value
                })
                this.props.data[this.state.saveID] = value[0].value;
                this.props.data[this.state.saveIDLabel] = value[0].label;
            }
            else {
                this.props.data[this.state.saveID] = 0;
                this.props.data[this.state.saveIDLabel] = "";
            }
            this.setState({
                selectedValue: selVal
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onMetricImpactedChange(value) {
        try {
            let selectedMetrics = "", MetricNames = "";
            if (value.length > 1 && this.props.data["IsAutoCalculatePMMScore"] == true) {
                alert(i18n.t('multipleMetricAutoScore'));
                return false;
            }
            this.setState({
                selectedValue: value
            });
            value.map((Metrics, index) => {
                if (index == 0) {
                    selectedMetrics = Metrics.value;
                    MetricNames = Metrics.label;
                } else {
                    selectedMetrics = selectedMetrics + "," + Metrics.value;
                    MetricNames = MetricNames + "," + Metrics.label;
                }
            });

            if (this.props.data["MetricImpacted"] != undefined && this.props.data["MetricImpacted"] != selectedMetrics) {
                editActive = true;
            }
            this.props.data["ConfigureMetrics"] = value;
            this.props.data["MetricImpacted"] = selectedMetrics != null && selectedMetrics.length > 0 ? selectedMetrics.toString() : selectedMetrics;
            this.props.data["MetricImpactedNames"] = MetricNames;
            this.props.data["MetricImpactedTypes"] = value;

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onIndustrySpecificChange(value) {
        try {
            let selectedIndustrySpecifics = "", IndustrySpecificNames = "", containsCrossIndustry = false, replaceCrossIndustry = false, crossIndustryItem = [];
            this.setState({
                selectedValue: value
            });
            value.map((item, index) => {
                if (value.length == 1) {
                    selectedIndustrySpecifics = item.value;
                    IndustrySpecificNames = item.label;
                }
                else {
                    if (index == 0) {
                        if (item.label == i18n.t('crossIndustry')) {
                            selectedIndustrySpecifics = ""
                            IndustrySpecificNames = ""
                            replaceCrossIndustry = true;
                            containsCrossIndustry = true;
                        }
                        else {
                            selectedIndustrySpecifics = item.value;
                            IndustrySpecificNames = item.label;
                        }
                    } else {
                        if (item.label == i18n.t('crossIndustry')) {
                            selectedIndustrySpecifics = item.value;
                            IndustrySpecificNames = item.label;
                            containsCrossIndustry = true;
                            crossIndustryItem.push(item);
                        }
                        else {
                            if (replaceCrossIndustry) {
                                selectedIndustrySpecifics = item.value;
                                IndustrySpecificNames = item.label;
                                crossIndustryItem.push(item)
                            } else {
                                selectedIndustrySpecifics = selectedIndustrySpecifics + "," + item.value;
                                IndustrySpecificNames = IndustrySpecificNames + "," + item.label;
                            }
                        }
                    }
                }
            });

            if (this.props.data["IndustrySpecificSelectedTypes"] != undefined && this.props.data["IndustrySpecificSelectedTypes"] != selectedIndustrySpecifics) {
                editActive = true;
            }

            this.props.data["IndustrySpecificSelectedTypes"] = selectedIndustrySpecifics;
            this.props.data["IndustrySpecificSelectedNames"] = IndustrySpecificNames;
            if (containsCrossIndustry == true) {
                this.setState({
                    selectedValue: crossIndustryItem
                });
                this.props.data["ConfigureIndustrySpecifics"] = crossIndustryItem;
            }
            else {
                this.setState({
                    selectedValue: value
                });
                this.props.data["ConfigureIndustrySpecifics"] = value;
            }
        } catch (error) {
            console.log(error)
        }
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        let isHidden = (this.props.colDef.cellRendererParams.groupId == this.props.data.ServiceComponentGroupID) ? true : false;
        return (
            <div>
                {
                //(this.props.api.gridOptionsWrapper.gridOptions.rowData.length > 4 && (this.props.node.lastChild || this.props.data.RowAdded)) ?
                    this.state.saveIDLabel == "BOILIName" ?
                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} id={"rs_metricImpactedChange" + this.props.node.id} onChange={this.onMetricImpactedChange} options={this.state.options} selected={this.state.selectedValue} multiple disabled={!isHidden} flip={true} />
                        :
                        this.state.saveIDLabel == "label" ?
                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} id={"rs_industrySpecificChange" + this.props.node.id} onChange={this.onIndustrySpecificChange} options={this.state.options} selected={this.state.selectedValue} multiple disabled={!isHidden} flip={true} />
                            :
                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} id={"rs_change" + this.props.node.id} onChange={this.onChange} options={this.state.options} selected={this.state.selectedValue} disabled={!isHidden} flip={true} filterBy={this.filterCallbackTypeahead.bind(this)} />
                    // :
                    // this.state.saveIDLabel == "BOILIName" ?
                    //     <Typeahead emptyLabel={i18n.t('noMatchesFound')} id={"rs_metricImpactedChange" + this.props.node.id} onChange={this.onMetricImpactedChange} options={this.state.options} selected={this.state.selectedValue} multiple disabled={!isHidden} flip={true} />
                    //     :
                    //     this.state.saveIDLabel == "label" ?
                    //         <Typeahead emptyLabel={i18n.t('noMatchesFound')} id={"rs_industrySpecificChange" + this.props.node.id} onChange={this.onIndustrySpecificChange} options={this.state.options} selected={this.state.selectedValue} multiple disabled={!isHidden} flip={true} />
                    //         :
                    //         <Typeahead emptyLabel={i18n.t('noMatchesFound')} id={"rs_change" + this.props.node.id} onChange={this.onChange} options={this.state.options} selected={this.state.selectedValue} disabled={!isHidden} flip={true} filterBy={this.filterCallbackTypeahead.bind(this)} />
                }
            </div>
        );
    }
}

class PMMConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CapabilityValue: [],
            TowerValue: [],
            ServiceCompValue: [],
            PMMGridData: [],
            levers: [],
            KPAs: [],
            metrics: [],
            deleteStatus: false,
            pageNames: [],
            showProductivityPopup: false,
            ProductivityGridData: [],
            pMMGridDataflag: true,
            showDocumentUploadPopup: false,

        };
        this.loadPMMs = this.loadPMMs.bind(this);
        this.savePMMs = this.savePMMs.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.checkCells = this.checkCells.bind(this);
        this.ShowProductivityPopup = this.ShowProductivityPopup.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.CreateCheckbox = this.CreateCheckbox.bind(this);
        this.CreateProductivityCheckbox = this.CreateProductivityCheckbox.bind(this);
        this.uploadReferenceFile = this.uploadReferenceFile.bind(this);
        this.closeDocumentUploadPopUp = this.closeDocumentUploadPopUp.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    CreateCheckbox(params) {
        try {
            this.eGui = document.createElement("div");
            this.eGui.width = "100%";

            var checkboxElement = document.createElement("input");
            checkboxElement.type = "checkbox";
            checkboxElement.className = "findColumns";

            checkboxElement.checked = params.data.IsAutoCalculatePMMScore;
            checkboxElement.addEventListener('change', (event) => {
                params.api.gridOptionsWrapper.gridOptions.rowData.map((eachRow, index) => {

                    if (index == params.rowIndex) {
                        if (event.currentTarget.checked == true && eachRow.MetricImpacted.toString().indexOf(',') == -1) {
                            eachRow["IsAutoCalculatePMMScore"] = event.currentTarget.checked
                        }
                        else {
                            if (event.currentTarget.checked == true) {
                                alert(i18n.t('autoScoringCannotBeChecked'));
                                event.currentTarget.checked = false;
                                eachRow["IsAutoCalculatePMMScore"] = false;
                            }
                            else {
                                event.currentTarget.checked = false;
                                eachRow["IsAutoCalculatePMMScore"] = false;
                            }
                        }
                    }
                })
                this.checkCells();
            });

            this.eGui.appendChild(checkboxElement);
            return this.eGui;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CreateProductivityCheckbox(params) {
        try {
            this.eGui = document.createElement("div");
            this.eGui.width = "100%";

            var checkboxElement = document.createElement("input");
            checkboxElement.type = "checkbox";
            checkboxElement.className = "findColumns";
            checkboxElement.checked = params.data.IsAutoCalclulate;
            checkboxElement.addEventListener('change', (event) => {
                params.api.gridOptionsWrapper.gridOptions.rowData.map((eachRow, index) => {

                    if (index == params.rowIndex) {
                        eachRow["IsAutoCalclulate"] = event.currentTarget.checked
                    }
                })
                this.checkCells();
            });

            this.eGui.appendChild(checkboxElement);
            return this.eGui;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    componentDidMount() {
        try {
            //window.tfo_event("PMM-MC-22", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            window.addEventListener('resize', this.reSizeGrid, true);
            editActive = false;
            this.setState({

                CapabilityValue: this.props.configValues.capability,
                TowerValue: this.props.configValues.tower,
                ServiceCompValue: this.props.configValues.serviceComponent,
                pageNames: this.props.pageName
            });
            this.loadPMMs(this.props.configValues.serviceComponent[0].value);
            var SearchedString = "";
            let productivityConfigData = {
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
                ProductivityConfigID: 0,
                SCID: this.props.configValues.serviceComponent[0].value,
                IsSelected: 2,
                SearchedString: SearchedString,
            }
            request("/PMM/GetProductivityConfig", 'POST', productivityConfigData)
                .then(res => {
                    if (res == "Data not there") {
                        this.setState({
                            ProductivityGridData: []
                        });
                    }
                    else {
                        this.setState({
                            ProductivityGridData: res
                        });
                    }
                    this.checkCells();
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    loadPMMs(serviceID) {
        try {
            $("#loader-div").show();
            var SearchedString = "";
            var obj = this;
            let PMMConfigData = {
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
                PMMConfigID: 0,
                BOILIID: 0,
                LeverID: 0,
                KPAID: 0,
                SCID: serviceID,
                SearchedString: SearchedString,
            }
            let BOILIMasterData = {
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
                SCId: serviceID,
                BOILIFlag: 2,
                SearchedString: SearchedString,
            }
            let dropdownData = {
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            Promise.all([request("/PMM/GetPMMConfigDetails", 'POST', PMMConfigData),
            request("/Common/DropDownsValue", 'POST', dropdownData),
            request("/BOI/GetBOILIMasterForSC", 'POST', BOILIMasterData)])
                .then((response) => {

                    let LeverArr = response[1].lever;
                    let KPAArr = response[1].kpa;
                    let IndustryArr = response[1].Industry;
                    let MetricsArr = response[2];
                    //added for Industry specific                 
                    let lstIndustry = IndustryArr.filter(item => item.label != "Global")
                    lstIndustry.push({ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null })
                    if (response[0]) {
                        let PMMArr = response[0];
                        PMMArr.map(PMM => {
                            PMM.LeverTypes = LeverArr;
                            PMM.KPATypes = KPAArr;
                            PMM.MetricsTypes = MetricsArr;
                            if (!PMM.IndustrySpecific || PMM.IndustrySpecific == 0) {
                            //     PMM.ConfigureIndustrySpecifics = PMM.ConfigureIndustrySpecifics;
                            // } else {
                                PMM.ConfigureIndustrySpecifics = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                            }
                            PMM.IndustrySpecific = lstIndustry;
                            PMM["ServiceComponentGroupID"] = obj.state.ServiceCompValue[0].GroupID;
                        });
                        obj.setState({
                            pMMGridDataflag: false,
                            PMMGridData: PMMArr,
                            levers: LeverArr,
                            KPAs: KPAArr,
                            metrics: MetricsArr,
                            industry: lstIndustry
                        });
                        obj.markSelected(PMMArr);
                    } else {
                        obj.setState({
                            pMMGridDataflag: false,
                            PMMGridData: [],
                            levers: LeverArr,
                            KPAs: KPAArr,
                            metrics: MetricsArr,
                            industry: lstIndustry
                        });
                    }

                }).catch((error) => {
                    $("#loader-div").hide();
                    console.log("Error Encountered")
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    fileUploaded(e) {
        try {            
            var fileExtension = ['xls'];
            var fileName = e.currentTarget.files[0].name.split('\\').pop();
            fileName = fileName.substr(0, fileName.lastIndexOf('.'));
            var file = e.currentTarget.files[0];
            //window.tfo_event("PMM-D-20", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    clearUploadedFile = (e) => {
        try {
            e.target.value = '';
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    uploadFile(obj) {
        try {
            //window.tfo_event("PMM-D-6", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            this.setState({ showProductivityPopup: false });
            var validDataList = [];
            var invalidDataList = [];
            var fileData = new FormData();

            fileData.append("File", this.refs.file.files[0]);
            var uploadedFile = this.refs.file.files[0];
            if (this.refs.file.files[0].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
                alert(i18n.t("invalidFileType"));
                $("#loader-div").hide();
            }
            else {
                var that = this;
                var reader = new FileReader();
                reader.readAsDataURL(uploadedFile);
                editActive = true;
                $("#loader-div").show();
                fetch(BaseURL + "/Common/ValidateSAPUpload?LanguageID=" + this.props.languageId, {
                    method: 'POST',
                    headers: {
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body: fileData
                })
                    .then(r => r.json())
                    .then(data => {
                        if (data[0].ErrorMessage == undefined && data[0].ErrorMessage == null) {
                            let PMMArr = [];
                            let PMMArrUpload = data;
                            PMMArrUpload.map(PMM => {
                                PMM.LeverTypes = this.state.levers;
                                PMM.KPATypes = this.state.KPAs;
                                PMM.MetricsTypes = this.state.metrics;
                                PMM.IndustrySpecific = this.state.industry;
                                PMM.BOILIId = 0;
                                PMM.BOILIIdvalue = [];
                                PMM.LeverID = 0;
                                PMM.LeverIDvalue = [];
                                PMM.KPAID = 0;
                                PMM.KPAIDvalue = [];
                                PMM["ServiceComponentGroupID"] = that.state.ServiceCompValue[0].GroupID;
                                PMMArr.push(PMM);
                            })
                            that.setState({
                                PMMGridData: PMMArr
                            });
                        }
                        else {
                            if (data[0].ErrorMessage == "Column names are not specified correctly.") {
                                alert(i18n.t('columnNamesInvalid'));
                            }
                            else if (data[0].ErrorMessage == "Please Do not Upload Empty File.") {
                                alert(i18n.t('emptyFileInvalid'));
                            }
                            else if (data[0].ErrorMessage == "Please Upload Valid Data Sheet.") {
                                alert(i18n.t('uploadValidSheet'));
                            }
                            else if (data[0].ErrorMessage == "Please Specify Name of the Sheet as Upload in Excel WorkBook.") {
                                alert(i18n.t('nameOfSheetInvalid'));
                            }
                            else if (data[0].ErrorMessage == "Error reading data from excel.") {
                                alert(i18n.t('readFromExcelInvalid'));
                            }
                            $("#loader-div").hide();
                        }
                        // To remove filename after 'Upload' button is clicked
                        document.getElementById("txt_fileUpload").value = "";
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    })

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    downloadTemplate(params) {
        try {
            //window.tfo_event("PMM-D-5", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            this.setState({ showProductivityPopup: false });

            $("#loader-div").show();

            // request("/Common/DownloadExcel?GroupID=" + this.props.groupId, 'GET')
            //     .then(res => {
            //         $("#loader-div").hide();
            //         window.location.href = res;
            //         alert(i18n.t('filedownloaded'));
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //     });
            fetch(BaseURL + "/Common/DownloadExcel?GroupID=" + params.groupId, {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                }
                
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `PMMExcelUpload.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_Productivity.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == "User Management"){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "Deal Management"){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "PMM Configuration"){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (editActive) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage,
                    showProductivityPopup: false
                })
            } else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage,
                        showProductivityPopup: false
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false,
                showProductivityPopup: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    validatePMM() {
        try {
            let PMMConfigDetails = this.refs.PMM_Config_Grid.gridOptions.rowData;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    savePMMs(event) {
        try {
            $("#loader-div").show();
            this.refs.PMM_Config_Grid.gridOptions.api.stopEditing();
            let pmmGridData = this.refs.PMM_Config_Grid.gridOptions.rowData;
            let inputValidate = true;
            let valid = true, errMsg = "";
            let PMMSaveArr = [];
            let pmmLength = pmmGridData.length;
            var numericStatus = true;
            valid = pmmLength > 0 ? true : false;
            errMsg = pmmLength > 0 ? i18n.t('selectAllFields_PMMConfig') : i18n.t('pmmDetailsNotLoaded')
            pmmGridData.map(pmmRow => {
                if (pmmRow.LeverID == 0 || pmmRow.LeverID == null || pmmRow.KPAID == 0 || pmmRow.KPAID == null || pmmRow.MetricImpactedTypes == null || pmmRow.IndustrySpecificSelectedTypes === "") {
                    valid = false;
                }
                let pmmObj = {
                    PMMRowId: 0,
                    BOILIId: pmmRow.IsAutoCalculatePMMScore == true ? pmmRow.MetricImpacted : pmmRow.BOILIId,
                    SCId: this.state.ServiceCompValue[0].value,
                    LeverID: pmmRow.LeverID,
                    KPAID: pmmRow.KPAID,
                    MaturityElement: pmmRow.MaturityElement,
                    Base: pmmRow.Base,
                    Enhanced: pmmRow.Enhanced,
                    AboveAverage: pmmRow.AboveAverage,
                    HighPerformance: pmmRow.HighPerformance,
                    Project: pmmRow.Project,
                    Time: pmmRow.Time,
                    Impact: pmmRow.Impact,
                    IsAutoCalclulate: pmmRow.IsAutoCalclulate,
                    IsAutoCalculatePMMScore: pmmRow.IsAutoCalculatePMMScore,
                    IsStandardization: pmmRow.IsStandardization,
                    loggedInUserId: this.props.enterpriseId,
                    MetricImpacted: pmmRow.MetricImpacted,
                    IndustrySpecific: pmmRow.IndustrySpecificSelectedTypes
                }
                if (pmmRow.Time != "" && pmmRow.Time != null) {
                    if(isNaN(parseFloat(pmmRow.Time)) && !isFinite(pmmRow.Time) )
                    {                     
                        numericStatus = false;
                    }
                }
                let maturityelementValidation = inputValidator(pmmRow.MaturityElement);
                let baseValidation = inputValidator(pmmRow.Base);
                let enhancedValidation = inputValidator(pmmRow.Enhanced);
                let aboveaverageValidation = inputValidator(pmmRow.AboveAverage);
                let highperformanceValidation = inputValidator(pmmRow.HighPerformance);
                let projectValidation = inputValidator(pmmRow.Project);
                let TimeValidation = inputValidator(pmmRow.Time);
                let ImpactValidation = inputValidator(pmmRow.Impact);


                if (maturityelementValidation == true || baseValidation == true || enhancedValidation == true || aboveaverageValidation == true || highperformanceValidation == true || projectValidation == true || TimeValidation == true || ImpactValidation == true) {
                    inputValidate = false;
                }

                PMMSaveArr.push(pmmObj);
            })
            if (valid) {


                var isError = true;
                isError = this.checkCells();
                if (inputValidate == true) {
                    if (isError == false) {
                        let PMMObj = {
                            pmmDetails: PMMSaveArr
                        }
                        editActive = false;
                        request("/PMM/SavePMMConfig?GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'POST', PMMObj)
                            .then(res => {
                                if (res.outputmessage == i18n.t('success')) {
                                    alert(res.outputmessage);
                                }
                                else if (res.Outputcode == -1) {
                                    alert(res.outputmessage);
                                }
                                else {
                                    alert(i18n.t('pmmDetailsNotSaved'));
                                }
                                $("#loader-div").hide();
                            })
                            .catch(error => {
                                console.log("Error Encountered");
                                $("#loader-div").hide();
                            })
                    }
                    else {
                        $("#loader-div").hide();
                        alert(i18n.t('correctValuesInHighlightedCells_PMMConfig'));
                    }

                }
                else {
                    alert(i18n.t("PleaseEntervalidValuesintheFields"));

                }

            } else {
                $("#loader-div").hide();
                alert(errMsg);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkCells() {
        try {
            let objGridDetails = this.refs.PMM_Config_Grid.gridOptions.rowData;
            let objProductivityDetails = this.refs.Grd_Productivity.gridOptions.rowData;
            var isErrorThere = false;
            var numStatus = false;

            objGridDetails.map(pmmRow => {

                if (pmmRow.MaturityElement == "" || pmmRow.MaturityElement == null) {
                    pmmRow["MaturityElemError"] = true;
                    pmmRow["MaturityElemMsg"] = i18n.t('maturityElementNotBeBlanked');
                    isErrorThere = true;
                }
                else if (pmmRow.MaturityElement.includes("^")) {
                    pmmRow["MaturityElemError"] = true;
                    pmmRow["MaturityElemMsg"] = i18n.t('maturityElementShouldNot');
                    isErrorThere = true;
                }
                else {
                    pmmRow["MaturityElemError"] = false;
                    pmmRow["MaturityElemMsg"] = pmmRow.MaturityElement;
                }

                if (pmmRow.Base == "" || pmmRow.Base == null) {
                    pmmRow["BaseError"] = true;
                    pmmRow["BaseMsg"] = i18n.t('baseShouldNotBeBlank');
                    isErrorThere = true;
                }
                else {
                    pmmRow["BaseError"] = false;
                    pmmRow["BaseMsg"] = pmmRow.Base;
                }

                if (pmmRow.HighPerformance == "" || pmmRow.HighPerformance == null) {
                    pmmRow["HighPerformanceError"] = true;
                    pmmRow["HighPerformanceMsg"] = i18n.t('highPerformanceNotBeBlank');
                    isErrorThere = true;
                }
                else {
                    pmmRow["HighPerformanceError"] = false;
                    pmmRow["HighPerformanceMsg"] = pmmRow.HighPerformance;
                }

                // Validation for Impact if Is Auto Calculate is selected.
                if (pmmRow.IsAutoCalclulate == true) {
                    if (pmmRow.Impact == "" || pmmRow.Impact == null) {
                        pmmRow["IsAutoCalclulateError"] = true;
                        pmmRow["ImpactMsg"] = i18n.t('enterUniqueID');
                        isErrorThere = true;
                    }
                    else {
                        let IsValidProdID = false;

                        if (objProductivityDetails == null) {
                            pmmRow["IsAutoCalclulateError"] = true;
                            pmmRow["ImpactMsg"] = i18n.t('noProductivityConfigured');
                            isErrorThere = true;
                        }
                        else {
                            objProductivityDetails.map(pmmProdRow => {
                                if (pmmProdRow.UniqueProductivityId == pmmRow.Impact) {
                                    IsValidProdID = true;
                                }
                            })

                            if (IsValidProdID == false) {
                                pmmRow["IsAutoCalclulateError"] = true;
                                pmmRow["ImpactMsg"] = i18n.t('enterUniqueID');
                                isErrorThere = true;
                            }
                            else {
                                pmmRow["IsAutoCalclulateError"] = false;
                                pmmRow["ImpactMsg"] = pmmRow.Impact;
                            }
                        }
                    }
                }
                else {
                    pmmRow["IsAutoCalclulateError"] = false;
                    pmmRow["ImpactMsg"] = pmmRow.Impact;
                }

                //Base, Enhancement, Above Average and High Performance validation
                if (pmmRow.IsAutoCalculatePMMScore == true) {
                    var strBase = pmmRow["Base"];
                    var strEnhanced = pmmRow["Enhanced"];
                    var strAboveAvg = pmmRow["AboveAverage"];
                    var strHighPerf = pmmRow["HighPerformance"];

                    var arrBase = [];
                    var arrEnhanced = [];

                    // Base Validation
                    if (strBase != null && strBase != "") {
                        arrBase = strBase.match(/[^\s>=<()&&*/%+-]+/g);
                        if (strBase.includes("&&")) {

                            if (arrBase.length == 4) {
                                var numStatus1 = false;
                                var numStatus2 = false;
                                !isNaN(parseFloat(arrBase[1])) && isFinite(arrBase[1]) ? numStatus1 = true : numStatus1 = false;
                                !isNaN(parseFloat(arrBase[3])) && isFinite(arrBase[3]) ? numStatus2 = true : numStatus2 = false;

                                if (numStatus1 == true && numStatus2 == true) {
                                    var tempVal = "";

                                    // Check Start value for range
                                    if (parseFloat(arrBase[1]) == 0 && parseFloat(arrBase[3]) == 100) {
                                        pmmRow["BaseError"] = true;
                                        pmmRow["BaseMsg"] = i18n.t('range0To100');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrBase[1]) == parseFloat(arrBase[3])) {
                                        pmmRow["BaseError"] = true;
                                        pmmRow["BaseMsg"] = i18n.t('rangeStartPoint');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrBase[1]) == 0) {

                                        if(parseFloat(arrBase[1]) == 0)
                                        {
                                            tempVal = (strBase.replace(arrBase[0], String(parseFloat(arrBase[1]) + 0.01))).replace(arrBase[2], String(parseFloat(arrBase[1]) + 0.01)) 
                                        }
                                        if (!eval(tempVal)) {
                                            pmmRow["BaseError"] = true;
                                            pmmRow["BaseMsg"] = i18n.t('enterValidBaseFormula');
                                            isErrorThere = true;
                                        }
                                        else {
                                            pmmRow["BaseError"] = false;
                                            pmmRow["BaseMsg"] = pmmRow.Base;
                                        }
                                    }
                                    else if (parseFloat(arrBase[3]) == 100) {
                                        if(parseFloat(arrBase[3]) == 100 )
                                        {
                                            tempVal = (strBase.replace(arrBase[0], String(parseFloat(arrBase[3]) - 0.01))).replace(arrBase[2], String(parseFloat(arrBase[3]) - 0.01));
                                        }
                                        if (!eval(tempVal)) {
                                            pmmRow["BaseError"] = true;
                                            pmmRow["BaseMsg"] = i18n.t('enterValidBaseFormula');
                                            isErrorThere = true;
                                        }
                                        else {
                                            pmmRow["BaseError"] = false;
                                            pmmRow["BaseMsg"] = pmmRow.Base;
                                        }
                                    }
                                    else if (parseFloat(arrBase[1]) != 0 && parseFloat(arrBase[3]) != 100) {
                                        pmmRow["BaseError"] = true;
                                        pmmRow["BaseMsg"] = i18n.t('baseRangeStartPoint');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrBase[1]) > parseFloat(arrBase[3])) {
                                        pmmRow["BaseError"] = true;
                                        pmmRow["BaseMsg"] = i18n.t('startLessThanEnd');
                                        isErrorThere = true;
                                    }

                                }
                                else {
                                    pmmRow["BaseError"] = true;
                                    pmmRow["BaseMsg"] = i18n.t('enterValidBaseFormula');
                                    isErrorThere = true;
                                }
                            }
                            else {
                                pmmRow["BaseError"] = true;
                                pmmRow["BaseMsg"] = i18n.t('enterValidBaseFormula');
                                isErrorThere = true;
                            }
                        }
                        else {
                            pmmRow["BaseError"] = true;
                            pmmRow["BaseMsg"] = i18n.t('enterValidBaseFormula');
                            isErrorThere = true;
                        }
                    }

                    // Enhanced Validation
                    if(strEnhanced == null)
                    {
                        strEnhanced = "";
                    }
                    if (strEnhanced != "") {
                        arrEnhanced = strEnhanced.match(/[^\s>=<()&&*/%+-]+/g);
                        if (strEnhanced.includes("&&")) {

                            if (arrEnhanced.length == 4) {
                                var numStatus1 = false;
                                var numStatus2 = false;
                                !isNaN(parseFloat(arrEnhanced[1])) && isFinite(arrEnhanced[1]) ? numStatus1 = true : numStatus1 = false;
                                !isNaN(parseFloat(arrEnhanced[3])) && isFinite(arrEnhanced[3]) ? numStatus2 = true : numStatus2 = false;
                                if (numStatus1 == true && numStatus2 == true) {
                                    var tempVal = "";
                                    if (parseFloat(arrEnhanced[1]) == 0 || parseFloat(arrEnhanced[3]) == 100 || parseFloat(arrEnhanced[1]) == 100 || parseFloat(arrEnhanced[3]) == 0) {
                                        pmmRow["EnhancedError"] = true;
                                        pmmRow["EnhancedMsg"] = i18n.t('rangeShouldContain');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrBase[1]) == 0 && parseFloat(arrBase[3]) != parseFloat(arrEnhanced[1])) {
                                        pmmRow["EnhancedError"] = true;
                                        pmmRow["EnhancedMsg"] = i18n.t('rangeStartEqualBaseEnd');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrEnhanced[1]) == parseFloat(arrEnhanced[3])) {
                                        pmmRow["EnhancedError"] = true;
                                        pmmRow["EnhancedMsg"] = i18n.t('rangeStartPoint');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrBase[1]) == 0) {
                                        if (parseFloat(arrBase[3]) != parseFloat(arrEnhanced[1])) {
                                            pmmRow["EnhancedError"] = true;
                                            pmmRow["EnhancedMsg"] = i18n.t('startEqualBaseEnd');
                                            isErrorThere = true;
                                        }
                                        else {
                                            if(parseFloat(arrBase[1]) == 0 )
                                            {
                                                tempVal = (strEnhanced.replace(arrEnhanced[0], String(parseFloat(arrEnhanced[1]) + 0.01))).replace(arrEnhanced[2], String(parseFloat(arrEnhanced[1]) + 0.01));
                                            }
                                            if (!eval(tempVal)) {
                                                pmmRow["EnhancedError"] = true;
                                                pmmRow["EnhancedMsg"] = i18n.t('enterValidBaseFormula');
                                                isErrorThere = true;
                                            }
                                            else {
                                                pmmRow["EnhancedError"] = false;
                                                pmmRow["EnhancedMsg"] = pmmRow.Enhanced;
                                            }
                                        }
                                    }
                                    else if (parseFloat(arrBase[3]) == 100) {
                                        if (parseFloat(arrBase[1]) != parseFloat(arrEnhanced[3])) {
                                            pmmRow["EnhancedError"] = true;
                                            pmmRow["EnhancedMsg"] = i18n.t('endEqualsStart_Enhanced');
                                            isErrorThere = true;
                                        }
                                        else {
                                            if(parseFloat(arrBase[3]) == 100)
                                            {
                                                tempVal = (strEnhanced.replace(arrEnhanced[0], String(parseFloat(arrEnhanced[3]) - 0.01))).replace(arrEnhanced[2], String(parseFloat(arrEnhanced[3]) - 0.01));
                                            }
                                            if (!eval(tempVal)) {
                                                pmmRow["EnhancedError"] = true;
                                                pmmRow["EnhancedMsg"] = i18n.t('enterValidBaseFormula');
                                                isErrorThere = true;
                                            }
                                            else {
                                                pmmRow["EnhancedError"] = false;
                                                pmmRow["EnhancedMsg"] = pmmRow.Enhanced;
                                            }
                                        }
                                    }
                                    else if (parseFloat(arrEnhanced[1]) > parseFloat(arrEnhanced[3])) {
                                        pmmRow["EnhancedError"] = true;
                                        pmmRow["EnhancedMsg"] = i18n.t('startLessThanEnd');
                                        isErrorThere = true;
                                    }
                                }
                                else {
                                    pmmRow["EnhancedError"] = true;
                                    pmmRow["EnhancedMsg"] = i18n.t('enterValidBaseFormula');
                                    isErrorThere = true;
                                }
                            }
                            else {
                                pmmRow["EnhancedError"] = true;
                                pmmRow["EnhancedMsg"] = i18n.t('enterValidBaseFormula');
                                isErrorThere = true;
                            }
                        }
                        else {
                            pmmRow["EnhancedError"] = true;
                            pmmRow["EnhancedMsg"] = i18n.t('enterValidBaseFormula');
                            isErrorThere = true;
                        }
                    }
                    else {
                        pmmRow["EnhancedError"] = false;
                        pmmRow["EnhancedMsg"] = pmmRow.Enhanced;
                    }

                    //Above Average Validation
                    if(strAboveAvg == null)
                    {
                        strAboveAvg = "";
                    }
                    if (strAboveAvg != "") {
                        var arrAboveAvg = strAboveAvg.match(/[^\s>=<()&&*/%+-]+/g);
                        if (strAboveAvg.includes("&&")) {

                            if (arrAboveAvg.length == 4) {
                                var numStatus1 = false;
                                var numStatus2 = false;
                                !isNaN(parseFloat(arrAboveAvg[1])) && isFinite(arrAboveAvg[1]) ? numStatus1 = true : numStatus1 = false;
                                !isNaN(parseFloat(arrAboveAvg[3])) && isFinite(arrAboveAvg[3]) ? numStatus2 = true : numStatus2 = false;
                                if (numStatus1 == true && numStatus2 == true) {
                                    var tempVal = "";
                                    var tempArr = [];
                                    strEnhanced == "" ? tempArr = arrBase : tempArr = arrEnhanced;
                                    if (parseFloat(arrAboveAvg[1]) == 0 || parseFloat(arrAboveAvg[3]) == 100 || parseFloat(arrAboveAvg[1]) == 100 || parseFloat(arrAboveAvg[3]) == 0) {
                                        pmmRow["AboveAvgError"] = true;
                                        pmmRow["AboveAvgMsg"] = i18n.t('rangeShouldContain');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrBase[1]) == 0 && parseFloat(tempArr[3]) != parseFloat(arrAboveAvg[1])) {
                                        pmmRow["AboveAvgError"] = true;
                                        strEnhanced != "" ?
                                            pmmRow["AboveAvgMsg"] = i18n.t('startEqualsEnhancedEnd') :
                                            pmmRow["AboveAvgMsg"] = i18n.t('startEqualBaseEnd');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrAboveAvg[1]) == parseFloat(arrAboveAvg[3])) {
                                        pmmRow["AboveAvgError"] = true;
                                        pmmRow["AboveAvgMsg"] = i18n.t('rangeStartPoint');
                                        isErrorThere = true;
                                    }
                                    else if (parseFloat(arrBase[1]) == 0) {
                                        if (parseFloat(tempArr[3]) != parseFloat(arrAboveAvg[1])) {
                                            pmmRow["AboveAvgError"] = true;
                                            strEnhanced != "" ?
                                                pmmRow["AboveAvgMsg"] = i18n.t('startEqualsEnhancedEnd') :
                                                pmmRow["AboveAvgMsg"] = i18n.t('startEqualBaseEnd');
                                            isErrorThere = true;
                                        }
                                        else {
                                            if(parseFloat(arrBase[1]) == 0)
                                            {
                                                tempVal = (strAboveAvg.replace(arrAboveAvg[0], String(parseFloat(arrAboveAvg[1]) + 0.01))).replace(arrAboveAvg[2], String(parseFloat(arrAboveAvg[1]) + 0.01));
                                            }
                                            if (!eval(tempVal)) {
                                                pmmRow["AboveAvgError"] = true;
                                                pmmRow["AboveAvgMsg"] = i18n.t('enterValidBaseFormula');
                                                isErrorThere = true;
                                            }
                                            else {
                                                pmmRow["AboveAvgError"] = false;
                                                pmmRow["AboveAvgMsg"] = pmmRow.AboveAverage;
                                            }
                                        }
                                    }
                                    else if (parseFloat(arrBase[3]) == 100) {
                                        if (parseFloat(tempArr[1]) != parseFloat(arrAboveAvg[3])) {
                                            pmmRow["AboveAvgError"] = true;
                                            strEnhanced != "" ?
                                                pmmRow["AboveAvgMsg"] = i18n.t('endEqualsEnhancedStart') :
                                                pmmRow["AboveAvgMsg"] = i18n.t('endEqualsStart_Enhanced');
                                            isErrorThere = true;
                                        }
                                        else {
                                            if(parseFloat(arrBase[3]) == 100)
                                            {
                                                tempVal = (strAboveAvg.replace(arrAboveAvg[0], String(parseFloat(arrAboveAvg[3]) - 0.01))).replace(arrAboveAvg[2], String(parseFloat(arrAboveAvg[3]) - 0.01));
                                            }
                                            if (!eval(tempVal)) {
                                                pmmRow["AboveAvgError"] = true;
                                                pmmRow["AboveAvgMsg"] = i18n.t('enterValidBaseFormula');
                                                isErrorThere = true;
                                            }
                                            else {
                                                pmmRow["AboveAvgError"] = false;
                                                pmmRow["AboveAvgMsg"] = pmmRow.AboveAverage;
                                            }
                                        }
                                    }
                                    else if (parseFloat(arrAboveAvg[1]) > parseFloat(arrAboveAvg[3])) {
                                        pmmRow["AboveAvgError"] = true;
                                        pmmRow["AboveAvgMsg"] = i18n.t('startLessThanEnd');
                                        isErrorThere = true;
                                    }
                                }
                                else {
                                    pmmRow["AboveAvgError"] = true;
                                    pmmRow["AboveAvgMsg"] = i18n.t('enterValidBaseFormula');
                                    isErrorThere = true;
                                }
                            }
                            else {
                                pmmRow["AboveAvgError"] = true;
                                pmmRow["AboveAvgMsg"] = i18n.t('enterValidBaseFormula');
                                isErrorThere = true;
                            }
                        }
                        else {
                            pmmRow["AboveAvgError"] = true;
                            pmmRow["AboveAvgMsg"] = i18n.t('enterValidBaseFormula');
                            isErrorThere = true;
                        }
                    }
                    else {
                        pmmRow["AboveAvgError"] = false;
                        pmmRow["AboveAvgMsg"] = pmmRow.AboveAverage;
                    }
                    //High Performance Validation
                    if(strHighPerf == null)
                    {
                        strHighPerf = "";
                    }
                    if (strHighPerf != "") {
                        var arrHighPerf = strHighPerf.match(/[^\s>=<()&&*/%+-]+/g);
                        if (strHighPerf.includes("&&")) {

                            if (arrHighPerf.length == 4) {
                                var numStatus1 = false;
                                var numStatus2 = false;
                                !isNaN(parseFloat(arrHighPerf[1])) && isFinite(arrHighPerf[1]) ? numStatus1 = true : numStatus1 = false;
                                !isNaN(parseFloat(arrHighPerf[3])) && isFinite(arrHighPerf[3]) ? numStatus2 = true : numStatus2 = false;
                                // Check Start value for range
                                if (numStatus1 == true && numStatus2 == true) {
                                    var tempVal = (strHighPerf.replace(arrHighPerf[0], String(parseFloat(arrHighPerf[1]) + 1))).replace(arrHighPerf[2], String(parseFloat(arrHighPerf[1]) + 1));
                                    var tempArr = [];
                                    if (parseFloat(arrBase[1]) == 0 && parseFloat(arrHighPerf[3]) != 100) {
                                        pmmRow["HighPerformanceError"] = true;
                                        pmmRow["HighPerformanceMsg"] = i18n.t('highPerformanceEnd100');
                                        isErrorThere = true;
                                    }

                                    else if (parseFloat(arrBase[3]) == 100 && parseFloat(arrHighPerf[1]) != 0) {
                                        pmmRow["HighPerformanceError"] = true;
                                        pmmRow["HighPerformanceMsg"] = i18n.t('highPerformanceStart100');
                                        isErrorThere = true;
                                    }

                                    else if (parseFloat(arrHighPerf[1]) == parseFloat(arrHighPerf[3])) {
                                        pmmRow["HighPerformanceError"] = true;
                                        pmmRow["HighPerformanceMsg"] = i18n.t('rangeStartPoint');
                                        isErrorThere = true;
                                    }

                                    else if (strEnhanced == "") {
                                        if (strAboveAvg == "") {
                                            if (parseFloat(arrBase[1]) == 0 && parseFloat(arrBase[3]) != parseFloat(arrHighPerf[1])) {
                                                pmmRow["HighPerformanceError"] = true;
                                                pmmRow["HighPerformanceMsg"] = i18n.t('rangeStartEqualBaseEnd');
                                                isErrorThere = true;
                                            }
                                            else if (parseFloat(arrBase[3]) == 100 && parseFloat(arrBase[1]) != parseFloat(arrHighPerf[3])) {
                                                pmmRow["HighPerformanceError"] = true;
                                                pmmRow["HighPerformanceMsg"] = i18n.t('rangeEndEqualsBaseStart');
                                                isErrorThere = true;
                                            }
                                        }
                                        else if (parseFloat(arrBase[1]) == 0 && parseFloat(arrAboveAvg[3]) != parseFloat(arrHighPerf[1])) {
                                            pmmRow["HighPerformanceError"] = true;
                                            pmmRow["HighPerformanceMsg"] = i18n.t('rangeStartEqualsAboveAvgEnd');
                                            isErrorThere = true;
                                        }
                                        else if (parseFloat(arrBase[3]) == 100 && parseFloat(arrAboveAvg[1]) != parseFloat(arrHighPerf[3])) {
                                            pmmRow["HighPerformanceError"] = true;
                                            pmmRow["HighPerformanceMsg"] = i18n.t('endEqualsAboveAvgStart');
                                            isErrorThere = true;
                                        }

                                    }

                                    else if (strEnhanced != "") {

                                        if (strAboveAvg == "") {
                                            if (parseFloat(arrBase[1]) == 0) {
                                                if (parseFloat(arrEnhanced[3]) != parseFloat(arrHighPerf[1])) {
                                                    pmmRow["HighPerformanceError"] = true;
                                                    pmmRow["HighPerformanceMsg"] = i18n.t('startEqualsEnhancedEnd');
                                                    isErrorThere = true;
                                                }
                                            }
                                            else if (parseFloat(arrBase[3]) == 100) {
                                                if (parseFloat(arrEnhanced[1]) != parseFloat(arrHighPerf[3])) {
                                                    pmmRow["HighPerformanceError"] = true;
                                                    pmmRow["HighPerformanceMsg"] = i18n.t('endEqualsEnhancedStart');
                                                    isErrorThere = true;
                                                }
                                            }
                                        }
                                        else {
                                            var arrAboveAvg = strAboveAvg.match(/[^\s>=<()&&*/%+-]+/g);
                                            if (parseFloat(arrBase[1]) == 0 && isNaN(parseFloat(arrAboveAvg[3])) == false && parseFloat(arrAboveAvg[3]) != parseFloat(arrHighPerf[1])) {
                                                pmmRow["HighPerformanceError"] = true;
                                                pmmRow["HighPerformanceMsg"] = i18n.t('rangeStartEqualsAboveAvgEnd');
                                                isErrorThere = true;
                                            }
                                            else if (parseFloat(arrBase[3]) == 100 && isNaN(parseFloat(arrAboveAvg[3])) == false && parseFloat(arrAboveAvg[1]) != parseFloat(arrHighPerf[3])) {
                                                pmmRow["HighPerformanceError"] = true;
                                                pmmRow["HighPerformanceMsg"] = i18n.t('endEqualsAboveAvgStart');
                                                isErrorThere = true;
                                            }

                                        }
                                    }

                                    if (pmmRow["HighPerformanceError"] != null && pmmRow["HighPerformanceError"] != true) {
                                        if(parseFloat(arrHighPerf[1]) == 0)
                                        {
                                            tempVal = (strHighPerf.replace(arrHighPerf[0], String(parseFloat(arrHighPerf[1]) + 0.01))).replace(arrHighPerf[2], String(parseFloat(arrHighPerf[1]) + 0.01));
                                        }
                                        if(parseFloat(arrHighPerf[1]) == 100)
                                        {
                                            tempVal = (strHighPerf.replace(arrHighPerf[0], String(parseFloat(arrHighPerf[1]) - 0.01))).replace(arrHighPerf[2], String(parseFloat(arrHighPerf[1]) - 0.01));
                                        }
                                        if (!eval(tempVal)) {
                                            pmmRow["HighPerformanceError"] = true;
                                            pmmRow["HighPerformanceMsg"] = i18n.t('enterValidBaseFormula');
                                            isErrorThere = true;
                                        }
                                        else {
                                            pmmRow["HighPerformanceError"] = false;
                                            pmmRow["HighPerformanceMsg"] = String(pmmRow.HighPerformance);
                                        }
                                    }
                                }
                                else {
                                    pmmRow["HighPerformanceError"] = true;
                                    pmmRow["HighPerformanceMsg"] = i18n.t('enterValidBaseFormula');
                                    isErrorThere = true;
                                }
                            }
                            else {
                                pmmRow["HighPerformanceError"] = true;
                                pmmRow["HighPerformanceMsg"] = i18n.t('enterValidBaseFormula');
                                isErrorThere = true;
                            }
                        }
                        else {
                            pmmRow["HighPerformanceError"] = true;
                            pmmRow["HighPerformanceMsg"] = i18n.t('enterValidBaseFormula');
                            isErrorThere = true;
                        }
                    }

                }
                else {
                    pmmRow["BaseError"] = false;

                    pmmRow["EnhancedError"] = false;

                    pmmRow["AboveAvgError"] = false;

                    pmmRow["HighPerformanceError"] = false;

                    if (pmmRow.Base == "" || pmmRow.Base == null) {
                        pmmRow["BaseError"] = true;
                        pmmRow["BaseMsg"] = i18n.t('baseShouldNotBeBlank');
                        isErrorThere = true;
                    }
                    else {
                        pmmRow["BaseError"] = false;
                        pmmRow["BaseMsg"] = pmmRow.Base;
                    }

                    if (pmmRow.HighPerformance == "" || pmmRow.HighPerformance == null) {
                        pmmRow["HighPerformanceError"] = true;
                        pmmRow["HighPerformanceMsg"] = i18n.t('highPerformanceNotBeBlank');
                        isErrorThere = true;
                    }
                    else {
                        pmmRow["HighPerformanceError"] = false;
                        pmmRow["HighPerformanceMsg"] = pmmRow.HighPerformance;
                    }
                }
            })

            this.refs.PMM_Config_Grid.gridOptions.api.setRowData(objGridDetails);
            this.setState({
                PMMGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.PMM_Config_Grid.gridOptions.api.refreshCells(params);
            return isErrorThere;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            (this.props.groupId
                == this.props.configValues.serviceComponent[0].GroupID) ?
                editActive = true
                :
                editActive = false;
            if (e.colDef.headerName == i18n.t('delete')) {
                if (confirm(i18n.t('deleteConfirmation'))) {
                    let PMMConfigDetails = this.refs.PMM_Config_Grid.gridOptions.rowData;
                    PMMConfigDetails.splice(e.rowIndex, 1);
                    this.refs.PMM_Config_Grid.gridOptions.api.setRowData(PMMConfigDetails);
                    this.markSelected(PMMConfigDetails);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            if (params.colDef.headerName == i18n.t('maturityElement') || params.colDef.headerName == i18n.t('base') || params.colDef.headerName == i18n.t('enhanced') || params.colDef.headerName == i18n.t('aboveAverage') || params.colDef.headerName == i18n.t('highPerformance') || params.colDef.headerName == i18n.t('impact')) {
                editActive = true;
                let PMMConfigDetails = this.refs.PMM_Config_Grid.gridOptions.rowData;
                this.checkCells();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    markSelected(PmmConfigArr) {
        let count = 0;
        this.refs.PMM_Config_Grid.gridOptions.api.forEachNode((node) => {
            PmmConfigArr.map((selectedPmmConfig) => {
                if (node.data.BOILIId === selectedPmmConfig.BOILIId) {
                    if(node.data.IsAutoCalclulate)
                    {
                        this.refs.PMM_Config_Grid.gridOptions.api.selectNode(node, true);
                    }
                }
            });
        });

    }

    onRowSelect(eve) {
        try {
            eve.node.data["IsAutoCalclulate"] = eve.node.selected ? true : false;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onAddRow() {
        try {
            editActive = true;
            let currentGridData = this.refs.PMM_Config_Grid.gridOptions.rowData;
            var serviceCompID = this.props.configValues.serviceComponent[0].value;
            var newData = {};
            var obj = this;
            let PMMConfigAddNewData = {
                SCID: serviceCompID,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId
            }
            $("#loader-div").show();

            request("/PMM/GetPMMConfigAddNewRow", 'POST', PMMConfigAddNewData)
                .then(response => {
                    if (response.length > 0) {
                        newData = response[0];
                        newData.IndustrySpecific = this.state.industry;
                        if (!newData.IndustrySpecific || newData.IndustrySpecific == 0) {
                        //     newData.ConfigureIndustrySpecifics = newData.ConfigureIndustrySpecifics;
                        // } else {
                            newData.ConfigureIndustrySpecifics = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                        }
                        newData.RowAdded = true;
                        newData["ServiceComponentGroupID"] = obj.state.ServiceCompValue[0].GroupID;
                        currentGridData.push(newData);
                        obj.refs.PMM_Config_Grid.gridOptions.api.setRowData(currentGridData);
                        obj.markSelected(currentGridData);
                        obj.setState({ deleteStatus: true });
                    }
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ShowProductivityPopup() {
        try {
            this.setState({ showProductivityPopup: true });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    uploadReferenceFile() {
        try {
            this.setState({ showDocumentUploadPopup: true });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeDocumentUploadPopUp() {
        try {
            this.setState({
                showDocumentUploadPopup: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun(eve) {
        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    /* Refresh the grid and reset the row height based on the cell data */
    refresh() {

    }

    render() {

        var columnDefs = [];
        var pmmGridData = [];
        let { t } = this.props;
        pmmGridData = this.state.PMMGridData;
        let isHidden = (this.props.groupId
            == this.props.configValues.serviceComponent[0].GroupID) ? true : false;
        columnDefs = [
            { headerName: t('lever'), field: 'Lever', cellRendererFramework: SingleSelect, cellRendererParams: { groupId: this.props.groupId }, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true },
            { headerName: t('KPA'), field: 'KPA', cellRendererFramework: SingleSelect, cellRendererParams: { groupId: this.props.groupId }, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true },
            { headerName: t('metricImpacted'), field: 'MetricImpacted', cellRendererFramework: SingleSelect, cellRendererParams: { groupId: this.props.groupId }, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, width: 280 },
            { headerName: t('applicableIndustry'), field: 'IndustrySpecific', cellRendererFramework: SingleSelect, cellRendererParams: { groupId: this.props.groupId }, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, width: 280 },
            {
                headerName: t('maturityElement'), field: 'MaturityElement', tooltipField: 'MaturityElement', suppressMovable: true, suppressResize: true, hide: false, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellStyle: function (params) {
                    let background = params.data.MaturityElemError == true ? "#E57373" : ""
                    return {
                        background: background
                    };
                }, tooltipFieldMsg: 'MaturityElemMsg'
            },
            {
                headerName: t('base'), field: 'Base', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellEditorParams: function (params) {
                    return {
                        maxLength: 2000,
                        rows: 5,
                        cols: 40
                    }
                }, cellStyle: function (params) {
                    let background = params.data.BaseError == true ? "#E57373" : ""
                    return {
                        background: background
                    };

                }, tooltip: function (params) {
                    return params.data.BaseMsg;
                }
            },
            {
                headerName: t('enhanced'), field: 'Enhanced', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellEditorParams: function (params) {
                    return {
                        maxLength: 2000,
                        rows: 5,
                        cols: 40
                    }
                }, cellStyle: function (params) {
                    let background = params.data.EnhancedError == true ? "#E57373" : ""
                    return {
                        background: background
                    };
                }, tooltipField: 'EnhancedMsg'
            },
            {
                headerName: t('aboveAverage'), field: 'AboveAverage', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellEditorParams: function (params) {
                    return {
                        maxLength: 2000,
                        rows: 5,
                        cols: 40
                    }
                }, cellStyle: function (params) {
                    let background = params.data.AboveAvgError == true ? "#E57373" : ""
                    return {
                        background: background
                    };
                }, tooltipField: 'AboveAvgMsg'
            },
            {
                headerName: t('highPerformance'), field: 'HighPerformance', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellEditorParams: function (params) {
                    return {
                        maxLength: 2000,
                        rows: 5,
                        cols: 40
                    }
                }, cellStyle: function (params) {
                    let background = params.data.HighPerformanceError == true ? "#E57373" : ""
                    return {
                        background: background
                    };
                }, tooltipField: 'HighPerformanceMsg'
            },
            {
                headerName: t('project'), field: 'Project', tooltipField: 'Project', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellEditorParams: function (params) {
                    return {
                        maxLength: 2000,
                        rows: 5,
                        cols: 40
                    }
                }
            },
            {
                headerName: t('time'), field: 'Time', tooltipField: 'Time', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellEditorParams: function (params) {
                    return {
                        maxLength: 2000,
                        rows: 5,
                        cols: 40
                    }
                }
            },
            {
                headerName: t('impact'), field: 'Impact', tooltipField: 'ImpactMsg', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellEditor: 'agLargeTextCellEditor', cellEditorParams: function (params) {
                    return {
                        maxLength: 2000,
                        rows: 5,
                        cols: 40
                    }
                }
                , cellStyle: function (params) {

                    let background = params.data.IsAutoCalclulateError ? "#E57373" : "transparent"
                    return {
                        background: background
                    };
                }
            },
            { headerName: t('autoProdCalc'), cellRenderer: this.CreateProductivityCheckbox, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, hide: !isHidden },
            {
                headerName: t('autoPMMScore'), cellRenderer: this.CreateCheckbox, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, hide: !isHidden
            },
            { headerName: t('standardizationScore'), field: 'IsStandardization', suppressMovable: true, suppressResize: true, cellRenderer: CreateStandardizationCheckbox, autoHeight: true, hide: !isHidden },
            { headerName: t('delete'), suppressMovable: true, suppressResize: true, cellRenderer: CreateDelete, hide: !isHidden }
        ];

        var PMMDataGrid = (
            <div className="ag-theme-material grid-div custom-select">
                <AgGridReact ref="PMM_Config_Grid" id="grid_PMM_Config"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={pmmGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    singleClickEdit={true}
                    onCellClicked={this.onCellClicked.bind(this)}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    onRowSelected={this.onRowSelect}
                    suppressRowClickSelection={true}
                    suppressScrollOnNewData={true}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        var productivityColDefs = [];
        productivityColDefs = [
            { headerName: t('description'), field: 'ProductivityDescription', tooltipField: 'ProductivityDescription', suppressMovable: true, suppressResize: true },
            { headerName: t('beforeEffort'), field: 'BeforeEffort', tooltipField: 'BeforeEffort', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: t('afterEffort'), field: 'AfterEffort', tooltipField: 'AfterEffort', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: t('uniqueID'), field: 'UniqueProductivityId', tooltipField: '', autoHeight: true, suppressMovable: true, suppressResize: true }
        ];

        var ProductivityDataGrid = (
            <div style={{ height: '200px' }} className="ag-theme-material padding5 custom-select">
                <AgGridReact ref="Grd_Productivity" enableSorting="true" id="grd_Productivity"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={productivityColDefs}
                    rowData={this.state.ProductivityGridData}
                    rowmodeltype="pagination"
                    suppressRowClickSelection={true}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}

                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <ul className="pagecounter"  >
                        <li><span title="BOI / KEY BUSINESS METRIC" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><a data-url="/BOIConfiguration" data-content="BOI Configuration" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="LEADING INDICATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="LEADING INDICATOR"><a data-url="/LIConfiguration" data-content="LI Configuration" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="EFFORT" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="EFFORT"><a data-url="/EFFORT" data-content="EFFORT" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="PRODUCTIVITY CALCULATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PRODUCTIVITY CALCULATOR"><a data-url="/ProductivityCalculator" data-content="Productivity Calculator" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="PMM CONFIG" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM CONFIG">...</span></li>
                    </ul>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            <li className="active">
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                        navigationPage={this.state.navigationPage} isEditAcitve={editActive}
                        url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                </div>
                <section className="bodydata  multiple-tabs-section">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <div className="">
                                <h4 className="config-title">
                                    <span className="statustxt-orng-italic" title="Capability">{this.props.configValues.capability != [] ? this.props.configValues.capability[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Tower">{this.props.configValues.tower != [] ? this.props.configValues.tower[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Service Component">{this.props.configValues.serviceComponent != [] ? this.props.configValues.serviceComponent[0].label : []}</span>

                                </h4>
                                <div>
                                    <div className="btn-group-md">
                                        <button id="btn_upload" type="button" className="btn btn-success" onClick={this.uploadFile} disabled={!isHidden}>{i18n.t('upload')}</button>
                                        <button id="btn_downloadTemplate" type="button" className="btn btn-danger" onClick={this.downloadTemplate} disabled={!isHidden}>{i18n.t('downloadTemplate')}</button>
                                    </div>
                                    <div className="fright upload-btn">
                                        <label id="lbl_selectFile" disabled={!isHidden}>{i18n.t('selectFile')}</label>
                                        <input id="txt_fileUpload" type="file" ref="file" name="PMMFileUpload" accept="application/vnd.ms-excel" onChange={this.fileUploaded} onClick={this.clearUploadedFile} disabled={!isHidden} />
                                    </div>
                                </div>
                            </div>
                            <h4 className="grid-title">
                                {i18n.t('productivityDetails')}
                            </h4>
                            <div className="row">
                                {ProductivityDataGrid}
                            </div>
                            <div className="row row-margin">
                                <h4 className="grid-title col-md-2">
                                    {i18n.t('pMMConfiguration')}
                                </h4>
                                <div className="btn-group-md">
                                    <button id="btn_documentUpload" type="button" className="btn btn-success pull-right" onClick={this.uploadReferenceFile} disabled={!isHidden}>{i18n.t('documentUpload')}</button>
                                    <button id="btn_ADD" type="button" className="btn btn-default pmmConfigAdd pull-right" onClick={this.onAddRow.bind(this)} disabled={!isHidden} >
                                        <img src={addButtonIcon} />{i18n.t('add')}
                                    </button>
                                </div>
                            </div>
                            <div className="PMM-Config-div">
                                {
                                    this.state.pMMGridDataflag == true ?
                                        <div>
                                            <center><img src={loderIcon} /></center>
                                        </div>
                                        :
                                        PMMDataGrid
                                }
                                {isHidden ?
                                    <div className="btn-group-md">
                                        <a type="button" className="btn btn-success" onClick={this.savePMMs}>{i18n.t('submit')}</a>
                                    </div>
                                    :
                                    <div className="btn-group-md">
                                        <Link type="button" className="btn btn-info" to="/BOIConfiguration">{i18n.t('backToBOIConfig')}</Link>
                                    </div>
                                }
                                {this.state.showProductivityPopup == true ? <ProductivityPopup show={this.state.showProductivityPopup} propsSC={this.state.ServiceCompValue} propProductivityData={this.state.ProductivityGridData} /> : null}
                                {this.state.showDocumentUploadPopup == true ? <DocumentUploadPopup SCID={this.state.ServiceCompValue[0].value} show={this.state.showDocumentUploadPopup} closeCallback={this.closeDocumentUploadPopUp} /> : null}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}
function mapStateToProps(state) {
    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName

    }
}
PMMConfig = connect(mapStateToProps)(withTranslation()(PMMConfig));
export default withRouter(PMMConfig);
