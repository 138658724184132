/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { Link, Redirect, withRouter } from 'react-router';
import $ from 'jquery';
import { AgGridLocale } from '../../../../Utils/AgGridLocale';

class ProductivityPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: this.props.show,
            ProductivityGridData: []
        };

        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.handleClose = this.handleClose.bind(this);
    };

    componentWillReceiveProps() {
        this.setState({ show: this.props.show })
    }

    handleClose() {
        this.setState({ show: false });
    };

    componentDidMount() {
       

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    reSizeGrid() {
        this.refs.Grd_Productivity.gridOptions.api.sizeColumnsToFit();
    }

    gridReadyFun(eve) {
        eve.api.sizeColumnsToFit();
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: 'Description', field: 'ProductivityDescription', tooltipField: 'ProductivityDescription', suppressMovable: true, suppressResize: true },
            { headerName: 'Before Effort (A)', field: 'BeforeEffort', tooltipField: 'BeforeEffort', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: 'After Effort (B)', field: 'AfterEffort', tooltipField: 'AfterEffort', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: 'Unique ID', field: 'UniqueProductivityId', tooltipField: '', autoHeight: true, suppressMovable: true, suppressResize: true }
        ];

        var ProductivityDataGrid = (
            <div style={{ height: '400px' }} className="ag-theme-material padding5 custom-select">
                <AgGridReact ref="Grd_Productivity" enableSorting="true" id="grd_Productivity"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.props.propProductivityData}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    suppressRowClickSelection={true}
                    onGridReady={this.gridReadyFun}
                    singleClickEdit= {true}
                    localeText={AgGridLocale()}
                    
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h2>Productivity Details</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="AsmtData viewprevassmt box-plain">
                        {ProductivityDataGrid}
                    </div>
                </Modal.Body>
            </Modal>
        </div>)
    }
}

export default withRouter(ProductivityPopup);