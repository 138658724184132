/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import PMMEffortTransaction from './PMMEffortTransaction';
import BenchmarkPopup from '../BenchmarkPopup';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { BaseURL } from '../../Constant';

class LIView extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                quickFilterText: null,
                LIGridData: [],
                EffortGridData: [],
                popUpBenchmark: false,
                selectedMetric: ""
            };
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.DownloadExcel = this.DownloadExcel.bind(this);
            this.ShowEffortPopup = this.ShowEffortPopup.bind(this);
            this.closeBenchmarkPopUp = this.closeBenchmarkPopUp.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            window.addEventListener('resize', this.reSizeGrid, true);
            $("#loader-div").show();
            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {                
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-3", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)        
                // }

                var SearchedString="";
                let viewEffortData = {
                    EffortTransactionId:0,
                    PMMProcessID:PMMProcessID,
                    SearchedString:SearchedString,
                    UserName:this.props.enterpriseId,				
			        GroupID: this.props.groupId,
			        LanguageID:this.props.languageId
                }
                request("/PMM/ViewEffortDetails", 'POST', viewEffortData)
                .then(response => {
                    let effortList = response != null ? response : [];
                    this.setState({ EffortGridData: effortList });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            }

            if (PMMProcessDraftID > 0) {            
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-10", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName) 
                // }
                var SearchedString="";
                let effortDraftData = {
                    EffortDraftID:0,
                    PMMProcessDraftID:this.props.Assessmentdetails.PMMProcessDraftID,
                    SCID:this.props.Assessmentdetails.ServiceComponentID,
                    SearchedString:SearchedString,
                    UserName:this.props.enterpriseId,				
			        GroupID: this.props.groupId,
			        LanguageID:this.props.languageId
                }
                request("/PMM/GetEffortDraft", 'POST', effortDraftData)
                .then(response => {
                    let effortList = response != null ? response : [];
                    this.setState({ EffortGridData: effortList });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_LIView.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun=(eve)=> {
        try {
            $("#loader-div").hide();
           var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {
                var SearchedString="";
                let data ={
                    UserName:this.props.enterpriseId,
                    MetricID:0,
                    PMMProcessID:PMMProcessID,
                    GroupID:this.props.groupId,
                    SearchedString:SearchedString,
                    LanguageID:this.props.languageId
                }
                eve.api.showLoadingOverlay();
                request("/LeadingIndicator/ViewLIDetails", 'POST', data)
                .then(response => {
                    if(response.length!==0){
                        eve.api.hideOverlay();
                    this.setState({ LIGridData: response });
                    $("#loader-div").hide();
                    }
                    else{
                        eve.api.showNoRowsOverlay();

                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

               
            }

            if (PMMProcessDraftID > 0) {
                var SearchedString="";
                let leadingIndicatorData = {
                    UserName:this.props.enterpriseId,
                    BOILIId:0,
                    PMMProcessDraftID:this.props.Assessmentdetails.PMMProcessDraftID,
                    IndustryID:this.props.Assessmentdetails.IndustryID,
                    SCID:this.props.Assessmentdetails.ServiceComponentID,
                    GroupID:this.props.groupId,
                    SearchedString:SearchedString,
                    LanguageID:this.props.languageId
                }
                eve.api.showLoadingOverlay();
                request("/LeadingIndicator/GetLeadingIndicatorDetails", 'POST', leadingIndicatorData)
                .then(response => {
                    if(response.length!==0){
                        eve.api.hideOverlay();
                    this.setState({ LIGridData: response });
                    $("#loader-div").hide();
                    }
                    else{
                        eve.api.showNoRowsOverlay();

                    }

                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
               
            }


            
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        try {
            this.setState({ quickFilterText: event.target.value });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadExcel() {
        try {
            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-D-25", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                let excelData = {
                    "PMMProcessID": this.props.Assessmentdetails.PMMProcessID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                   
                }
                let jsonexcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelTransaction", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonexcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Transaction.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
                
                // await request("/Common/DownloadtoExcelTransaction", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;

                //     })
                //     .catch(error => {
                //         console.log("Error Encountered");
                //     });
            }

            if (PMMProcessDraftID > 0) {
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-D-21", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                var objThis = this;
                let excelData = {
                    "PMMProcessDraftID": PMMProcessDraftID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                let jsonExcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelDraft", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonExcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Draft.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
            
                // await request("/Common/DownloadtoExcelDraft", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;
                //     })
                //     .catch(error => {
                //         window.addEventListener("beforeunload", objThis.unloadEvent);
                //         console.log("Error Encountered");
                //     });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ShowEffortPopup() {
        try {
            // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-20", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
            // }
            this.setState({
                showEffortPopup: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CloseEffortPopup() {
        try {
            this.setState({
                showEffortPopup: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(params) {
        try {
            if (params.colDef.field == "BOILIName") {
                this.setState({
                    popUpBenchmark: true,
                    selectedMetric: params.data.BOILIName
                })
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeBenchmarkPopUp() {
        try {
            this.setState({
                popUpBenchmark: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('metrics'), field: 'BOILIName', suppressMovable: true, suppressResize: true, tooltipField: 'BOILIName' },
            { headerName: i18n.t('InternalBenchmark'), field: 'Benchmark', suppressMovable: true, suppressResize: true, tooltipField: 'Benchmark', autoHeight: true },
            { headerName: i18n.t('ExternalBenchmark'), field: 'Median', suppressMovable: true, suppressResize: true, hide: true, tooltipField: 'Median', autoHeight: true },
            { headerName: i18n.t('Remark'), field: 'DisplayName', suppressMovable: true, suppressResize: true, tooltipField: 'DisplayName' },
            { headerName: i18n.t('As-Is'), field: 'AsIs', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('To-Be'), field: 'ToBe', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('BOIKeyBusinessMetricImpacted'), field: 'BOIImpacted', suppressMovable: true, suppressResize: true, tooltipField: 'BOIImpacted' }
        ];
        var liGridData = this.state.LIGridData;

        var LIDataGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_LIView" id="grd_LIView"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={liGridData}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <div>
                <section className="content">
                    <div className="innerpage-hd"><Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back"><img src={backArrowIcon} /> {i18n.t('bACKTOHOME')}</a></Link>
                        <ul className="pagecounter"  >
                            <li><span title={i18n.t("vIEWPreviousassesssment")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page"><Link to="/ViewPreviousAssessmentHome"></Link></span></li>
                            <li><span title={i18n.t("BOIKeyBusinessMetrics")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><Link to="/BOIView"></Link></span></li>
                            <li><span title={i18n.t("lEADINGINDICATOR")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator">...</span></li>
                            <li><span title={i18n.t("pMMASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">3</span></li>
                            <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                            <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                            <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                        </ul>
                    </div>
                    <section className="bodydata">
                        <div className="bodydata-full">
                            <div className="box-plain">
                                <h4><div className="pull-left" title={i18n.t("boiBusinessMetricLeaddIndiList")}>
                                    {i18n.t('LeadingIndicatorView')}
                                </div>
                                    <div className="pull-right">
                                        <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.Assessmentdetails.DealName}>{this.props.Assessmentdetails.DealName}
                                        </span> &nbsp;&nbsp;||<span class='statustxt-orng-italic' title={i18n.t('region') + ': ' + this.props.Assessmentdetails.RegionName}>{this.props.Assessmentdetails.RegionName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.CountryName}>{this.props.Assessmentdetails.CountryName}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.Assessmentdetails.OGName}>{this.props.Assessmentdetails.OGName} */}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.Assessmentdetails.IndustryName}>{this.props.Assessmentdetails.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.CapabilityName}>{this.props.Assessmentdetails.CapabilityName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.TowerName}>{this.props.Assessmentdetails.TowerName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.SCName}>{this.props.Assessmentdetails.SCName}</span>
                                    </div>
                                </h4>
                                <h4>
                                    <span className="col-md-4 infoblockfilter ">
                                        <label className="col-md-3" id="lbl_search" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                        <input type="text" id="txt_searchTextLIView" className="col-md-9 searchText" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />
                                    </span>

                                    <div className="btn-group btn-group-pmmasmt">
                                        <button type="button" id="btn_downloadSavedScreensLIView" title={i18n.t("DownloadSavedScreensforthisAssessment")} className="btn btn-default btn-downld" onClick={this.DownloadExcel}>
                                            <img src={downloadIcon} />
                                        </button>
                                    </div>
                                </h4>
                                <div className="box-plain pmm-asmt-tbl LI-view">
                                    {LIDataGrid}

                                    <div className="btn-group-md">
                                        {this.state.EffortGridData.length > 0 ? <a type="button" className="btn btn-warning" onClick={this.ShowEffortPopup}>{i18n.t('effort')}</a> : null}
                                        <Link to="/PMMAssessmentView"><a type="button" className="btn btn-success">{i18n.t('ViewPMMAssessment')}</a></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                {this.state.showEffortPopup == true ? <PMMEffortTransaction show={this.state.showEffortPopup} close={this.CloseEffortPopup.bind(this)} propsAssessmentdetails={this.props.Assessmentdetails} /> : null}
                {this.state.popUpBenchmark && <BenchmarkPopup show={this.state.popUpBenchmark} closeCallback={this.closeBenchmarkPopUp} selectedMetric={this.state.selectedMetric} dealId={this.props.Assessmentdetails.DealID} industry={this.props.Assessmentdetails.IndustryName} />}
            </div>
        );
    }
};

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

LIView = connect(mapStateToProps)(LIView)

export default LIView;
