import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch
} from 'react-router';

import Home from './Home';
import PMMUpload from './PMM/Component/PMMUpload';
import CreateNewAssessment from './PMM/CreateNewAssessment/CreateNewAssessment';
import BOIGrid from './PMM/BOI/BOIGrid';
import LeadingIndicatorGrid from './PMM/LeadingIndicator/LeadingIndicatorGrid';
import PMMAssessment from './PMM/PMMAssessment/PMMAssessment';
import StoryBoard from './PMM/StoryBoard/StoryBoard';
import PotentialProject from './PMM/PotentialProject/PotentialProject';
import ProjectPrioritization from './PMM/ProjectPrioritization/ProjectPrioritization';
import higherOrderComp from './GenericComp';
import PMMConfiguration from './Master/PMMConfiguration/PMMConfiguration';
import BOIView from './PMM/BOI/BOIView';
import LIView from './PMM/LeadingIndicator/LIView';
import PMMAssessmentView from './PMM/PMMAssessment/PMMAssessmentView';
import PotentialProjectView from './PMM/PotentialProject/PotentialProjectView';
import ViewPreviousAssessmentDraft from './PMM/ViewPreviousAssessment/ViewPreviousAssessmentDraft';
import ViewPreviousAssessmentSubmitted from './PMM/ViewPreviousAssessment/ViewPreviousAssessmentSubmitted';
import PMMReports from './PMM/ViewPreviousAssessment/PMMReports';
import BOILITrendReport from './PMM/ViewPreviousAssessment/BOILITrendReport';
import StoryBoardView from './PMM/StoryBoard/StoryBoardView';
import ProjectPrioritizationView from './PMM/ProjectPrioritization/ProjPrioritizationView';
import UserManagement from './UserManagement/Component/UserManagement';
import DealMapping from './UserManagement/DealMapping/DealMapping';
import BOIConfiguration from './Master/PMMConfiguration/Configuration/BOI/BOIConfiguration';
import LIConfiguration from './Master/PMMConfiguration/Configuration/LeadingIndicator/LIConfiguration';
import Effort from './Master/PMMConfiguration/Configuration/Effort/Effort';
import ProductivityCalculator from './Master/PMMConfiguration/Configuration/Productivity/ProductivityCalculator';
import PMMConfig from './Master/PMMConfiguration/Configuration/PMM/PMMConfig';
import Deal from './Master/PMMConfiguration/Deal/Deal';
import DealRegionMapping from './Master/PMMConfiguration/Deal/DealRegionMapping';
import UnlockAccessment from './Master/PMMConfiguration/UnlockAccessment/UnlockAccessment';
import CompareRegionGrid from './PMM/ViewPreviousAssessment/CompareRegionGrid';
import ComparativeReport from './PMM/ViewPreviousAssessment/ComparativeReport';
import TrendReport from './PMM/ViewPreviousAssessment/TrendReport';

var configDetails = {};
var BOILIConfig = {};

class MainRouter extends React.Component {

    constructor() {
        super();
        try {
            this.Assessmentdetails = {};
            this.TrendReportdetails = {};
            this.AsIsNoInd = {};
            this.SetBOILIValues = this.SetBOILIValues.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SetPMMConfigDetails(e) {

        try {

            console.log(JSON.stringify(e));
            configDetails = e;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SetSelectedAssessmentDetails(e) {

        try {

            console.log(JSON.stringify(e));
            this.Assessmentdetails = e;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SetSelectedTrendReportDetails(e) {

        try {

            console.log(JSON.stringify(e));
            this.TrendReportdetails = e;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SetAsIsNoIndProj(e) {

        try {

            console.log(JSON.stringify(e));
            this.AsIsNoInd = e;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SetBOILIValues(e) {
        try {
            BOILIConfig = e;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        return (
            <Switch>
                <Route exact path='/' component={() => (<Home closeGroupSwitchPopup={this.props.closeGroupSwitchPopup} />)} />
                <Route exact path='/Home' component={() => (<Home closeGroupSwitchPopup={this.props.closeGroupSwitchPopup} />)} />
                <Route exact path='/CreateNewAssessment' component={higherOrderComp(CreateNewAssessment, { screenName: "CreateNewAssessment", pageNames: this.props.pageNames, propsPMMConfigDetails: this.SetPMMConfigDetails.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PMMUpload' component={higherOrderComp(PMMUpload, { screenName: "PMMUpload", pageNames: this.props.pageNames, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/BOI' component={higherOrderComp(BOIGrid, { screenName: "BOI", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/LeadingIndicator' component={higherOrderComp(LeadingIndicatorGrid, { screenName: "LeadingIndicator", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PMMAssessment' component={higherOrderComp(PMMAssessment, { screenName: "PMMAssessment", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/StoryBoard' component={higherOrderComp(StoryBoard, { screenName: "StoryBoard", pageNames: this.props.pageNames, configDetails: configDetails, propsSetAsIsNoIndProj: this.SetAsIsNoIndProj.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PotentialProject' component={higherOrderComp(PotentialProject, { screenName: "PotentialProject", pageNames: this.props.pageNames, configDetails: configDetails, AsIsNoInd: this.AsIsNoInd, propsSetAsIsNoIndProj: this.SetAsIsNoIndProj.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/ProjectPrioritization' component={higherOrderComp(ProjectPrioritization, { screenName: "ProjectPrioritization", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, configDetails: configDetails, AsIsNoInd: this.AsIsNoInd, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PMMConfiguration' component={higherOrderComp(PMMConfiguration, { screenName: "PMMConfiguration", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/ViewPreviousAssessmentHome' component={higherOrderComp(ViewPreviousAssessmentSubmitted, { screenName: "ViewPreviousAssessmentHome", pageNames: this.props.pageNames, propsSelectedAssessmentDetails: this.SetSelectedAssessmentDetails.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/BOIView' component={higherOrderComp(BOIView, { screenName: "BOIView", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/LIView' component={higherOrderComp(LIView, { screenName: "LIView", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PMMAssessmentView' component={higherOrderComp(PMMAssessmentView, { screenName: "PMMAssessmentView", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PotentialProjectView' component={higherOrderComp(PotentialProjectView, { screenName: "PotentialProjectView", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/StoryBoardView' component={higherOrderComp(StoryBoardView, { screenName: "StoryBoardView", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/ProjectPrioritizationView' component={higherOrderComp(ProjectPrioritizationView, { screenName: "ProjectPrioritizationView", pageNames: this.props.pageNames, configDetails: configDetails, Assessmentdetails: this.Assessmentdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/UserManagement' component={higherOrderComp(UserManagement, { screenName: "UserManagement", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/DealMapping' component={higherOrderComp(DealMapping, { screenName: "DealMapping", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/BOIConfiguration' component={higherOrderComp(BOIConfiguration, { screenName: "BOIConfiguration", pageNames: this.props.pageNames, BOILIConfig: this.SetBOILIValues, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/LIConfiguration' component={higherOrderComp(LIConfiguration, { screenName: "LIConfiguration", pageNames: this.props.pageNames, configValues: BOILIConfig, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PMMConfig' component={higherOrderComp(PMMConfig, { screenName: "PMMConfig", pageNames: this.props.pageNames, configValues: BOILIConfig, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/DealManagement' component={higherOrderComp(Deal, { screenName: "DealManagement", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/DealRegionMapping' component={higherOrderComp(DealRegionMapping, { screenName: "DealRegionMapping", pageNames: this.props.pageNames, configDetails: configDetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/UnlockAssessment' component={higherOrderComp(UnlockAccessment, { screenName: "UnlockAssessment", pageNames: this.props.pageNames, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />

                <Route path='/ViewPreviousAssessmentDraft' component={higherOrderComp(ViewPreviousAssessmentDraft, { screenName: "", pageNames: this.props.pageNames, propsSelectedAssessmentDetails: this.SetSelectedAssessmentDetails.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/PMMReports' component={higherOrderComp(PMMReports, { screenName: "", pageNames: this.props.pageNames, propsSelectedAssessmentDetails: this.SetSelectedAssessmentDetails.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/BOILITrendReport' component={higherOrderComp(BOILITrendReport, { screenName: "", pageNames: this.props.pageNames, propsSelectedAssessmentDetails: this.SetSelectedAssessmentDetails.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/Effort' component={higherOrderComp(Effort, { screenName: "", pageNames: this.props.pageNames, configValues: BOILIConfig, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/ProductivityCalculator' component={higherOrderComp(ProductivityCalculator, { screenName: "", pageNames: this.props.pageNames, configValues: BOILIConfig, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/CompareRegionGrid' component={higherOrderComp(CompareRegionGrid, { screenName: "", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, propsSetSelectedTrendReportDetails: this.SetSelectedTrendReportDetails.bind(this), closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/ComparativeReport' component={higherOrderComp(ComparativeReport, { screenName: "", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/TrendReport' component={higherOrderComp(TrendReport, { screenName: "", pageNames: this.props.pageNames, Assessmentdetails: this.Assessmentdetails, TrendReportdetails: this.TrendReportdetails, closeGroupSwitchPopup: this.props.closeGroupSwitchPopup })} />
                <Route path='/:user' component={() => (<Home closeGroupSwitchPopup={this.props.closeGroupSwitchPopup} />)} />

            </Switch>
        )
    }
};
export default MainRouter;