
// for deployment

// import { adalApiFetch } from '../config/AdalConfig';
// const request = async (url,req,body) => {

//     const rawResponse= await adalApiFetch(fetch,url, {
//       method: req,
//       headers: {
//               'Cache-Control':'no-cache, no-store, must-revalidate',
//               'Accept': 'application/json',
//               'Content-Type': 'application/json',
//               'Pragma':'no-cache'
//                },
//       body:JSON.stringify(body)
//     });
//     return await rawResponse.json();
//   };
//   export default request;


//for local
const request = async (url, req, body) => {
  // const rawResponse= await fetch('https://localhost:44312/'+url, {
  const rawResponse = await fetch(window.webConfig.REACT_APP_apiUrl + url, {
    method: req,
    headers: {
      "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken"),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Pragma': 'no-cache'
    },
    body: JSON.stringify(body)
  });
  return await rawResponse.json();
};
export default request;


