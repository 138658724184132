/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import NavigationConfirmation from '../../../../PMM/NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../../../images/icon-back-arrow.png';
import request from '../../../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../../Utils/AgGridLocale';
import {connect} from 'react-redux';
var numericStatus = false;
var editActive = false;
var isDeSelectAll = false;

//added for Industry specific dropdown
class SingleSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IndustrySpecificValue: [],
            IndustrySpecificOptions: this.props.data.IndustrySpecific,
            selectedIndustrySpecific: "",

        };
        this.onIndustrySpecificChange = this.onIndustrySpecificChange.bind(this);
        this.populateIndustrySpecifics = this.populateIndustrySpecifics.bind(this);


    }

    componentDidMount() {        
        this.populateIndustrySpecifics();
    }

    populateIndustrySpecifics() {
        try {
            if (this.props.data.ConfigureIndustrySpecifics && this.props.data.ConfigureIndustrySpecifics != null && this.props.data.ConfigureIndustrySpecifics.length > 0) {
                this.onIndustrySpecificChange(this.props.data.ConfigureIndustrySpecifics);
            }
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    onIndustrySpecificChange = (value) => {
        try {
            let selectedIndustrySpecifics = "", IndustrySpecificNames = "", containsCrossIndustry = false, replaceCrossIndustry = false, crossIndustryItem = [];
            this.setState({
                IndustrySpecificValue: value
            });
            value.map((item, index) => {
                if (value.length == 1) {
                    selectedIndustrySpecifics = item.value;
                    IndustrySpecificNames = item.label;
                }
                else {
                    if (index == 0) {
                        if (item.label == i18n.t('crossIndustry')) {
                            selectedIndustrySpecifics = item.value;
                            IndustrySpecificNames = item.label;
                            replaceCrossIndustry = true;
                            containsCrossIndustry = true;
                        }
                        else {
                            selectedIndustrySpecifics = item.value;
                            IndustrySpecificNames = item.label;
                        }
                    } else {
                        if (item.label == i18n.t('crossIndustry')) {
                            selectedIndustrySpecifics = item.value;
                            IndustrySpecificNames = item.label;
                            containsCrossIndustry = true;
                            crossIndustryItem.push(item);
                        }
                        else {
                            if (replaceCrossIndustry) {
                                selectedIndustrySpecifics = item.value;
                                IndustrySpecificNames = item.label;
                                crossIndustryItem.push(item);
                            } else {
                                selectedIndustrySpecifics = selectedIndustrySpecifics + "," + item.value;
                                IndustrySpecificNames = IndustrySpecificNames + "," + item.label;
                            }
                        }
                    }
                }
            });
            if (this.props.data["IndustrySpecificSelectedTypes"] != undefined && this.props.data["IndustrySpecificSelectedTypes"] != selectedIndustrySpecifics) {
                editActive = true;
            }
            this.props.data["IndustrySpecificSelectedTypes"] = selectedIndustrySpecifics;
            this.props.data["IndustrySpecificSelectedNames"] = IndustrySpecificNames;

            if (containsCrossIndustry == true) {
                this.setState({
                    IndustrySpecificValue: crossIndustryItem
                });
                this.props.data["ConfigureIndustrySpecifics"] = crossIndustryItem;
            }
            else {
                this.setState({
                    IndustrySpecificValue: value
                });
                this.props.data["ConfigureIndustrySpecifics"] = value;
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {

        let isDisabled = (this.props.colDef.cellRendererParams.groupId==
             this.props.data.ServiceComponentGroupID) ? true : false;
        return (
            <div>
                {
                    this.props.node.lastChild && this.props.api.gridOptionsWrapper.gridOptions.rowData.length > 4 ?
                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="rs_boiSelect1_BOIConfiguration" labelKey="label" onChange={this.onIndustrySpecificChange} options={this.state.IndustrySpecificOptions} selected={this.state.IndustrySpecificValue} menuContainerStyle={{ top: 'auto', bottom: '100%' }} clearButton multiple disabled={!isDisabled} />
                        :
                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="rs_boiSelect2_BOIConfiguration" labelKey="label" onChange={this.onIndustrySpecificChange} options={this.state.IndustrySpecificOptions} selected={this.state.IndustrySpecificValue} clearButton multiple disabled={!isDisabled} />
                }
            </div>
        );
    }
}
class BOIConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditAcitve: false,
            popUpDisplay: false,
            lstIndustry: [],
            lstCapability: [],
            lstTower: [],
            lstServiceComp: [],
            IndustryValue: "",
            Industry: "",
            CapabilityValue: [],
            TowerValue: [],
            ServiceCompValue: [],
            BOIGridArr: [],
            selectedBOIs: [],
            searchBOIs: "",
            isUnSaved: false,
            navigationPage: '',
            selectedCount: 0,
            pageNames: [],
            selectedGroupID: 0,
            sortCount:0
        };

        this.SaveBOIDetails = this.SaveBOIDetails.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.GetBOIConfigDetails = this.GetBOIConfigDetails.bind(this);
        this.loadBOIs = this.loadBOIs.bind(this);
        this.SelectTower = this.SelectTower.bind(this);
        this.SelectServiceComponent = this.SelectServiceComponent.bind(this);
        this.markSelected = this.markSelected.bind(this);
        this.sortBySelect = this.sortBySelect.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.checkSelected = this.checkSelected.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.SelectCapability = this.SelectCapability.bind(this);
        this.refresh = this.refresh.bind(this);
        this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
    }

    reSizeGrid() {
        this.refs.Grd_BOI.gridOptions.api.sizeColumnsToFit();
    }

    componentDidMount() {
        try {
            //window.tfo_event("PMM-MC-18", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
            editActive = false;
            window.addEventListener('resize', this.reSizeGrid, true);
            this.setState({ pageNames: this.props.pageName
            });
            $("#loader-div").show();
            var objThis = this;
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "ID": 0,
                "DealId": 0,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
            }
            request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(res => {
                    this.setState({ lstIndustry: res.Industry });
                    this.setState({ lstCapability: res.Capability });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    GetBOIConfigDetails(serviceId) {
        try {
            var IndustryVal = this.state.IndustryValue.value == undefined ? 0 : this.state.IndustryValue.value;
            $("#loader-div").show();
            var SearchedString = "";
            let data = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                BOILIID:0,
                BOIConfigID:0,
                SCID:serviceId, 
                SearchedString:SearchedString,
            }
            request("/BOI/GetBOIConfigDetails", 'POST', data)
                .then(res => {
                   if(res === ""){
                    $("#loader-div").css("display", "none")
                   } 
                   if (res) {
                        this.setState({ selectedBOIs: res });
                        this.markSelected(res);
                    }
                    else {
                        let objGridDetails = this.refs.Grd_BOI.gridOptions.rowData;
                        objGridDetails.map(eachRow => {
                            eachRow["ConfigureIndustrySpecifics"] = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                            eachRow["ServiceComponentGroupID"] = this.state.ServiceCompValue[0].GroupID;
                        })
                        this.refs.Grd_BOI.gridOptions.api.setRowData(objGridDetails);
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    loadBOIs(towerID) {
        try {
            $("#loader-div").show();
            var SearchedString = "";
            let data = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                BOILIFlag:1,
                TowerId:towerID,
                SearchedString:SearchedString,
            }
            request("/BOI/GetBOILIMasterForDropDown", 'POST', data)
                .then(response => {
                    $("#loader-div").hide();
                    let BOIList = response != "null" ? response : [];
                    if (BOIList.length > 0) {
                        let lstIndustry = this.state.lstIndustry.filter(item => item.label != "Global")
                        lstIndustry.push({ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null })
                        BOIList.map(item => item.IndustrySpecific = lstIndustry)
                    }

                    this.setState({
                        BOIGridArr: BOIList
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
            this.reSizeGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectIndustry(val) {
        this.setState({ IndustryValue: val });
    }

    SelectCapability(val) {
        try {
            $("#loader-div").show();
            this.setState({
                CapabilityValue: val,
                TowerValue: [],
                ServiceCompValue: [],
                lstServiceComp: [],

            });
            $("#boi-config-grid").addClass("disabled-grid");

            if (val.length != 0) {
                $("#loader-div").show();
                let towerData = {
                    "UserName":this.props.enterpriseId,
                    "GroupID":this.props.groupId,
                    "LanguageID":this.props.languageId,
                    "TowerID": 0,
                    "DealId": 0,
                    "DeliveryLocationId": 0,
                    "CapabilityId": val[0].value,
                }
                request("/Common/GetTowerBasedOnCapability", 'POST', towerData)
                    .then(response => {
                        let towerList = response != null ? response : [];
                        this.setState({ TowerValue: [], lstTower: towerList });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectTower(val) {
        try {
            $("#loader-div").show();
            this.setState({
                TowerValue: val,
                ServiceCompValue: [],
            });
            $("#boi-config-grid").addClass("disabled-grid");
            if (val.length != 0) {
                this.loadBOIs(val[0].value);
                let scData = {
                    "UserName":this.props.enterpriseId,
                    "GroupID":this.props.groupId,
                    "LanguageID":this.props.languageId,
                    "TowerID": val[0].value,
                    "SCId": 0,
                }
                request("/Common/GetSCBasedOnTower", 'POST', scData)
                    .then(res => {
                        let SCList = res != "" ? res : [];
                        this.setState({ ServiceCompValue: [], lstServiceComp: SCList });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectServiceComponent(val) {
        try {
            this.setState({
                ServiceCompValue: val,
                selectedGroupID: val[0].GroupID
            });
            this.GetBOIConfigDetails(val[0].value);
            this.refs.Grd_BOI.gridOptions.api.deselectAll();
            isDeSelectAll = true;
            $("#boi-config-grid").removeClass("disabled-grid");
            let configValues = {
                capability: this.state.CapabilityValue,
                tower: this.state.TowerValue,
                serviceComponent: val,
            }
            this.props.BOILIConfig(configValues);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    markSelected(BOIArr) {
        try {
            this.reSizeGrid();
            let objGridDetails = this.refs.Grd_BOI.gridOptions.rowData;
            objGridDetails.map(eachRow => {
                let temp = BOIArr.find(item => (item.BOILIId == eachRow.BOILIId))
                if (temp != undefined) {
                    if (temp.IndustrySpecific && temp.IndustrySpecific != 0) {
                        eachRow["ConfigureIndustrySpecifics"] = temp.ConfigureIndustrySpecifics;
                    } else {
                        eachRow["ConfigureIndustrySpecifics"] = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                    }
                }
                else {
                    eachRow["ConfigureIndustrySpecifics"] = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                }
                eachRow["ServiceComponentGroupID"] = this.state.ServiceCompValue[0].GroupID;
            })
            this.refs.Grd_BOI.gridOptions.api.setRowData(objGridDetails);

            this.refs.Grd_BOI.gridOptions.api.forEachNode((node) => {
                BOIArr.map((selectedBOI) => {
                    if (node.data.BOILIId === selectedBOI.BOILIId) {
                        node.data["BOIConfigId"] = selectedBOI.BOIConfigId;
                        this.refs.Grd_BOI.gridOptions.api.selectNode(node, true);
                    }
                });
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkSelected() {

        try {
            this.reSizeGrid();
            let count = this.refs.Grd_BOI.gridOptions.api.getSelectedRows().length;
            if (this.state.selectedCount != 0 && this.state.selectedCount != count) {
                editActive = true;
            }
            this.setState({
                selectedCount: count
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onRowSelect(eve) {

        try {
            eve.node.data["IsSelected"] = eve.node.selected ? true : false;
            if (eve.node.selected) {
                this.sortBySelect();
                isDeSelectAll = false;
                let params = {
                    force: true
                }
                this.refs.Grd_BOI.gridOptions.api.refreshCells(params);
            } else if (!isDeSelectAll) {
                let data = {
                    UserName:this.props.enterpriseId,
                    GroupID:this.props.groupId,
                    LanguageID:this.props.languageId,
                    BOILIId:eve.node.data.BOILIId,
                    SCId:this.state.ServiceCompValue[0].value,
                }
                request("/BOI/ValidateBOIImpacted", 'POST', data)
                    .then(res => {
                        $("#loader-div").hide();
                        if (res.outputmessage == i18n.t('success')) {
                        } else {
                            eve.node.setSelected(true, false);
                            eve.node.data["IsSelected"] = true;
                            alert(res.outputmessage);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    sortBySelect() {
        try {
            let sort = [{
                colId: 'IsSelected',
                sort: 'desc'
            }]
            this.refs.Grd_BOI.gridOptions.api.setSortModel(sort);
           // to remove loader only after the cell marking is completed
            this.setState({
                sortCount : this.state.sortCount+1
            },() => {
                if(this.state.sortCount === this.state.selectedBOIs.length){
                    $("#loader-div").css("display", "none")
                    this.setState({sortCount:0})
                }
            })
            
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            if (params.colDef.headerName == "As-Is" || params.colDef.headerName == "To-Be") {
                var valid = (params.value.match(/^[\d.]+$/));
                if (!valid) {
                    alert(i18n.t('pleaseEnterNumericValue'));
                    return false;
                }
                else {
                    numericStatus = false;
                }
                if (params.data.Operator != "" && params.data.Operator == "Greater Than (>)") {
                    if (params.data.AsIs < params.data.ToBe) {
                        alert(i18n.t('asIsGreaterToBe'));
                    }
                }
                else if (params.data.Operator != "" && params.data.Operator == "Less Than (<)") {
                    if (params.data.AsIs > params.data.ToBe) {
                        alert(i18n.t('asIsLessThanToBe'));
                    }
                }
            }

            if (params.colDef.headerName == "Operator") {
                if (params.data.AsIs != "" && params.data.ToBe != "" && params.value == "Greater Than (>)") {
                    if (params.data.AsIs < params.data.ToBe) {
                        alert(i18n.t('asIsGreaterToBe'));
                    }
                }
                else if (params.data.Operator != "" && params.data.ToBe != "" && params.value == "Less Than (<)") {
                    if (params.data.AsIs > params.data.ToBe) {
                        alert(i18n.t('asIsLessThanToBe'));
                    }
                }
            }

            if (params.colDef.headerName == "Max (As-Is)") {

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveBOIDetails(e) {

        try {
            this.refs.Grd_BOI.gridOptions.api.stopEditing();
            let evtSrc = e.target.text.trim();
            let grdData = this.refs.Grd_BOI.gridOptions.rowData;
            let selectedBOI = grdData.filter(row => row.IsSelected == true && row.IndustrySpecificSelectedTypes !== "");
            let emptyIndustry = grdData.filter(row => row.IsSelected == true && row.IndustrySpecificSelectedTypes === "");
            let BOIData = [];
            let user = this.props.enterpriseId;
            let SelectedBOICount = selectedBOI.length;
            selectedBOI.map(BOI => {
                let BOIObj = {
                    BOIConfigId: BOI.BOIConfigId ? BOI.BOIConfigId : 0,
                    BOILIId: BOI.BOILIId,
                    SCID: this.state.ServiceCompValue[0].value,
                    loggedInUserId: user,
                    IndustrySpecific: BOI.IndustrySpecificSelectedTypes
                }
                BOIData.push(BOIObj);
            });
            numericStatus = true;
            if (SelectedBOICount >= 1 && this.state.ServiceCompValue != [] && emptyIndustry.length == 0) {
                var BOIDetails = {
                    "boiDetails": BOIData
                };
                this.setState({
                    isEditAcitve: false
                })
                editActive = false;
                $("#loader-div").show();
                request("/BOI/SaveBOIConfigDetails?GroupID="+this.props.groupId+ "&LanguageID=" + this.props.languageId,'POST',BOIDetails)
                    .then(res => {
                        alert(res.outputmessage);
                        $("#loader-div").hide();
                        if (evtSrc != i18n.t('save') && res.Outputcode != 0) {
                            this.props.history.push("/LIConfiguration");
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
                if (this.state.ServiceCompValue == [] || this.state.ServiceCompValue == null) {
                    alert(i18n.t('selectSCForBOI'));
                } else if (emptyIndustry.length > 0) {
                    alert(i18n.t('selectOneIndustry'));
                } else {
                    alert(i18n.t('selectOneBOI'));
                }
                return;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == "User Management"){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "Deal Management"){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "PMM Configuration"){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve || editActive) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            } else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        this.setState({
            popUpDisplay: false
        })
    }

    gridReadyFun(eve) {
        eve.api.sizeColumnsToFit();
        $("#loader-div").show();
    }

    Clear() {
        this.setState({
            CapabilityValue: [],
            TowerValue: [],
            ServiceCompValue: [],
            IndustryValue: "",
            BOIGridArr: [],
            lstTower: [],
            lstServiceComp: [],
            selectedCount: 0,
            isEditAcitve: false,
            searchBOIs: ""
        });
        editActive = false;
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onCellClicked(e) {
        try {
            if (e.colDef.headerName != i18n.t('applicableIndustry')) {
                this.refresh();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /* Refresh the grid and reset the row height based on the cell data */
    refresh() {
        try {
            let objGridDetails = this.refs.Grd_BOI.gridOptions.rowData;
            this.refs.Grd_BOI.gridOptions.api.setRowData(objGridDetails);
            this.setState({
                BOIGridArr: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.Grd_BOI.gridOptions.api.refreshCells(params);
            this.refs.Grd_BOI.gridOptions.api.forEachNode((node) => {
                if (node.data.IsSelected) {
                    node.setSelected(true, false);
                }
            });
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        let { t } = this.props;
        let isHidden = (this.props.groupId
            == this.state.selectedGroupID) ? true : false;
        columnDefs = [
            { headerName: t('bOILIId'), field: 'BOILIId', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: t('iD#'), field: 'UniqueFormulaID', suppressMovable: true, suppressResize: true, hide: false },
            { headerName: t('boiKeyBusinessMetrics'), field: 'BOILIName', tooltipField: 'BOILIName', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: t('definition'), field: 'BOILIDefinition', tooltipField: 'BOILIDefinition', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: t('applicableIndustry'), field: 'IndustrySpecific', cellRendererFramework: SingleSelect, cellRendererParams: {groupId : this.props.groupId}, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, width: 280, editable: false },
            {
                headerName: t('selectAll'), checkboxSelection: true, suppressMenu: true, suppressSorting: true, headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, suppressResize: true, autoHeight: true, hide: !isHidden
            },
            { headerName: t('isSelected'), field: 'IsSelected', hide: true, suppressMovable: true, suppressResize: true, autoHeight: true }

        ];

        var BOIDataGrid = (
            <div id="boi-config-grid" className="ag-theme-material grid-div custom-select disabled-grid">
                <AgGridReact ref="Grd_BOI" enableSorting="true" id="grd_BOI"
                    paginationPageSize="10"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.BOIGridArr}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    suppressRowClickSelection={true}
                    onGridReady={this.gridReadyFun}
                    quickFilterText={this.state.searchBOIs}
                    onRowSelected={this.onRowSelect}
                    onSelectionChanged={this.checkSelected}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    suppressScrollOnNewData={true}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" />{i18n.t('bACKTOHOME')}</a>
                    <ul className="pagecounter">
                        <li><span title="BOI / KEY BUSINESS METRIC" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI">...</span></li>
                        <li><span title="LEADING INDICATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="LEADING INDICATOR">1</span></li>
                        <li><span title="EFFORT" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="EFFORT">2</span></li>
                        <li><span title="PRODUCTIVITY CALCULATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PRODUCTIVITY CALCULATOR">3</span></li>
                        <li><span title="PMM CONFIG" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM CONFIG">4</span></li>
                    </ul>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            <li className="active">
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li> : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                        navigationPage={this.state.navigationPage} isEditAcitve={editActive}
                        url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                </div>
                <section className="bodydata multiple-tabs-section">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <div className="box">
                                <div className="box-in viewPrj-filter">
                                    <div className="form-group col-md-2">
                                        <label id="lbl_capability">{i18n.t('capability')}<span className="mandatory-cls">*</span></label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_Capability_BOIConfiguration" labelKey="label" onChange={this.SelectCapability} options={this.state.lstCapability} selected={this.state.CapabilityValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                    </div>

                                    <div className="form-group col-md-2">
                                        <label id="lbl_tower">{i18n.t('tower')}<span className="mandatory-cls">*</span></label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_Tower_BOIConfiguration" labelKey="label" onChange={this.SelectTower} options={this.state.lstTower} selected={this.state.TowerValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                    </div>

                                    <div className="form-group col-md-2">
                                        <label id="lbl_serviceComponent">{i18n.t('serviceComponent')}<span className="mandatory-cls">*</span></label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_ServiceComp_BOIConfiguration" labelKey="label" onChange={this.SelectServiceComponent} options={this.state.lstServiceComp} selected={this.state.ServiceCompValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                    </div>
                                </div>
                            </div>
                            <h4 className="grid-title">{i18n.t('boiKeyBusinessMetrics')}
                                <span className="badge">{this.state.selectedCount}</span>
                            </h4>
                            <div className="search-div">
                                <input id="txt_search" type="text" placeholder={i18n.t('search')} className="fright searchBox" data-field='searchBOIs' value={this.state.searchBOIs} onChange={this.onSearch} />
                            </div>
                            <div className="AsmtData viewprevassmt box-plain pmm-asmt-tbl">
                                {BOIDataGrid}
                                {
                                    isHidden ? //redirect to Next Liconfig screen for group other than BPS
                                        <div className="btn-group-md">
                                            <a type="button" className="btn btn-danger" onClick={this.SaveBOIDetails}>{i18n.t('save')}</a>
                                            <a type="button" className="btn btn-info" onClick={this.Clear.bind(this)}>{i18n.t('clear_PMMConfig')}</a>
                                            <a type="button" className="btn btn-success" onClick={this.SaveBOIDetails}>{i18n.t('configureLeadIndicator')}</a>
                                        </div>
                                        :
                                        <div className="btn-group-md">
                                            <Link type="button" className="btn btn-success" to="/LIConfiguration">{i18n.t('next')}</Link>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}
BOIConfiguration = connect(mapStateToProps)(withTranslation()(BOIConfiguration));
export default withRouter(BOIConfiguration);
