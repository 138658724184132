/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import NavigationConfirmation from '../../../../PMM/NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../../../images/icon-back-arrow.png';
import request from '../../../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AgGridLocale } from '../../../../Utils/AgGridLocale';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {connect} from 'react-redux';

var numericStatus = false;
var isDeSelectAll = false;
var editActive = false;

class BOISelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            BOIValue: [],
            BOIOptions: props.data.BOIList
        };
        this.onBOIChange = this.onBOIChange.bind(this);
        this.populateBOIs = this.populateBOIs.bind(this);
    }

    componentDidMount() {
        try {            
            this.populateBOIs();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(nextProps) {
        try {
            this.populateBOIs();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    populateBOIs() {
        try {
            if (this.props.data.BOIImpactedTypes && this.props.data.BOIImpactedTypes != null && this.props.data.BOIImpactedTypes.length > 0) {

                this.onBOIChange(this.props.data.BOIImpactedTypes);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBOIChange(value) {
        try {
            let selectedBOIs = "", BOINames = "";
            this.setState({
                BOIValue: value
            });
            value.map((BOI, index) => {
                if (index == 0) {
                    selectedBOIs = BOI.value;
                    BOINames = BOI.label;
                } else {
                    selectedBOIs = selectedBOIs + "," + BOI.value;
                    BOINames = BOINames + "," + BOI.label;
                }
            });
            if (this.props.data["BOIImpacted"] != undefined && this.props.data["BOIImpacted"] != selectedBOIs) {
                editActive = true;
            }
            this.props.data["ConfigureBOIs"] = value;
            this.props.data["BOIImpacted"] = selectedBOIs != null && selectedBOIs.length > 0 ? selectedBOIs.toString() : selectedBOIs;
            this.props.data["BOIImpactedNames"] = BOINames;
            this.props.data["BOIImpactedTypes"] = value;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        let isDisabled = (this.props.colDef.cellRendererParams.groupId == this.props.data.ServiceComponentGroupID) ? true : false;
        return (
            <div>
                {
                    this.props.node.lastChild && this.props.api.gridOptionsWrapper.gridOptions.rowData.length > 4 ?
                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="boi_select" id="rs_boi_select1_LIConfiguration" labelKey="label" onChange={this.onBOIChange} options={this.state.BOIOptions} selected={this.state.BOIValue} clearButton menuContainerStyle={{ top: 'auto', bottom: '100%' }} multiple disabled={!isDisabled}/>
                        :
                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="boi_select" id="rs_boi_select2_LIConfiguration" labelKey="label" onChange={this.onBOIChange} options={this.state.BOIOptions} selected={this.state.BOIValue} disabled={!isDisabled} multiple clearButton />
                }
            </div>
        );
    }
}

//added for industry specifics
class IndustrySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IndustrySpecificValue: [],
            IndustrySpecificOptions: this.props.data.IndustrySpecific,
            selectedIndustrySpecific: ""
        };
        this.onIndustrySpecificChange = this.onIndustrySpecificChange.bind(this);
        this.populateIndustrySpecifics = this.populateIndustrySpecifics.bind(this);
    }

    componentDidMount() {
        this.populateIndustrySpecifics();
    }

    populateIndustrySpecifics() {
        try {
            if (this.props.data.ConfigureIndustrySpecifics && this.props.data.ConfigureIndustrySpecifics != null && this.props.data.ConfigureIndustrySpecifics.length > 0) {
                this.onIndustrySpecificChange(this.props.data.ConfigureIndustrySpecifics);
            }
        } catch (error) {
            console.log(error)
        }
    }

    onIndustrySpecificChange(value) {
        try {
            let selectedIndustrySpecifics = "", IndustrySpecificNames = "", containsCrossIndustry = false, replaceCrossIndustry = false, crossIndustryItem = [];
            this.setState({
                IndustrySpecificValue: value
            });
            value.map((item, index) => {
                if (value.length == 1) {
                    selectedIndustrySpecifics = item.value;
                    IndustrySpecificNames = item.label;
                }
                else {
                    if (index == 0) {
                        if (item.label == i18n.t('crossIndustry')) {
                            selectedIndustrySpecifics = ""
                            IndustrySpecificNames = ""
                            replaceCrossIndustry = true;
                            containsCrossIndustry = true;
                        }
                        else {
                            selectedIndustrySpecifics = item.value;
                            IndustrySpecificNames = item.label;
                        }
                    } else {
                        if (item.label == i18n.t('crossIndustry')) {
                            selectedIndustrySpecifics = item.value;
                            IndustrySpecificNames = item.label;
                            containsCrossIndustry = true;
                            crossIndustryItem.push(item);
                        }
                        else {
                            if (replaceCrossIndustry) {
                                selectedIndustrySpecifics = item.value;
                                IndustrySpecificNames = item.label;
                                crossIndustryItem.push(item)
                            } else {
                                selectedIndustrySpecifics = selectedIndustrySpecifics + "," + item.value;
                                IndustrySpecificNames = IndustrySpecificNames + "," + item.label;
                            }
                        }
                    }
                }
            });

            if (this.props.data["IndustrySpecificSelectedTypes"] != undefined && this.props.data["IndustrySpecificSelectedTypes"] != selectedIndustrySpecifics) {
                editActive = true;
            }
            this.props.data["IndustrySpecificSelectedTypes"] = selectedIndustrySpecifics;
            this.props.data["IndustrySpecificSelectedNames"] = IndustrySpecificNames;
            if (containsCrossIndustry == true) {
                this.setState({
                    IndustrySpecificValue: crossIndustryItem,
                });
                this.props.data["ConfigureIndustrySpecifics"] = crossIndustryItem;
            }
            else {
                this.setState({
                    IndustrySpecificValue: value
                });
                this.props.data["ConfigureIndustrySpecifics"] = value;
            }
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        let isDisabled = (this.props.colDef.cellRendererParams.groupId
            == this.props.data.ServiceComponentGroupID) ? true : false;
        
        return (
            <div>
                {this.props.node.lastChild && this.props.api.gridOptionsWrapper.gridOptions.rowData.length > 4 ?

                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="boi_select" id="rs_select" labelKey="label" onChange={this.onIndustrySpecificChange} options={this.state.IndustrySpecificOptions} selected={this.state.IndustrySpecificValue} menuContainerStyle={{ top: 'auto', bottom: '100%' }} disabled={!isDisabled} clearButton multiple />
                    :
                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="boi_select" id="rs_select" labelKey="label" onChange={this.onIndustrySpecificChange} options={this.state.IndustrySpecificOptions} selected={this.state.IndustrySpecificValue} disabled={!isDisabled} clearButton multiple />
                }
            </div>
        );
    }
}

class LIConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditAcitve: false,
            popUpDisplay: false,
            lstIndustry: [],
            lstCapability: [],
            lstTower: [],
            lstServiceComp: [],
            IndustryValue: "",
            Industry: "",
            CapabilityValue: [],
            TowerValue: [],
            ServiceCompValue: [],
            LIGridData: [],
            selectedLIs: [],
            selectedCount: 0,
            searchLIs: "",
            BOIList: [],
            pageNames: [],
            selectedGroupID: 0
        };
        this.SaveLeadingIndicator = this.SaveLeadingIndicator.bind(this);
        this.loadLIs = this.loadLIs.bind(this);
        this.loadConfigureLIs = this.loadConfigureLIs.bind(this);
        this.Clear = this.Clear.bind(this);
        this.sortBySelect = this.sortBySelect.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.checkSelected = this.checkSelected.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.loadBOIsForService = this.loadBOIsForService.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        try {
            //window.tfo_event("PMM-MC-19", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
            window.addEventListener('resize', this.reSizeGrid, true);
            this.setState({
                CapabilityValue: this.props.configValues.capability,
                TowerValue: this.props.configValues.tower,
                ServiceCompValue: this.props.configValues.serviceComponent,
                pageNames:this.props.pageName
            });
            if (Object.keys(this.props.configValues).length > 0) {
                this.loadLIs(this.props.configValues.tower[0].value, this.props.configValues.serviceComponent[0].value);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props, nextProps) {
        try {
            this.setState({
                CapabilityValue: this.props.configValues.capability,
                TowerValue: this.props.configValues.tower,
                selectedGroupID: this.props.configValues.serviceComponent[0].GroupID
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    loadLIs(towerID, serviceID) {

        try {
            $("#loader-div").show();
            var SearchedString = "";
            let BOILIMasterData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                TowerId: towerID,
                BOILIFlag: 2,
                SearchedString: SearchedString,
            }
            let BOILIMasterForSCData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                SCId: serviceID,
                BOILIFlag: 1,
                SearchedString: SearchedString,
            }
            let LIConfigData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                BOILIID:0,
                LIConfigID:0,
                SCID:serviceID,
                SearchedString:SearchedString,
            }
            
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }


            Promise.all([request("/BOI/GetBOILIMasterForDropDown", 'POST', BOILIMasterData),
            request("/BOI/GetBOILIMasterForSC", 'POST', BOILIMasterForSCData),
            request("/LeadingIndicator/GetLIConfigDetails", 'POST', LIConfigData),
            request("/Common/DropDownsValue", 'POST', dropdownData)])
                .then((response) => {

                    $("#loader-div").hide();
                    let LIList = response[0] != null ? response[0] : [];
                    let BOIList = response[1] != null ? response[1] : [];
                    let ConfiguredLIs = response[2] != "" ? response[2] : [];
                    //added for industry specific
                    let lstIndustry = response[3] != "" ? response[3].Industry : [];
                    if (lstIndustry.length > 0) {
                        lstIndustry = lstIndustry.filter(item => item.label != "Global")
                        lstIndustry.push({ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null })
                    }
                    let LIArr = [];
                    LIList.map(LI => {
                        LI.BOIList = BOIList;
                        LI.IndustrySpecific = lstIndustry;
                        let temp;

                        ConfiguredLIs.map(selLI => {
                            if (LI.BOILIId === selLI.BOILIId) {
                                LI.BOIImpactedTypes = selLI.BOIImpactedTypes;
                            }
                        })
                        temp = ConfiguredLIs.find(item => (item.BOILIId == LI.BOILIId))
                        LI["ServiceComponentGroupID"] = this.state.ServiceCompValue[0].GroupID;

                        if (temp != undefined) {
                            if (temp.IndustrySpecific && temp.IndustrySpecific != "0") {
                                LI["ConfigureIndustrySpecifics"] = temp.ConfigureIndustrySpecifics;
                            } else {
                                LI["ConfigureIndustrySpecifics"] = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                            }
                        }
                        else {
                            LI["ConfigureIndustrySpecifics"] = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                        }
                        LIArr.push(LI);
                    })
                    this.setState({
                        LIGridData: LIArr
                    });

                    this.markSelected(ConfiguredLIs);
                }).catch((error) => {
                    $("#loader-div").hide();
                    console.log("Error in Get User", error)
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    loadConfigureLIs(serviceId) {
        try {
            $("#loader-div").show();
            var SearchedString = "";
            let data = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                BOILIID:0,
                LIConfigID:0,
                SCID:serviceId,
                SearchedString:SearchedString,
            }
            request("/LeadingIndicator/GetLIConfigDetails", 'POST', data)
                .then(response => {
                    $("#loader-div").hide();
                    this.setState({ selectedLIs: response });
                    this.markSelected(response);
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    loadBOIsForService(serviceID) {
        try {
            $("#loader-div").show();
            var SearchedString = "";
            debugger;
            let data = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                SCId: serviceID,
                SearchedString: SearchedString,
            }
            request("/BOI/GetBOILIMasterForSC", 'POST', data)
                .then(response => {
                    $("#loader-div").hide();
                    let newLIArr = [];
                    this.state.LIGridData.forEach(LI => {
                        LI.BOIList = response;
                        return(newLIArr.push(LI));
                    });
                    this.setState({
                        BOIList: response,
                        LIGridData: newLIArr
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectIndustry(val) {
        try {
            this.setState({ IndustryValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCapability(val) {

        try {
            $("#loader-div").show();
            this.setState({ CapabilityValue: val, selectedGroupID: val[0].GroupID });
            let towerData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CapabilityId": val[0].value,
            }
            request("/Common/GetTowerBasedOnCapability", 'POST', towerData)
                .then(response => {
                    $("#loader-div").hide();
                    this.setState({ TowerValue: [], lstTower: response });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectTower(val) {
        try {

            $("#loader-div").show();
            this.setState({ TowerValue: val, selectedGroupID: val.GroupID });
            this.setState({ showComparePopup: false });
            this.loadLIs(val[0].value);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectServiceComponent(val) {

        try {
            this.setState({
                ServiceCompValue: val,
                selectedGroupID: val[0].GroupID
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_LeadInd.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    markSelected(LIArr) {
        try {

            this.refs.Grd_LeadInd.gridOptions.api.forEachNode((node) => {
                LIArr.map((LI) => {

                    if (node.data.BOILIId === LI.BOILIId) {

                        node.setSelected(true, false);
                    }
                });
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkSelected(eve, params) {
        try {
            let count = this.refs.Grd_LeadInd.gridOptions.api.getSelectedRows().length;
            if (this.state.selectedCount != 0 && this.state.selectedCount != count) {
                editActive = true;
            }
            this.setState({
                selectedCount: count,
                isEditAcitve: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onRowSelect(eve) {
        try {
            eve.node.data["IsSelected"] = eve.node.selected ? true : false;
            if (!eve.node.selected) {

            }

            if (eve.node.selected) {
                this.sortBySelect();
                isDeSelectAll = false;
                let params = {
                    force: true
                }
                this.refs.Grd_LeadInd.gridOptions.api.refreshCells(params);
            } else if (!isDeSelectAll) {
                let data ={
                    UserName:this.props.enterpriseId,
                    GroupID:this.props.groupId,
                    LanguageID:this.props.languageId,
                    BOILIId:eve.node.data.BOILIId,
                    SCId:this.state.ServiceCompValue[0].value,
                }
                request("/LeadingIndicator/ValidateMetrics", 'POST', data)
                    .then(response => {
                        $("#loader-div").hide();
                        if (response.outputmessage == "Success") {
                            eve.node.data.BOIImpactedTypes = [];

                            let params = {
                                force: true
                            }
                            this.refs.Grd_LeadInd.gridOptions.api.refreshCells(params);


                        } else {
                            eve.node.setSelected(true, false);
                            eve.node.data["IsSelected"] = true;
                            alert(response.outputmessage);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Sorts the table so that the selected countries are displayed in the first page */
    sortBySelect() {
        try {
            let sort = [{
                colId: 'IsSelected',
                sort: 'desc'
            }]
            this.refs.Grd_LeadInd.gridOptions.api.setSortModel(sort);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            if (params.colDef.headerName == "As-Is" || params.colDef.headerName == "To-Be") {
                var valid = (params.value.match(/^[\d.]+$/));
                if (!valid) {
                    alert(i18n.t('pleaseEnterNumericValue'));
                    return false;
                }
                else {
                    numericStatus = false;
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onOverallEdit() {

    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == "User Management"){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "Deal Management"){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "PMM Configuration"){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve ||
                editActive) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            } else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveLeadingIndicator(e) {
        try {
            $("#loader-div").show();
            this.refs.Grd_LeadInd.gridOptions.api.stopEditing();
            let evtSrc = e.target.text.trim();
            let metricNameBlankStatus = false;
            let grdData = this.refs.Grd_LeadInd.gridOptions.rowData;
            let selectedLIs = grdData.filter(row => row.IsSelected && row.IndustrySpecificSelectedTypes !== "");
            let emptyIndustry = grdData.filter(row => row.IsSelected == true && row.IndustrySpecificSelectedTypes === "");
            let totalNoOfRecords = selectedLIs.length;
            let LIData = [];
            let user = this.props.enterpriseId;
            selectedLIs.map(LI => {
                let LIObj = {
                    LIConfigID: LI.LIConfigId ? LI.LIConfigId : 0,
                    BOILIId: LI.BOILIId,
                    SCID: this.state.ServiceCompValue[0].value,
                    BOIImpacted: LI.BOIImpacted,
                    loggedInUserId: user,
                    IndustrySpecific: LI.IndustrySpecificSelectedTypes
                }
                LIData.push(LIObj);
            });
            if (totalNoOfRecords > 0 && emptyIndustry.length == 0) {
                var LeadingIndicatorDetails = {
                    "liDetails": LIData
                };
                this.setState({
                    isEditAcitve: false
                })
                editActive = false;
                $("#loader-div").show();
                request("/LeadingIndicator/SaveLIConfigDetails?GroupID="+this.props.groupId+"&LanguageID=" +this.props.languageId,'POST',LeadingIndicatorDetails)    
                .then(res => {
                        if (res.outputmessage == i18n.t('success')) {
                            alert(res.outputmessage);
                            if (evtSrc == i18n.t('skipToPMMConfig') && res.Outputcode != 0) {
                                this.props.history.push("/PMMConfig");
                            }
                            else if (evtSrc != i18n.t('save') && res.Outputcode != 0) {
                                this.props.history.push("/Effort");
                            }
                        }
                        else if (res.Outputcode == -1){
                            alert(res.outputmessage);
                        }
                        else {
                            alert(i18n.t('LINotSaved'));
                        }
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
                if (emptyIndustry.length > 0) {
                    alert(i18n.t('selectOneIndustry'));
                }
                else {
                    alert(i18n.t('selectOneLI'));
                }
                $("#loader-div").hide();
                return;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun(eve) {
        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    Clear() {
        try {
            if (this.props.groupId
                == this.props.configValues.serviceComponent[0].GroupID) {
                let gridData = this.refs.Grd_LeadInd.gridOptions.rowData;
                gridData.map(row => {
                    row.BOIImpactedTypes = [];
                    row.ConfigureIndustrySpecifics = [{ "label": i18n.t('crossIndustry'), "value": 0, "CapabilityID": null, "OGID": null, "DeliveryLocationIds": null }]
                })
                this.setState({
                    isEditAcitve: false,
                    searchLIs: "",
                });
                editActive = false;
                this.refs.Grd_LeadInd.gridOptions.api.deselectAll();
                isDeSelectAll = true;
                let params = {
                    force: true
                }
                this.refs.Grd_LeadInd.gridOptions.api.refreshCells(params);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SkipToPMMConfig(e) {
        try {
            if (confirm(i18n.t('sureSkipToPMM'))) {

                this.SaveLeadingIndicator(e);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onCellClicked(e) {

    }

    /* Refresh the grid and reset the row height based on the cell data */
    refresh() {

    }

    //redirect to Next Liconfig screen
    onNext(eve) {
        this.props.history.push("/PMMConfig");
    }

    render() {
        var columnDefs = [];
        let { t } = this.props;
        let isHidden = (this.props.groupId
            == this.props.configValues.serviceComponent[0].GroupID) ? true : false;
        columnDefs = [
            { headerName: t('bOILIId'), field: 'BOILIId', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: t('iD#'), field: 'UniqueFormulaID', suppressMovable: true, suppressResize: true, hide: false, width: 100 },
            { headerName: t('lIs'), field: 'BOILIName', tooltipField: 'BOILIName', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: t('definition'), field: 'BOILIDefinition', tooltipField: 'BOILIDefinition', autoHeight: true, suppressMovable: true, suppressResize: true },
            { headerName: t('applicableIndustry'), field: 'IndustrySpecific', cellRendererFramework: IndustrySelect, cellRendererParams: {groupId: this.props.groupId}, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, width: 280 },
            { headerName: t('bOIMetricImpacted'), field: 'ConfigureBOIs', tooltipField: 'ConfigureBOIs', cellRendererFramework: BOISelect, cellRendererParams: {groupId: this.props.groupId}, suppressMovable: true, suppressResize: true, width: 300 },
            {
                headerName: t('selectAll'), checkboxSelection: true, suppressMenu: true, suppressSorting: true, headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, suppressResize: true, autoHeight: true, hide: !isHidden
            },
            { headerName: t('isSelected'), field: 'IsSelected', hide: true, suppressMovable: true, suppressResize: true, autoHeight: true }
        ];

        var LeadingIndicatorDataGrid = (
            <div className="ag-theme-material grid-div custom-select">
                <AgGridReact ref="Grd_LeadInd" enableSorting="true" id="grd_LeadInd"
                    paginationPageSize="10"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.LIGridData}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    suppressRowClickSelection={true}
                    onGridReady={this.gridReadyFun}
                    quickFilterText={this.state.searchLIs}
                    onRowSelected={this.onRowSelect}
                    onSelectionChanged={this.checkSelected}
                    onCellEditingStarted={this.onOverallEdit}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    listOptions={this.state.BOIList}
                    suppressScrollOnNewData={true}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <ul className="pagecounter"  >
                        <li><span title="BOI / KEY BUSINESS METRIC" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><a data-url="/BOIConfiguration" data-content="BOI Configuration" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="LEADING INDICATOR" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="LEADING INDICATOR">...</span></li>
                        <li><span title="EFFORT" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="EFFORT">2</span></li>
                        <li><span title="PRODUCTIVITY CALCULATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PRODUCTIVITY CALCULATOR">3</span></li>
                        <li><span title="PMM CONFIG" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM CONFIG">4</span></li>
                    </ul>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            <li className="active">
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                        navigationPage={this.state.navigationPage} isEditAcitve={editActive}
                        url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                </div>
                <section className="bodydata multiple-tabs-section">
                    <div className="bodydata-full">
                        <div className="box-plain">

                            <div className="">
                                <h4>

                                    <span className="statustxt-orng-italic" title="Capability">{this.props.configValues.capability != [] ? this.props.configValues.capability[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Tower">{this.props.configValues.tower != [] ? this.props.configValues.tower[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Service Component">{this.props.configValues.serviceComponent != [] ? this.props.configValues.serviceComponent[0].label : []}</span>
                                </h4>
                            </div>
                            <div className="">
                                <h4 className="grid-title">
                                    {i18n.t('leadingIndicator')}
                                    <span className="badge">{this.state.selectedCount}</span>
                                </h4>
                                <input id="txt_search" type="text" placeholder="Search" className="fright searchBox" data-field='searchLIs' value={this.state.searchLIs} onChange={this.onSearch} />
                            </div>
                            <div className="AsmtData viewprevassmt box-plain pmm-asmt-tbl">
                                {LeadingIndicatorDataGrid}
                                {
                                    isHidden ?
                                        <div className="btn-group-md">
                                            <a type="button" title="Go to PMM Config screen without configuring productivity Calculations" className="btn btn-warning" onClick={this.SkipToPMMConfig.bind(this)} >{i18n.t('skipToPMMConfig')}</a>
                                            <a type="button" className="btn btn-danger" onClick={this.SaveLeadingIndicator}>{i18n.t('save')}</a>
                                            <a type="button" className="btn btn-info" onClick={this.Clear.bind(this)}>{i18n.t('clear')}</a>
                                            <a type="button" className="btn btn-success" onClick={this.SaveLeadingIndicator}>{i18n.t('configureEffort')}</a>
                                        </div>
                                        :
                                        <div className="btn-group-md">
                                            <a type="button" title="Go to PMM Config screen without configuring productivity Calculations" className="btn btn-warning" onClick={this.SkipToPMMConfig.bind(this)} >{i18n.t('skipToPMMConfig')}</a>
                                            <Link type="button" className="btn btn-danger" to="/BOIConfiguration">{i18n.t('previous')}</Link>
                                            <Link type="button" className="btn btn-success" to="/Effort">{i18n.t('next')}</Link>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}

LIConfiguration = connect(mapStateToProps)( withTranslation()(LIConfiguration));
export default withRouter(LIConfiguration);