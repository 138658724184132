/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import NavigationConfirmation from '../../../../PMM/NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../../../images/icon-back-arrow.png';
import addButtonIcon from '../../../../images/icon-add-btn.png';
import request from '../../../../services/Service';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { inputValidator } from '../../../../Utils/InputElementValidation';


var newCount = 1;
var numericStatusBefore = true;
var numericStatusAfter = true;
var evtText = "";
var pageError = false;
var isDeSelectAll = false;
var editActive = false;

function CreateDelete(params) {
    try {
        if (params.data.UniqueProductivityId == "") {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = '<span class="glyphicon glyphicon-trash"></span>';
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class ProductivityCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditAcitve: false,
            popUpDisplay: false,
            ProductivityGridData: [],
            BOILIData: [],
            EffortData: [],
            selectedCount: 0,
            pageNames: [],
            CapabilityValue: [],
            TowerValue: [],
            ServiceCompValue: [],
            showBoiLiPopup: false,
            showEffortPopup: false,
            deleteStatus: false
        }
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.markSelected = this.markSelected.bind(this);
        this.checkCells = this.checkCells.bind(this);
    }

    componentDidUpdate() {
        try {
            this.refs.Grd_Productivity.gridOptions.api.sizeColumnsToFit();
            this.refs.Grd_BOILI.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {        
            //window.tfo_event("PMM-MC-21", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            $("#loader-div").show();
            this.setState({
                CapabilityValue: this.props.configValues.capability,
                TowerValue: this.props.configValues.tower,
                ServiceCompValue: this.props.configValues.serviceComponent,
                pageNames: this.props.pageName
            });
            var SearchedString = "";
            let productivityConfigData = {
                UserName: this.props.enterpriseId,
                LanguageID: this.props.languageId,
                ProductivityConfigID: 0,
                SCID: this.props.configValues.serviceComponent[0].value,
                IsSelected: 1,
                SearchedString: SearchedString,
            }
            let BOILIConfig = {
                UserName: this.props.enterpriseId,
                LanguageID: this.props.languageId,
                GroupID: this.props.groupId,
                BOILIID: 0,
                SCID: this.props.configValues.serviceComponent[0].value,
                SearchedString: SearchedString,
            }
            let effortConfigData = {
                UserName: this.props.enterpriseId,
                LanguageID: this.props.languageId,
                GroupID: this.props.groupId,
                EffortConfigID: 0,
                SCID: this.props.configValues.serviceComponent[0].value,
                IsSelected: 2,
                SearchedString: SearchedString,
            }
            Promise.all([request("/PMM/GetProductivityConfig", 'POST', productivityConfigData)
                , request("/PMM/GetBOILIConfigForSC", 'POST', BOILIConfig)
                , request("/PMM/GetEffortConfig", 'POST', effortConfigData)])
                .then(response => {
                    let productivityList = response[0] != "Data not there" ? response[0] : [];
                    let effortList = response[2] != "Data not there" ? response[2] : [];
                    this.setState({
                        ProductivityGridData: productivityList,
                        BOILIData: response[1],
                        EffortData: effortList
                    });
                    this.checkCells();
                    this.markSelected(response[0]);
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props, nextProps) {
        try {
            this.setState({
                CapabilityValue: this.props.configValues.capability,
                TowerValue: this.props.configValues.tower,
                ServiceCompValue: this.props.configValues.serviceComponent
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == i18n.t('userManagement')){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('dealManagement')){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('pMMConfiguration')){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve || editActive) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage,
                    showBoiLiPopup: false,
                    showEffortPopup: false
                })
            } else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage,
                        showBoiLiPopup: false,
                        showEffortPopup: false
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        this.setState({
            popUpDisplay: false
        })
    }

    gridReadyFun(eve) {
        eve.api.sizeColumnsToFit();
    }

    onAddRow() {
        try {
            var newItem = this.CreateNewRowData();
            let currentGridData = this.refs.Grd_Productivity.gridOptions.rowData;
            currentGridData.push(newItem);
            this.refs.Grd_Productivity.gridOptions.api.setRowData(currentGridData);
            this.setState({
                deleteStatus: true,
                showBoiLiPopup: false,
                showEffortPopup: false,
                popUpDisplay: false
            });
            this.markSelected(currentGridData);
            editActive = true;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CreateNewRowData() {
        try {
            var newData = {
                ProductivityConfigId: 0,
                ProductivityRowId: 0,
                UniqueProductivityId: "",
                ProductivityDescription: "",
                BeforeEffort: "",
                AfterEffort: "",
                SCId: this.props.configValues.serviceComponent[0].value,
                SCName: this.props.configValues.serviceComponent[0].label,
                TowerId: 0,
                TowerName: "",
                CapabilityID: 0,
                CapabilityName: "",
                IsActive: "true",
                CreatedBy: this.props.enterpriseId,
                loggedInUserId: this.props.enterpriseId,
            };

            newCount++;
            return newData;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveProductivity(e) {
        try {
            this.setState({ popUpDisplay: false })
            $("#loader-div").show();
            this.refs.Grd_Productivity.gridOptions.api.stopEditing();
            let evtSrc = e.target.text.trim();
            evtText = evtSrc;
            let grdData = this.refs.Grd_Productivity.gridOptions.rowData;

            var isError = true;
            isError = this.checkCells();
            if (!isError) {

                if (pageError == true) {
                    $("#loader-div").hide();
                    alert(i18n.t('correctValuesInHighlightedCells'));
                    pageError = false;
                    this.markSelected(grdData);
                    return false;
                }

                var ProductivityDetails = {
                    "productivityDetails": grdData
                };
                var invalid = false;
                for (var i = 0; i < grdData.length; i++) {
                    var ValidDescription = inputValidator(grdData[i].ProductivityDescription);
                    if (ValidDescription) {
                        invalid = true;
                    }
                }

                if (!invalid) {
                    request("/PMM/SaveProductivityConfig?GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'POST', ProductivityDetails)
                    .then(res => {
                        if (res.Outputcode == -1) {
                            alert(res.outputmessage);
                            evtText = "";
                            $("#loader-div").hide();
                        }
                        else {
                            alert(i18n.t('productivitySaved'));
                            if (evtSrc != i18n.t('save')) {
                                this.props.history.push("/PMMConfig")
                            }
                            else {
                                var SearchedString = "";
                                let productivityConfigData = {
                                    UserName: this.props.enterpriseId,
                                    LanguageID: this.props.languageId,
                                    GroupID: this.props.groupId,
                                    ProductivityConfigID: 0,
                                    SCID: this.props.configValues.serviceComponent[0].value,
                                    IsSelected: 1,
                                    SearchedString: SearchedString,
                                }
                                request("/PMM/GetProductivityConfig", 'POST', productivityConfigData)
                                    .then(res => {
                                        let productivityList = res != null ? res : [];
                                        this.setState({ ProductivityGridData: productivityList });
                                        this.checkCells();
                                        this.markSelected(productivityList);
                                        editActive = false;
                                    })
                                    .catch(error => {
                                        console.log("Error Encountered");
                                    });
                            }
                            evtText = "";
                            $("#loader-div").hide();
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
                }
                else {
                    alert(i18n.t("PleaseEntervalidValuesintheFields"));
                    $("#loader-div").hide();
                }
            }
            else {
                $("#loader-div").hide();
                this.refs.Grd_Productivity.gridOptions.api.sizeColumnsToFit();
                this.markSelected(grdData);
                alert(i18n.t('enterProductivityFields'));
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ShowBOILIPopup() {
        this.setState({
            showBoiLiPopup: true,
            showEffortPopup: false,
            popUpDisplay: false
        });
    }

    ShowEffortPopup() {
        this.setState({
            showBoiLiPopup: false,
            showEffortPopup: true,
            popUpDisplay: false
        });
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('delete') && e.data.UniqueProductivityId == "") {
                if (confirm(i18n.t('deleteConfirmation'))) {
                    this.setState({ popUpDisplay: false })
                    let ProductivityDetails = this.refs.Grd_Productivity.gridOptions.rowData;
                    ProductivityDetails.splice(e.rowIndex, 1);
                    var result = ProductivityDetails.filter(function (obj) {
                        return (obj.UniqueProductivityId === "");
                    });
                    this.refs.Grd_Productivity.gridOptions.api.setRowData(ProductivityDetails);

                    if (result.length == 0) {
                        this.setState({ deleteStatus: false });
                    }
                    this.markSelected(ProductivityDetails);
                    this.refs.Grd_Productivity.gridOptions.api.sizeColumnsToFit();
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    markSelected(ProductivityArr) {
        try {
            let count = 0;
            this.refs.Grd_Productivity.gridOptions.api.forEachNode((node) => {
                ProductivityArr.map((selectedProductivity) => {
                    if (node.data.ProductivityConfigId === selectedProductivity.ProductivityConfigId) {
                        if(node.data.IsActive){
                            this.refs.Grd_Productivity.gridOptions.api.selectNode(node, true);
                        }
                    }
                });
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onRowSelect(eve) {
        try {
            eve.node.data["IsActive"] = eve.node.selected ? true : false;

            if (eve.node.selected) {
                this.sortBySelect();
                isDeSelectAll = false;
            } else if (!isDeSelectAll) {
                let validateProductivityData = {
                    UserName: this.props.enterpriseId,
                    LanguageID: this.props.languageId,
                    GroupID: this.props.groupId,
                    UniqueProductivityId: eve.node.data.UniqueProductivityId,
                    SCId: this.state.ServiceCompValue.value,
                }
                request("/PMM/ValidateProductivity", 'POST', validateProductivityData)
                    .then(res => {
                        $("#loader-div").hide();
                        if (res.outputmessage == i18n.t('success')) {
                            let params = {
                                force: false,
                                rowNodes: [eve.node]
                            }
                            this.refs.Grd_Effort.gridOptions.api.refreshCells(params);
                        } else {
                            eve.node.setSelected(true, false);
                            eve.node.data["IsSelected"] = true;
                            alert(res.outputmessage);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    sortBySelect() {
        let sort = [{
            colId: 'IsSelected',
            sort: 'desc'
        }]
        this.refs.Grd_Productivity.gridOptions.api.setSortModel(sort);
    }

    checkCells() {
        try {
            pageError = false;
            let objGridDetails = this.refs.Grd_Productivity.gridOptions.rowData;
            var isErrorThere = false;
            var numStatus = false;
            var that = this;
            var BOILIArr = this.state.BOILIData;
            var EffortArr = this.state.EffortData;

            objGridDetails.map((row, i) => {
                let BeforeEffortFormula = row.BeforeEffort;
                let AfterEffortFormula = row.AfterEffort;

                // Blank Value Validation.

                if (row.ProductivityDescription == "" || row.ProductivityDescription == null) {
                    isErrorThere = true;
                }
                if (row.BeforeEffort == "" || row.BeforeEffort == null) {
                    isErrorThere = true;
                }
                if (row.AfterEffort == "" || row.AfterEffort == null) {
                    isErrorThere = true;
                }

                // Numeric Value Validation
                !isNaN(parseFloat(BeforeEffortFormula)) && isFinite(BeforeEffortFormula) ? numericStatusBefore = true : numericStatusBefore = false;
                !isNaN(parseFloat(AfterEffortFormula)) && isFinite(AfterEffortFormula) ? numericStatusAfter = true : numericStatusAfter = false;
                if (numericStatusBefore == true) {
                    row["ErrorBefore"] = false;
                    row["MsgBefore"] = i18n.t('validValue');
                }
                if (numericStatusAfter == true) {
                    row["ErrorAfter"] = false;
                    row["MsgAfter"] = i18n.t('validValue');
                }
                if (BeforeEffortFormula != "" && BeforeEffortFormula != null && numericStatusBefore == false) {
                    var formulaFieldsBefore = BeforeEffortFormula.match(/[^>=<+-/*()%]+/g);

                    var i = 0;
                    for (i = 0; i < formulaFieldsBefore.length; i++) {
                        !isNaN(parseFloat(formulaFieldsBefore[i])) && isFinite(formulaFieldsBefore[i]) ? numStatus = true : numStatus = false;
                        if (numStatus == false) {
                            var resultBOILI = [];
                            var resultEffort = [];
                            if (BOILIArr != null) {
                                resultBOILI = BOILIArr.filter(function (el) {
                                    return (el.UniqueFormulaID === formulaFieldsBefore[i]);
                                });
                            }

                            if (EffortArr != null) {
                                resultEffort = EffortArr.filter(function (el) {
                                    return (el.UniqueEffortId === formulaFieldsBefore[i]);
                                });
                            }

                            if (resultBOILI.length == 0 && resultEffort.length == 0) {
                                row["ErrorBefore"] = true;
                                row["MsgBefore"] = i18n.t('usedIDsNotPresent');
                                pageError = true;
                                break;
                            }
                            else {
                                row["ErrorBefore"] = false;
                                row["MsgBefore"] = i18n.t('validValue');
                            }

                            BeforeEffortFormula = BeforeEffortFormula.replace(formulaFieldsBefore[i], "1");
                        }
                    }
                }
                if (AfterEffortFormula != "" && AfterEffortFormula != null && numericStatusAfter == false) {
                    var formulaFieldsAfter = AfterEffortFormula.match(/[^>=<+-/*()]+/g);

                    var i = 0;
                    for (i = 0; i < formulaFieldsAfter.length; i++) {
                        !isNaN(parseFloat(formulaFieldsAfter[i])) && isFinite(formulaFieldsAfter[i]) ? numStatus = true : numStatus = false;
                        if (numStatus == false) {
                            var resultBOILI = [];
                            var resultEffort = [];

                            if (BOILIArr != null) {
                                resultBOILI = BOILIArr.filter(function (el) {
                                    return (el.UniqueFormulaID === formulaFieldsAfter[i]);
                                });
                            }

                            if (EffortArr != null) {
                                resultEffort = EffortArr.filter(function (el) {
                                    return (el.UniqueEffortId === formulaFieldsAfter[i]);
                                });
                            }

                            if (resultBOILI.length == 0 && resultEffort.length == 0) {
                                row["ErrorAfter"] = true;
                                row["MsgAfter"] = i18n.t('usedIDsNotPresent');
                                pageError = true;
                                break;
                            }
                            else {
                                row["ErrorAfter"] = false;
                                row["MsgAfter"] = i18n.t('validValue');
                            }

                            AfterEffortFormula = AfterEffortFormula.replace(formulaFieldsAfter[i], "1");
                        }

                    }
                }

                //Evaluate Formula
                try {
                    var IsvalidFormulaBefore = "";
                    IsvalidFormulaBefore = eval(BeforeEffortFormula);
                }
                catch (err) {
                    row["ErrorBefore"] = true;
                    row["MsgBefore"] = row["MsgBefore"] != "" && row["MsgBefore"] != null && row["MsgBefore"] != i18n.t('validValue') ? row["MsgBefore"] : i18n.t('pleaseentervalidformula');
                    pageError = true;
                }
                try {
                    var IsvalidFormulaAfter = "";
                    IsvalidFormulaAfter = eval(AfterEffortFormula);
                }
                catch (err) {
                    row["ErrorAfter"] = true;
                    row["MsgAfter"] = row["MsgAfter"] != "" && row["MsgAfter"] != null && row["MsgAfter"] != i18n.t('validValue') ? row["MsgAfter"] : i18n.t('pleaseentervalidformula');
                    pageError = true;
                }
            })

            this.refs.Grd_Productivity.gridOptions.api.setRowData(objGridDetails);

            this.setState({
                ProductivityGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.Grd_Productivity.gridOptions.api.refreshCells(params);

            return isErrorThere;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            editActive = true;
            if (params.colDef.headerName == i18n.t('beforeEffort') || params.colDef.headerName == i18n.t('afterEffort')) {
                this.checkCells();
                this.setState({
                    showBoiLiPopup: false,
                    showEffortPopup: false,
                    popUpDisplay: false
                });
                let ProductivityDetails = this.refs.Grd_Productivity.gridOptions.rowData;
                this.markSelected(ProductivityDetails);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SkipToPMMConfig() {
        if (confirm(i18n.t('skipWithoutSavingProductivity'))) {
            this.props.history.push("/PMMConfig");
        }
    }

    Clear() {
        try {
            this.setState({
                isEditAcitve: false
            });
            editActive = false;
            this.refs.Grd_Productivity.gridOptions.api.deselectAll();
            isDeSelectAll = true;
            let params = {
                force: true
            }
            this.refs.Grd_Productivity.gridOptions.api.refreshCells(params);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    checkSelected() {
        debugger;
        try {
            let count = this.refs.Grd_Productivity.gridOptions.api.getSelectedRows().length;
            if (this.state.selectedCount != 0 && this.state.selectedCount != count) {
                editActive = true;
            }
            this.setState({
                selectedCount: count,
                isEditAcitve: true,
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        let isHidden = (this.props.groupId
            == this.props.configValues.serviceComponent[0].GroupID) ? true : false;
        var columnDefs = [];
        let { t } = this.props;
        if (this.state.deleteStatus == false) {
            columnDefs = [
                { headerName: t('description'), field: 'ProductivityDescription', tooltipField: 'ProductivityDescription', suppressMovable: true, suppressResize: true, editable: isHidden },
                {
                    headerName: t('beforeEffort'), field: 'BeforeEffort', tooltipField: 'BeforeEffort', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellStyle: function (params) {
                        let background = params.data.ErrorBefore ? "#E57373" : "transparent"
                        return {
                            background: background
                        };
                    }, tooltipFieldMsg: 'MsgBefore'
                },
                {
                    headerName: t('afterEffort'), field: 'AfterEffort', tooltipField: 'AfterEffort', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellStyle: function (params) {
                        let background = params.data.ErrorAfter ? "#E57373" : "transparent"
                        return {
                            background: background
                        };
                    }, tooltipFieldMsg: 'MsgAfter'
                },
                { headerName: t('UniqueID'), field: 'UniqueProductivityId', tooltipField: '', autoHeight: true, suppressMovable: true, suppressResize: true },
                { headerName: t('selectAll'), headerCheckboxSelection: true, checkboxSelection: true, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, hide: !isHidden }

            ];
        }
        else if (this.state.deleteStatus == true) {
            columnDefs = [
                { headerName: t('description'), field: 'ProductivityDescription', tooltipField: 'ProductivityDescription', suppressMovable: true, suppressResize: true, editable: isHidden },
                {
                    headerName: t('beforeEffort'), field: 'BeforeEffort', tooltipField: 'BeforeEffort', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellStyle: function (params) {

                        let background = params.data.ErrorBefore ? "#E57373" : "transparent"
                        return {
                            background: background
                        };
                    }, tooltipFieldMsg: 'MsgBefore'
                },
                {
                    headerName: t('afterEffort'), field: 'AfterEffort', tooltipField: 'AfterEffort', autoHeight: true, suppressMovable: true, suppressResize: true, editable: isHidden, cellStyle: function (params) {

                        let background = params.data.ErrorAfter ? "#E57373" : "transparent"
                        return {
                            background: background
                        };
                    }, tooltipFieldMsg: 'MsgAfter'
                },
                { headerName: t('UniqueID'), field: 'UniqueProductivityId', tooltipField: '', autoHeight: true, suppressMovable: true, suppressResize: true },
                { headerName: t('selectAll'), headerCheckboxSelection: true, checkboxSelection: true, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, hide: !isHidden },
                { headerName: t('delete'), suppressMovable: true, suppressResize: true, cellRenderer: CreateDelete, width: 100 }
            ];
        }

        var ProductivityDataGrid = (
            <div style={{ height: '400px' }} className="ag-theme-material padding5 custom-select">
                <AgGridReact ref="Grd_Productivity" id="grd_Productivity"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.ProductivityGridData}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    suppressRowClickSelection={true}
                    onGridReady={this.gridReadyFun}
                    singleClickEdit={true}
                    onRowSelected={this.onRowSelect}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    onSelectionChanged={this.checkSelected.bind(this)}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        var boiLiColumnDefs = [];
        boiLiColumnDefs = [
            { headerName: t('UniqueID'), field: 'UniqueFormulaID', tooltipField: 'UniqueFormulaID', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: t('boiLIName'), field: 'BOILIName', tooltipField: 'BOILIName', suppressMovable: true, suppressResize: true, autoHeight: true }
        ];

        var BOILIDataGrid = (
            <div style={{ height: '200px' }} className="ag-theme-material padding5 col-md-6">
                <AgGridReact ref="Grd_BOILI" id="grd_BOILI"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={boiLiColumnDefs}
                    rowData={this.state.BOILIData}
                    headerHeight="48"
                    gridAutoHeight={true}
                    onGridReady={this.gridReadyFun}
                    rowmodeltype="pagination"
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        var effortColumnDefs = [];
        effortColumnDefs = [
            { headerName: t('uniqueEffortID'), field: 'UniqueEffortId', tooltipField: 'UniqueEffortId', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: t('effortDescription'), field: 'EffortDescription', tooltipField: 'EffortDescription', suppressMovable: true, suppressResize: true, autoHeight: true }
        ];

        var EffortDataGrid = (
            <div style={{ height: '200px' }} className="ag-theme-material padding5 col-md-6">
                <AgGridReact ref="Grd_Effort" id="grd_Effort"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={effortColumnDefs}
                    rowData={this.state.EffortData}
                    headerHeight="48"
                    gridAutoHeight={true}
                    onGridReady={this.gridReadyFun}
                    rowmodeltype="pagination"
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <ul className="pagecounter" >
                        <li><span title="BOI / KEY BUSINESS METRIC" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><a data-url="/BOIConfiguration" data-content="BOI Configuration" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="LEADING INDICATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="LEADING INDICATOR"><a data-url="/LIConfiguration" data-content="LI Configuration" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="EFFORT" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="EFFORT"><a data-url="/Effort" data-content="Effort" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="PRODUCTIVITY CALCULATOR" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PRODUCTIVITY CALCULATOR">...</span></li>
                        <li><span title="PMM CONFIG" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM CONFIG">4</span></li>
                    </ul>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            <li className="active">
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                        navigationPage={this.state.navigationPage} isEditAcitve={editActive}
                        url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                </div>
                <section className="bodydata multiple-tabs-section">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <div className="">
                                <h4>
                                    <span className="statustxt-orng-italic" title="Capability">{this.props.configValues.capability != [] ? this.props.configValues.capability[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Tower">{this.props.configValues.tower != [] ? this.props.configValues.tower[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Service Component">{this.props.configValues.serviceComponent != [] ? this.props.configValues.serviceComponent[0].label : []}</span>
                                </h4>
                            </div>
                            <div className="row">
                                <div className="col-md-6 nopadding">
                                    <h4 className="grid-title">
                                        {i18n.t('selectedBOILI')}
                                    </h4>
                                </div>
                                <div className="col-md-6 nopadding">
                                    <h4 className="grid-title">
                                        {i18n.t('effortDetails')}
                                    </h4>
                                </div>
                            </div>
                            <div className="row">
                                {BOILIDataGrid}
                                {EffortDataGrid}
                            </div>
                            <div className="row row-margin">
                                <h4 className="grid-title col-md-2">
                                    {i18n.t('productivityCalculator')}
                                </h4>
                                <button id="btn_ADD" type="button" className="btn btn-default pmmConfigAdd pull-right" onClick={this.onAddRow.bind(this)} disabled={!isHidden} >
                                    <img src={addButtonIcon} />{i18n.t('add')}
                                </button>
                            </div>
                            <div className="AsmtData viewprevassmt box-plain">
                                {ProductivityDataGrid}
                                {
                                    isHidden ?
                                        <div className="btn-group-md">
                                            <a type="button" title="Go to PMM Config screen without configuring productivity Calculations" className="btn btn-warning" onClick={this.SkipToPMMConfig.bind(this)} >{i18n.t('skipToPMMConfig')}</a>
                                            <a type="button" className="btn btn-danger" onClick={this.SaveProductivity.bind(this)} >{i18n.t('save')}</a>
                                            <a type="button" className="btn btn-info" onClick={this.Clear.bind(this)}>{i18n.t('clear')}</a>
                                            <a type="button" className="btn btn-success" onClick={this.SaveProductivity.bind(this)}>{i18n.t('configurePMM')}</a>
                                        </div>
                                        :
                                        <div className="btn-group-md">
                                            <a type="button" title="Go to PMM Config screen without configuring productivity Calculations" className="btn btn-warning" onClick={this.SkipToPMMConfig.bind(this)} >{i18n.t('skipToPMMConfig')}</a>
                                            <Link type="button" className="btn btn-danger" to="/Effort">{i18n.t('previous')}</Link>
                                            <Link type="button" className="btn btn-success" to="/PMMConfig">{i18n.t('next')}</Link>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state) {

    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName
    }
}
ProductivityCalculator = connect(mapStateToProps)(withTranslation()(ProductivityCalculator));
export default withRouter(ProductivityCalculator);