import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

var Selecteddata;

function createCircelText() {
    try {
        this.eGui = document.createElement('div');
        this.eGui.className = "row";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.init = function (params) {
    try {
        let circle = document.createElement('div')
        circle.className = "circleDiv";
        circle.title = i18n.t('plsClicktoEditCellValues');
        switch (Number(params.value)) {
            case 1:
                circle.style.backgroundColor = "#d01a1a"
                break
            case 2:
                circle.style.backgroundColor = "#cd7825"
                break
            case 3:
                circle.style.backgroundColor = "#cd7825"
                break
            case 4:
                circle.style.backgroundColor = "#80bc59"
                break
        }
        let spanValue = document.createElement('span');
        spanValue.className = "AsIsToBeLabel";
        params.className == "To Be PMM Score" && params.value == 0 && params.data.IsSelected == 1 ? spanValue.textContent = "4" : spanValue.textContent = params.value;

        let leverTypeSum = 0;
        let leverTypeCount = 0;
        let standardSum = 0;
        let standardCount = 0;
        let rwData = params.agGridReact.gridOptions.rowData;

        let leverAvg = (leverTypeSum / leverTypeCount).toFixed(2);
        let standardAvg = (standardSum / standardCount).toFixed(2);
        params.data.loggedInUserId =this.props.enterpriseId
        if (params.className == "As Is PMM Score") {

            if (params.data.LeverName.trim() == i18n.t('policy')) {

                document.getElementById("idPolicyAsIsAvg").textContent = leverAvg
            }
            else if (params.data.LeverName.trim() == i18n.t('practice')) {
                document.getElementById("idPracticeAsIsAvg").textContent = leverAvg
            }
            else {
                document.getElementById("idPlatformAsIsAvg").textContent = leverAvg;
            }
            document.getElementById("AsIsPMMScore").textContent = ((Number(document.getElementById("idPolicyAsIsAvg").innerHTML) + Number(document.getElementById("idPracticeAsIsAvg").innerHTML) + Number(document.getElementById("idPlatformAsIsAvg").innerHTML)) / 3).toFixed(2);
            document.getElementById("idStandardAsIsAvg").textContent = standardAvg;
        }
        else {
            if (params.data.LeverName.trim() == i18n.t('policy')) {

                document.getElementById("idPolicyToBeAvg").textContent = leverAvg
            }
            else if (params.data.LeverName.trim() == i18n.t('practice')) {
                document.getElementById("idPracticeToBeAvg").textContent = leverAvg
            }
            else {
                document.getElementById("idPlatformToBeAvg").textContent = leverAvg;
            }
            document.getElementById("ToBePMMScore").textContent = ((Number(document.getElementById("idPolicyToBeAvg").innerHTML) + Number(document.getElementById("idPracticeToBeAvg").innerHTML) + Number(document.getElementById("idPlatformToBeAvg").innerHTML)) / 3).toFixed(2);
            document.getElementById("idStandardToBeAvg").textContent = standardAvg;
        }
        this.eGui.appendChild(circle);
        this.eGui.appendChild(spanValue);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}
createCircelText.prototype.getGui = function (params) {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

function CreateDropdown() {
}

CreateDropdown.prototype.init = function (params) {
    try {
        var circleDiv = document.createElement("div");
        var dropDownElement = document.createElement("select");
        dropDownElement.id = params.rowIndex;
        circleDiv.id = "circle" + params.colDef.headerName + params.rowIndex;
        dropDownElement.className = "col-md-2";
        if (params.className == "As Is PMM Score" || params.className == "To Be PMM Score") {

            circleDiv.className = "col-md-1 circleDiv"
            let asIsValue = [1, 2, 3, 4, "NA"]
            if (params.data.Base == "" || params.data.Base == null) {
                asIsValue.splice(0, 1);
            }
            if (params.data.Enhanced == "" || params.data.Enhanced == null) {
                asIsValue.splice(1, 1);
            }
            if (params.data.AboveAverage == "" || params.data.AboveAverage == null) {
                asIsValue.splice(2, 1);
            }
            if (params.data.HighPerformance == "" || params.data.HighPerformance == null) {
                asIsValue.splice(3, 1);
            }
        }
        return { values: [1, 2, 3, 4, "NA"] }

    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateDropdown.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateDropdown.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class MaturityElementDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            PMMAssessmentMaturityElementData: [],
            AsIsPMMScoreOptions: [{ label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }, { label: "4", value: 4 }, { label: "NA", value: "NA" }],
            ToBePMMScoreOptions: [{ label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }, { label: "4", value: 4 }, { label: "NA", value: "NA" }],
            selectedAsIsPMMScore: { label: "0", value: 0 },
            selectedToBePMMScore: { label: "0", value: 0 },
            maturityElement: "",
            KPA: "",
            Lever: "",
            metricImpacted: "",
            standardizationElem: "",
            Selectedrow: [],
            remarks: "",
            PMMDraftID: 0,
            PMMConfigID: 0
        }
        this.closeMaturityElementPopUp = this.closeMaturityElementPopUp.bind(this);
        this.Nextdatadetails = this.Nextdatadetails.bind(this);
        this.Previousdatadetails = this.Previousdatadetails.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
    }

    componentDidMount() {
        try {
            window.addEventListener('resize', this.reSizeGrid, true);
            this.displayGrid();
            this.setState({ selectedAsIsPMMScore: { label: this.props.selectedMetric.AsIsPMMScore, value: this.props.selectedMetric.AsIsPMMScore } });
            this.setState({ selectedToBePMMScore: { label: this.props.selectedMetric.TobePMMScore, value: this.props.selectedMetric.TobePMMScore } });
            this.setState({
                maturityElement: this.props.selectedMetric.MaturityElement,
                KPA: this.props.selectedMetric.KPA,
                Lever: this.props.selectedMetric.LeverName,
                metricImpacted: this.props.selectedMetric.MetricImpacted,
                standardizationElem: this.props.selectedMetric.IsStandardization == true ? i18n.t('yes') : i18n.t('no'),
                remarks: this.props.selectedMetric.Remarks == null ? "" : this.props.selectedMetric.Remarks,
                PMMDraftID: this.props.selectedMetric.PMMDraftID,
                PMMConfigID: this.props.selectedMetric.PMMConfigID
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    reSizeGrid() {
        try {
            this.refs.grd_PMMAssessment.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    displayGrid() {
        try {
            const { show } = this.props;
            this.setState({
                show: this.props.show
            }, () => {
                this.MaturityElementPopUp(this.props.selectedMetric);
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    MaturityElementPopUp(val) {
        try {
            var gdrData = [];
            gdrData.push(val);
            this.setState({ PMMAssessmentMaturityElementData: gdrData });
            Selecteddata = this.props.AllpmmdataArray.findIndex(a => {
                return a == val;
            });

            this.setState({
                selectedAsIsPMMScore: { label: val.AsIsPMMScore, value: val.AsIsPMMScore },
                selectedToBePMMScore: { label: val.TobePMMScore, value: val.TobePMMScore },
                maturityElement: val.MaturityElement,
                KPA: val.KPA,
                Lever: val.LeverName,
                metricImpacted: val.MetricImpacted,
                standardizationElem: val.IsStandardization == true ? i18n.t('yes') : i18n.t('no'),
                remarks: val.Remarks == null ? "" : val.Remarks,
                PMMDraftID: val.PMMDraftID,
                PMMConfigID: val.PMMConfigID
            })
            let asIsValue = []

            if (gdrData[0].Base != "" && gdrData[0].Base != null && (gdrData[0].Base).toLowerCase() != "null") {
                asIsValue.push({ label: "1", value: 1 });

            }
            if (gdrData[0].Enhanced != "" && gdrData[0].Enhanced != null && (gdrData[0].Enhanced).toLowerCase() != "null") {

                asIsValue.push({ label: "2", value: 2 });
            }
            if (gdrData[0].AboveAverage != "" && gdrData[0].AboveAverage != null && (gdrData[0].AboveAverage).toLowerCase() != "null") {

                asIsValue.push({ label: "3", value: 3 });
            }
            if (gdrData[0].HighPerformance != "" && gdrData[0].HighPerformance != null && (gdrData[0].HighPerformance).toLowerCase() != "null") {

                asIsValue.push({ label: "4", value: 4 });
            }
            asIsValue.push({ label: "NA", value: "NA" }) //for AsIs and ToBe value dropdown to show NA
            this.setState({ AsIsPMMScoreOptions: asIsValue });
            this.setState({ ToBePMMScoreOptions: asIsValue });
            window.addEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    closeMaturityElementPopUp(eve) {
        try {
            if(typeof(this.state.selectedAsIsPMMScore.value) !== typeof(this.state.selectedToBePMMScore.value)){
                alert(i18n.t("ifOneScoreisNAThenOtherShouldBeNA"));
            }else{
                this.setState({
                    show: false
                });
                this.props.closeCallback();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleGridMaturityValueChange(params) {
        try {
            const { onMaturityValueChange } = this.props;
            onMaturityValueChange(params);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChangeAsIsPMMScore = selectedAsIsPMMScore => {
        try {
            if (selectedAsIsPMMScore.value > this.state.selectedToBePMMScore.value) {
                alert(i18n.t('ToBePMMscoreshouldbegreaterthan'));
                return false;
            }
            this.setState({ selectedAsIsPMMScore },()=> {
               //If as is score is set as NA, then automatically set to be score as NA
                if(selectedAsIsPMMScore.value === 'NA'){
                    this.setState({
                        selectedToBePMMScore:{
                            "label": "NA",
                            "value": "NA"
                        }
                    },() => {
                        this.props.onScoreChange(this.state.selectedAsIsPMMScore.value, selectedAsIsPMMScore.value, this.state.remarks, this.state.PMMDraftID, this.state.PMMConfigID);
                    })
                }
            });
         
            this.props.onScoreChange(selectedAsIsPMMScore.value, this.state.selectedToBePMMScore.value, this.state.remarks, this.state.PMMDraftID, this.state.PMMConfigID);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChangeToBePMMScore = selectedToBePMMScore => {
        try {
            if (selectedToBePMMScore.value < this.state.selectedAsIsPMMScore.value) {
                alert(i18n.t("ToBePMMscoreshouldbegreaterthan"));
                return false;
            }
            this.setState({ selectedToBePMMScore },
                //If 'NA' value is set in To be score , then As is score should be automatically set to 'NA'
                ()=> {
                    if(selectedToBePMMScore.value === 'NA'){
                        this.setState({
                            selectedAsIsPMMScore:{
                                "label": "NA",
                                "value": "NA"
                            }
                        },() => {
                            this.props.onScoreChange(this.state.selectedAsIsPMMScore.value, selectedToBePMMScore.value, this.state.remarks, this.state.PMMDraftID, this.state.PMMConfigID);
                        })
                    }
                }
            )
          
            this.props.onScoreChange(this.state.selectedAsIsPMMScore.value, selectedToBePMMScore.value, this.state.remarks, this.state.PMMDraftID, this.state.PMMConfigID);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onRemarksChange = remark => {
        try {
            this.setState({ remarks: remark.currentTarget.value })
            this.props.onScoreChange(this.state.selectedAsIsPMMScore.value, this.state.selectedToBePMMScore.value, remark.currentTarget.value, this.state.PMMDraftID, this.state.PMMConfigID);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    Nextdatadetails() {
        try {
            let i = Selecteddata;
            if(typeof(this.state.selectedAsIsPMMScore.value) !== typeof(this.state.selectedToBePMMScore.value)){
                alert(i18n.t("ifOneScoreisNAThenOtherShouldBeNA"));
                return false
            }
            else if (Selecteddata == this.props.AllpmmdataArray.length - 1) {
                alert(i18n.t("Youalreadythelastrecord"));
                return false;
            }
            else {
                if (i < this.props.AllpmmdataArray.length) {
                    this.MaturityElementPopUp(this.props.AllpmmdataArray[++i]);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    Previousdatadetails() {
        try {
            let i = Selecteddata;
            if(typeof(this.state.selectedAsIsPMMScore.value) !== typeof(this.state.selectedToBePMMScore.value)){
                alert(i18n.t("ifOneScoreisNAThenOtherShouldBeNA"));
                return false
            }
            else if (Selecteddata == 0) {
                alert(i18n.t("Youarealreadyinrecord"));
                return false;
            }
            else {
                if (i < this.props.AllpmmdataArray.length) {
                    this.MaturityElementPopUp(this.props.AllpmmdataArray[--i]);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [

            { headerName: i18n.t('BaseScore1'), field: 'Base', suppressMovable: true, suppressResize: true, tooltipField: 'Base', autoHeight: true },
            { headerName: i18n.t('EnhancedScore2'), field: 'Enhanced', suppressMovable: true, suppressResize: true, tooltipField: 'Enhanced', autoHeight: true },
            { headerName: i18n.t('AboveAverageScore3'), field: 'AboveAverage', suppressMovable: true, suppressResize: true, tooltipField: 'AboveAverage', autoHeight: true },
            { headerName: i18n.t('HighPerformanceScore4'), field: 'HighPerformance', suppressMovable: true, suppressResize: true, tooltipField: 'HighPerformance', autoHeight: true },
            { headerName: i18n.t('CurrentMetricValue'), field: 'CurrentMetricValue', suppressMovable: true, suppressResize: true, tooltipField: 'CurrentMetricValue', autoHeight: true, width: 80, hide: true },

        ];
        var PMMAssessmentData = this.state.PMMAssessmentMaturityElementData;

        var PMMAssessmentDtaGrd = (
            <div style={{ height: '300px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_PMMAssessment" id="grd_PMMAssessment"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={PMMAssessmentData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.onGridReady.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )


        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closeMaturityElementPopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5>
                        </h5>
                        <div className="row" style={{ paddingTop: '10px' }}>
                            <div className="col-md-7">
                                <div className="col-md-3"><b>{i18n.t('maturityElement')} -</b></div>
                                <div className="col-md-9"><b><span class="infoHeaderContent">{this.state.maturityElement}</span></b></div>

                            </div>
                            <div className="col-md-5">
                                <div className="col-md-5"><b>{i18n.t('StandardizationElement')} - </b></div>
                                <div className="col-md-7"><b><span class="infoHeaderContent">{this.state.standardizationElem}</span></b></div>

                            </div>
                            <div className="col-md-7">
                                <div className="col-md-3"><b>{i18n.t('lever')} - </b></div>
                                <div className="col-md-9"><b><span class="infoHeaderContent">{this.state.Lever}</span></b></div>

                            </div>
                            <div className="col-md-5">
                                <div className="col-md-5"><b>{i18n.t('KPA')} - </b></div>
                                <div className="col-md-7"><b><span class="infoHeaderContent">{this.state.KPA}</span></b></div>

                            </div>
                            <div className="col-md-7">
                                <div className="col-md-3"><b>{i18n.t('metricImpacted')} - </b></div>
                                <div className="col-md-9"><b><span class="infoHeaderContent">{this.state.metricImpacted}</span></b></div>

                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="box-plain pmm-asmt-tbl BOI-div">
                            {PMMAssessmentDtaGrd}
                        </div>
                        <br />
                        <div className="form-horizontal">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" id="lbl_asIsPMMScore" >{i18n.t('asIsPMMScore')}<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-6">
                                            <Select clearable={false}
                                                required
                                                value={this.state.selectedAsIsPMMScore.value === -1 ? 'NA' : this.state.selectedAsIsPMMScore}
                                                onChange={this.onChangeAsIsPMMScore.bind(this)}
                                                options={this.state.AsIsPMMScoreOptions}
                                                className="form-control-select form-field-name" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" id="lbl_toBePMMScore" >{i18n.t('toBePMMScore')}<span className="mandatory-cls">*</span> </label>
                                        <div className="col-sm-6">
                                            <Select clearable={false}
                                                required
                                                value={this.state.selectedToBePMMScore.value === -1 ? 'NA' : this.state.selectedToBePMMScore}
                                                onChange={this.onChangeToBePMMScore.bind(this)}
                                                options={this.state.ToBePMMScoreOptions}
                                                className="form-control-select form-field-name"

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" id="lbl_remarks" >{i18n.t('Remarks')}
                                    </label>
                                        <div className="control-label col-sm-7">
                                            <textarea ref="remarks" id="txtArea_remarksMatElement" name="remarks" data-field="remarks" rows="3" className="form-control" placeholder="" type="text" value={this.state.remarks} onChange={this.onRemarksChange.bind(this)} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_close" className="btn btn-info common-btn pull-left" value={i18n.t("close")} onClick={this.closeMaturityElementPopUp} />
                        <input type="button" id="btn_next" className="btn btn-success common-btn pull-right" value={i18n.t("next")} onClick={this.Nextdatadetails} />
                        <input type="button" id="btn_previous" className="btn btn-success common-btn pull-right" value={i18n.t("previous")} onClick={this.Previousdatadetails} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        enterpriseId:state.enterpriseId,
       }
}

MaturityElementDetail = connect(mapStateToProps)(withRouter(MaturityElementDetail))
export default MaturityElementDetail