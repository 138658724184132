/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from '../../services/Service';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

class StoryBoardDetail extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show, headerName: "", innerContent: "",
                PMMAssessmentGrdDta: [],
                dynamicLeverData: []
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.StoryBoardMetricDetails = this.StoryBoardMetricDetails.bind(this);
            this.dynamicLeverDataCalculation = this.dynamicLeverDataCalculation.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    dynamicLeverDataCalculation = (response) => {
        try {
            var objThis = this;
            var leverWithValue = [];
            let labelsLever = [];
            var sumForOverAllAsIs = 0;
            var sumForOverAllToBe = 0;
            var PMMAssessmentGrdDta = response;
            var distinctLevers = [...new Set(PMMAssessmentGrdDta.map(x => x.LeverName))]

            for (var i = 0; i < distinctLevers.length; i++) {

                var data = PMMAssessmentGrdDta.filter(x => x.LeverName == distinctLevers[i])
                var asisSum = 0;
                var tobeSum = 0;
                var asisCount = 0;
                var tobeCount = 0;

                data.map((eachdata, index) => {
                    asisSum += Number(eachdata.AsIsPMMScore), asisCount += 1;
                    tobeSum += Number(eachdata.TobePMMScore), tobeCount += 1;
                    let labelArr;
                    eachdata.id = `M${index + 1}`;
                    labelArr = eachdata.MaturityElement.substr(0, 24) + "...";

                    labelsLever.push(labelArr);

                })

                this.setState({ PMMAssessmentGrdDta: data });


                leverWithValue.push(
                    {
                        'LeverName': distinctLevers[i],
                        'AsIsSum': asisSum,
                        'ToBeSum': tobeSum,
                        'AsIsAvg': (asisSum / asisCount).toFixed(2),
                        'ToBeAvg': (tobeSum / tobeCount).toFixed(2)
                    }
                )
                var asisAvg = (asisSum / asisCount).toFixed(2);
                var tobeAvg = (tobeSum / tobeCount).toFixed(2);
                sumForOverAllAsIs = parseFloat(sumForOverAllAsIs) + parseFloat(asisAvg)
                sumForOverAllToBe = parseFloat(sumForOverAllToBe) + parseFloat(tobeAvg)

            }

            objThis.setState({ dynamicLeverData: leverWithValue })


        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            $("#loader-div").show();
            this.setState({
                show: this.props.show
            });

            var PMMProcessID = this.props.PMMProcessID;
            var PMMTransactionID = this.props.PMMTransactionID == undefined ? 0 : this.props.PMMTransactionID;
            var PMMDraftID = this.props.PMMDraftID == undefined ? 0 : this.props.PMMDraftID;
            var PMMProcessDraftID = this.props.PMMProcessDraftID;

            var SearchedString = "";
            let PMMDetailsData = {
                PMMProcessID:PMMProcessID,
                PMMTransactionID:PMMTransactionID,
                PMMProcessDraftID:PMMProcessDraftID,
                PMMDraftID:PMMDraftID,
                LeverID:0,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            request("/PMM/ViewPMMDetailsByMaturityElement", 'POST', PMMDetailsData)
                .then(response => {
                    this.dynamicLeverDataCalculation(response);

                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp(eve) {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    StoryBoardMetricDetails() {
        try {
            $("#loader-div").show();
            var CapabilityID = this.state.CapabilityValue != "" ? this.state.CapabilityValue.value : 0;
            var TowerId = this.state.TowerValue != "" ? this.state.TowerValue.value : 0;
            let scData = {
                "CapabilityId": CapabilityID,
                "TowerID": TowerId,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/Common/GetActiveServiceComponentDetails", 'POST', scData)
                .then(response => {
                    this.setState({ ServiceComponentGridData: response });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('lever'), field: 'LeverName', suppressMovable: true, suppressResize: true, tooltipField: 'LeverName', autoHeight: true, width: 80 },
            { headerName: i18n.t('kpa'), field: 'KPA', suppressMovable: true, suppressResize: true, tooltipField: 'KPA', autoHeight: true, width: 100, hide: false },
            { headerName: i18n.t('metricImpacted'), field: 'MetricImpacted', suppressMovable: true, suppressResize: true, tooltipField: 'MetricName', autoHeight: true, width: 150 },
            { headerName: i18n.t('maturityElement'), field: 'MaturityElement', suppressMovable: true, suppressResize: true, tooltipField: 'MaturityElement', autoHeight: true },
            { headerName: i18n.t('BaseScore1'), field: 'Base', suppressMovable: true, suppressResize: true, tooltipField: 'Base', autoHeight: true },
            { headerName: i18n.t('EnhancedScore2'), field: 'Enhanced', suppressMovable: true, suppressResize: true, tooltipField: 'Enhanced', autoHeight: true },
            { headerName: i18n.t('AboveAverageScore3'), field: 'AboveAverage', suppressMovable: true, suppressResize: true, tooltipField: 'AboveAverage', autoHeight: true },
            { headerName: i18n.t('HighPerformanceScore4'), field: 'HighPerformance', suppressMovable: true, suppressResize: true, tooltipField: 'HighPerformance', autoHeight: true },
            { headerName: i18n.t('asIsPMMScore'), field: 'AsIsPMMScore', suppressMovable: true, suppressResize: true, tooltipField: 'AsIsPMMScore', autoHeight: true, width: 100 },
            { headerName: i18n.t('toBePMMScore'), field: 'TobePMMScore', suppressMovable: true, suppressResize: true, tooltipField: 'TobePMMScore', autoHeight: true, width: 100 },
            { headerName: i18n.t('Remarks'), field: 'Remarks', tooltipField: 'Remarks', suppressMovable: true, suppressResize: true, autoHeight: true }
        ];
        var PMMAssessmentData = this.state.PMMAssessmentGrdDta;

        var PMMAssessmentDtaGrd = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_PMMAssessment" id="PMMAssessment_grd"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={PMMAssessmentData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('StoryBoardGAPAssessmentMetricDetail')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="box-plain pmm-asmt-tbl BOI-div">
                            {PMMAssessmentDtaGrd}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input id="txt_close" type="button" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
      }
}

StoryBoardDetail = connect(mapStateToProps)(withRouter(StoryBoardDetail))
export default StoryBoardDetail