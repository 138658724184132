/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import { BaseURL, UIURL } from '../../Constant';
import BOIView from '../BOI/BOIView';
import $ from 'jquery';
import viewIcon from '../../images/icon-view.png';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

class ViewPreviousAssessmentGrid extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                prevAssessmentGridData: []
            };
            this.reSizeGrid = this.reSizeGrid.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CreateEditDetails() {
        try {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = `<span><img src=${viewIcon}></span>`;
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            window.addEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_ViewAssess.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun=(eve)=> {

        try {
            let objThis=this
            $("#loader-div").hide();
            eve.api.showLoadingOverlay();
            if(objThis.props.propsprevAssessmentGridData.length!==0){
                eve.api.hideOverlay();
                $("#loader-div").hide();
            }
            else{
                eve.api.showNoRowsOverlay();
            }
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t("view")) {
                var data = e.data;
                this.props.propsSelectedAssessmentDetails(data);
                this.props.history.push("/BOIView");
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        let {t} = this.props;
        var columnDefs = [];
        let isHidden = (this.props.groupId == "1")? true:false;
        columnDefs = [
            { headerName: i18n.t('view'), cellRenderer: this.CreateEditDetails.bind(this), suppressMovable: true, suppressResize: true },
            { headerName:i18n.t('pMMProcessID'), field: 'PMMProcessID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('pMMTransactionID'), field: 'PMMTransactionID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('deal_Name'), field: 'DealName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('clientBusinessRegion'), field: 'RegionName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('accentureDeliveryLocation'), field: 'DeliveryLocationName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('capability'), field: 'CapabilityName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('tower'), field: 'TowerName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('serviceComponent'), field: 'SCName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('oMID'), field: 'OMIDValue', suppressMovable: true, suppressResize: true, hide:!isHidden },
            { headerName: i18n.t('mMSIDs'), field: 'MMSID', suppressMovable: true, suppressResize: true, hide: isHidden },
            { headerName: i18n.t('FTEs'), field: 'FTECount', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('asIsPMMScore'), field: 'AsIsPMMScore', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('targetPMMScore'), field: 'TobePMMScore', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('submittedBy'), field: 'CreatedBy', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('submittedDate'), field: 'CreatedDate', suppressMovable: true, suppressResize: true }
        ];

        var prevAssessmentGridData = this.props.propsprevAssessmentGridData;

        var PrevAssessmentDataGrid = (
            <div style={{ height: '380px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_ViewAssess" id="grd_ViewAssess" enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={prevAssessmentGridData}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <div>
                {PrevAssessmentDataGrid}
            </div>
        );
    }
};
function mapStateToProps(state){
    return{
        groupId:state.groupId, 
    }
}
ViewPreviousAssessmentGrid = connect(mapStateToProps)(withRouter(ViewPreviousAssessmentGrid))
export default ViewPreviousAssessmentGrid;