import React, { PropTypes } from 'react';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ModalPopup from './ModalPopup';
import { APP_LOGOUTURL } from './Constant';
import { BaseURL } from './Constant';
import Support from './Support';
import 'bootstrap/dist/js/bootstrap.js';
import VersionChangePopup from './VersionChangePopup';
import AboutPMM from './AboutPMM';
import AccessTransferPopup from './UserManagement/DealMapping/AccessTransferPopup';
import ActiveServiceComponent from './ActiveServiceComponent';
import $ from 'jquery';
import iconSettings from './images/icon-settings.png'
import pmmInner from './images/icn_pmm_inner.png';
import pmmLogo from './images/pmm-logo-home1.png';
import emailIcon from './images/icon-email.ico';
import scIcon from './images/icon-sc.ico';
import userTransferIcon from './images/change-user.ico';
import metricsIcon from './images/icon-metrics.png';
import processIcon from './images/icon-process.png';
import gapIcon from './images/icon-gap-asmt.png';
import ppIcon from './images/icon-ppg.png';
import infoIcon from './images/icon-info.ico';
import boxTickIcon from './images/icon-box-tick.png';
import viewSavedIcon from './images/icon-folder-outline.png';
import userMenuArrowIcon from './images/user-menu-arrow.png';
import PMMUserMgtIconHover from './images/PMM-UserMgt-iconHover.png';
import request from './services/Service';
import i18n from 'i18next';
// import i18n from './i18n.js';
import langKeys from './Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';

class Home extends React.Component {
    componentWillMount() {
        try {
            this.state = {
                popUpDisplay: false, popUpAbout: false, modalOpen: false, NotificationMsg: "",
                pageNames: [], popUpSC: false, popUpAT: false, popUpVersion: false, isAccessTranfer: false,
                groupSwitch: "", languageSwitch: "", roleAssosciatedUserData: [], showSwitchAccountsBtn: false, activeData: [],
                langOptions: [
                    {
                        label: "English",
                        value: 1,
                        languagekey: "en"
                    }, {
                        label: "Japanese",
                        value: 2,
                        languagekey: "ja"
                    }
                ],
                selectedLang: {
                    label: "English",
                    value: 1,
                    languagekey: "en"
                }
            };
            this.onSupportClick = this.onSupportClick.bind(this);
            this.onAboutClick = this.onAboutClick.bind(this);
            this.onSCClick = this.onSCClick.bind(this);
            this.closeSCPopUp = this.closeSCPopUp.bind(this);
            this.closeSupportPopUp = this.closeSupportPopUp.bind(this);
            this.closeAboutPopUp = this.closeAboutPopUp.bind(this);
            this.GetRoleByLoggedinUser = this.GetRoleByLoggedinUser.bind(this);
            this.handleLogout = this.handleLogout.bind(this);
            this.getCookie = this.getCookie.bind(this);
            this.onATClick = this.onATClick.bind(this);
            this.switchGroupPopup = this.switchGroupPopup.bind(this);
            this.switchLanguagePopup = this.switchLanguagePopup.bind(this);
            this.langChange = this.langChange.bind(this);
            this.loadLanguages = this.loadLanguages.bind(this);
            this.saveLangChange = this.saveLangChange.bind(this);
            this.onMasterClick = this.onMasterClick.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {

        try {
            this.ValidateGDPR();
            this.InsertUserLogin();
            this.GetRoleByLoggedinUser();
            this.loadLanguages();
            let applicationVersionData = {
                EnterpriseId:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId
            }
            request("/MasterScreens/GetApplicationVersion", 'POST', applicationVersionData) 
                .then(res => {
                    var newVersion = res[0].Version;
                    if (newVersion != "") {
                        var arrCookie = document.cookie.split(";");
                        if (arrCookie.length > 0) {
                            var oldVersion = this.getCookie('version');
                            if (oldVersion == "") {
                                document.cookie = "version=" + String(newVersion);
                                this.setState({ popUpVersion: false });
                                return false;
                            }
                            if (newVersion != oldVersion) {

                                this.setState({ popUpVersion: true });
                            }
                            else {
                                this.setState({ popUpVersion: false });
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    getCookie(cname) {
        try {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onMasterClick(){
        //window.tfo_event("PMM-MC-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
    }

    onSupportClick(eve) {
        try {
            this.setState({
                popUpDisplay: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onAboutClick(eve) {
        try {
            this.setState({
                popUpAbout: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSCClick(eve) {
        try {
            this.setState({
                popUpSC: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onATClick(eve) {
        try {
            //window.tfo_event("PMM-Home-4", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            this.setState({
                popUpAT: true,
                isAccessTranfer: true  //Check for Access Transfer popup open
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeAccessTransferPopUp = (value) => {
        if (value >= 1) {
            this.setState({
                arrval: true
            });
        }

        try {
            this.setState({
                popUpAT: false,
                isAccessTranfer: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeSupportPopUp() {

        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeAboutPopUp() {

        try {
            this.setState({
                popUpAbout: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeSCPopUp() {

        try {
            this.setState({
                popUpSC: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async GetRoleByLoggedinUser() {
        try {

            var obj = this;
                let userData = {
                "LanguageID": this.props.languageId
            }
            await request("/Common/GetRoleAssociatedWithUser", 'POST', userData)
                .then(res => {
                    if (res != null && res.length > 0) {

                        let activeData = [];
                        if (res.length > 0) {
                            res.map(obj => {
                                if (obj.IsActive == true) {
                                    activeData.push(obj);
                                }
                            })
                        }
                        this.setState({ activeData });
                        activeData.forEach((obj) => {
                            if (obj.GroupID == this.props.groupId) {
                                return(this.setState({ RoleName: obj.RoleName, pageNames: obj.ScreenName.split(','), roleAssosciatedUserData: res }));
                            }
                        })
                        if (activeData.length > 1) {
                            this.setState({ showSwitchAccountsBtn: true })
                        }
                    }

                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    InsertUserLogin() {
        try {
            const gdprData1 = {
                UserName: this.props.enterpriseId,
            };
            const response = fetch(BaseURL+ "/Common/InsertUserLogin", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(gdprData1),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
    
            const data = response.json();
            console.log("User login successful:", data);
        } catch (error) {
            console.error("Error encountered while inserting user login:", error);
        }
    }

    async ValidateGDPR() {

        try {
            var obj = this;
            let gdprData = {
                "UserName": this.props.enterpriseId,
                "NotificationType": "One Time PD Consent",
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            await request("/Common/GetGDPRNotification", 'POST', gdprData)
                .then(response => {
                    if (response[0].notificationMsg != "") {
                        obj.setState({ NotificationMsg: response[0].notificationMsg });
                        obj.setState({ modalOpen: true });
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    _openModal() {

        try {
            this.setState({ modalOpen: true });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async _closeModal() {
        try {
            var obj = this;
            var GDPR = {
                UserName:this.props.enterpriseId,
                Name: "One Time PD Consent"
            };
            await request("/Common/AcceptGDPRNotification", 'POST', GDPR)
                .then(res => {
                    if (res.Outputcode == 1) {
                        alert("Save Successfully");
                        obj.setState({ modalOpen: false });
                    }
                    else {
                        alert("Error in Save");
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

   async handleLogout() {

        try {
            //await window.tfo_event("PMM-Home-7", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var PMMProcessDraftID = sessionStorage.getItem("PMMProcessDraftID");
            var data = {
                LoggedInUserId:this.props.enterpriseId,
                PMMProcessDraftID: PMMProcessDraftID,
                FlagId: 2
            };
            if (PMMProcessDraftID != undefined && PMMProcessDraftID != null) {
                request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                .then(res => {

                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
           await request("/Common/UpdateIsActiveToken" , 'POST')
            .then(res => {

                localStorage.removeItem('UserName');
                localStorage.removeItem('PMMProcessDraftID');
                localStorage.removeItem('access_token_api');
                localStorage.removeItem('access_token');
                localStorage.removeItem("adal.idtoken");
                localStorage.clear();
                window.location = APP_LOGOUTURL;
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }



    switchGroupPopup = (groupId) => {

        this.props.closeGroupSwitchPopup(groupId);

    }
    switchLanguagePopup = (languageId) => {

        this.props.closeGroupSwitchPopup(languageId);

    }

    loadLanguages() {

        $("#loader-div").css("display", "block")
        let th = this;
              var userData = {
            "UserName": this.props.enterpriseId,
            "GroupID": this.props.groupId,
            "LanguageID": this.props.languageId
        };
        request("/Common/GetUserPreferredLanguage", 'POST', userData)
            .then(res => {
                res = JSON.parse(res);
                let langArr = res.Languages ? res.Languages : [];
                let setLang = this.props.i18n.language;
                setLang = res.GetUserPreferredLanguage.length && res.GetUserPreferredLanguage[0].LanguageKey ? res.GetUserPreferredLanguage[0].LanguageKey : setLang;
                let setLangObj = [];
                setLangObj = langArr.filter(lang => lang.languagekey == setLang);
                if (setLangObj.length) {
                    this.setState({
                        selectedLang: setLangObj[0],
                        langOptions: langArr
                    })
                    sessionStorage["LanguageID"] = setLangObj[0].value;
                    th.props.i18n.changeLanguage(setLangObj[0].languagekey)

                    if (setLangObj[0].value == 2) {
                        $("body").addClass("langchange");

                    }
                    else {
                        $("body").removeClass("langchange");

                    }
                } else {
                    this.setState({
                        langOptions: langArr
                    })
                }
                $("#loader-div").css("display", "none")
            })
            .catch(error => {
                $("#loader-div").css("display", "none")
                alert(" ");
            })
    }

    langChange(e) {
        if (e == null || e == '') {
            return false
        }
        this.saveLangChange(e.value);
    }

    async saveLangChange(langID) {
        $("#loader-div").css("display", "block")
        let changeMsg = this.props.t('langChangeMsg');
        changeMsg = langID == 1 ? "Language changed successfully!" : "言語が正常に変更されました!"
        // if(langID ==1 ){
        //     await window.tfo_event("PMM-Home-5", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
        // }
        // if(langID ==2 ){
        //     await window.tfo_event("PMM-Home-6", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
        // }
        request("/Common/SaveUserPreferredLanguage?UserName=" + this.props.enterpriseId + "&GroupID=" + this.props.groupId + "&LanguageID=" + langID, 'POST')
        
        .then(res => {
                alert(changeMsg);
                $("#loader-div").hide();
                window.location.reload(true);
                sessionStorage["LanguageID"] = langID;
            })
            .catch(err => {
                $("#loader-div").css("display", "none")
                alert(this.props.t('langChangeFailure'));
            })
    }
    render() {
        let { t } = this.props;
        const { modalOpen } = this.state;
        return (
            <div className="pagecontainer home">
                <header>
                    <div className="pmm-logo">
                        <a href="index.html">
                            <img src={pmmInner} />
                            <img src={pmmLogo} />
                        </a>
                    </div>
                    <div className="pull-right contactHome"><img title={i18n.t('support')} className="contactHomeImg" src={emailIcon} onClick={this.onSupportClick} /></div>
                    <div className="pull-right contactHome"><img title={i18n.t('info')} className="contactHomeImg" src={infoIcon} onClick={this.onAboutClick} /></div>
                    <div className="pull-right contactHome"><img title={i18n.t('activeServiceComponents')} className="contactHomeImg" src={scIcon} onClick={this.onSCClick} /></div>
                    {this.state.pageNames.includes("UserAccessTransfer") ? <div className="pull-right contactHome"><img title={i18n.t('accessTransfer')} className="contactHomeImg" src={userTransferIcon} onClick={this.onATClick} /></div> : null}
                    <ul className="hd-menu">
                        <li className="user dropdown">
                            <a href="#" data-toggle="dropdown">
                                {/* <span>{localStorage.getItem("UserName")} */}
                                <span>{this.props.enterpriseId}
                                    <b>
                                        {/* {this.state.activeData.length > 1 ?
                                            localStorage.getItem("RoleName") + " - " + localStorage.getItem("GroupName")
                                            :
                                            localStorage.getItem("RoleName")
                                        } */}
                                         {this.state.activeData.length > 1 ?
                                            this.props.roleName + " - " + this.props.groupName
                                            :
                                            this.props.roleName
                                        }
                                    </b>
                                </span>
                                <img src={userMenuArrowIcon} />
                            </a>
                            <ul className="dropdown-menu">
                                {this.state.showSwitchAccountsBtn ?
                                    <li className="user dropdown" id="visible">
                                        <a className="mousePointerSwitchAccounts">{i18n.t('switchAccounts')}

                                        </a>

                                        <ul className="" id="hidden">
                                            {this.state.activeData.map((obj) => {
                                                return <li><a className="groupName" onClick={(groupId) => this.switchGroupPopup(obj.GroupID)}><img style={{ height: '19px' }} src={PMMUserMgtIconHover} />  {obj.GroupName}</a></li>
                                            })}
                                        </ul>
                                    </li>
                                    :
                                    null
                                }
                                <li className="user dropdown" id="visible">
                                    <a className="mousePointerSwitchLanguage">{i18n.t('switchLanguage')}

                                    </a>

                                    <ul className="" id="hidden">
                                        {this.state.langOptions.map((obj) => {
                                            return <li><a className="languageName" onClick={(languageId) => this.langChange(obj)}><img style={{ height: '19px' }} />  {obj.label} </a></li>
                                        })}
                                    </ul>
                                </li>

                                <li><a className="mousePointer" onClick={this.handleLogout.bind(this)}>{i18n.t('logout')}</a></li>
                            </ul>
                        </li>
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li className="settings dropdown">
                                <a href="#" data-toggle="dropdown"><img src={iconSettings} /></a>
                                <ul className="dropdown-menu">
                                    <li><Link to="/PMMConfiguration" onClick={this.onMasterClick}>{i18n.t('masterConfig')}</Link></li>
                                </ul>
                            </li> : null}
                    </ul>
                </header>
                <section className="content">
                    <h1>{i18n.t('prosMaturityMeasurementTool')}</h1>
                    <h5>{i18n.t('measureAserviceComponent')}</h5>

                    {/* {localStorage.getItem("LanguageID") == 1 ? */}
                    {this.props.languageId == 1 ?
                        <div className="btns-assesment">
                            {this.state.pageNames.includes("CreateNewAssessment") ? <Link to="/CreateNewAssessment" className="btn btn-primary">
                                <img src={boxTickIcon} />
                                <span>
                                    <b>{i18n.t('takePMMAssessment')}</b>
                                    <span>{i18n.t('plsClickHereToTakeNeworSavedAssessment')}</span>
                                </span>
                            </Link> : null}
                            {this.state.pageNames.includes("ViewPreviousAssessmentHome") ? <Link to="/ViewPreviousAssessmentHome" className="btn btn-danger">
                                <img src={viewSavedIcon} />
                                <span>
                                    <b>{i18n.t('viewSaveSubmittedAssessment')}</b>
                                    <span>{i18n.t('plsClickHereToViewSaveAndPrevSubAssessmentment')}</span>
                                </span>
                            </Link> : null}
                        </div>
                        :
                        <div className="btns-assesment">
                            {this.state.pageNames.includes("CreateNewAssessment") ? <Link to="/CreateNewAssessment" className="btn btn-primaryja">
                                <img src={boxTickIcon} />
                                <span>
                                    <b>{i18n.t('takePMMAssessment')}</b>
                                    <span>{i18n.t('plsClickHereToTakeNeworSavedAssessment')}</span>
                                </span>
                            </Link> : null}
                            {this.state.pageNames.includes("ViewPreviousAssessmentHome") ? <Link to="/ViewPreviousAssessmentHome" className="btn btn-dangerja">
                                <img src={viewSavedIcon} />
                                <span>
                                    <b>{i18n.t('viewSaveSubmittedAssessment')}</b>
                                    <span>{i18n.t('plsClickHereToViewSaveAndPrevSubAssessmentment')}</span>
                                </span>
                            </Link> : null}
                        </div>
                    }

                    <div className="panelset">
                        <div className="box">
                            <div className="box-in tile1">
                                <img src={metricsIcon} />
                                <h3>{i18n.t('boiKeyBusinessMetricLdIndi')}</h3>
                                <p>{i18n.t('boiBusinessMetricLeaddIndiList')}</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-in tile2">
                                <img src={processIcon} />
                                <h3>{i18n.t('processMaturityModel')}</h3>
                                <p>{i18n.t('processMatrityModeList')}</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-in tile3">
                                <img src={gapIcon} />
                                <h3>{i18n.t('gAPASSESSMENT')}</h3>
                                <p>{i18n.t('itShowsthegapToHighPerform')}</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-in tile4">
                                <img src={ppIcon} />
                                <h3>{i18n.t('pROJECTPRIORITIZATIONGRID')}</h3>
                                <p>{i18n.t('prioritizationOnInitiativesIs')}
                                </p>
                            </div>
                        </div>

                        {this.state.popUpDisplay && <Support show={this.state.popUpDisplay} closeCallback={this.closeSupportPopUp} />}
                        {this.state.popUpAbout && <AboutPMM show={this.state.popUpAbout} closeCallback={this.closeAboutPopUp} />}
                        {this.state.popUpVersion && <VersionChangePopup show={this.state.popUpVersion} />}
                        {this.state.popUpSC && <ActiveServiceComponent show={this.state.popUpSC} closeCallback={this.closeSCPopUp} />}
                        {this.state.popUpAT && <AccessTransferPopup base="Home" arr={this.state.arrval} show={this.state.popUpAT} accessTransferFlag={this.state.isAccessTranfer} closeAccessTransferPopUp={(val) => this.closeAccessTransferPopUp(val)} />}
                        {modalOpen ? <ModalPopup showModal={modalOpen} closeModal={this._closeModal.bind(this)} ModalTitle={i18n.t('privacyStatement')} NotificationMsg={this.state.NotificationMsg} /> : null}
                    </div>

                </section>
                <Footer />
            </div>

        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

Home = connect(mapStateToProps)(withTranslation()(Home));
export default Home;
