/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import BenchmarkPopup from '../BenchmarkPopup';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { BaseURL } from '../../Constant';

class BOIView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickFilterText: null,
            boiGridData: [],
            popUpBenchmark: false,
            selectedMetric: ""
        };
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.DownloadExcel = this.DownloadExcel.bind(this);
        this.closeBenchmarkPopUp = this.closeBenchmarkPopUp.bind(this);
    }

    componentDidMount() {

        try {
            $("#loader-div").show();
            window.addEventListener('resize', this.reSizeGrid, true);

        }
        catch (error) {
            console.log("Error Encountered");;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    reSizeGrid() {
        this.refs.Grd_BOIView.gridOptions.api.sizeColumnsToFit();
    }

    gridReadyFun = (eve) => {
        try { 
            $("#loader-div").hide();
            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {                
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)  
                // }
                var SearchedString = "";
                let data ={
                    BOIID:0,
                    PMMProcessID:PMMProcessID,
                    SearchedString:SearchedString,
                    UserName:this.props.enterpriseId,				
                    GroupID: this.props.groupId,
                    LanguageID:this.props.languageId

                }
                eve.api.showLoadingOverlay();
                request("/BOI/ViewBOIDetails", 'POST', data)
                    .then(response => {
                        if (response.length !== 0) {
                            eve.api.hideOverlay();
                            this.setState({ boiGridData: response });
                            $("#loader-div").hide();
                        }
                        else {
                            eve.api.showNoRowsOverlay();
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");;
                        $("#loader-div").hide();
                    });
            }

            if (PMMProcessDraftID > 0) {    
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-9", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                var SearchedString = "";
                let data = {
                    BOILIId:0,
                    BOILIMappingId:0,
                    PMMProcessDraftID:this.props.Assessmentdetails.PMMProcessDraftID,
                    IndustryID:this.props.Assessmentdetails.IndustryID,
                    SCID:this.props.Assessmentdetails.ServiceComponentID,
                    SearchedString:SearchedString,
                    UserName:this.props.enterpriseId,				
                    GroupID: this.props.groupId,
                    LanguageID:this.props.languageId
                }
                eve.api.showLoadingOverlay();
                request("/BOI/GetBOIDetails", 'POST', data)
                    .then(response => {
                        if (response.length !== 0) {
                            this.setState({ boiGridData: response });
                            $("#loader-div").hide();
                        }
                        else {
                            eve.api.showNoRowsOverlay();
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");;
                        $("#loader-div").hide();
                    });
            }
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        this.setState({ quickFilterText: event.target.value });
    }

    async DownloadExcel() {
        try {
            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-D-25", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                let excelData = {
                    "PMMProcessID": this.props.Assessmentdetails.PMMProcessID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
                    "GroupID": this.props.groupId,
                    "LanguageID":this.props.languageId
                }
                let jsonexcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelTransaction", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonexcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Transaction.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
                
                // await request("/Common/DownloadtoExcelTransaction", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;

                //     })
                //     .catch(error => {
                //         console.log("Error Encountered");;
                //     });
            }

            if (PMMProcessDraftID > 0) {
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-D-21", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                var objThis = this;
                let excelData = {
                    "PMMProcessDraftID": PMMProcessDraftID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
                    "GroupID": this.props.groupId,
                    "LanguageID":this.props.languageId
                }
                let jsonExcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelDraft", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonExcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Draft.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
            
                // await request("/Common/DownloadtoExcelDraft", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;
                //     })
                //     .catch(error => {
                //         window.addEventListener("beforeunload", objThis.unloadEvent);
                //         console.log("Error Encountered");;
                //     });
            }
        }
        catch (error) {
            console.log("Error Encountered");;
        }
    }

    onCellClicked(params) {
        try {
            if (params.colDef.field == "BOILIName") {
                this.setState({
                    popUpBenchmark: true,
                    selectedMetric: params.data.BOILIName
                })
            }
        }
        catch (error) {
            console.log("Error Encountered");;
        }
    }

    closeBenchmarkPopUp() {
        try {
            this.setState({
                popUpBenchmark: false
            });
        }
        catch (error) {
            console.log("Error Encountered");;
        }
    }

    render() {

        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('BOIKeyBusinessMetrics'), field: 'BOILIName', suppressMovable: true, tooltipField: 'BOILIDefinition' },
            { headerName: i18n.t('InternalBenchmark'), field: 'Benchmark', suppressMovable: true, suppressResize: true, tooltipField: 'Benchmark', autoHeight: true },
            { headerName: i18n.t('ExternalBenchmark'), field: 'Median', suppressMovable: true, suppressResize: true, hide: true, tooltipField: 'Median', autoHeight: true },
            { headerName: i18n.t('Remark'), field: 'DisplayName', suppressMovable: true, tooltipField: 'DisplayName' }, // cellRenderer: CreateTextbox,
            { headerName: i18n.t('As-Is'), field: 'AsIs', suppressMovable: true, tooltipField: 'AsIs' }, //, cellRenderer: CreateTextbox
            { headerName: i18n.t('To-Be'), field: 'ToBe', suppressMovable: true, tooltipField: 'ToBe' } //, cellRenderer: CreateTextbox
        ];

        var boiGridData = this.state.boiGridData;

        var BOIDataGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_BOIView" id="grd_BOIView"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={boiGridData}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <div>
                <section className="content">
                    <div className="innerpage-hd"><Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back"><img src={backArrowIcon} />{i18n.t('bACKTOHOME')}</a></Link>
                        <ul className="pagecounter"  >
                            <li><span title={i18n.t("vIEWPreviousassesssment")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page"><Link to="/ViewPreviousAssessmentHome"></Link></span></li>
                            <li><span title={i18n.t("BOIKeyBusinessMetrics")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI">...</span></li>
                            <li><span title={i18n.t("lEADINGINDICATOR")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator ">2</span></li>
                            <li><span title={i18n.t("pMMASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">3</span></li>
                            <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                            <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                            <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                        </ul>
                    </div>
                    <section className="bodydata">
                        <div className="bodydata-full">
                            <div className="box-plain">
                                <h4><div className="pull-left" title={i18n.t("BOIKeyBusinessMetricLeadIndicators")}>
                                    {i18n.t('BOIKeyBusinessMetricVIEW')}
                                </div>
                                    <div className="pull-right">
                                        <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.Assessmentdetails.DealName}>{this.props.Assessmentdetails.DealName}
                                        </span> &nbsp;&nbsp;||<span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.Assessmentdetails.RegionName}>{this.props.Assessmentdetails.RegionName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.CountryName}>{this.props.Assessmentdetails.CountryName}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.Assessmentdetails.OGName}>{this.props.Assessmentdetails.OGName} */}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.Assessmentdetails.IndustryName}>{this.props.Assessmentdetails.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.CapabilityName}>{this.props.Assessmentdetails.CapabilityName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.TowerName}>{this.props.Assessmentdetails.TowerName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.SCName}>{this.props.Assessmentdetails.SCName}</span>
                                    </div>

                                </h4>
                                <h4 className="col-md-12" style={{ padding: '0px' }}>
                                    <span className="col-md-4 infoblockfilter ">
                                        <label className="col-md-3" id="lbl_searchBOIView" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                        <input type="text" id="txt_searchTextBOIView" className="col-md-9 searchText" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />
                                    </span>

                                    <div className="pull-right btn-group btn-group-pmmasmt">
                                        <button type="button" id="btn_downloadSavedScreensForBOIView" title={i18n.t("DownloadSavedScreensforthisAssessment")} className="btn btn-default btn-downld" onClick={this.DownloadExcel}>
                                            <img src={downloadIcon} />
                                        </button>
                                    </div>
                                </h4>
                                <br />
                                <div className="box-plain pmm-asmt-tbl boi-grid" style={{ marginTop: "42px" }}>
                                    {BOIDataGrid}

                                    <div className="btn-group-md">
                                        <Link to="/LIView"><a type="button" className="btn btn-success">{i18n.t('ViewLeadindicator')}</a></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.popUpBenchmark && <BenchmarkPopup show={this.state.popUpBenchmark} closeCallback={this.closeBenchmarkPopUp} selectedMetric={this.state.selectedMetric} dealId={this.props.Assessmentdetails.DealID} industry={this.props.Assessmentdetails.IndustryName} />}
                    </section>
                </section>
            </div>
        );
    }
};

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

BOIView= connect(mapStateToProps)(BOIView)
export default BOIView