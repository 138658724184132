/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';


class PotentialPopup extends React.Component {
    constructor(props) {
        super(props)
        try {
            this.state = { show: true };
            this.handleClose = this.handleClose.bind(this);
            this.handleResult = this.handleResult.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };
    componentWillReceiveProps() {
        
    }
    handleClose() {
        
        try {
            this.setState({ show: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };
    handleResult(event) {
        
    }
    render() {
        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h2>{i18n.t('confirm')}</h2>
                </Modal.Header>
                <Modal.Body>
                    Praveen
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button id="btn_yes" bsStyle="success" bsSize="large" onClick={this.handleResult}>{i18n.t('yes')}</Button>
                        <Button id="btn_no" bsStyle="danger" bsSize="large" onClick={this.handleResult}>{i18n.t('no')}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>)
    }
}

export default withRouter(PotentialPopup);