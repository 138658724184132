/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

var editActive = false;
var isErrorThere = false;


class PMMEffort extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                isEditAcitve: false,
                EffortGridData: [],
                pageNames: [],
                CapabilityValue: "",
                TowerValue: "",
                ServiceCompValue: "",
                numericStatus: false,
                show: this.props.show
            }
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.checkCells = this.checkCells.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.SaveEffData = this.SaveEffData.bind(this);
            this.CopyIndicativeValue = this.CopyIndicativeValue.bind(this);
            this.Cancel = this.Cancel.bind(this);
            var effortListTemp = [];
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps() {
        try {
            this.setState({ show: this.props.show })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleClose() {
        try {
            this.setState({ show: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    componentDidMount() {
        try {
            window.addEventListener('resize', this.reSizeGrid, true);

            var SCID = this.props.propsConfigDetails.ServiceComponent.value;
            var SearchedString="";
            let effortDraftData = {
                EffortDraftID:0,
                PMMProcessDraftID:this.props.propsConfigDetails.PMMProcessDraftID,
                SCID:SCID,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            request("/PMM/GetEffortDraft", 'POST', effortDraftData)
                .then(response => {
                    let effortList = response != null ? response : [];
                    this.effortListTemp = effortList.map(e => ({ ...e }));
                    this.setState({ EffortGridData: effortList });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    reSizeGrid() {
        try {
            this.refs.Grd_Effort.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onOverallEdit(e) {
        try {
            editActive = true;
            this.setState({
                isEditAcitve: true,
                popUpDisplay: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkCells() {
        try {
            let objGridDetails = this.refs.Grd_Effort.gridOptions.rowData;
            var numericStatus = true;
            isErrorThere = false;
            objGridDetails.map(effRow => {
                effRow["PMMProcessDraftID"] = this.props.propsConfigDetails.PMMProcessDraftID;
                var ActualValue = effRow.ActualValue;
                !isNaN(parseFloat(ActualValue)) && isFinite(ActualValue) ? numericStatus = true : numericStatus = false;

                if (effRow.ActualValue == "" || effRow.ActualValue == null) {
                    effRow["ErrorActualValue"] = true;
                    effRow["MsgActualValue"] = i18n.t('dealEffortValueShouldNotBeBlank');
                    isErrorThere = true;
                }
                else {
                    effRow["ErrorActualValue"] = false;
                    effRow["MsgActualValue"] = effRow.ActualValue;
                }
                if (numericStatus == false) {
                    effRow["ErrorActualValue"] = true;
                    effRow["MsgActualValue"] = i18n.t("pleaseenternumericvalueforDealEffort");
                    isErrorThere = true;
                }
                else {
                    // effRow["ActualValue"] = effRow.ActualValue;
                    effRow["ErrorActualValue"] = false;
                    effRow["MsgActualValue"] = effRow.ActualValue;
                }
            });

            this.setState({
                EffortGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.Grd_Effort.gridOptions.api.refreshCells(params);
            return isErrorThere;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            if (params.colDef.field == "ActualValue") {
                this.checkCells();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveEffData = () => {
        try {
            let enterpriseId = this.props.enterpriseId
            this.refs.Grd_Effort.gridOptions.api.stopEditing();
            let SCID = this.props.propsConfigDetails.ServiceComponent.value;

            var EffortGridData = [];
            this.refs.Grd_Effort.gridOptions.api.forEachNode(function (node) {
                node.data.SCID = SCID;
                node.data.loggedInUserId = enterpriseId;
                EffortGridData.push(node.data);
            });

            let rowsCount = this.refs.Grd_Effort.gridOptions.rowData;
            let rowslength = rowsCount.length;
            var isError = false;
            isError = this.checkCells();
            if (isError == true) {
                $("#loader-div").hide();
                alert(i18n.t("Pleasecorrectvalueshighlightedcells"));
                return false;
            }

            var EffortConfig = {
                "effortDetails": EffortGridData
            };
            this.setState({
                isEditAcitve: false
            })
            editActive = false;
            var data=EffortConfig;
            $("#loader-div").show();
            request("/PMM/SaveEffortDraft?LanguageID=" + this.props.languageId, 'POST',data)
            .then(res => {
                    if (res.outputmessage == i18n.t('success')) {
                        alert(res.outputmessage);
                        this.props.close();
                    }
                    else {
                        alert(res.outputmessage);
                    }
                    this.refs.Grd_Effort.gridOptions.api.sizeColumnsToFit();
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
           $("#loader-div").hide();
        }
        catch (error) {
            console.log("Error Encountered");
         }
   }

    gridReadyFun(eve) {
        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    Cancel() {
        try {
            let objGridDetails = this.effortListTemp;
            var objResult = [];
            objResult = objGridDetails.filter(function (effRow) {
                return effRow.EffortDraftId == 0;
            });
            if (objResult.length > 0) {
                if (confirm(i18n.t("dealspecificvalueswillbediscardedandcalulation"))) {/* eslint-disable-line */
                    isErrorThere = false;
                    this.props.close();
                }
            }
            
            else {

                let objRes = [];
                objRes = objGridDetails.filter(function (effRow) {
                    if(effRow.ActualValue == null)
                    {
                        effRow.ActualValue = "";
                    }
                    else{
                        effRow.ActualValue = null;/* eslint-disable-line */
                    }
                    return effRow.ActualValue == "";
                });

                if (objRes.length == this.state.EffortGridData.length) {
                    if (confirm(i18n.t("dealspecificvalueswillbediscardedandcalulation"))) {
                        this.props.close();
                    }
                }
                else {
                    if (confirm(i18n.t("allchangeswillbediscardedandcalculation"))) {
                        isErrorThere = false;
                        this.props.close();
                    }
                }

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CopyIndicativeValue() {
        try {
            let objGridDetails = this.refs.Grd_Effort.gridOptions.rowData;

            objGridDetails.map(effRow => {
                var ActualValue = effRow.ActualValue;
                effRow.ActualValue = effRow.IndicativeValue;
            });

            this.setState({
                EffortGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.Grd_Effort.gridOptions.api.refreshCells(params);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        var columnDefs = [];
        columnDefs = [{ headerName: i18n.t("effortDescription"), field: "EffortDescription", suppressMovable: true, suppressResize: true },
        { headerName: i18n.t("units"), field: "Units", suppressMovable: true, suppressResize: true },
        { headerName: i18n.t("IndicativeEffortValue"), field: "IndicativeValue", suppressMovable: true, suppressResize: true },
        {
            headerName: i18n.t("Dealspecificeffortvalue"), field: "ActualValue", suppressMovable: true, editable: true, suppressResize: true, cellStyle: function (params) {
                let background = params.data.ErrorActualValue ? "#E57373" : "transparent"
                return {
                    background: background
                };
            }, tooltipField: 'MsgActualValue'
        }
        ];

        var EffDataTable = this.state.EffortGridData;
        var EffortDataGrid = (
            <div className="ag-theme-material grid-div custom-select">
                <AgGridReact ref="Grd_Effort" id="grd_EffortData" enableSorting="false"
                    paginationPageSize="10"
                    enableColResize={false}
                    singleClickEdit={true}
                    columnDefs={columnDefs}
                    rowData={EffDataTable}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    stopEditingWhenGridLosesFocus={true}
                    suppressRowClickSelection={true}
                    onGridReady={this.gridReadyFun.bind(this)}
                    onCellEditingStarted={this.onOverallEdit.bind(this)}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h2>{i18n.t('updateEffort')}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="AsmtData viewprevassmt box-plain">
                        {EffortDataGrid}
                        <div className="btn-group-md">
                            <a type="button" onClick={this.CopyIndicativeValue} className="btn btn-success" title={i18n.t("copyIndicativeEffortvalue")}>{i18n.t('copy')}</a>
                            <a type="button" className="btn btn-info" onClick={this.Cancel} >{i18n.t('cancel')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.SaveEffData} >{i18n.t('SaveandreturntoLIscreen')}</a>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>)
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

PMMEffort= connect(mapStateToProps)(withRouter(PMMEffort))
export default PMMEffort;