import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Chart } from 'chart.js';
import i18n from 'i18next';

var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
    "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
    "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
    "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];


class LandscapeGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
            show: this.props.show
        }
        this.closePopUp = this.closePopUp.bind(this);
        this.landscapeGraph = this.landscapeGraph.bind(this);

    }

    componentWillReceiveProps(props) {
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show
            });
            this.landscapeGraph();

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    generateData() {
        try {
            var data = [];
            for (var i = 0; i < this.props.asToValX.length; i++) {
                if (this.props.asToValX[i] == null || this.props.asToValX[i] == undefined) {
                    console.log("Invalid Data");
                }
                else if (this.props.asToValY[i] == null || this.props.asToValY[i] == undefined) {
                    console.log("Invalid Data");
                }
                else {
                    data.push({
                        data: [{
                            x: this.props.asToValY[i],
                            y: this.props.asToValX[i]
                        }],
                        label:
                            this.props.YLabel[i] != null ? "" + this.props.XLabel[i] + "-" + this.props.YLabel[i] : "" + this.props.XLabel[i],
                        radius: 15,
                        pointRadius: 15,
                        borderColor: color[i],
                        backgroundColor: color[i]
                    });
                }
            }
            return data;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    generateLabelData() {
        try {
            var labels = [];
            for (var i = 0; i < this.props.asToValX.length; i++) {
                if (this.props.asToValX[i] == null || this.props.asToValX[i] == undefined) {
                    console.log("Invalid Data");
                }
                else if (this.props.asToValY[i] == null || this.props.asToValY[i] == undefined) {
                    console.log("Invalid Data");
                }
                else {
                    labels.push({
                        x: this.props.XLabel[i],
                        y: this.props.YLabel[i]
                    });
                }
            }
            return labels;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    landscapeGraph() {
        try {
            setTimeout(() => {
                var ctx = document.getElementById('asToChart').getContext('2d');
                window.asToChart = new Chart(ctx, {
                    type: 'scatter',
                    data: {
                        labels: this.generateLabelData(),
                        datasets:
                            this.generateData(),

                    },
                    options: {
                        tooltips: {
                            enabled: true,
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = data.datasets[tooltipItem.datasetIndex].label
                                    return label + ': ( STD.: ' + tooltipItem.xLabel + ', PMM: ' + tooltipItem.yLabel + ')';
                                }
                            }
                        },
                        layout: {
                            padding: {
                                left: 50,
                                right: 50,
                                top: 0,
                                bottom: 0
                            }
                        },
                        legend: {
                            display: true,
                            labels: {
                                fontFamily: "Comic Sans MS"
                            }
                        },
                        showLines: false,
                        scales: {
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: i18n.t('standardizationScore'),
                                    fontSize: 16
                                },
                                type: 'linear',
                                position: 'bottom',
                                ticks: {
                                    beginAtZero: false,
                                    stepSize: 1,
                                    min: 0,
                                    max: 4
                                },
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: i18n.t('overAllPMMScore'),
                                    fontSize: 16
                                },
                                type: 'linear',
                                position: 'bottom',
                                ticks: {
                                    beginAtZero: false,
                                    stepSize: 1,
                                    min: 0,
                                    max: 4
                                },
                            }]
                        },
                        plugins: {
                            datalabels: {
                                display: false,
                            },
                        }
                    }
                });
            }, 1);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp(eve) {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {
        return (
            <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>

                <Modal.Header closeButton>
                    <h5 align='center'><b><span className="infoHeaderContent">{this.props.ServiceComponentName}</span> {i18n.t('LandscapeforDeal')} <span className="infoHeaderContent">{this.props.DealName}</span></b></h5>
                </Modal.Header>
                <Modal.Body>
                    <canvas id="asToChart" width="800" height="350"></canvas>
                </Modal.Body>
                <Modal.Footer>
                    <input id="txt_close" type="button" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                </Modal.Footer>

            </Modal>

        );
    }
}

export default withRouter(LandscapeGraph)
