import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import { BaseURL } from '../../Constant';
import { AgGridReact } from 'ag-grid-react';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

function CreateUploadButton(params) {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = `<input type="button" class="btn btn-success" value=${i18n.t("upload")} />`;
        eDiv.className = "form-group";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

function CreateDeleteButton(params) {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = '<span class="glyphicon glyphicon-trash"></span>';
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        if (params.data.Filename == "") {
            eDiv.style = "color:lightgray";
        } else {
            eDiv.className = "col-md-12 text-dark";
        }
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class ReportDocumentUpload extends Component {
    constructor(props) {
        super(props);
        try {
            let reportDocumentArr = new Array(10);
            this.state = {
                show: this.props.show,
                reportDocumentGridData: reportDocumentArr,
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.fileUploaded = this.fileUploaded.bind(this);
            this.loadReportDocuments = this.loadReportDocuments.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.CreateUploadField = this.CreateUploadField.bind(this);

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show
            });
            window.addEventListener('resize', this.reSizeGrid, true);
            this.loadReportDocuments();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.reportDocuments_Grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    loadReportDocuments() {
        try {
            $("#loader-div").show();
            let gridData = {
                "PMMProcessDraftID": this.props.selectedPMMProcessDraftID,
                "PMMProcessID": 0,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/PotentialProjects/GetReportDocumentNames", 'POST', gridData)
                .then((response) => {
                    if (response != "") {
                        let reportDocumentArr = new Array(10);
                        let counter = 10;
                        for (var i = 0; i < counter; i++) {
                            if (i < response.length) {
                                let PMM = {
                                    File: {},
                                    Filename: response[i].Filename,
                                    isFileEmpty: true,
                                    DocumentID: response[i].ID
                                }
                                reportDocumentArr[i] = PMM;
                            }
                            else {
                                let PMM = {
                                    File: {},
                                    Filename: '',
                                    isFileEmpty: true,
                                    DocumentID: 0
                                }
                                reportDocumentArr[i] = PMM;
                            }
                        }
                        this.setState({
                            reportDocumentGridData: reportDocumentArr
                        });
                    } else {
                        let reportDocumentArr = new Array(10);
                        for (var i = 0; i < reportDocumentArr.length; i++) {
                            let PMM = {
                                File: {},
                                Filename: '',
                                isFileEmpty: true,
                                DocumentID: 0
                            }
                            reportDocumentArr[i] = PMM;
                        }
                        this.setState({
                            reportDocumentGridData: reportDocumentArr
                        });
                    }
                    this.refresh();
                    $("#loader-div").hide();
                }).catch((error) => {
                    $("#loader-div").hide();
                    console.log("Error in Get User", error)
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
            params.api.resetRowHeights();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    clearUploadedFile = (e) => {
        try {
            e.target.value = '';
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {

            if (e.colDef.headerName == i18n.t('upload')) {
                this.clearUploadedFile(e);
                this.UploadReportFiles(e.rowIndex);
            }
            if (e.colDef.headerName == i18n.t('uploadedFileName')) {
                this.refresh();
            }
            if (e.colDef.headerName == i18n.t('delete')) {
                if (e.data.Filename != "") {
                    if (window.confirm(i18n.t('deleteConfirmation'))) {
                        let PMMConfigDetails = this.refs.reportDocuments_Grid.gridOptions.rowData;
                        let rowDataToBeDeleted = PMMConfigDetails.find((item, index) => index == e.rowIndex);
                        PMMConfigDetails.splice(e.rowIndex, 1);
                        this.refs.reportDocuments_Grid.gridOptions.api.setRowData(PMMConfigDetails);
                        this.refs.reportDocuments_Grid.gridOptions.api.sizeColumnsToFit();
                        this.RemoveReportFiles(rowDataToBeDeleted);
                    }
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    refresh() {
        try {
            let objGridDetails = this.refs.reportDocuments_Grid.gridOptions.rowData;
            this.refs.reportDocuments_Grid.gridOptions.api.setRowData(objGridDetails);
            this.setState({
                reportDocumentGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.reportDocuments_Grid.gridOptions.api.refreshCells(params);
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
            let currentGridData = [];
            let reportDocuments = [];
            currentGridData = this.refs.reportDocuments_Grid.gridOptions.rowData;
            reportDocuments = currentGridData.filter(item => item.Filename != "");
            this.props.closeCallback(reportDocuments.length);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CreateUploadField(params) {
        try {
            this.eGui = document.createElement("div");
            this.eGui.className = "form-group form-horizontal";
            this.eGui.width = "100%";

            var index = (params.rowIndex + 1);
            var labelElement = document.createElement("label");
            labelElement.className = "control-label pull-left";
            labelElement.textContent = index;

            var divElement = document.createElement("div");
            divElement.className = "col-sm-8 btn-group-md";
            var fileElement = document.createElement("input");
            fileElement.type = "file";
            fileElement.ref = "file";

            if (params.data.File.name != undefined) {
                let list = new DataTransfer();
                let file = params.data.File;
                list.items.add(file);
                fileElement.files = list.files;
            }
            fileElement.addEventListener('change', (event) => {
                this.fileUploaded(event, params.rowIndex);
            });

            divElement.appendChild(fileElement)

            this.eGui.appendChild(labelElement);
            this.eGui.appendChild(divElement);
            return this.eGui;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    UploadReportFiles(currentRowIndex) {
        try {
            var fileData = new FormData();
            let objGridDetails = this.refs.reportDocuments_Grid.gridOptions.rowData;
            var emptyfiles = [];
            emptyfiles = objGridDetails.find((row, index) => index == currentRowIndex && row.isFileEmpty);
            var replacedFiles = objGridDetails.find((row, index) => index == currentRowIndex && row.IsReplacement);
            var duplicateFiles = objGridDetails.find((row, index) => index == currentRowIndex && row.IsDuplicate);
            if (emptyfiles !== undefined && emptyfiles.isFileEmpty) {
                alert(i18n.t("Pleaseselectafiletoupload"));
            } else {
                if (replacedFiles !== undefined) {
                    if (window.confirm(i18n.t("Doyouwanttoreplacetheexistingfile"))) {
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                fileData.append("File", row.File);
                                fileData.append('Operation', 1);
                                fileData.append('PMMProcessDraftID', this.props.selectedPMMProcessDraftID);
                                fileData.append("ID", row.DocumentID);
                                fileData.append("UserName", this.props.enterpriseId);
                                fileData.append("GroupID", this.props.groupId);
                                fileData.append("LanguageID", this.props.languageId)
                                return true;
                            }
                        });
                        $("#loader-div").show();

                        fetch(BaseURL + "/PotentialProjects/UpsertReportDocuments?LanguageID=" + this.props.languageId, {
                            method: 'POST',
                            headers: {
                                "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                            },
                            body: fileData
                        }).then(r => r.json())
                            .then(data => {
                                alert(data.outputmessage);
                                this.loadReportDocuments();
                                $("#loader-div").hide();
                            })
                            .catch(error => {
                                console.log("Error Encountered");
                            })
                    }
                    else {
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                row.Filename = row.OldFilename;
                                row.File = {};
                                row.isFileEmpty = true;
                                return false;
                            }
                        })
                    }
                }
                else if (duplicateFiles !== undefined) {
                    if (window.confirm(i18n.t("Doyouwanttoreplaceexistingfile"))) {
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                fileData.append("File", row.File);
                                fileData.append('Operation', 1);
                                fileData.append('PMMProcessDraftID', this.props.selectedPMMProcessDraftID);
                                fileData.append("ID", row.DocumentID);
                                fileData.append("UserName", this.props.enterpriseId);
                                fileData.append("GroupID", this.props.groupId);
                                fileData.append("LanguageID", this.props.languageId)
                                return true;
                            }
                        });
                        $("#loader-div").show();

                        fetch(BaseURL + "/PotentialProjects/UpsertReportDocuments", {
                            method: 'POST',
                            headers: {
                                "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                            },
                            body: fileData
                        }).then(r => r.json())
                            .then(data => {
                                alert(data.outputmessage);
                                this.loadReportDocuments();
                                $("#loader-div").hide();
                            })
                            .catch(error => {
                                console.log("Error Encountered");
                            })
                    }
                    else {
                        objGridDetails.map((row, index) => {
                            if (index == currentRowIndex) {
                                row.File = {};
                                row.isFileEmpty = true;
                                return false;
                            }
                        })
                    }
                }
                else {
                    objGridDetails.map((row, index) => {
                        if (index == currentRowIndex) {
                            fileData.append("File", row.File);
                            fileData.append('Operation', 0);
                            fileData.append('PMMProcessDraftID', this.props.selectedPMMProcessDraftID);
                            fileData.append("DocumentID", row.DocumentID);
                            fileData.append("UserName", this.props.enterpriseId);
                            fileData.append("GroupID", this.props.groupId);
                            fileData.append("LanguageID", this.props.languageId)
                            return true;
                        }
                    });
                    $("#loader-div").show();

                    fetch(BaseURL + "/PotentialProjects/UpsertReportDocuments", {
                        method: 'POST',
                        headers: {
                            "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                        },
                        body: fileData
                    }).then(r => r.json())
                        .then(data => {
                            alert(data.outputmessage);
                            this.loadReportDocuments();
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            $("#loader-div").hide();
                            console.log("Error Encountered");
                        })
                }
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    RemoveReportFiles(rowData) {
        try {
            $("#loader-div").show();
            let gridData = {
                "DocumentID": rowData.DocumentID,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/PotentialProjects/RemoveReportDocuments", 'POST', gridData)
                .then(res => {
                    alert(res.outputmessage);
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        } catch (error) {
            console.log("Error Encountered");
            $("#loader-div").hide();
        }
    }

    fileUploaded(e, currentRowIndex) {
        try {
            let objGridDetails = this.refs.reportDocuments_Grid.gridOptions.rowData;
            if (e.target.value != "") {
                var fileExtension = ['.xls', '.xlsx', '.xlsm', '.pdf', '.doc', '.docx'];
                var fileName = e.target.files[0].name.split('\\').pop();
                var fileExt = fileName.substring(fileName.lastIndexOf('.'));

                if (fileExtension.indexOf(fileExt) < 0) {
                    alert(i18n.t("Invalidfileselectedvalidfilesareof") + fileExtension.toString() + i18n.t('types_PMMConfig'));
                    e.target.value = "";
                    return false;
                } else if (e.target.files[0].size > 1000000) {
                    alert(i18n.t("maximumfilesizecanbeofMB"));
                    e.target.value = "";
                    return false;
                } else {
                    objGridDetails.map((row, index) => {
                        if (index == currentRowIndex) {
                            if (row.Filename == e.target.files[0].name) {
                                row.IsDuplicate = true;
                            } else if (row.Filename != "" && row.Filename != e.target.files[0].name) {
                                row.IsReplacement = true;
                                row.OldFilename = row.Filename;
                            }
                            row.File = e.target.files[0];
                            row.isFileEmpty = false;
                        }
                        else {
                            if (row.File.name == e.target.files[0].name || row.Filename == e.target.files[0].name) {
                                alert(i18n.t("duplicateFilePleaseselectdifferentfile"))
                                e.target.value = "";
                                return false;
                            }
                        }
                    });
                }
            }
            else {
                objGridDetails.map((row, index) => {
                    if (index == currentRowIndex) {
                        row.File = {};
                        row.isFileEmpty = true;
                    }
                });
            }
            this.refs.reportDocuments_Grid.gridOptions.api.setRowData(objGridDetails);
            this.setState({
                reportDocumentGridData: objGridDetails
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        var reportDocumentsGrid = this.state.reportDocumentGridData;

        columnDefs = [
            { headerName: i18n.t('document#'), suppressMovable: true, suppressResize: true, width: 300, cellRenderer: this.CreateUploadField },
            { headerName: i18n.t('uploadedFileName'), field: 'Filename', tooltipField: 'Filename', suppressMovable: true, suppressResize: true, autoHeight: true, width: 180 },
            { headerName: i18n.t('upload'), suppressMovable: true, suppressResize: true, width: 100, cellRenderer: CreateUploadButton },
            { headerName: i18n.t('delete'), suppressMovable: true, suppressResize: true, width: 100, cellRenderer: CreateDeleteButton }
        ];

        var ReportDocumentsGrid = (
            <div className="ag-theme-material grid-div custom-select">
                <AgGridReact ref="reportDocuments_Grid"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    onGridSizeChanged={this.reSizeGrid.bind(this)}
                    rowData={reportDocumentsGrid}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection="single"
                    rowmodeltype="pagination"
                    singleClickEdit={true}
                    onCellClicked={this.onCellClicked.bind(this)}
                    suppressScrollOnNewData={true}
                    localeText={AgGridLocale()}

                />
            </div>
        )

        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('reportDocumentUpload')}</b></h5>
                    </Modal.Header>
                    <Modal.Body >
                        {ReportDocumentsGrid}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-left"><h6><i>{i18n.t('nOTEPermissibleFileTypesareExcel')}.</i></h6></div>
                        <div className="btn-group-md">
                            <input id="txt_close" type="button" className="btn btn-info" value={i18n.t("close")} onClick={this.closePopUp} />
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId
    }
}

ReportDocumentUpload = connect(mapStateToProps)(withRouter(ReportDocumentUpload))
export default ReportDocumentUpload