// to set action types

export const TEST_ACTION = "TEST_ACTION";
export const SET_ENTERPRISE_ID = "SET_ENTERPRISE_ID";
export const SET_LANGUAGE_ID = "SET_LANGUAGE_ID";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const SET_ROLE_NAME = "SET_ROLE_NAME";
export const SET_PAGE_NAME = "SET_PAGE_NAME";
export const SET_GROUP_NAME = "SET_GROUP_NAME";
export const SET_DROPDOWN_VALUES = "SET_DROPDOWN_VALUES";
