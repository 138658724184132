/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from '../../services/Service';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { inputValidator } from '../../Utils/InputElementValidation';

class ReferenceDocumentsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            PMMAssessmentReferenceDocuments: [],
            PMMAssessmentGeneralReferenceDocuments: [],
            SCID: this.props.SCID
        }
        this.closePopUp = this.closePopUp.bind(this);
        this.saveFile = this.saveFile.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            show: this.props.show
        });
    }

    componentDidMount() {
        $("#loader-div").show();

        this.setState({
            show: this.props.show
        });

        var MaturityElement = "";

        if (this.props.selectedMaturity != "") {
            MaturityElement = this.props.selectedMaturity.trim();
        }

        if (MaturityElement != "All") {
            let referenceDocumentData = {
                SCID:this.state.SCID,
                MaturityElement:MaturityElement,
                GroupID: this.props.groupId,
                LanguageID:this.props.languageId
            }
            request("/PMM/GetReferenceDocumentNames", 'POST', referenceDocumentData)
                .then(response => {
                    if (response) {
                        this.setState({ PMMAssessmentReferenceDocuments: response });
                    }
                    else {
                        this.setState({ PMMAssessmentReferenceDocuments: [] });
                    }
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        else if (MaturityElement == "All") {

            let getReferenceDocumentNamesData = {
                SCID:this.state.SCID,
                MaturityElement:MaturityElement,
               LanguageID:this.props.languageId
            }
            Promise.all([request("/PMM/GetReferenceDocumentNames", 'POST', getReferenceDocumentNamesData)
            ])
                .then((response) => {
                    if (response[0]) {
                        var groupedByData = this.groupBy(response[0], 'Filename');
                        this.setState({ PMMAssessmentReferenceDocuments: groupedByData });
                    }
                    else {
                        this.setState({ PMMAssessmentReferenceDocuments: [] });
                    }
                    if (response[1]) {
                        this.setState({ PMMAssessmentGeneralReferenceDocuments: response[1] });
                    }
                    else {
                        this.setState({ PMMAssessmentGeneralReferenceDocuments: [] });
                    }
                    $("#loader-div").hide();
                })


                .catch((error) => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                })
        }
    }

    groupBy = (arrObject, property) => {
        return arrObject.reduce((accumulator, object) => {
            let common = accumulator.find(obj => obj.Filename == object.Filename)
            if (common != undefined) {
                accumulator.forEach((element, index) => {
                    if (element.Filename === object.Filename) {
                        accumulator[index].MaturityElement = accumulator[index].MaturityElement + " , " + object.MaturityElement.split("^").join(",");
                    }
                });
            }
            else {
                object.MaturityElement = object.MaturityElement.split("^").join(",")
                accumulator.push(object);
            }
            return accumulator;
        }, []);
    };

    closePopUp(eve) {
        this.setState({
            show: false
        });
        this.props.closeCallback();
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    onCellClicked(params) {
        try {            
            //window.tfo_event("PMM-D-4", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])            
            debugger;
            if (params.colDef.field == "Filename") {
                let referenceDocumentsData = {
                    DocumentID:params.data.ID,
                    GroupID: this.props.groupId,
			        LanguageID:this.props.languageId
                }
                $("#loader-div").show();
                request("/PMM/GetReferenceDocuments", 'POST', referenceDocumentsData)
                    .then(response => {
                        if (response) {
                            this.saveFile(response[0].Filename, response[0].ContentType, response[0].FileData)
                            $("#loader-div").hide();
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    saveFile(strFileName, strMimeType, strData) {
        try {
            var D = document, A = arguments, a = D.createElement("a"),
                d = A[0], n = A[1], t = A[2] || "application/pdf";

            var newdata = "data:" + strMimeType + ";base64," + escape(strData);
            //build download link: 
            a.href = newdata;
            if ('download' in a) {
                let validateFileName  = inputValidator(strFileName);
                if (validateFileName == false){
                    let newFileName = strFileName;
                    a.setAttribute("download", newFileName);
                }
                
                a.textContent = i18n.t('downloading');
                D.body.appendChild(a);
                setTimeout(function () {
                    var e = D.createEvent("MouseEvents");
                    e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    a.dispatchEvent(e);
                    D.body.removeChild(a);
                }, 66);
                return true;
            };
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('DocumentID'), field: 'ID', hide: true, suppressMovable: true, suppressResize: true, tooltipField: 'ID', autoHeight: true },
            { headerName: i18n.t('maturityElement'), field: 'MaturityElement', suppressMovable: true, suppressResize: true, tooltipField: 'MaturityElement', autoHeight: true },
            {
                headerName: i18n.t('DocumentName'), field: 'Filename', suppressMovable: true, suppressResize: true, tooltipField: 'Filename', autoHeight: true,
                cellStyle: function () {
                    return {
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "blue"
                    };
                },
                tooltip: function (params) {
                    return i18n.t('clickToDownloadFile');
                }
            },
        ];

        var PMMAssessmentReferenceDocumentData = this.state.PMMAssessmentReferenceDocuments;

        var PMMAssessmentReferenceDocumentsGrd = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_PMMAssessmentReferenceDocument" id="grd_PMMAssessmentReferenceDocument"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={PMMAssessmentReferenceDocumentData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.onGridReady.bind(this)}
                    onCellClicked={this.onCellClicked.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('PMMAssessmentReferenceDocuments')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="box-plain multiple-tabs-section no-padding-lr">{PMMAssessmentReferenceDocumentsGrd}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_closeRef" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        groupId:state.groupId,
        groupName: state.groupName,
      }
}

export default connect(mapStateToProps)(withRouter(ReferenceDocumentsDetail))