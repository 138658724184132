import React from "react"
import Header from './Header';
import Footer from './Footer';
import Home from "./Home";

export default function higherOrderComp(ChildComponent, props) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showPage: false
      }
    }

    componentDidMount() {
      try {
        if (props.screenName == "") {
          if (this.props.history.action != undefined && this.props.history.action == "PUSH") {
            //only show page for menus, when routed pages from components
            this.setState({
              showPage: true
            })
          }
          else {
            this.setState({
              showPage: false
            })
          }
        }
        else {
          if (props.pageNames.length != 0 && props.pageNames.includes(props.screenName)) {
            this.setState({
              showPage: true
            })
          }
          else {
            this.setState({
              showPage: false
            })
          }
        }
      } catch (error) {
        console.log("Error Encountered");
      }
    }

    render() {
      return (
        this.state.showPage ?
          <div className="pagecontainer innerpages">
            <Header {...props} />
            <ChildComponent {...props} />
            <Footer />
          </div>
          :
          <Home closeGroupSwitchPopup={props.closeGroupSwitchPopup} />
      );
    }
  };
}