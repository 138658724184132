/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from '../../services/Service';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

class MetricImpactedDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show, headerName: "", innerContent: "",
            PMMAssessmentMetricData: [],

        }
        this.closePopUp = this.closePopUp.bind(this);
    }

    componentWillReceiveProps(props) {

        this.setState({
            show: this.props.show
        });
    }

    componentDidMount() {
        try {
            $("#loader-div").show();

            this.setState({
                show: this.props.show
            });

            var PMMProcessDraftID = this.props.selectedMetric.PMMProcessDraftId;
            var PMMProcessID = this.props.selectedMetric.PMMProcessID;
            var BOILINames = this.props.selectedMetric.MetricImpacted.split(',  ').join(",").trim();
            let viewMetricData = {
                BOILIName:BOILINames,
                PMMProcessDraftId:PMMProcessDraftID,
                GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            if (PMMProcessDraftID > 0) {
                request("/PMM/ViewMetricImpactedDetails", 'POST', viewMetricData)
                    .then(response => {
                        this.setState({ PMMAssessmentMetricData: response });
                        $("#loader-div").hide();

                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }

            if (PMMProcessID > 0) {
                BOILINames = this.props.selectedMetric.MetricImpacted;
                let viewTransactionData = {
                    BOILIName:BOILINames,
                    PMMProcessID:PMMProcessID,
                    GroupID: this.props.groupId,
			        LanguageID:this.props.languageId
                }
                request("/PMM/ViewTransactionMetricImpactedDetails", 'POST', viewTransactionData)
                    .then(response => {
                        this.setState({ PMMAssessmentMetricData: response });


                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp(eve) {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('metrics'), field: 'BOILIName', suppressMovable: true, suppressResize: true, tooltipField: 'BOILIDefinition' },
            { headerName: i18n.t('InternalBenchmark'), field: 'Benchmark', suppressMovable: true, suppressResize: true, tooltipField: 'Benchmark', autoHeight: true },
            { headerName: i18n.t('Remark'), field: 'DisplayName', tooltipField: 'DisplayNameMsg', suppressMovable: true, suppressResize: true, editable: true, autoHeight: true },
            { headerName: i18n.t('As-Is'), field: 'AsIs', suppressMovable: true, suppressResize: true, tooltipField: 'AsIsMsg', width: 100 },
            { headerName: i18n.t('To-Be'), field: 'ToBe', suppressMovable: true, suppressResize: true, tooltipField: 'ToBeMsg', width: 100 },

        ];

        var PMMAssessmentData = this.state.PMMAssessmentMetricData;

        var PMMAssessmentDtaGrd = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_PMMAssessmentDetails" id="grd_PMMAssessmentDetails"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={PMMAssessmentData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.onGridReady.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('PMMAssessmentPMMAssessmentMetricDetail')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="box-plain pmm-asmt-tbl BOI-div">
                            {PMMAssessmentDtaGrd}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_close" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        groupId:state.groupId,
      }
}

MetricImpactedDetail = connect(mapStateToProps)(withRouter(withTranslation()(MetricImpactedDetail)))
export default withRouter(MetricImpactedDetail)