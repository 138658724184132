/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from '../EditConfirmation';
import { withRouter } from 'react-router';
import $ from 'jquery';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect} from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';


const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.TowerName;

const renderSuggestion = suggestion => <span>{suggestion.TowerName}</span>;



function CreateEditBox(params) {
    try {
        let eDiv = document.createElement("div");
        let groupId=params.colDef.cellRendererParams.groupId;
        if (params.data.GroupID == parseInt(groupId)
            ) {
            eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
        }
        else {
            eDiv.innerHTML = ``;
        }
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}


class Tower extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                lstCapability: [],
                CapabilityValue: [],
                TowerGridData: [],
                isEdit: 1,
                errors: {},
                selectedRow: {},
                TowerId: 0,
                IsDeleted: false,
                TowerName: '',
                Description: '',
                value: '',
                suggestions: [],
                isEditPopUp: false,
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                IsActive: true


            };
            this.SelectCapability = this.SelectCapability.bind(this);
            this.ChangeIsActive = this.ChangeIsActive.bind(this);
            this.OnCancel = this.OnCancel.bind(this);
            this.onInputChange = this.onInputChange.bind(this);
            this.onChange = this.onChange.bind(this);
            this.onBlur = this.onBlur.bind(this);
            this.getSuggestions = this.getSuggestions.bind(this);
            this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
            this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
            this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
            this.populateValues = this.populateValues.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.SaveTowerDetails = this.SaveTowerDetails.bind(this);
            this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
            this.passValue = this.passValue.bind(this);
            this.formatActive = this.formatActive.bind(this);
            this.onSearch = this.onSearch.bind(this);
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async FetchCapabilitylist() {
        try {
            var objThis = this;
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            await request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(res => {
                    let CapabilityByGroup = []
                    CapabilityByGroup = res.Capability != null || res.Capability != "" ? res.Capability : []
                    objThis.setState({ lstCapability: CapabilityByGroup },() => {this.FetchConnTowerGrid();});
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    componentDidMount() {
        try {        
            //window.tfo_event("PMM-MC-6", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"]) 
            window.addEventListener('resize', this.reSizeGrid, true);
            this.FetchCapabilitylist();
            //NOTE : commented below line and called the FetchConnTowerGrid after APIs from FetchCapabilitylist function are fully executed.
            //this.FetchConnTowerGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    reSizeGrid() {
        try {
            this.refs.tower_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            debugger;
            console.log("Error Encountered");
        }
    }

    async FetchConnTowerGrid() {
        try {
            var objThis = this;
            var SearchedString = "";
            let towerData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerId": 0,
            }
            await request("/MasterScreens/GetTower", 'POST', towerData)
                .then(res => {
                    objThis.setState({ TowerGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveTowerDetails(eve) {
        try {
            const obj = this;
            let { TowerId, TowerName, IsActive, CapabilityValue, IsDeleted, value, Description } = obj.state;
            let Operation = eve && TowerId != 0 ? 2 : 1;
            let towernamevalidation = inputValidator(value)
            let descriptionvalidation = inputValidator(Description)

if(towernamevalidation === false && descriptionvalidation=== false){
            if (obj.IsFormValid()) {
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && TowerName != "") {
                    this.setState({
                        isEditPopUp: true
                    })
                    return alert(i18n.t('updateExistingorCreateNew'));
                }
                const requestData = obj.state.selectedRow;
                requestData["UserName"] = this.props.enterpriseId;
                requestData["Operation"] = Operation;
                requestData["TowerId"] = eve ? TowerId : 0;
                requestData["TowerName"] = value;
                requestData["Description"] = Description;


                if (this.state.CapabilityValue[0].length == 0) {
                    requestData["CapabilityId"] = 0
                }
                else {
                    requestData["CapabilityId"] = this.state.CapabilityValue[0].value;
                    requestData["CapabilityName"] = this.state.CapabilityValue[0].label;
                }

                requestData["IsActive"] = IsActive;
                requestData["IsDeleted"] = IsDeleted;
                requestData["GroupID"] = this.props.groupId;
                requestData["LanguageID"] = this.props.languageId;

                request("/MasterScreens/UpSertTower", 'POST', requestData)
                    .then(res => {
                        if (res.Outputcode != -1) {

                            alert(res["outputmessage"]);
                            obj.FetchConnTowerGrid();
                            obj.OnCancel();
                            obj.props.editFun(false);
                        }
                        else {
                            alert(res["outputmessage"]);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
            }
        }

        else{

            alert(i18n.t("PleaseEntervalidValuesintheFields"));

        }


        }
  
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};
            let reg = /^[A-Za-z\d\s]+$/;
            let regForAlphabets = /^[a-zA-Z]+$/;

            if (!this.refs.TowerName.input.value) {
                errors.TowerName = i18n.t('pleaseEnterTowerName');
                alert(i18n.t('pleaseEnterTowerName'));
                formIsValid = false;
            }
            if (this.refs.TowerName.input.value.includes(",")) {
                errors.TowerName = i18n.t('towernameshouldnotcontain') + ',';
                alert(i18n.t('towernameshouldnotcontain') + ',');
                formIsValid = false;
            }
            if (this.state.CapabilityValue == undefined && this.state.CapabilityValue.length == 0 && formIsValid == true) {
                errors.CapabilityValue = i18n.t('pleaseSelectaCapability');
                alert(i18n.t('pleaseSelectaCapability'));
                formIsValid = false;
            }

            if (!this.refs.Description.value && formIsValid == true) {
                errors.Description = i18n.t('pleaseEnterTowerDescription');
                alert(i18n.t('pleaseEnterTowerDescription'));
                formIsValid = false;
            }

            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }



    onGridReady=(params)=> {
        try {
            $("#loader-div").hide();

            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            var objThis = this;
            var SearchedString = "";
            let towerData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerId": 0,
            }
            params.api.showLoadingOverlay();

            request("/MasterScreens/GetTower", 'POST', towerData)
                .then(res => {
if(res!==0){
    params.api.hideOverlay();
    objThis.setState({ TowerGridData: res });
    $("#loader-div").hide();
}
else{
    params.api.showNoRowsOverlay();
}
                   

                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCapability(val) {
        this.setState({ CapabilityValue: val });
    }

    onCellClicked(e) {
        debugger;
        try {
            if (e.colDef.headerName == i18n.t('edit')) {
                if (e.data.GroupID == parseInt(this.props.groupId)
                    ) {
                    this.populateValues(e.data);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(tower) {
        debugger;
        try {
            this.setState({
                isEdit: 2,
                selectedRow: tower,
                TowerId: tower.TowerId,
                IsActive: tower.IsActive,
                IsDeleted: tower.IsDeleted,
                value: tower.TowerName,
                TowerName: tower.TowerName,
                Description: tower.Description,
                CapabilityValue: this.state.lstCapability != undefined ? this.state.lstCapability.filter(item => item.value == tower.CapabilityId) : this.state.lstCapability,
                isUpdateExisting: true
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {

        try {
            this.setState({ IsActive: event.target.checked });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnCancel() {

        try {
            this.setState({
                CapabilityValue: [],
                isEdit: 1,
                errors: '',
                IsActive: false,
                value: '',
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                TowerName: '',
                Description: '',
                searchTower: '',
                TowerId: 0
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.TowerGridData != undefined ? this.state.TowerGridData.filter(Tower => Tower.GroupID == parseInt(this.props.groupId)
                    ) : this.state.TowerGridData;
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.TowerGridData != undefined ? this.state.TowerGridData.filter(Tower => regex.test(Tower.TowerName) && Tower.GroupID == parseInt(this.props.groupId)
                ) : this.state.TowerGridData;
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        try {
            this.setState({
                value: newValue
            });
            $(event.target).attr('type', 'new');
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        try {
            this.setState({
                suggestions: this.getSuggestions(value)
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsClearRequested = () => {
        try {
            this.setState({
                suggestions: []
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionSelected(event, { suggestion }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event) {
        try {
            let type = $(event.target).attr('type');
            if (type == "new") {

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value
            });
            if (value) {
                this.SaveTowerDetails(value);
            } else {
                this.SaveTowerDetails();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    render() {

        var columnDefs = [];
        columnDefs = [
             { headerName: i18n.t('edit'), field: '', cellRenderer: CreateEditBox,cellRendererParams:{groupId:this.props.groupId}, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('towerId'), field: 'TowerId', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('towerName'), field: 'TowerName', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('capabilityID'), field: 'CapabilityID', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('description'), field: 'Description', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('capabilityName'), field: 'CapabilityName', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('createdBy'), field: 'CreatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('createdDate'), field: 'CreatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('updatedBy'), field: 'UpdatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('updatedDate'), field: 'UpdatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('isActive'), field: 'IsActive', valueFormatter: this.formatActive, suppressMovable: true, suppressResize: true, },

        ];

        var TowerDataGrid = (
            <div style={{ height: '350px' }} className="ag-theme-material grid-div">
                <AgGridReact ref="tower_grid" id="grd_tower"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.TowerGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='true'
                    quickFilterText={this.state.searchTower}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        let { value, suggestions } = this.state;

        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur
        }

        return (
            <div>
                <div className="box">
                    <div className="box-in">
                        {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={"TowerName"} editValue={this.state.TowerName}
                            passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />}
                         <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_towerName">{i18n.t('towerName')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Autosuggest ref="TowerName"
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps} />


                                </div>
                            </div>
                        </div> 
                         <div className="col-lg-5 form-horizontal">

                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_towerCapability" >{i18n.t('capability')} :<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_capabilityName" labelKey="label" onChange={this.SelectCapability.bind(this)} options={this.state.lstCapability} selected={this.state.CapabilityValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('selectPlace')} />

                                </div>
                            </div>

                        </div> 
                         <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_towerDescription">{i18n.t('description')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <textarea ref="Description" id="txtArea_description" name="Description" data-field="Description" rows="1" className="form-control" placeholder="" type="" onChange={this.onInputChange} value={this.state.Description} />

                                </div>
                            </div>
                        </div> 
                         <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_towerIsActive">{i18n.t('isActive')} </label>
                                <div className="col-sm-8">
                                    <div className="commoncheckbox">
                                        <input ref="IsActive" id="chk_isActive" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} defaultChecked={true} onChange={this.ChangeIsActive} />
                                    </div>
                                </div>
                            </div>
                        </div> 
                         <div className="fright btn-group-md">
                            <a type="button" className="btn btn-success" onClick={this.SaveTowerDetails.bind(this)} >{i18n.t('save')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.OnCancel.bind(this)} >{i18n.t('clear')}</a>
                        </div> 
                        <div className="search-div">
                            <h4 className="fleft">{i18n.t('towerDetails')}</h4>
                            <input type="text" id="txt_searchTower" placeholder={i18n.t('searchTower')} className="fright searchBox" data-field='searchTower' value={this.state.searchTower} onChange={this.onSearch} />
                        </div>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {TowerDataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}

Tower=connect(mapStateToProps)(withRouter(Tower));
export default Tower;