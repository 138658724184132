import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import pmmInner from './images/icn_pmm_inner.png';
import pmmLogo from './images/pmm-logo-home1.png';

class GroupSwitchModal extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show
            }
            this.closePopUp = this.closePopUp.bind(this);
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {
       
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    closePopUp=(groupId)=> {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback(groupId);
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    render() {
       
        return (
            <div>
                <Modal className="switchModal" size="sm" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                   
                    <Modal.Body>
                        <div style={{marginTop:'0px', textAlign:'left'}} className="btns-assesment">
                        
                            <img style={{marginTop:'48px'}} src={pmmInner} />
                            <img style={{marginTop:'48px'}} src={pmmLogo} />
                        <h5 style={{marginTop:'62px',color:'#2844A0'}}><b>Please choose the group you want to login to </b></h5>
                            {
                                this.props.roleAssosciatedUserData.map((obj)=>{
                                    return(
                                        <>
                                        <a  className="col-sm-6" onClick={(groupId)=>this.closePopUp(obj.GroupID)} type="button" className="btn btn-primary">
                                                <span>
                                                    <b>{obj.GroupName}</b><br/>
                                                    <span>Please click here to login to {obj.GroupName}</span>
                                                </span>
                                            </a>
                                        <br/>
                                        <br/>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withRouter(GroupSwitchModal)