/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';


class BenchmarkValidationPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show
        }
        this.closePopUp = this.closePopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        
    }

    componentWillReceiveProps(props) {

        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            window.addEventListener('resize', this.reSizeGrid, true);
            
            this.setState({
                show: this.props.show
            });
            window.addEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {

        this.refs.benchmark_grid.gridOptions.api.sizeColumnsToFit();
    }


    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        var columnDefs = [];
        columnDefs = [
            { headerName:i18n.t('BenchmarkType'), field: 'BenchmarkTypeName', hide: false, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('capabilityName'), field: 'CapabilityName', tooltipField: 'TowerName', suppressMovable: true, suppressResize: true },
            { headerName:i18n.t('towerName'), field: 'TowerName', tooltipField: 'TowerName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('MetricName'), field: 'MetricName', tooltipField: 'MetricName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('IndustryName'), field: 'IndustryName', hide: false, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('RegionName'), field: 'RegionName', hide: false, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('MetricType'), field: 'MetricType', hide: true, suppressMovable: true, suppressResize: true },
            { headerName:i18n.t('UpperQuartileInternalBenchmark'), field: 'InternalBenchmark', hide: true, suppressMovable: true, suppressResize: true },
            {
                headerName: i18n.t('Error'), field: 'ValidationMessage', tooltipField: 'ValidationMessage', hide: false, suppressMovable: true, suppressResize: true, cellStyle: function () {

                 
                    return {
                        color: 'red'
                    };
                }, autoHeight: true
            },

        ];

        var BenchmarkGridData = this.props.benchmarkUploadData;

        var ServiceComponentDataGrid = (
            <div style={{ height: '350px' }} className="ag-theme-material">
                <AgGridReact ref="benchmark_grid" id="grd_Benchmark"
               
                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs}
                    rowData={BenchmarkGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    localeText={AgGridLocale()}

                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )
        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('UploadFailed')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="btn-group-md pull-right">
                            <button type="button" id="btn_download" className="btn btn-danger" onClick={this.props.downloadInvalidBenchmark}>{i18n.t('download')}</button>
                        </div>
                        <div className="box-plain pmm-asmt-tbl BOI-div">
                            {ServiceComponentDataGrid}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_close" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(BenchmarkValidationPopup)