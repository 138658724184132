/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from '../EditConfirmation';
import { withRouter } from 'react-router';
import $ from 'jquery';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect } from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.IndustryName;

const renderSuggestion = suggestion => <span>{suggestion.IndustryName}</span>;

var IndustryData = []

function CreateEditBox(params) {
    try {
        let eDiv = document.createElement("div");
        let groupId=params.colDef.cellRendererParams.groupId;
        if (params.data.GroupID == parseInt(groupId)){
            eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
        }
        else {
            eDiv.innerHTML = ``;
        }
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class Industry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IndustryGridData: [],
            isEdit: 1,
            errors: {},
            selectedRow: {},
            value: '',
            suggestions: [],
            IndustryName: '',
            IndustryID: 0,
            Description: '',
            isEditPopUp: false,
            IsConfirmUpdate: false,
            isUpdateExisting: false,
            IsActive: true

        };
        this.ChangeIsActive = this.ChangeIsActive.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.populateValues = this.populateValues.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.SaveIndustryDetails = this.SaveIndustryDetails.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.passValue = this.passValue.bind(this);
        this.formatActive = this.formatActive.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    componentWillMount() {
        try {
            
            this.setState({ IndustryGridData: IndustryData });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            $("#loader-div").hide();
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            var objThis = this;
            var SearchedString = " ";
            params.api.showLoadingOverlay();
            request("/MasterScreens/GetIndustry?IndustryID=0&GroupID="+this.props.groupId+ "&SearchedString=" + SearchedString + "&LanguageID=" +this.props.languageId,'GET')
                .then(res => {if(res.length!==0){
                    params.api.hideOverlay();
                    objThis.setState({ IndustryGridData: res });
                    $("#loader-div").hide();
                }
                else{
                    params.api.showNoRowsOverlay();
                }
                   
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('edit')) {
                if (e.data.GroupID == parseInt(this.props.groupId)){
                    this.populateValues(e.data);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(Industry) {
        try {
            this.setState({
                isEdit: 2,
                selectedRow: Industry,
                IndustryID: Industry.OGID,
                IsActive: Industry.IsActive,
                IsDeleted: Industry.IsDeleted,
                value: Industry.IndustryName,
                IndustryName: Industry.IndustryName,
                Description: Industry.Description,
                isUpdateExisting: true,
                LanguageID:this.props.languageId
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {
        this.setState({ IsActive: event.target.checked });
        this.props.editFun(true);
    }

    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnCancel(event) {
        try {
            this.setState({
                isEdit: 1,
                errors: '',
                IsActive: false,
                value: '',
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                IndustryName: "",
                Description: "",
                searchIndustry: "",
                IndustryID: 0

            });
            this.props.editFun(false);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-MC-3", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
            window.addEventListener('resize', this.reSizeGrid, true);
            this.FetchConnIndustryGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            if (this.refs == {}) {
                this.state.IndustryGridData.api.sizeColumnsToFit();
            }
            else {
                this.refs.industry_grid.gridOptions.api.sizeColumnsToFit();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async FetchConnIndustryGrid() {
        try {
            var objThis = this;
            var SearchedString = " ";
            await request("/MasterScreens/GetIndustry?IndustryID=0&GroupID="+this.props.groupId+ "&SearchedString=" + SearchedString + "&LanguageID=" +this.props.languageId,'GET')
                .then(res => {
                    objThis.setState({ IndustryGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveIndustryDetails(eve) {
        try {
            const obj = this;
            let { isEdit, IndustryID, IndustryName, IsActive, IsDeleted, value, Description } = obj.state;
            let Operation = eve && IndustryID != 0 ? 2 : 1;
            let industrynamevalidation = inputValidator(value);
            let descriptionvalidation = inputValidator(Description);

            
         if(industrynamevalidation===false && descriptionvalidation===false ){
            if (obj.IsFormValid()) {
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && IndustryName != "") {
                    this.setState({
                        isEditPopUp: true
                    })
                    return alert(i18n.t("updateExistingorCreateNew"));
                }


                const requestData = obj.state.selectedRow;
                requestData["UserName"] =this.props.enterpriseId;
                requestData["Operation"] = Operation;
                requestData["IndustryID"] = eve ? IndustryID : 0;
                requestData["IndustryName"] = value;
                requestData["Description"] = Description;
                requestData["IsActive"] = IsActive;
                requestData["IsDeleted"] = IsDeleted;
                requestData["GroupID"] =this.props.groupId;
                requestData["LanguageID"] = this.props.languageId;


                request('/MasterScreens/UpsertIndustry', 'POST', requestData)
                    .then(res => {
                        if (res.Outputcode != -1) {

                            alert(res["outputmessage"]);
                            obj.FetchConnIndustryGrid();
                            obj.OnCancel();
                            obj.props.editFun(false);
                        }
                        else {
                            alert(res["outputmessage"]);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
            }
        }
        else{
            alert(i18n.t("PleaseEntervalidValuesintheFields"));

        }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};
            let reg = /^[A-Za-z\d\s]+$/;
            let regForAlphabets = /^[a-zA-Z]+$/;


            if (!this.refs.IndustryName.input.value) {
                errors.OGName = i18n.t('pleaseEnterIndustryName');
                alert(i18n.t('pleaseEnterIndustryName'));
                formIsValid = false;
            }

            if (!this.refs.Description.value && formIsValid == true) {
                errors.Description = i18n.t('pleaseEnterIndustryDescription');
                alert(i18n.t('pleaseEnterIndustryDescription'));
                formIsValid = false;
            }

            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.IndustryGridData.filter(Industry => Industry.GroupID == parseInt(this.props.groupId));
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.IndustryGridData.filter(Industry => regex.test(Industry.IndustryName) && Industry.GroupID == parseInt(this.props.groupId));
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        try {
            this.setState({
                value: newValue
            });
            $(event.target).attr('type', 'new');
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        try {
            this.setState({
                suggestions: this.getSuggestions(value)
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });

    };

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event, { suggestion }) {
        try {
            let type = $(event.target).attr('type');
            let value = event.target.value.toLowerCase();
            this.state.IndustryGridData.forEach(ind => {
                if (ind.IndustryName.toLowerCase() == value) {
                    if (ind.GroupID == parseInt(this.props.groupId)){
                        this.populateValues(ind);
                    }
                }
                return ind;
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        this.setState({
            isEditPopUp: false
        })
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value
            });
            if (value) {
                this.SaveIndustryDetails(value);
            } else {
                this.SaveIndustryDetails();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }

    render() {
        var columnDefs = [];
        columnDefs = [
             { headerName: i18n.t('edit'), field: '', cellRenderer: CreateEditBox,cellRendererParams:{groupId:this.props.groupId}, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('industryId'), field: 'IndustryId', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('industryName'), field: 'IndustryName', tooltipField: 'IndustryName', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('description'), field: 'Description', tooltipField: 'Description', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('createdBy'), field: 'CreatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('createdDate'), field: 'CreatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('updatedBy'), field: 'UpdatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('updatedDate'), field: 'UpdatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('isActive'), field: 'IsActive', valueFormatter: this.formatActive, suppressMovable: true, suppressResize: true, },

        ];



        var IndustryDataGrid = (
            <div style={{ height: '400px' }} className="ag-theme-material grid-div">
                <AgGridReact ref="industry_grid" id="grid_industry"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs}
                    rowData={this.state.IndustryGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='true'
                    quickFilterText={this.state.searchIndustry}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )
        let { value, suggestions } = this.state;

        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur
        }
        return (
            <div>
                <div className="box">
                    <div className="box-in">
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_industryName" className="control-label col-sm-4">{i18n.t('industryName')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Autosuggest ref="IndustryName"
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps} />


                                </div>
                            </div>
                            {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={'IndustryName'} editValue={this.state.IndustryName}
                                passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />}
                        </div>
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_description" className="control-label col-sm-4">{i18n.t('description')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <textarea id="txtArea_description" ref="Description" name="Description" data-field="Description" rows="1" className="form-control" placeholder="" type="" onChange={this.onInputChange} value={this.state.Description} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_isActive " className="control-label col-sm-2">{i18n.t('isActive')} </label>
                                <div className="col-sm-8">
                                    <div className="commoncheckbox">
                                        <input id="txt_isActive" ref="IsActive" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} defaultChecked={true} onChange={this.ChangeIsActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-group-md fright">
                            <a type="button" className="btn btn-success" onClick={this.SaveIndustryDetails} >{i18n.t('save')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.OnCancel} >{i18n.t('clear')}</a>
                        </div>
                        <div className="search-div">
                            <h4 className="fleft">{i18n.t('industryDetails')}</h4>
                            <input id="txt_searchIndustry" type="text" placeholder={i18n.t('searchIndustry')} className="fright searchBox" data-field='searchIndustry' value={this.state.searchIndustry} onChange={this.onSearch} />
                        </div>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {IndustryDataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        pageName:state.pageName,
        roleName:state.roleName,
        groupName:state.groupName
    }
}
Industry=connect(mapStateToProps)(withRouter(Industry));
export default Industry;