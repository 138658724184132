/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import NavigationConfirmation from '../../../../PMM/NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../../../images/icon-back-arrow.png';
import addButtonIcon from '../../../../images/icon-add-btn.png';
import request from '../../../../services/Service';
import { AgGridLocale } from '../../../../Utils/AgGridLocale';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { inputValidator } from '../../../../Utils/InputElementValidation';

var newCount = 1;
var editActive = false;
var isDeSelectAll = false;
function CreateDelete(params) {
    try {

        if (params.data.UniqueEffortId == "") {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = '<span class="glyphicon glyphicon-trash"></span>';
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
    }
    catch (error) {
        console.log("Error Encountered");
    }
}
class Effort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditAcitve: false,
            EffortGridData: [],
            selectedCount: 0,
            pageNames: [],
            CapabilityValue: [],
            TowerValue: [],
            ServiceCompValue: [],
            deleteStatus: false,
            numericStatus: false
        }
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.checkCells = this.checkCells.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.markSelected = this.markSelected.bind(this);
    }

    componentDidMount() {
        debugger;        
        //window.tfo_event("PMM-MC-20", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
        try {
            window.addEventListener('resize', this.reSizeGrid, true);
            this.setState({
                CapabilityValue: this.props.configValues.capability,
                TowerValue: this.props.configValues.tower,
                ServiceCompValue: this.props.configValues.serviceComponent,
                pageNames: this.props.pageName
            });

            var SCID = this.props.configValues.serviceComponent[0].value;
            var SearchedString = "";
            let EffortConfigData = {
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
                EffortConfigID: 0,
                SCID: SCID,
                IsSelected: 1,
                SearchedString: SearchedString,
            }
            request("/PMM/GetEffortConfig", 'POST', EffortConfigData)
                .then(response => {
                    let effortList = response != "Data not there" ? response : [];
                    if (response.length > 0) {
                        var result = response.filter(function (obj) {
                            return (obj.EffortDescription === "");
                        });
                        if (result.length > 0) {
                            this.setState({ deleteStatus: true });
                        }
                    }

                    debugger;
                    this.setState({ EffortGridData: effortList });
                    this.markSelected(effortList);
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    componentWillReceiveProps(props, nextProps) {
        try {
            this.setState({
                CapabilityValue: this.props.configValues.capability,
                TowerValue: this.props.configValues.tower,
                ServiceCompValue: this.props.configValues.serviceComponent
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        this.refs.Grd_Effort.gridOptions.api.sizeColumnsToFit();
    }

    onOverallEdit(e) {

    }

    checkCells() {
        try {
            editActive = true;
            let objGridDetails = this.refs.Grd_Effort.gridOptions.rowData;
            var numericStatus = true;
            var isErrorThere = false;
            var NumericAndBlank = false;
            objGridDetails.map(effRow => {
                var numericValue = effRow.IndicativeValue;

                !isNaN(parseFloat(numericValue)) && isFinite(numericValue) ? numericStatus = true : numericStatus = false;

                if (effRow.EffortDescription == "" || effRow.EffortDescription == null) {
                    isErrorThere = true;
                }
                if (effRow.Units == "" || effRow.Units == null) {

                    isErrorThere = true;
                }
                if (effRow.IndicativeValue == "" || effRow.IndicativeValue == null) {
                    isErrorThere = true;

                }

            });

            if (isErrorThere == true) {
                alert(i18n.t('pleaseEnterEffortFields'));
                $("#loader-div").hide();
                isErrorThere = true;
                NumericAndBlank = isErrorThere;
            } else {
                if (numericStatus == false) {
                    alert(i18n.t('numericValueForIndicative'));
                    $("#loader-div").hide();
                    numericStatus = false;
                    NumericAndBlank = numericStatus;
                }
            }

            this.setState({
                EffortGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.Grd_Effort.gridOptions.api.refreshCells(params);

            return NumericAndBlank;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            editActive = true;
            if (params.colDef.field == "IndicativeValue") {
                var numStatus = false;
                !isNaN(parseFloat(params.value)) && isFinite(params.value) ? numStatus = true : numStatus = false;
                if (numStatus == false) {
                    alert(i18n.t('numericValueForIndicative'));
                    this.refs.Grd_Effort.gridOptions.api.setFocusedCell(params.rowIndex, 'IndicativeValue');
                    return false;
                }

            }

            let effdetails = this.refs.Grd_Effort.gridOptions.rowData;
            this.markSelected(effdetails);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveEffData = (e) => {

        try {
            this.refs.Grd_Effort.gridOptions.api.stopEditing();
            let evtSrc = e.target.text.trim();
            let SCID = this.props.configValues.serviceComponent[0].value;
            var EffortGridData = [];
            this.refs.Grd_Effort.gridOptions.api.forEachNode(function (node) {
                node.data.SCID = SCID;
                EffortGridData.push(node.data);
            });

            let rowsCount = this.refs.Grd_Effort.gridOptions.rowData;
            let rowslength = rowsCount.length;
            var isError = true;
            isError = this.checkCells();
            if (rowslength > 0 && isError == false) {
                var EffortConfig = {
                    "effortDetails": EffortGridData
                };
                this.setState({
                    isEditAcitve: false
                })
                $("#loader-div").show();
                var SearchedString = "";
                var invalid = false;
                for (var i = 0; i < EffortGridData.length; i++) {
                    var ValidUnits = inputValidator(EffortGridData[i].Units);
                    var ValidDescription = inputValidator(EffortGridData[i].EffortDescription);
                    if (ValidUnits || ValidDescription) {
                        invalid = true;
                    }
                }

                if (!invalid) {
                    request("/PMM/SaveEffortConfig?GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'POST', EffortConfig)
                        .then(res => {
                            if (res.outputmessage == i18n.t('success')) {
                                alert(res.outputmessage);
                                if (evtSrc != i18n.t('save')) {
                                    this.props.history.push("/ProductivityCalculator");
                                } else {
                                    let EffortConfig = {
                                        UserName: this.props.enterpriseId,
                                        LanguageID: this.props.languageId,
                                        EffortConfigID: 0,
                                        SCID: SCID,
                                        IsSelected: 1,
                                        SearchedString: SearchedString,
                                    }
                                    request("/PMM/GetEffortConfig", 'POST', EffortConfig)
                                        .then(response => {
                                            let effortList = response != null ? response : [];
                                            if (response.length > 0) {
                                                var result = response.filter(function (obj) {
                                                    return (obj.EffortDescription === "");
                                                });
                                                if (result.length > 0) {
                                                    this.setState({ deleteStatus: true });
                                                }
                                            }
                                            this.setState({ EffortGridData: effortList });
                                            this.markSelected(effortList);
                                            editActive = false;
                                        })
                                        .catch(error => {
                                            console.log("Error Encountered");
                                            $("#loader-div").hide();
                                        });
                                }
                                $("#loader-div").hide();
                            } else if (res.Outputcode == -1) {
                                alert(res.outputmessage);
                            }

                            this.refs.Grd_Effort.gridOptions.api.sizeColumnsToFit();
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                            $("#loader-div").hide();
                        });
                }
                else {
                    alert(i18n.t("PleaseEntervalidValuesintheFields"));
                    $("#loader-div").hide();
                }

            } else if (rowslength == 0) {
                alert(i18n.t('addOneEffort'));
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkSelected() {
        debugger;
        try {
            let count = this.refs.Grd_Effort.gridOptions.api.getSelectedRows().length;
            if (this.state.selectedCount != 0 && this.state.selectedCount != count) {
                editActive = true;
            }
            this.setState({
                selectedCount: count,
                isEditAcitve: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onAddRow() {
        debugger;
        try {
            var newItem = this.CreateNewRowData();
            let currentGridData = this.refs.Grd_Effort.gridOptions.rowData;
            currentGridData.push(newItem);
            this.refs.Grd_Effort.gridOptions.api.setRowData(currentGridData);
            this.setState({
                deleteStatus: true
            });
            setTimeout(() => {
                this.refs.Grd_Effort.gridOptions.api.sizeColumnsToFit();
            }, 100);
            //timerid;
            this.markSelected(currentGridData);
            editActive = true;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    CreateNewRowData() {

        try {

            var newData = {
                CapabilityID: "",
                CapabilityName: "",
                CreatedBy: this.props.enterpriseId,
                CreatedDate: null,
                EffortConfigId: 0,
                EffortDescription: "",
                EffortRowId: 0,
                IndicativeValue: "",
                IsActive: true,
                IsDeleted: false,
                SCId: this.props.configValues.serviceComponent[0].value,
                SCName: this.props.configValues.serviceComponent[0].label,
                TowerId: "",
                TowerName: "",
                UniqueEffortId: "",
                Units: "",
                loggedInUserId: this.props.enterpriseId,
                updatedBy: this.props.enterpriseId,
                updatedDate: null
            };

            newCount++;
            return newData;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onRowSelect(eve) {
        debugger;
        try {
            eve.node.data["IsActive"] = eve.node.selected ? true : false;

            if (eve.node.selected) {
                this.sortBySelect();
                isDeSelectAll = false;
            } else if (!isDeSelectAll) {
                let validateEffortData = {
                    UserName: this.props.enterpriseId,
                    GroupID: this.props.groupId,
                    LanguageID: this.props.languageId,
                    BOILIId: 0,
                    SCID: this.state.ServiceCompValue[0].value,
                }
                request("/PMM/ValidateEffort", 'POST', validateEffortData)
                    .then(res => {
                        debugger;
                        $("#loader-div").hide();
                        if (res.outputmessage == i18n.t('success')) {
                            let params = {
                                force: false,
                                rowNodes: [eve.node]
                            }
                            this.refs.Grd_Effort.gridOptions.api.refreshCells(params);
                        } else {
                            eve.node.setSelected(true, false);
                            eve.node.data["IsSelected"] = true;
                            alert(res.outputmessage);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    sortBySelect() {
        try {
            let sort = [{
                colId: 'IsSelected',
                sort: 'desc'
            }]
            this.refs.Grd_Effort.gridOptions.api.setSortModel(sort);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('delete') && e.data.UniqueEffortId == "") {
                if (confirm(i18n.t('deleteConfirmation'))) {
                    let EFFConfigDetails = this.refs.Grd_Effort.gridOptions.rowData;
                    EFFConfigDetails.splice(e.rowIndex, 1);
                    var result = EFFConfigDetails.filter(function (obj) {
                        return (obj.UniqueEffortId === "");
                    });
                    this.refs.Grd_Effort.gridOptions.api.setRowData(EFFConfigDetails);

                    if (result.length == 0) {
                        this.setState({ deleteStatus: false });
                    }
                    this.markSelected(EFFConfigDetails);
                    this.refs.Grd_Effort.gridOptions.api.sizeColumnsToFit();
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    markSelected(effortArr) {
        try {
            this.refs.Grd_Effort.gridOptions.api.forEachNode((node) => {
                if (node.data.IsActive) {
                    node.setSelected(true, false);
                }

            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == "User Management"){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "Deal Management"){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == "PMM Configuration"){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (editActive) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            } else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        this.setState({
            popUpDisplay: false
        })
    }



    gridReadyFun(eve) {
        eve.api.sizeColumnsToFit();
    }

    Clear() {
        try {
            if (this.props.groupId
                == this.props.configValues.serviceComponent[0].GroupID) {
                this.setState({
                    isEditAcitve: false
                });
                editActive = false;
                this.refs.Grd_Effort.gridOptions.api.deselectAll();
                isDeSelectAll = true;
                let params = {
                    force: true
                }
                this.refs.Grd_Effort.gridOptions.api.refreshCells(params);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SkipToPMMConfig() {
        if (confirm(i18n.t('sureSkipToPMM'))) {
            this.props.history.push("/PMMConfig");
        }
    }

    render() {
        let { t } = this.props;
        let isHidden = (this.props.groupId
            == this.props.configValues.serviceComponent[0].GroupID) ? true : false;

        var columnDefs = [];
        if (this.state.deleteStatus == false) {
            columnDefs = [{ headerName: t('effortDescription'), field: "EffortDescription", suppressMovable: true, suppressResize: true, editable: isHidden },
            { headerName: t('units'), field: "Units", suppressMovable: true, suppressResize: true, editable: isHidden },
            { headerName: t('indicativeEffortValue'), field: "IndicativeValue", suppressMovable: true, suppressResize: true, editable: isHidden },
            { headerName: t('uniqueID'), field: "UniqueEffortId", suppressMovable: true, suppressResize: true },
            {
                headerName: t('selectAll'), checkboxSelection: true, suppressMenu: true, suppressSorting: true, hide: !isHidden, headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, suppressResize: true, autoHeight: true, width: 100
            },
            { headerName: t('isSelected'), field: 'IsSelected', hide: true, suppressMovable: true, suppressResize: true, autoHeight: true }
            ];
        } else if (this.state.deleteStatus == true) {
            columnDefs = [{ headerName: t('effortDescription'), field: "EffortDescription", suppressMovable: true, suppressResize: true, editable: isHidden },
            { headerName: t('units'), field: "Units", suppressMovable: true, suppressResize: true, editable: isHidden },
            { headerName: t('indicativeEffortValue'), field: "IndicativeValue", suppressMovable: true, suppressResize: true, editable: isHidden },
            { headerName: t('uniqueID'), field: "UniqueEffortId", suppressMovable: true, suppressResize: true },
            { headerName: t('selectAll'), headerCheckboxSelection: true, checkboxSelection: true, suppressMenu: true, suppressSorting: true, suppressMovable: true, suppressResize: true, autoHeight: true, hide: !isHidden },
            { headerName: t('delete'), suppressMovable: true, suppressResize: true, cellRenderer: CreateDelete, width: 100, hide: !isHidden }
            ];
        }


        var EffDataTable = this.state.EffortGridData;
        debugger;
        var EffortDataGrid = (
            <div className="ag-theme-material grid-div custom-select">
                <AgGridReact ref="Grd_Effort" enableSorting="false" id="grd_Effort"
                    paginationPageSize="10"
                    enableColResize={false}
                    singleClickEdit={true}
                    columnDefs={columnDefs}
                    rowData={EffDataTable}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    headerCheckboxSelection={true}
                    headerCheckboxSelectionFilteredOnly={true}
                    checkboxSelection={true}
                    stopEditingWhenGridLosesFocus={true}
                    suppressRowClickSelection={true}
                    suppressScrollOnNewData={true}
                    onCellClicked={this.onCellClicked.bind(this)}
                    onRowSelected={this.onRowSelect}
                    onSelectionChanged={this.checkSelected.bind(this)}
                    onGridReady={this.gridReadyFun.bind(this)}
                    onCellEditingStarted={this.onOverallEdit.bind(this)}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span className="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <ul className="pagecounter"  >
                        <li><span title="BOI" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><a data-url="/BOIConfiguration" data-content="BOI Configuration" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="LEADING INDICATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="LEADING INDICATOR"><a data-url="/LIConfiguration" data-content="LI Configuration" onClick={this.onNavigationClick}></a></span></li>
                        <li><span title="EFFORT" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="EFFORT">...</span></li>
                        <li><span title="PRODUCTIVITY CALCULATOR" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PRODUCTIVITY CALCULATOR">3</span></li>
                        <li><span title="PMM CONFIG" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM CONFIG">4</span></li>
                    </ul>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            <li className="active">
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                        navigationPage={this.state.navigationPage} isEditAcitve={editActive}
                        url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                </div>
                <section className="bodydata multiple-tabs-section">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <div className="">
                                <h4>
                                    <span className="statustxt-orng-italic" title="Capability">{this.props.configValues.capability != [] ? this.props.configValues.capability[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Tower">{this.props.configValues.tower != [] ? this.props.configValues.tower[0].label : []}</span> &nbsp;&nbsp;||
                                    <span className="statustxt-orng-italic" title="Service Component">{this.props.configValues.serviceComponent != [] ? this.props.configValues.serviceComponent[0].label : []}</span>
                                </h4>
                            </div>
                            <div className="row">
                                <h4 className="grid-title col-md-2">
                                    {i18n.t('effort')}
                                    <span className="badge">{this.state.selectedCount}</span>
                                </h4>
                                <button id="btn_ADD" onClick={this.onAddRow.bind(this)} type="button" className="btn btn-default pmmConfigAdd pull-right" disabled={!isHidden}>
                                    <img src={addButtonIcon} />{i18n.t('add')}
                                </button>
                            </div>
                            <div className="AsmtData viewprevassmt box-plain">
                                {EffortDataGrid}
                                {
                                    isHidden ?
                                        <div className="btn-group-md">
                                            <a type="button" className="btn btn-warning" onClick={this.SkipToPMMConfig.bind(this)} title="Go to PMM Config screen without configuring effort & productivity Calculations">{i18n.t('skipToPMMConfig')}</a>
                                            <a type="button" className="btn btn-danger" onClick={this.SaveEffData.bind(this)}>{i18n.t('save')}</a>
                                            <a type="button" className="btn btn-info" onClick={this.Clear.bind(this)}>{i18n.t('clear')}</a>

                                            <a type="button" className="btn btn-success" onClick={this.SaveEffData.bind(this)}>{i18n.t('configureProductivity')}</a>
                                        </div>
                                        :
                                        <div className="btn-group-md">
                                            <a type="button" className="btn btn-warning" onClick={this.SkipToPMMConfig.bind(this)} title="Go to PMM Config screen without configuring effort & productivity Calculations">{i18n.t('skipToPMMConfig')}</a>
                                            <Link type="button" className="btn btn-danger" to="/LIConfiguration">{i18n.t('previous')}</Link>
                                            <Link type="button" className="btn btn-success" to="/ProductivityCalculator">{i18n.t('next')}</Link>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}
function mapStateToProps(state) {
    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName

    }
}


Effort = connect(mapStateToProps)(withTranslation()(Effort));
export default withRouter(Effort);