
/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import {connect} from 'react-redux';

var editActive = false;
var UserdealIdArray = [];
var primaryfilterArrayone = [];


function CreateCheckbox() {
    try {
        this.eGui = document.createElement("div");
        this.eGui.width = "100%";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}
CreateCheckbox.prototype.init = function (params) {
    debugger;
    try {
        var checkboxElement = document.createElement("input");
        checkboxElement.type = "checkbox";
        checkboxElement.id = params.data.UserDealmappingId;
        checkboxElement.className = "findColumns";
        checkboxElement.checked = params.data.IsSelected;
        checkboxElement.addEventListener('change', function (event) {

            editActive = true;

            params.api.gridOptionsWrapper.gridOptions.rowData.map((eachRow, index) => {
                if (index == params.rowIndex) {
                    eachRow["IsSelected"] = event.currentTarget.checked
                    if (event.currentTarget.checked) {
                        UserdealIdArray.push(eachRow.UserDealmappingId)
                        primaryfilterArrayone.push(eachRow.PrimaryFilterName)
                        }
                    else {
                        var indexUser = UserdealIdArray.indexOf(eachRow.UserDealmappingId)
                        UserdealIdArray.splice(indexUser, 1);
                        var indexPrimary = primaryfilterArrayone.indexOf(eachRow.PrimaryFilterName)
                        primaryfilterArrayone.splice(indexPrimary, 1);
                       
                    }
                }
            })

        });

        this.eGui.appendChild(checkboxElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}
CreateCheckbox.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateCheckbox.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateCheckbox.prototype.onChange = function () {

};

class AccessTransferPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            userList: [],
            userSelected: [],
            userSelectedTwo: [],
            dealGridDt: [],
            dealGriddatatwo: [],
            selectedMetric: "",
            primaryfiltertwo: [],
            accessTransferFlag: this.props.accessTransferFlag === undefined ? true : this.props.accessTransferFlag
        }
        this.onUserChange = this.onUserChange.bind(this);
        this.SaveAccessTransfer = this.SaveAccessTransfer.bind(this);
        this.onUserChangeCheck = this.onUserChangeCheck.bind(this);
        this.clearInputs = this.clearInputs.bind(this);
        this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
    }
    componentWillReceiveProps(props) {
        debugger;
        try {
            this.setState({
                show: this.props.show,
                accessTransferFlag: this.props.accessTransferFlag === undefined ? true : this.props.accessTransferFlag
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            if (this.props.arr) {
                UserdealIdArray = [];
                primaryfilterArrayone = [];
            }
            this.setState({
                accessTransferFlag: this.props.accessTransferFlag === undefined ? true : this.props.accessTransferFlag
            });
            window.addEventListener('resize', this.reSizeGrid, true);
            this.loadUsers();
            this.loadDropDownValues();
            let dealData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "DealID": 0,
                "PrimaryFilter": 1,
                "OGID": 0,
                "DLID": 0,
            }
            request("/Common/GetDealForUserMapping", 'POST', dealData)
                .then(response => {

                    this.setState({
                        dealList: response[0]
                    });
                    $("#loader-div").hide();
                    //$("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    loadUsers() {

        try {
            $("#loader-div").show();
           // $("#loader-div").show();
            let userData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "UserID": 0,
                "RoleID": 0,
            }
            request("/Common/GetUserDetails", 'POST', userData)
                .then(response => {
                    $("#loader-div").hide();
                   // $("#loader-div").hide();
                    let fullData = response;
                    let userArr = [];
                    fullData.map((user) => {
                        if (user.IsActive) {
                            let userObj = {}
                            userObj = {
                                label: user.EnterpriseID,
                                value: user.UserID,
                                roleId: user.RoleId,
                                roleName: user.RoleName
                            }
                            userArr.push(userObj);

                        }
                    })
                    if (this.props.base && this.props.base == "Home") {
                        const currentUserObj = userArr.filter(item => item.label == this.props.enterpriseId)
                        this.onUserChange(currentUserObj[0])
                    }

                    this.setState({
                        userList: userArr,
                        lstDlvryLoc: [],
                        DlvryLocValue: ""
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onCellClicked(params) {

        try {
            if (params.colDef.field == "UserDealmappingId") {
                this.setState({
                    popUpBenchmark: true,
                    selectedMetric: params.data.UserDealmappingId
                })
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    clearInputs() {
        try {
            if (this.props.base != "Home") {
                this.setState({
                    userSelected: [],
                    userSelectedTwo: [],
                    dealSelected: "",
                    lstDlvryLoc: [],
                    DlvryLocValue: "",
                    rolenamecheck: "",
                    roleName: "",
                    capabilitySelected: [],
                    towerSelected: [],
                    IsActive: true,
                    isDealSelected: false,
                    IsEditActive: false,
                    isUnSaved: false,
                    isUpdateExisting: false,
                    dealGridData: [],
                    RoleName: "",
                    RoleID: "",
                    primaryFilterValue: "",
                    OGValue: "",
                    dealOptions: [],
                    OGOptions: [],
                    capabilityOptions: [],
                    towerOptions: [],
                    isSubmitted: true,
                    isSaved: false,
                    isTakeAssessment: false,
                    isSubmittedEnable: false,
                    isSavedEnable: false,
                    isTakeAssessmentEnable: false,
                    viewStatus: 1,
                    isReport: false,
                    dealGridDt: []
                });
                document.getElementById('rolename').textContent = '';
                UserdealIdArray = [];
                primaryfilterArrayone = [];
            }
            else {
                this.setState({
                    userSelectedTwo: [],
                    rolenamecheck: "",
                    IsActive: true,
                    isDealSelected: false,
                    IsEditActive: false,
                    isUnSaved: false,
                    isUpdateExisting: false,
                    OGValue: "",
                    isSubmitted: true,
                    isSaved: false,
                    isTakeAssessment: false,
                    isSubmittedEnable: false,
                    isSavedEnable: false,
                    isTakeAssessmentEnable: false,
                    viewStatus: 1,
                    isReport: false
                });
                UserdealIdArray = [];
                this.refs.deal_user_grid.gridOptions.api.forEachNode(function (node) {
                    node.data.IsSelected = false;
                });
                let params = {
                    force: true
                }
                this.refs.deal_user_grid.gridOptions.api.refreshCells(params);
            }
            document.getElementById('rolenamecheck').textContent = '';

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    loadDeal(userName) {
        try {
            UserdealIdArray = [];
            primaryfilterArrayone = [];
            let userData = {
                "UserName": userName,              
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
            }
            request("/MasterScreens/GetUSerDealDetails", 'POST', userData)

                .then(response => {
                    $("#loader-div").hide();
                    // $("#loader-div").hide();

                    if (response == "Data not there") {
                        this.setState({
                            dealGridDt: []
                        });
                    }
                    else {
                        this.setState({
                            dealGridDt: response
                        });
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
                $("#loader-div").show();
           // $("#loader-div").show();

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onUserChange(value) {

        try {
            if (this.state.userSelectedTwo.label == value[0].label) {
                alert(i18n.t("theenterpriseidshouldnotbesame"))
            }
            else {

                this.setState({ RoleName: "" });
                if (value[0].roleId != 1 && value[0].roleId != 2) {
                    this.loadDeal(value[0].label);
                    this.setState({
                        userSelected: value,
                        isUnSaved: true,
                        capabilitySelected: [],
                        towerSelected: [],
                        RoleName: value[0].roleName,
                        RoleID: value[0].roleId,
                        dealSelected: [],
                        OGValue: [],
                        DlvryLocValue: [],
                        primaryFilterValue: []
                    });
                    if (value[0].roleId == 3 || value[0].roleId == 4) {
                        this.setState({
                            isSavedEnable: false,
                            isTakeAssessmentEnable: false
                        });
                    }
                    else if (value[0].roleId == 5) {
                        this.setState({
                            isSavedEnable: false,
                            isTakeAssessmentEnable: true,
                            isTakeAssessment: false
                        });
                    }
                    document.getElementById('rolename').textContent = value[0].roleName;
                } else {
                    alert(i18n.t('userRoleIs') + value[0].roleName + ". " + i18n.t('AdminSupermapdeals') + ".");
                }

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }
    onUserChangeCheck = value => {

        try {
            if (this.state.userSelected.label == value[0].label) {
                alert(i18n.t("theenterpriseidshouldnotbesame"))
            }
            else {

                this.setState({
                    RoleName: ""
                });
                if (value[0].roleId != 1 && value[0].roleId != 2) {
                    this.loadDealCheck(value[0].label);
                    this.setState({
                        userSelectedTwo: value,
                        isUnSaved: true,
                        capabilitySelected: [],
                        towerSelected: [],
                        RoleName: value[0].roleName,
                        RoleID: value[0].roleId,
                        dealSelected: [],
                        OGValue: [],
                        DlvryLocValue: [],
                        primaryFilterValue: []
                    });

                    if (value[0].roleId == 3 || value[0].roleId == 4) {
                        this.setState({
                            isSavedEnable: false,
                            isTakeAssessmentEnable: false
                        });
                    }
                    else if (value[0].roleId == 5) {
                        this.setState({
                            isSavedEnable: false,
                            isTakeAssessmentEnable: true,
                            isTakeAssessment: false
                        });
                    }
                    document.getElementById('rolenamecheck').textContent = value[0].roleName;
                }
                else {
                    alert(i18n.t('userRoleIs') + value[0].roleName + "." + i18n.t('AdminSupermapdeals') + ".");
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    async loadDealCheck(userName) {
        try {
            let userData = {
                "UserName": userName,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
            }
            await request("/MasterScreens/GetUSerDealDetails", 'POST', userData)

                .then(response => {
                    let primaryfilterrow = response.filter((i) => {
                        if (i.IsActive) {
                            return i.PrimaryFilterName;
                        }
                    });

                    this.setState({
                        dealGridDtwo: response,
                        primaryfiltertwo: primaryfilterrow.map((i) => {
                            if (i.IsActive) {
                                return i.PrimaryFilterName;
                            }
                        }),
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    closePopUp() {
        try {
            this.setState({
                show: false,
                accessTransferFlag: false
            });
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    loadDropDownValues() {

        try {
            $("#loader-div").show();
            //$("#loader-div").show();

            let scData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CapabilityId": 0,
                "TowerID": 0,
            }
            request("/Common/GetActiveServiceComponentDetails", 'POST', scData)
                .then(response => {

                    console.log(response);

                    this.setState({ primaryFilterOptions: response[0] });
                    $("#loader-div").hide();
                    //$("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    SaveAccessTransfer() {
        try {
            var gridOne = primaryfilterArrayone;
            var gridTwo = this.state.primaryfiltertwo;
            var checkgrid = true;
            gridOne.forEach((data) => {
                gridTwo.forEach((datatwo) => {
                    if (data != datatwo) {
                        checkgrid = false;
                    }
                    return checkgrid;
                });
                return checkgrid;
            });

            if (this.state.userSelected[0].label != undefined && this.state.userSelectedTwo[0].label != undefined) {
                if (UserdealIdArray.length != 0) {
                    if (this.state.userSelected[0].label == undefined || this.state.userSelectedTwo[0].label == undefined) {
                        alert(i18n.t("enterpriseidshouldnotbeblank"));
                    }
                    else if (this.state.userSelected[0].roleName != this.state.userSelectedTwo[0].roleName) {
                        alert(i18n.t("userRoleisdifferent"));
                    }
                    else if (checkgrid == false) {
                        alert(i18n.t('selectedprimaryfilterisnotsame'));
                    }
                    else {
                        var text = confirm(i18n.t("areyousureyouwanttotransfer"));
                        if (text == true) {

                            var datavalue = {
                                UserID1: this.state.userSelected[0].value,
                                UserID2: this.state.userSelectedTwo[0].value,
                                IDS: UserdealIdArray.join(','),
                                UserName:this.props.enterpriseId,
                                GroupID:this.props.groupId,
                                LanguageID:this.props.languageId
                            }

                            request("/MasterScreens/UserAccessTransfer", 'POST', datavalue)
                                .then(res => {
                                    if(res.Outputcode == -1) {
                                        alert (res.outputmessage) 
                                    }
                                    else {
                                        alert('Success');
                                        if (this.props.base && this.props.base == "Home") {
                                            this.props.closeAccessTransferPopUp(UserdealIdArray.length)
                                        }
                                        else {
                                            this.props.history.push("/DealMapping");
                                            $("#loader-div").hide();
                                            // $("#loader-div").hide();
                                        }
                                    }
                                })
                                .catch(error => {
                                    console.log("Error Encountered");
                                });
                            UserdealIdArray = [];
                            primaryfilterArrayone = [];


                        }
                    }
                }
                else {
                    alert(i18n.t("pleaseselectatleastonecheckbox"));
                }
            }
            else {
                alert(i18n.t("enterpriseidshouldnotbeblank"));
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount(){
        this.setState({
            accessTransferFlag: false
        })
    }

    render() {
        var columnDefs = [];
        let { t } = this.props;
        columnDefs = [
            { headerName: i18n.t('select'), field: 'Select', suppressMovable: true, suppressResize: true, cellRenderer: CreateCheckbox, width: 100, tooltipField: 'errorMsg' },
            { headerName: i18n.t('userName'), field: 'UserName', suppressMovable: true, suppressResize: true, tooltipField: 'User ID' },
            {
                headerName: i18n.t('accessType'),
                cellRenderer: function (params) {

                    if (params.data.PermissionLevel == "1") {
                        return i18n.t('viewSubmittedAssessment');
                    }
                    else if (params.data.PermissionLevel == "2") {
                        return i18n.t('viewSubmittedSavedAssessment');
                    }
                    else if (params.data.PermissionLevel == "3") {
                        return i18n.t('takeAssessments');
                    }
                },
                suppressMovable: true, suppressResize: true, autoHeight: true
            },
            { headerName: i18n.t('primaryFilter'), field: 'PrimaryFilterName', suppressMovable: true, suppressResize: true, tooltipField: 'PrimaryFilterName' },
            { headerName: i18n.t('deal'), field: 'DealName', suppressMovable: true, suppressResize: true, tooltipField: 'DealName', autoHeight: true },
            { headerName: i18n.t('capability'), field: 'CapabilityName', suppressMovable: true, suppressResize: true, tooltipField: 'CapabilityName', autoHeight: true },
            { headerName: i18n.t('tower'), field: 'TowerName', suppressMovable: true, suppressResize: true, tooltipField: 'TowerName', autoHeight: true },
            { headerName: i18n.t('oG'), field: 'OGName', suppressMovable: true, suppressResize: true, tooltipField: 'OGName', autoHeight: true },
            { headerName: i18n.t('deliveryLocation'), field: 'DeliveryLocationName', suppressMovable: true, suppressResize: true, tooltipField: 'DeliveryLocationName', autoHeight: true },
            { headerName: i18n.t('active'), field: 'IsActive', valueFormatter: this.formatActive, suppressResize: true, tooltipField: 'Active', headerTooltip: 'Active' }
        ];
        var dealGriddata = (
            <div style={{ height: '380px' }} className="ag-theme-material">
                <AgGridReact ref="deal_user_grid" id="grid_deal_user"
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={this.state.dealGridDt}
                    enableFilter={true}
                    rowmodeltype="pagination"
                    onGridReady={this.gridReadyFun}
                    quickFilterText={this.state.searchDeal}
                    onCellClicked={this.onCellClicked.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        );


        return (
            <Modal className="modal-dialog-sc" show={this.props.show} onHide={(e) => this.props.closeAccessTransferPopUp(UserdealIdArray.length)} backdrop="static" keyboard={false}>

                <Modal.Header closeButton>
                    <h5><b>{i18n.t('userAccessTransfer')} <span className="infoHeaderContent"></span></b></h5>
                </Modal.Header>

                <Modal.Body>
                    <div className="box">
                        <div id="userCreate" className="box-in">
                            <div className="row">
                                {
                                    this.props.base && this.props.base == "Home" ?
                                        <div className="col-lg-6 form-horizontal">
                                            <div className="form-group">
                                                <label id="lbl_user1EnterpriseID" className="control-label col-sm-4" >{i18n.t('UserEnterpriseID')}<span className="mandatory-cls">*</span></label>
                                                <div className="col-sm-6" style={{ paddingTop: "7px" }}>
                                                    {this.props.enterpriseId
                                                }
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label id="lbl_userRole" className="control-label col-sm-4" >{i18n.t('userRole')}: </label>
                                                <div className="control-label col-sm-6 infoRole"><span id="rolename" name="Rolename">{
                                                this.props.roleName
                                                }</span></div>
                                            </div>
                                        </div> :
                                        <div className="col-lg-6 form-horizontal">
                                            <div className="form-group">
                                                <label id="lbl_User1EnterpriseID" className="control-label col-sm-4" >{i18n.t('UserEnterpriseID')}<span className="mandatory-cls">*</span></label>
                                                <div className="col-sm-6">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="UserName" id="rs_userChange" name="form-field-name" labelKey="label" onChange={this.onUserChange.bind(this)} options={this.state.userList} selected={this.state.userSelected} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label id="lbl_UserRole" className="control-label col-sm-4" >{i18n.t('userRole')}: </label>
                                                <div className="control-label col-sm-6 infoRole"><span id="rolename" name="Rolename"></span></div>
                                            </div>
                                        </div>
                                }
                                <div className="col-lg-6 form-horizontal">
                                    <div className="form-group">
                                        <label id="lbl_user2EnterpriseID" className="control-label col-sm-4" >{i18n.t('UsetwoEnterpriseID')}<span className="mandatory-cls">*</span></label>
                                        <div className="col-sm-6">
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="UserName" id="rs_userChangeCheck" name="form-field-name" labelKey="label" onChange={this.onUserChangeCheck.bind(this)} options={this.state.userList} selected={this.state.userSelectedTwo} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label id="lbl_userRole" className="control-label col-sm-4" >{i18n.t('userRole')}: </label>
                                        <div className="control-label col-sm-2 infoRole"><span id="rolenamecheck" name="Rolenamecheck" ></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-plain pmm-asmt-tbl">
                            {dealGriddata}

                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-group-md ">
                        <input id="txt_transferAndSave" type="button" className="btn btn-success" value={i18n.t("transfer&Save")} onClick={this.SaveAccessTransfer.bind(this)} />
                        <input id="txt_cancel" type="button" className="btn btn-info" value={i18n.t("cancel")} onClick={(e) => this.props.closeAccessTransferPopUp(UserdealIdArray.length)} />
                        <input id="txt_clear" type="button" className="btn btn-danger" value={i18n.t("clear")} onClick={this.clearInputs} />
                    </div>
                </Modal.Footer>

            </Modal>

        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.roleName,
        groupName:state.groupName
    }
}
AccessTransferPopup=connect(mapStateToProps)(withRouter(AccessTransferPopup));
export default AccessTransferPopup