/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';



class PMMEffortTransaction extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                EffortGridData: [],
                show: this.props.show
            }
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.Cancel = this.Cancel.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps() {
        try {
            this.setState({ show: this.props.show })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleClose() {
        try {
            this.setState({ show: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    componentDidMount() {
        try {
            window.addEventListener('resize', this.reSizeGrid, true);

            var PMMProcessID = this.props.propsAssessmentdetails.PMMProcessID;
            var SearchedString="";
            let viewEffortData = {
                EffortTransactionId:0,
                PMMProcessID:PMMProcessID,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            request("/PMM/ViewEffortDetails", 'POST', viewEffortData)           
                .then(response => {
                    let effortList = response != null ? response : [];
                    this.setState({ EffortGridData: effortList });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    reSizeGrid() {
        try {
            this.refs.Grd_Effort.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun(eve) {
        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    Cancel() {
        try {
            this.props.close();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        var columnDefs = [];
        columnDefs = [{ headerName: i18n.t("effortDescription"), field: "EffortDescription", suppressMovable: true, suppressResize: true },
        { headerName: i18n.t("units"), field: "Units", suppressMovable: true, suppressResize: true },
        { headerName: i18n.t("IndicativeEffortValue"), field: "IndicativeValue", suppressMovable: true, suppressResize: true, tooltipField: 'IndicativeValue' },
        { headerName: i18n.t("Dealspecificeffortvalue"), field: "ActualValue", suppressMovable: true, suppressResize: true, tooltipField: 'ActualValue' }
        ];

        var EffDataTable = this.state.EffortGridData;
        var EffortDataGrid = (
            <div className="ag-theme-material grid-div custom-select">
                <AgGridReact ref="Grd_Effort" id="grd_EffortData" enableSorting="false"
                    paginationPageSize="10"
                    enableColResize={false}
                    singleClickEdit={true}
                    columnDefs={columnDefs}
                    rowData={EffDataTable}
                    rowSelection="multiple"
                    rowmodeltype="pagination"
                    stopEditingWhenGridLosesFocus={true}
                    suppressRowClickSelection={true}
                    localeText={AgGridLocale()}
                    onGridReady={this.gridReadyFun.bind(this)}
                   
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <h2>{i18n.t('effort')}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="AsmtData viewprevassmt box-plain">
                        {EffortDataGrid}
                        <div className="btn-group-md">
                            <a type="button" className="btn btn-info" onClick={this.Cancel} >{i18n.t('cancel')}</a>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>)
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
    }
}

PMMEffortTransaction = connect(mapStateToProps)(withRouter(PMMEffortTransaction))
export default PMMEffortTransaction;