/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { inputValidator } from '../../Utils/InputElementValidation';


class ReportOutputDocumentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            reportDocumentGridData: []
        }
        this.closePopUp = this.closePopUp.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.loadReportDocuments = this.loadReportDocuments.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            show: this.props.show
        });
    }
    async loadReportDocuments() {
        try {
            await request("/?PMMProcessDraftID=" + this.props.selectedPMMProcessDraftID + "&PMMProcessID=" + this.props.selectedPMMProcessID + "&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
            .then((response) => {
                    if (response != "") {
                        let reportDocumentArr = [];
                        for (var i = 0; i < response.length; i++) {
                            let PMM = {
                                File: {},
                                Filename: response[i].Filename,
                                isFileEmpty: true,
                                DocumentID: response[i].ID
                            }
                            reportDocumentArr[i] = PMM;
                        }
                        this.setState({
                            reportDocumentGridData: reportDocumentArr
                        });
                    }
                    $("#loader-div").hide();
                }).catch((error) => {
                    $("#loader-div").hide();
                    console.log("Error Encountered", error)
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {

        $("#loader-div").show();

        this.setState({
            show: this.props.show
        });
        this.loadReportDocuments();

    }

    closePopUp(eve) {
        this.setState({
            show: false
        });
        this.props.closeCallback();
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    onCellClicked(params) {
        try {
            debugger;
            if (params.colDef.field == "Filename") {
                $("#loader-div").show();
                let gridData = {
                    "DocumentID": params.data.DocumentID,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/PotentialProjects/GetPMMReportDocuments", 'POST', gridData)
                    .then(response => {
                        if (response) {
                            this.saveFile(response[0].Filename, response[0].ContentType, response[0].FileData)
                            $("#loader-div").hide();
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    saveFile(strFileName, strMimeType, strData) {
        try {
            var D = document, A = arguments, a = D.createElement("a"),
                d = A[0], n = A[1], t = A[2] || "application/pdf";

            var newdata = "data:" + strMimeType + ";base64," + escape(strData);
            //build download link: 
            a.href = newdata;
            if ('download' in a) {
                let validateFileName  = inputValidator(strFileName);
                if (validateFileName == false){
                    let newFileName = strFileName;
                    a.setAttribute("download", newFileName);
                }
                //a.setAttribute("download", strFileName);
                a.textContent = i18n.t('downloading');
                D.body.appendChild(a);
                setTimeout(function () {
                    var e = D.createEvent("MouseEvents");
                    e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    a.dispatchEvent(e);
                    D.body.removeChild(a);
                }, 66);
                return true;
            };
        } catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('DocumentID'), field: 'ID', hide: true, suppressMovable: true, suppressResize: true, tooltipField: 'ID', autoHeight: true },
            {
                headerName: i18n.t('DocumentName'), field: 'Filename', suppressMovable: true, suppressResize: true, tooltipField: 'Filename', autoHeight: true,
                cellStyle: function () {
                    return {
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "blue"
                    };
                },
                tooltip: function (params) {
                    return i18n.t('clickToDownload');
                }
            },
        ];

        var reportDocumentGridData = this.state.reportDocumentGridData;

        var reportDocumentGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_PMMAssessmentReportDocument" id="grd_PMMAssessmentReportDocument"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={reportDocumentGridData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.onGridReady.bind(this)}
                    onCellClicked={this.onCellClicked.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{i18n.t('reportOutputDocuments')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="box-plain multiple-tabs-section no-padding-lr">{reportDocumentGrid}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" id="btn_closeRef" className="btn btn-info common-btn pull-right" value={i18n.t('close')} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
      }
}
ReportOutputDocumentDetails = connect(mapStateToProps)(withRouter(ReportOutputDocumentDetails))
export default ReportOutputDocumentDetails