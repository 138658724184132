import initialState from "./initialState";
import * as types from "../actions/actionTypes";

//reducer functions to modify redux store states

export function languageIdReducer(state = initialState.languageId, action) {
  switch (action.type) {
    case types.SET_LANGUAGE_ID:
      return action.data;

    default:
      return state;
  }
}

export function enterpriseIdReducer(state = initialState.enterpriseId, action) {
  switch (action.type) {
    case types.SET_ENTERPRISE_ID:
      return action.data;

    default:
      return state;
  }
}

export function groupIdReducer(state = initialState.groupId, action) {
  switch (action.type) {
    case types.SET_GROUP_ID:
      return action.data;

    default:
      return state;
  }
}

export function roleNameReducer(state = initialState.roleName, action) {
  switch (action.type) {
    case types.SET_ROLE_NAME:
      return action.data;

    default:
      return state;
  }
}

export function pageNameReducer(state = initialState.pageName, action) {
  switch (action.type) {
    case types.SET_PAGE_NAME:
      return action.data;

    default:
      return state;
  }
}

export function groupNameReducer(state = initialState.groupName, action) {
  switch (action.type) {
    case types.SET_GROUP_NAME:
      return action.data;

    default:
      return state;
  }
}

// to store response of /Common/DropDownsValue
export function dropDownsValueReducer(
  state = initialState.dropDownsValue,
  action
) {
  switch (action.type) {
    case types.SET_DROPDOWN_VALUES:
      return action.data;

    default:
      return state;
  }
}
