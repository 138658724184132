/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import ReactSelect from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { BaseURL } from '../../../Constant';
import Autosuggest from 'react-autosuggest';
import $ from 'jquery';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import downloadIcon from '../../../images/icon-download.png';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from '../../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import { connect } from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';


var BOIData = [];

var AsIsToBe = [{ "label": ">=", "value": ">=" }, { "label": "<=", "value": "<=" }];

var BoiLIID = 0;


const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.BOILIName;

const renderSuggestion = suggestion => <span>{suggestion.BOILIName}</span>;

function CreateEditBox(params) {
    let eDiv = document.createElement("div");
    let groupId = params.colDef.cellRendererParams.groupId;
    if (params.data.GroupID == parseInt(groupId)
    ) {
        eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
    }
    else {
        eDiv.innerHTML = ``;
    }
    eDiv.className = "col-md-12";
    eDiv.width = "100%";
    return eDiv;
}

export default class BOILIMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickFilterText: null,
            BOIGridData: [],
            IndustryGridData: [],
            isEdit: 1,
            IsCriticalMetric: false,
            IsActive: true,
            IsBOI: true,
            IsLI: true,
            errors: {},
            selectedRow: {},
            lstCapability: [],
            lstTower: [],
            lstIndustry: [],
            CapabilityValue: [],
            TowerValue: [],
            IndustryValue: "",
            AsIsToBeValue: [],
            AutoCalculateValue: [],
            suggestions: [],
            BOILIName: "",
            yesNo: [{ "label": i18n.t('yes'), "value": i18n.t('yes') }, { "label": i18n.t('no'), "value": i18n.t('no') }]

        };
        this.ChangeIsActive = this.ChangeIsActive.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.ValidateFormula = this.ValidateFormula.bind(this);
        this.populateValues = this.populateValues.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.downloadActiveBOILI = this.downloadActiveBOILI.bind(this);
    }

    componentWillMount() {
       
        this.setState({ BOIGridData: BOIData });
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-MC-9", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
            window.addEventListener('resize', this.reSizeGrid, true);
            this.GetDropDownValues();
            //this.FetchBOILIDetails();
            this.props.showDownloadUpload(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    CreateDelete(params) {
        try {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = '<span class="glyphicon glyphicon-trash" ></span>';
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    alertmesasage() {
        alert(i18n.t('Thresholdvalueisrequired'));
    }

    alertmesasageForNull() {
        alert(i18n.t('PleaseselectAsIsToBe'));
    }

    async GetDropDownValues() {

        try {
            var objThis = this;
            let dropdownData = {
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            await request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(response => {
                    let CapabilityByGroup = []
                    CapabilityByGroup = response.Capability != null || response.Capability != "" ? response.Capability : []
                    this.setState({ lstIndustry: response.Industry });
                    this.setState({ lstCapability: CapabilityByGroup });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCapability(val) {
        try {
            this.setState({ CapabilityValue: val });
            let towerData = {
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId,
                "TowerID": 0,
                "DealId": 0,
                "CapabilityId": val[0].value,
                "DeliveryLocationId": 0,
            }
            request("/Common/GetTowerBasedOnCapability", 'POST', towerData)
                .then(response => {
                    let towerByGroup = [];

                    if (response.length == 1 && response[0].value === 0) {
                        towerByGroup = [];
                    }
                    else {
                        towerByGroup = response != null || response != "" ? response.filter(item => item.GroupID == this.props.groupId
                        ) : []
                        this.setState({ TowerValue: [], lstTower: towerByGroup });
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectTower(val) {
        try {
            this.setState({ TowerValue: val });

            var objThis = this;
            let boiLIDetails = [];
            var SearchedString = " ";
            let data = {
                BOILIID: 0,
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
                TowerId: val[0].value,
                SearchedString: SearchedString,
            }
            request("/BOI/GetBOILIMasterDetails", 'POST', data)
                .then(response => {
                    boiLIDetails = response;
                    objThis.refs.Grd_BOILI.gridOptions.api.setRowData(boiLIDetails);
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectIndustry(val) {
        this.setState({ IndustryValue: val });
    }

    SelectAsIsToBe(val) {
        this.setState({ AsIsToBeValue: val });
    }

    SelectAutocalculate(val) {
        this.setState({ AutoCalculateValue: val });
    }

    onGridReady=(eve)=> {

try{

    $("#loader-div").hide();
    this.gridApi = eve.api;
    this.gridColumnApi = eve.columnApi;
   

    var objThis = this;
    var SearchedString = " ";
    let data = {
        BOILIID: 0,
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
        SearchedString: SearchedString,
        TowerId: 0,
    }
    eve.api.showLoadingOverlay();

    request("/BOI/GetBOILIMasterDetails", 'POST', data)
        .then(response => {
            if(response.length!== 0){
                eve.api.hideOverlay();
                objThis.setState({ BOIGridData: response });
                $("#loader-div").hide();
            }
            
else{
    eve.api.showNoRowsOverlay();
}


        })
        .catch(error => {
            console.log("Error Encountered");
            $("#loader-div").hide();
        });


        eve.api.sizeColumnsToFit();
}

catch (error) {
    console.log("Error Encountered");
}


       
    }

    reSizeGrid() {
        this.refs.Grd_BOILI.gridOptions.api.sizeColumnsToFit();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t("edit")) {
                if (e.data.GroupID == parseInt(this.props.groupId)
                ) {
                    this.populateValues(e.data);
                }
            }
            else if (e.colDef.headerName == i18n.t('delete')) {

                if (confirm(i18n.t("areyousureyouwanttodelet"))) {
                    let currentGridData = this.refs.Grd_Industry.gridOptions.rowData;
                    if (currentGridData[e.rowIndex].BOILIMappingId != undefined) {
                        alert(i18n.t('youwillnotbeabletodelete'));
                    }
                    else {
                        currentGridData.splice(e.rowIndex, 1)
                        this.refs.Grd_Industry.gridOptions.api.setRowData(currentGridData);
                    }
                }

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(dataObj) {
        try {
            BoiLIID = dataObj.BOILIId;
            this.setState({ BOILIName: dataObj.BOILIName, IsActive: dataObj.IsActive, Metric: dataObj.IsCriticalMetric });
            this.refs.Definition.value = dataObj.BOILIDefinition;
            this.refs.Min.value = dataObj.Minimum;
            this.refs.ThresholdValue.value = dataObj.ThresholdValue;
            this.refs.Max.value = dataObj.Maximum;
            this.refs.IfCond.value = dataObj.IsZero;
            this.refs.BOILIFormula.value = dataObj.Formula;
            var SearchedString = "";
            let data = {
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
                BOILIID: dataObj.BOILIId,
                BOILIMappingId: 0,
                IndustryId: 0,
                SearchedString: SearchedString,
            }
            request("/BOI/GetBOILIIndustryDetails", 'POST', data)
                .then(res => {
                    this.setState({ IndustryGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            var IsAutoCal = []
            if (dataObj.IsAutoCalclulate != null) {
                IsAutoCal = dataObj.IsAutoCalclulate == true ? [{ "label": i18n.t('yes'), "value": "Yes" }] : [{ "label": i18n.t('no'), "value": "No" }];
            }
            var IsLessthanEq = [];
            if (dataObj.LessThanEqulaTo != null) {
                IsLessthanEq = dataObj.LessThanEqulaTo == true ? [{ "label": ">=", "value": ">=" }] : [{ "label": "<=", "value": "<=" }];
            }
            if (dataObj.BOILIType == 1) {
                this.setState({ IsBOI: true });
                this.setState({ IsLI: false });
            }
            else if (dataObj.BOILIType == 2) {
                this.setState({ IsBOI: false });
                this.setState({ IsLI: true });
            }
            else if (dataObj.BOILIType == 3) {
                this.setState({ IsBOI: true });
                this.setState({ IsLI: true });
            }
            this.setState({
                isEdit: 2,
                selectedRow: dataObj,
                TowerValue: [{ "label": dataObj.TowerName, "value": dataObj.TowerId }],
                AutoCalculateValue: IsAutoCal,
                CapabilityValue: [{ "label": dataObj.CapabilityName, "value": dataObj.CapabilityID }],
                AsIsToBeValue: IsLessthanEq,
                IsActive: dataObj.IsActive,
                IsCriticalMetric: dataObj.IsCriticalMetric
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {
        this.setState({ IsActive: event.target.checked });
    }

    ChangeIsBOI(event) {
        this.setState({ IsBOI: event.target.checked });
    }

    ChangeIsLI(event) {
        this.setState({ IsLI: event.target.checked });
    }

    OnCancel() {
        try {
            BoiLIID = 0;
            this.refs.Definition.value = "";
            this.refs.Min.value = "";
            this.refs.ThresholdValue.value = "";
            this.refs.Max.value = "";
            this.refs.IfCond.value = "";
            this.refs.BOILIFormula.value = "";
            this.setState({ isEdit: 1 });
            this.setState({ CapabilityValue: [] });
            this.setState({ TowerValue: [] });
            this.setState({ AsIsToBeValue: [] });
            this.setState({ AutoCalculateValue: [] });
            this.setState({ IsActive: true });
            this.setState({ IsCriticalMetric: false });
            this.setState({ IsLI: true });
            this.setState({ IsBOI: true });
            this.setState({ IndustryValue: [] });
            this.setState({
                IndustryGridData: [],
                BOILIName: "",
                lstTower: []
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async FetchBOILIDetails() {
        try {
            var objThis = this;
            var SearchedString = " ";
            let data = {
                BOILIID: 0,
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
                SearchedString: SearchedString,
                TowerId: 0,
            }
            await request("/BOI/GetBOILIMasterDetails", 'POST', data)
                .then(response => {
                    objThis.setState({ BOIGridData: response });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveBOILIDetails(e) {
        try {
            let benchstatus=true;          
            const obj = this;
            this.refs.Grd_Industry.gridOptions.api.stopEditing();
            let evtSrc = e.target.text.trim();
            let standardStatus = false;
            let numStatus = true;
            let thresholValueStatus = true;
            let maxMinNumStatus = true;
            var IsZeroFieldValue = this.refs.IfCond.value;
            var formulaValidStatus = true;
            let BOILIGridDetails = this.refs.Grd_BOILI.gridOptions.rowData;
            if (this.state.CapabilityValue == "" || this.state.CapabilityValue == null) {
                alert(i18n.t("pleaseselectCapability"));
                return false;
            }
            if (this.state.TowerValue == "" || this.state.TowerValue == null) {
                alert(i18n.t('pleaseselectTower'));
                return false;
            }
            if (this.state.BOILIName == "") {
                alert(i18n.t("pleaseenterBOIMetricname"));
                return false;
            }
            if (BoiLIID == 0 && BOILIGridDetails != null) {
                var MetricName = this.state.BOILIName;
                var resultDuplicate = BOILIGridDetails.filter(function (el) {
                    return (el.BOILIName === MetricName);
                });

                if (resultDuplicate.length > 0) {
                    alert("'" + this.state.BOILIName + "'" + i18n.t("enterDifferentMetricName"));
                    return false;
                }
            }

            if (this.refs.Definition.value == "") {
                alert(i18n.t("pleaseenterDefinition"));
                return false;
            }

            if (this.refs.Min.value != "") {
                if(isNaN(parseFloat(this.refs.Min.value)) && !isFinite(this.refs.Min.value))
                {
                    maxMinNumStatus = false;
                }
                if (maxMinNumStatus == false) {
                    alert(i18n.t("pleaseenternumericvalueforMin"));
                    return false;
                }
            }
            if (this.refs.Max.value != "") {
                if(isNaN(parseFloat(this.refs.Max.value)) && !isFinite(this.refs.Max.value))
                {
                    maxMinNumStatus = false;
                }
                if (maxMinNumStatus == false) {
                    alert(i18n.t("pleaseenternumericvalueforMax"));
                    return false;
                }
            }
            if (this.refs.Min.value != "" && this.refs.Max.value != "") {
                if (parseFloat(this.refs.Min.value) > parseFloat(this.refs.Max.value)) {
                    alert(i18n.t("maxvalueshouldalwaysbegreater"));
                    return false;
                }
            }
            if (this.state.IsBOI == false && this.state.IsLI == false) {
                alert(i18n.t("pleaseselectBOIorLIcheckbox"));
                return false;
            }

            if ((this.refs.Min.value == "" || this.refs.Max.value == "")) {
                var s = this.refs.ThresholdValue.value.split('.');
                if (s[0].length > 6 || (s[1] != undefined && s[1].length > 2)) {
                    alert(i18n.t("thresholdValuecanbemaximumof6"));
                    return false;
                }
            }

            if (this.refs.Min.value != "") {
                if (parseFloat(this.refs.Min.value) > parseFloat(this.refs.ThresholdValue.value)) {
                    alert(i18n.t("thresholdValuemustbebetween"))
                    return false;
                }
            }

            if (this.refs.Max.value != "") {
                if (parseFloat(this.refs.Max.value) < parseFloat(this.refs.ThresholdValue.value)) {
                    alert(i18n.t("thresholdValuemustbebetween"))
                    return false;
                }
            }

            if (parseFloat(this.refs.Min.value) <= parseFloat(this.refs.ThresholdValue.value) || parseFloat(this.refs.ThresholdValue.value) <= parseFloat(this.refs.Max.value)) {
                var s = this.refs.ThresholdValue.value.split('.');
                if (s[0].length > 6 || (s[1] != undefined && s[1].length > 2)) {
                    alert(i18n.t("thresholdValuecanbemaximum"));
                    return false;
                }
            }

            if (this.refs.ThresholdValue.value != "") {
                if(isNaN(parseFloat(this.refs.ThresholdValue.value)) && !isFinite(this.refs.ThresholdValue.value))
                {
                    thresholValueStatus = false;
                }                    
                if (thresholValueStatus == false) {
                    alert(i18n.t("pleaseenternumericvalueforThresholdValue"));
                    return false;
                }
            }

            //Is Zero validation
            if (this.refs.IfCond.value != "") {
                if (BOILIGridDetails != null) {
                    var resultIsZero = BOILIGridDetails.filter(function (el) {
                        return (el.UniqueFormulaID === IsZeroFieldValue);
                    });
                    if (IsZeroFieldValue != "" && resultIsZero.length == 0) {
                        alert("'" + IsZeroFieldValue + "'" + i18n.t('ofConditionisNotPresent'));
                        return false;
                    }
                }
                else {
                    alert(i18n.t("conditiocannotbeadded"));
                    return false;
                }
            }

            if (this.refs.BOILIFormula.value != "") {
                if (BOILIGridDetails != null) {
                    if (this.refs.BOILIFormula.value != "" && this.state.AutoCalculateValue.length == 0) {
                        alert(i18n.t("thereissomeformulaentered"));
                        return false;
                    }
                }
                else {
                    alert(i18n.t("formulacannotbeaddednorecordsinBOI"));
                    return false;
                }
            }

            var BOILIType = 0;
            if (this.state.IsBOI == true && this.state.IsLI == true) {
                BOILIType = 3;
            }
            else if (this.state.IsBOI == true) {
                BOILIType = 1;
            }
            else if (this.state.IsLI == true) {
                BOILIType = 2;
            }
            var IsAutoCal = false;
            if (this.state.AutoCalculateValue.length != 0 && this.state.AutoCalculateValue[0].value == i18n.t('yes')) {
                IsAutoCal = true;
            }
            else if (this.state.AutoCalculateValue.length != 0 && this.state.AutoCalculateValue[0].value == i18n.t('no')) {
                IsAutoCal = false;
            }

            var IsLessthanEq = "";
            if (this.state.AsIsToBeValue.length != 0 && this.state.AsIsToBeValue[0].value == ">=") {
                IsLessthanEq = true;
            }
            else if (this.state.AsIsToBeValue.length != 0 && this.state.AsIsToBeValue[0].value == "<=") {
                IsLessthanEq = false;
            }

            if (this.refs.ThresholdValue.value != "" && IsLessthanEq == null && IsLessthanEq == "") {
                alert(i18n.t('PleaseselectAsIsToBe'));
                return false;
            }

            if (this.refs.ThresholdValue.value != "") {
                this.state.IsCriticalMetric = true;
            }
            else {
                this.state.IsCriticalMetric = false;
            }

            let boiLIDetails = {
                BOILIId: BoiLIID,
                BOILIName: this.state.BOILIName,
                BOILIDefinition: this.refs.Definition.value,
                TowerId: this.state.TowerValue[0].value,
                TowerName: this.state.TowerValue[0].label,
                LessThanEqulaTo: IsLessthanEq,
                IsZero: this.refs.IfCond.value,
                Formula: this.refs.BOILIFormula.value,
                IsAutoCalclulate: IsAutoCal,
                IsActive: this.state.IsActive,
                Minimum: this.refs.Min.value,
                IsCriticalMetric: this.state.IsCriticalMetric,
                ThresholdValue: this.refs.ThresholdValue.value == "" ? null : this.refs.ThresholdValue.value,
                Maximum: this.refs.Max.value,
                BOILIType: BOILIType,
                Units: "",
                Operation: this.state.isEdit,
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId,
            };

            let industryDetails = this.refs.Grd_Industry.gridOptions.rowData;

            industryDetails.filter(item => item.hasOwnProperty('AsIs'))
                .map((item, i) => {
                    var valAsIs = item.AsIs;
                    var valToBe = item.ToBe;
                    var benchmark = item.Benchmark;
                    var median = item.Median;                 

                    if(item.IndustryName == i18n.t("global")){
                        standardStatus = true;
                    }
                    if (evtSrc == i18n.t('save') && valAsIs != "" && valAsIs != null) {
                        if(isNaN(parseFloat(valAsIs)) && !isFinite(valAsIs)){
                            numStatus = false;
                        }
                    }
                    if (evtSrc == i18n.t('save') && valToBe != "" && valToBe != null) {
                        if(isNaN(parseFloat(valToBe)) && !isFinite(valToBe)){
                            numStatus = false;
                        }
                    }

                    if (evtSrc == i18n.t('save') && median != "" && median != null) {
                        if(!isNaN(parseFloat(median)) && isFinite(median)){
                        }
                        else{ 
                            benchstatus = false;
                        }
                    }

                    if (evtSrc == i18n.t('save') && benchmark != "" && benchmark != null) {
                        if(!isNaN(parseFloat(benchmark)) && isFinite(benchmark)){
                        }
                        else{
                            benchstatus = false;
                        }
                    }

                });

            if (benchstatus==false){
                alert(i18n.t("Internal/ExternalBenchmarkShouldonlyContainnumericvalues"));
                return false;
             }
            if (standardStatus == false) {
                alert(i18n.t("pleaseaddGlobalindustry"));
                return false;
            }
            if (evtSrc == i18n.t('save') && numStatus == false) {
                alert(i18n.t("pleaseenternumericvalues"));
                return false;
            }

            var boiLIIndustryDetails = {
                "boiLIDetails": boiLIDetails,
                "industryDetails": industryDetails
            };

            //Formula Validation
            if (this.refs.BOILIFormula.value != "") {
                if (boiLIDetails != null) {
                    formulaValidStatus = this.ValidateFormula();
                }
                else {
                    alert(i18n.t("formulacannotbeadded"));
                    return false;
                }
            }

            if (formulaValidStatus == true) {

let boilinamevalidation = inputValidator(boiLIDetails.BOILIName);
let boilidefinationvalidation = inputValidator(boiLIDetails.BOILIDefinition);
                if(boilinamevalidation === false && boilidefinationvalidation === false ){
                request("/BOI/SaveBOILIDetails?GroupID=" + this.props.groupId, 'POST', boiLIIndustryDetails)
                    .then(res => {
                        if (res.Outputcode == 1) {
                            alert(i18n.t("savedSuccessfully"));
                            obj.FetchBOILIDetails();
                            obj.OnCancel();
                        }
                        else {
                            alert(res["outputmessage"]);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
                }
                else{
                    alert(i18n.t("PleaseEntervalidValuesintheFields"));
    
                }
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};
            let reg = /^[A-Za-z\d\s]+$/;
            let regForAlphabets = /^[a-zA-Z]+$/;

            if (!this.state.CapabilityValue) {
                errors.CapabilityName = i18n.t('pleaseSelectaCapability');
                formIsValid = false;
            }
            if (!this.state.TowerValue) {
                errors.TowerName = i18n.t('pleaseSelectaTower');
                formIsValid = false;
            }
            if (!this.refs.BOIName) {
                errors.BOIName = i18n.t('pleaseSelectaTower');
                formIsValid = false;
            }

            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ValidateFormula() {
        try {
            var BOILIFormulaAsIs = this.refs.BOILIFormula.value;
            var BOILIFormulaToBe = this.refs.BOILIFormula.value;
            var formulaFields = BOILIFormulaAsIs.match(/[^>=<+-/*()]+/g);
            let industryDetails = this.refs.Grd_Industry.gridOptions.rowData;
            let boiLIDetails = this.refs.Grd_BOILI.gridOptions.rowData;
            var IsBOILI = 0;
            var valAsIs = "";
            var valToBe = "";
            var numStatus = false;

            industryDetails.filter(item => item.hasOwnProperty('AsIs'))
                .map((item, i) => {
                    if (item.IndustryName == i18n.t("global")) {
                        valAsIs = item.AsIs;
                        valToBe = item.ToBe;

                    }
                });

            var i;
            var FormulaWithValueAsIs = "";
            var FormulaWithValueToBe = "";
            for (i = 0; i < formulaFields.length; i++) {
                !isNaN(parseFloat(formulaFields[i])) && isFinite(formulaFields[i]) ? numStatus = true : numStatus = false;

                if (numStatus == false) {
                    var result = boiLIDetails.filter(function (el) {
                        return (el.UniqueFormulaID === formulaFields[i]);
                    });

                    if (result.length == 0) {
                        alert("'" + formulaFields[i] + "'" + i18n.t('isnotpresentintheBOI'));
                        return false;
                    }
                    else {
                        if(this.state.IsBOI == true && this.state.IsLI == false){
                            IsBOILI = 1;
                        }
                        if(this.state.IsBOI == false && this.state.IsLI == true){
                            IsBOILI = 2;
                        }
                        if(this.state.IsBOI == true && this.state.IsLI == true){
                            IsBOILI = 3;
                        }

                        if (IsBOILI == 1 && result[0].BOILIType == 2) {
                            alert("'" + formulaFields[i] + "'" + i18n.t('isnotpartofBOI'));
                            return false;
                        }
                        else if (IsBOILI == 2 && result[0].BOILIType == 1) {
                            alert("'" + formulaFields[i] + "'" + i18n.t('isnotpartofLI'));
                            return false;
                        }
                        else if (IsBOILI == 3 && result[0].BOILIType != 3) {
                            alert("'" + formulaFields[i] + "'" + i18n.t('isnotpartofbothLI'));
                            return false;
                        }
                        var AsIsArr = result[0].AsIsValues.split(',');
                        var ToBeArr = result[0].ToBeValues.split(',');

                        //Assign 1 to all the fields

                        BOILIFormulaAsIs = BOILIFormulaAsIs.replace(formulaFields[i], "1");
                        BOILIFormulaToBe = BOILIFormulaToBe.replace(formulaFields[i], "1");
                    }
                }
                numStatus = false;
            }
            if (numStatus == false) {
                try {
                    var IsvalidFormulaAsIS = "";
                    if (BOILIFormulaAsIs.includes(">=")) {
                        IsvalidFormulaAsIS = eval("1" + BOILIFormulaAsIs);
                    }
                    else if (BOILIFormulaAsIs.includes("<=")) {
                        IsvalidFormulaAsIS = eval("1" + BOILIFormulaAsIs);
                    }
                    else {
                        IsvalidFormulaAsIS = eval(BOILIFormulaAsIs);
                    }

                    var IsvalidFormulaToBe = "";
                    if (BOILIFormulaToBe.includes(">=")) {
                        IsvalidFormulaToBe = eval("1" + BOILIFormulaToBe);
                    }
                    else if (BOILIFormulaAsIs.includes("<=")) {
                        IsvalidFormulaToBe = eval("1" + BOILIFormulaToBe);
                    }
                    else {
                        IsvalidFormulaToBe = eval(BOILIFormulaToBe);
                    }
                    alert(i18n.t("formulavalidationSuccessful"));
                    return true;
                }
                catch (err) {
                    alert(i18n.t("pleaseentervalidformula"));
                    return false;
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    AddIndustry() {
        try {
            var selectedIndustry = this.state.IndustryValue[0] != undefined ? this.state.IndustryValue[0].label : undefined;
            var selectedIndustryID = this.state.IndustryValue[0] != undefined ? this.state.IndustryValue[0].value : undefined
            if (selectedIndustry == undefined && selectedIndustryID == undefined) {
                alert(i18n.t('pleaseselectIndustry'));
                return false;
            }

            let currentGridData = [];
            currentGridData = this.refs.Grd_Industry.gridOptions.rowData;
            var newArray = currentGridData.filter(function (el) {
                return el.IndustryId == selectedIndustryID;
            });
            if (newArray.length > 0) {
                alert(i18n.t('selectedIndustrylreadyadded'));
                return false;
            }
            if (currentGridData.length == 0 && this.state.IndustryValue[0].value != 1) {
                alert(i18n.t('pleaseselectGlobalindustry'));
                return false;
            }
            currentGridData.push({ "IndustryId": this.state.IndustryValue[0].value, "IndustryName": selectedIndustry, "Benchmark": "", "Median": "", "AsIs": "", "ToBe": "" });
            this.refs.Grd_Industry.gridOptions.api.setRowData(currentGridData);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        this.setState({ quickFilterText: event.target.value });
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.BOIGridData.filter(BOI => BOI.GroupID == parseInt(this.props.groupId)
                );
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.BOIGridData.filter(BOI => regex.test(BOI.BOILIName) && BOI.GroupID == parseInt(this.props.groupId)
                );
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            BOILIName: newValue
        });
        $(event.target).attr('type', 'new');
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event, { suggestion }) {
        try {
            let type = $(event.target).attr('type');
            let value = event.target.value.toLowerCase();
            this.state.BOIGridData.forEach(BOI => {
                if (BOI.BOILIName.toLowerCase() == value) {
                    if (BOI.GroupID == parseInt(this.props.groupId)
                    ) {
                        this.populateValues(BOI);
                    }
                }
                return BOI;
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    uploadFile(obj) {
        try {
            this.setState({ showProductivityPopup: false });

            var fileData = new FormData();

            fileData.append("File", this.refs.file.files[0]);
            var uploadedFile = this.refs.file.files[0];
            var that = this;
            var reader = new FileReader();
            reader.readAsDataURL(uploadedFile);
            var editActive = true;
            $("#loader-div").show();

            fetch(BaseURL + "/Common/ValidateSAPUpload", {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body: fileData
            }).then(r => r.json())
                .then(res => {
                    let PMMArr = [];
                    res.forEach(PMM => {
                        PMM.LeverTypes = this.state.levers;
                        PMM.KPATypes = this.state.KPAs;
                        PMM.MetricsTypes = this.state.metrics;
                        PMM.BOILIId = 0;
                        PMM.BOILIIdvalue = {};
                        PMM.LeverID = 0;
                        PMM.LeverIDvalue = {};
                        PMM.KPAID = 0;
                        PMM.KPAIDvalue = {};
                        PMMArr.push(PMM);
                    })
                    that.setState({
                        PMMGridData: PMMArr
                    });

                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    downloadTemplate(e) {
        let validateGroupId  = inputValidator(this.props.groupId);
            if (validateGroupId == false){
                let newgroupId = this.props.groupId;
                window.open(`${BaseURL}/Common/DownloadExcel?GroupID=` + newgroupId);
            }
    }

    async downloadActiveBOILI() {

        try {    
            //window.tfo_event("PMM-D-13", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var SearchedString = " "
            let scData = {
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId,
                "TowerID": 0,
                "SearchedString": SearchedString,

            }
            let jsonscdata = JSON.stringify(scData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadActiveBOILI", {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                //'charset': 'utf-8',
                Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                },
                body: jsonscdata,
            })
            .then((response) => response.blob())
                .then((blob) => {
                const BlobUrl = window.URL.createObjectURL(blob);
                var downLink = document.createElement("a");
                downLink.href = BlobUrl;
                downLink.download = `ExcelDownloadTemplate.xlsx`;
                document.body.appendChild(downLink);
                downLink.click();
                downLink.remove();
                $("#loader-div").hide();
                alert(i18n.t("filedownloaded"));
                });
            // await request("/Common/DownloadActiveBOILI", 'POST', scData)
            //     .then(res => {

            //         var filePath = res.Table1[0].Value;
            //         window.location.href = filePath;
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        let { t } = this.props
        columnDefs = [
            { headerName: i18n.t('Edit'), width: 80, field: '', cellRenderer: CreateEditBox, cellRendererParams: { groupId: this.props.groupId }, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('UniqueID'), field: 'UniqueFormulaID', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('BOIMetricName'), field: 'BOILIName', tooltipField: 'BOILIName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('types'), field: 'BOILITypes', suppressMovable: true, suppressResize: true, tooltipField: 'BOILITypes' },
            { headerName: i18n.t('definition'), field: 'BOILIDefinition', tooltipField: 'BOILIDefinition', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('min'), field: 'Minimum', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('max'), field: 'Maximum', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('AsIsToB'), field: 'AsIsToBeCondition', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('IfCond'), field: 'IsZero', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('formula'), field: 'Formula', tooltipField: 'Formula', hide: true, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('autoCalculate'), field: 'IsAutoCalclulate', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('industryTypes'), field: 'IndustryTypes', tooltipField: 'IndustryTypes', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('IsActive'), field: 'IsActive', width: 100, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('IsCritical'), field: 'IsCriticalMetric', width: 100, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('thresholdValue'), field: 'ThresholdValue', suppressMovable: true, suppressResize: true, autoHeight: true },

        ];

        var columnDefsIndustry = [];

        columnDefsIndustry = [
            { headerName: i18n.t('IndustryId'), field: 'IndustryId', hide: true, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('industry_Name'), field: 'IndustryName', suppressMovable: true, suppressResize: true },
            {
                headerName: i18n.t('internalBenchmark'), field: 'Benchmark', editable: function (params) {
                    if (params.data.Benchmark != null && params.data.Benchmark != "" && params.data.BOILIMappingId != undefined) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }, suppressMovable: true, suppressResize: true
            },
            {
                headerName: i18n.t('externalBenchmark'), field: 'Median', editable: function (params) {
                    if (params.data.Median != null && params.data.Median != "" && params.data.BOILIMappingId != undefined) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }, suppressMovable: true, suppressResize: true
            },
            { headerName: i18n.t('As-Is'), field: 'AsIs', editable: true, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('To-Be'), field: 'ToBe', editable: true, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('delete'), suppressMovable: true, suppressResize: true, cellRenderer: this.CreateDelete.bind(this) }
        ];


        var IndustryGridData = this.state.IndustryGridData;

        var BOIDataGrid = (
            <div style={{ height: '250px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_BOILI" id="grd_BOILI" enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.BOIGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    singleClickEdit={true}
                    quickFilterText={this.state.quickFilterText}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        var IndustryDataGrid = (
            <div style={{ height: '250px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_Industry" id="grd_Industry" enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefsIndustry}
                    rowData={IndustryGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    singleClickEdit={true}
                    overlayLoadingTemplate='<span className="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        let { suggestions, BOILIName: value } = this.state;
        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur
        }

        return (
            <div>
                <div className="box">
                    <div className="box-plain">
                        <div className="box-in viewPrj-filter">
                            <div className="row">
                                <span>
                                    <div className="form-group col-md-3">
                                        <label id="lbl_capability_BOILIMaster"><span style={{ color: "red" }}>*</span>{i18n.t('capability')}</label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlCapability" id="ddl_Capability" name="form-field-name" filter={true} labelKey="label" onChange={this.SelectCapability.bind(this)} options={this.state.lstCapability} selected={this.state.CapabilityValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('selectPlace')} />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-3">
                                        <label id="lbl_tower_BOILIMaster"><span style={{ color: "red" }}>*</span>{i18n.t('tower')}</label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlTower" id="ddl_Tower" name="form-field-name" filter={true} labelKey="label" onChange={this.SelectTower.bind(this)} options={this.state.lstTower} selected={this.state.TowerValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('selectPlace')} />
                                    </div>
                                </span>

                            </div>
                            <div className="row">
                                <span>
                                    <div className="form-group col-md-3">
                                        <label id="lbl_BOI/MetricName(Global Search)"><span style={{ color: "red" }}>*</span>{i18n.t('bOIMetricNameGlobalSearch')}:</label>
                                        <Autosuggest ref="BOIMetricName"
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps} />

                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-3">
                                        <label id="lbl_definition_BOILIMaster"><span style={{ color: "red" }}>*</span>{i18n.t('definition')}:</label>
                                        <textarea id="txtArea_definition" ref="Definition" data-field="Definition" name="Definition" rows="1" className="form-control" placeholder="" type="" />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-2">
                                        <label id="lbl_min_BOILIMaster">{i18n.t('min')}:</label>
                                        <input id="txt_min_BOILIMaster" ref="Min" data-field="Min" name="Min" type="text" className="form-control" placeholder="" />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-2">
                                        <label id="lbl_max_BOILIMaster">{i18n.t('max')}:</label>
                                        <input id="txt_max_BOILIMaster" ref="Max" name="Max" type="text" className="form-control" placeholder="" />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-3">
                                        <label><span style={{ color: "red" }}></span>{i18n.t('thresholdValue')}:</label>
                                        <input id="thresholdValue" ref="ThresholdValue" data-field="ThresholdValue" name="ThresholdValue" type="text" className="form-control" placeholder="" />
                                    </div>
                                </span>

                                <span>
                                    <div className="form-group col-md-2">
                                        <label id="lbl_ASIS/TOBE">{i18n.t('Asis')} / {i18n.t('toBe')}:</label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlAsIsToBe" id="ddl_AsIsToBe" name="form-field-name" filter={true} labelKey="label" onChange={this.SelectAsIsToBe.bind(this)} options={AsIsToBe} selected={this.state.AsIsToBeValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                    </div>
                                </span>

                            </div>
                            <div className="row">
                                <span>
                                    <div className="form-group col-md-3">
                                        <label id="lbl_ifCondition">{i18n.t('ConditionIf0')}:</label>
                                        <input id="txt_ifCond" ref="IfCond" name="IfCond" type="text" className="form-control" placeholder="" />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-3">
                                        <label id="lbl_formula">{i18n.t('formula')}:</label>
                                        <textarea ref="BOILIFormula" id="txtArea_BOILIFormula" name="BOILIFormula" rows="1" className="form-control" placeholder="" type="" />
                                    </div>
                                </span>

                                <span>
                                    <div className="form-group col-md-2">
                                        <label id="lbl_autoCalculate">{i18n.t('autoCalculate')}:</label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlAutoCalculate" id="ddl_AutoCalculate" name="form-field-name" filter={true} labelKey="label" onChange={this.SelectAutocalculate.bind(this)} options={this.state.yesNo} selected={this.state.AutoCalculateValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group btn-group-md-master col-md-1">
                                        <label id="lbl_isActive">{i18n.t('isActive')} </label>
                                        <input ref="IsActive" id="txt_isActive_BOILIMaster" type="checkbox" name="IsActive" className="chkBox chkboxMargin" checked={this.state.IsActive} defaultChecked={true} onChange={this.ChangeIsActive} />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group btn-group-md-master col-md-1">
                                        <label id="lbl_BOI">{i18n.t('boi')} </label>
                                        <input ref="chkBOI" id="txt_isBOI" type="checkbox" name="IsBOI" className="chkBox chkboxMargin" checked={this.state.IsBOI} defaultChecked={true} onChange={this.ChangeIsBOI.bind(this)} />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group btn-group-md-master col-md-1">
                                        <label id="lbl_li">LI </label>
                                        <input ref="chkLI" id="txt_isLI" type="checkbox" name="IsLI" className="chkBox chkboxMargin" checked={this.state.IsLI} defaultChecked={true} onChange={this.ChangeIsLI.bind(this)} />
                                    </div>
                                </span>

                            </div>
                            <div className="row">
                                <span>
                                    <div className="form-group col-md-3">
                                        <label id="lbl_Industry">{i18n.t('industry')}:</label>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlIndustry" id="ddl_Industry" name="form-field-name" filter={true} labelKey="label" onChange={this.SelectIndustry.bind(this)} options={this.state.lstIndustry} selected={this.state.IndustryValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-3">
                                        <div className="btn-group-md btn-group-md-master">
                                            <a type="button" className="btn btn-info pull-left" onClick={this.AddIndustry.bind(this)}>{i18n.t('add')}</a>
                                        </div>
                                    </div>
                                </span>
                                <span>
                                    <div className="form-group col-md-6">
                                        <div className="btn-group-md btn-group-md-master">
                                            <a type="button" className="btn btn-danger pull-right" onClick={this.OnCancel.bind(this)} >{i18n.t('clear')}</a>
                                            <a type="button" className="btn btn-success pull-right" onClick={this.SaveBOILIDetails.bind(this)} >{i18n.t('save')}</a>
                                        </div>
                                    </div>
                                </span>
                            </div>

                        </div>

                    </div>

                    <div className="box-in">
                        <h4>{i18n.t('industryDetails')} </h4>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {IndustryDataGrid}
                            </div>
                        </div>
                    </div>


                    <div className="box-in">
                        <div className="search-div">
                            <h4 className="fleft">{i18n.t('BOILIDetails')}</h4>
                            <input id="txt_quickFilter" type="text" placeholder={i18n.t("searchBOILI")} className="fright searchBox" data-field='searchOG' onChange={this.onQuickFilterText.bind(this)} />
                            {
                                this.props.groupId == "1" ?
                                    <div className="btn-group btn-group-pmmasmt">
                                        <button style={{ marginRight: '10px' }} type="button" id="btn_downloadAssesment" title={i18n.t("downloadBOILI")} className="btn btn-default btn-downld" onClick={this.downloadActiveBOILI}>
                                            <img src={downloadIcon} />
                                        </button>
                                    </div>
                                    : null}

                        </div>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {BOIDataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName

    }
}
BOILIMaster = connect(mapStateToProps)(BOILIMaster);