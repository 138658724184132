import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import langKeys from './Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
class Support extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.handleResponse = this.handleResponse.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {

        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            //window.tfo_event("PMM-Home-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleResponse(eve) {
        try {
            let targetText = eve.target.value;
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        return (
            <div>
                <Modal show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                    <h5 id="customerSupport"><b>{i18n.t('customerSupport')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <span>
                        <p id="thankYouforUsing">{i18n.t('thankYouforUsing')} <b>PMM</b></p>
                            <p id="weAreHereToProvide">{i18n.t('weAreHereToProvide')}</p>
                            <p>{i18n.t('contactCustomerSupport')}:
                                {this.props.groupId
                                == 1 ? <span className="support"> PMMTool.Support@accenture.com</span>
                                    : <span className="support"> pmmtoolgbs.support@accenture.com</span>}
                            </p>
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" className="btn btn-info pull-right" value={i18n.t("close")} onClick={this.handleResponse} />

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state){
    
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName
    }
}
Support = connect(mapStateToProps)(withTranslation()(Support));
export default withRouter(Support)
