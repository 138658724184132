/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import request from '../../../services/Service';
import { AgGridReact } from 'ag-grid-react'
import editIcon from '../../../images/icon-edit-active.png';
import EditConfirmation from '../EditConfirmation';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect} from 'react-redux';
function CreateEditBox() {
    try {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class DealMMSIDMapping extends React.Component {
    constructor(props) {
        super(props)
        try {
            this.state = {
                show: this.props.show,
                selectedDeal: this.props.selectedRow,
                DealName: this.props.DealName,
                MMSIDGridData: this.props.gridData,
                isEdit: 1,
                selectedRow: {},
                MMSID: "",
                IsActive: true,
                IsDeleted: false,
                DealId: this.props.DealId,
                ID: 0,
                isUpdateExisting: false,
                isEditPopUp: false,
                IsConfirmUpdate: false,
                prevMMSID: "",
                isUnSaved: false,
                IsEditActive: false,
                value: ""
            };
            this.handleClose = this.handleClose.bind(this);
            this.ChangeIsActive = this.ChangeIsActive.bind(this);
            this.onInputChange = this.onInputChange.bind(this);
            this.populateValues = this.populateValues.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.formatActive = this.formatActive.bind(this);
            this.saveMMSID = this.saveMMSID.bind(this);
            this.OnCancel = this.OnCancel.bind(this);
            this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
            this.passValue = this.passValue.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    componentDidMount() {
        try {
            this.setState({ show: this.props.show })
            window.addEventListener('resize', this.reSizeGrid, true);
            this.FetchConnMMSIDGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.mmsid_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async FetchConnMMSIDGrid() {
        try {
            var objThis = this;
            debugger;
            if (objThis.state.MMSIDGridData.length == 0) {
                let GetMMSIDdata = {
                    UserName:this.props.enterpriseId,
                    GroupID:this.props.groupId,
                    LanguageID:this.props.languageId,
                    DealId:this.state.DealId,
                }
                await request('/MasterScreens/GetMMSID', 'POST', GetMMSIDdata)
                    .then(res => {
                        res != "" ? objThis.setState({ MMSIDGridData: res }) : objThis.setState({ MMSIDGridData: [] })
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveMMSID(eve) {
        try {
            const obj = this;
            let { ID, MMSID, IsActive, IsDeleted, DealId, prevMMSID } = obj.state;
            if (obj.IsFormValid()) {
                if (!IsActive && DealId != 0) {
                    var SearchedString = "";
                    let data ={
                        UserName:this.props.enterpriseId,
                        GroupID:this.props.groupId,
                        LanguageID:this.props.languageId,
                        PMMProcessDraftID:0,
                        DealID:DealId,
                        RegionID:0,
                        CountryID:0,
                        CapabilityID:0,
                        TowerID:0,
                        IndustryID:0,
                        SCID:0,
                        SearchedString:SearchedString,
                        PermissionLevel:3,
                    }
                    request("/BOI/ViewAssessmentDraftDetailsBasedOnUserAccess", 'POST', data)
                        .then(response => {
                            if (response.Table.length > 0) {
                                let activeDraftAssessment = response.Table.find(item => item.MMSID == MMSID.toUpperCase())
                                if (activeDraftAssessment !== undefined) {
                                    alert(i18n.t('cannotInactiveMMSIDFromThisdeal'));
                                    return;
                                }
                            }
                            if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && MMSID != "") {
                                this.setState({
                                    isEditPopUp: true
                                })
                                return

                            }
                            //save the changes to the Grid
                            let currentGridData = obj.refs.mmsid_grid.gridOptions.rowData;
                            if (prevMMSID == "") {
                                var newData = {};
                                newData["ID"] = eve ? ID : 0;
                                newData["MMSID"] = MMSID.toUpperCase();
                                newData["DealId"] = DealId;
                                newData["IsActive"] = IsActive;
                                newData["IsDeleted"] = IsDeleted;
                                newData["CreatedBy"]=this.props.enterpriseId;
                                newData["CreatedDate"] = new Date().toISOString().split('T')[0];

                                currentGridData.push(newData);
                            } else {
                                currentGridData.map((item, index) => {
                                    if (item.MMSID == prevMMSID) {
                                        item.MMSID = MMSID;
                                        item.IsActive = IsActive;
                                        item.UpdatedBy=this.props.enterpriseId;
                                        item.UpdatedDate = new Date().toISOString().split('T')[0];
                                        return true;
                                    }
                                })
                            }

                            obj.refs.mmsid_grid.gridOptions.api.setRowData(currentGridData);
                            obj.OnCancel();
                            obj.setState({ isUnSaved: true })
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                        });
                }
                else {
                    if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && MMSID != "") {
                        this.setState({
                            isEditPopUp: true
                        })
                        return

                    }

                    //save the changes to the Grid
                    let currentGridData = obj.refs.mmsid_grid.gridOptions.rowData;
                    if (prevMMSID == "") {
                        var newData = {};
                        newData["ID"] = eve ? ID : 0;
                        newData["MMSID"] = MMSID.toUpperCase();
                        newData["DealId"] = DealId;
                        newData["IsActive"] = IsActive;
                        newData["IsDeleted"] = IsDeleted;
                        newData["CreatedBy"] = this.props.enterpriseId;
                        newData["CreatedDate"] = new Date().toISOString().split('T')[0];

                        currentGridData.push(newData);
                    } else {
                        currentGridData.map((item, index) => {
                            if (item.MMSID == prevMMSID) {
                                item.MMSID = MMSID;
                                item.IsActive = IsActive;
                                item.UpdatedBy=this.props.enterpriseId;
                                item.UpdatedDate = new Date().toISOString().split('T')[0];
                                return true;
                            }
                        })
                    }

                    obj.refs.mmsid_grid.gridOptions.api.setRowData(currentGridData);
                    obj.OnCancel();
                    obj.setState({ isUnSaved: true })
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};
            let reg = /(MMS)[0-9]{8}/;

            if (!this.refs.txt_MMSID.value) {
                errors.MMSID = 'Please Enter MMSID';
                alert(i18n.t('pleaseEnterMMSID'));
                formIsValid = false;
            }
            else {
                let MMSID = this.refs.txt_MMSID.value
                let currentGridData = this.refs.mmsid_grid.gridOptions.rowData;
                let duplicateMMSID = currentGridData.find(item => item.MMSID.toUpperCase() == MMSID.toUpperCase())
                if (MMSID.length != 11 || !reg.test(MMSID.toUpperCase())) {
                    alert(i18n.t('pleaseFillOnlyAlphanumericValueforMMSID'));
                    formIsValid = false;
                } else if (duplicateMMSID !== undefined && !this.state.isUpdateExisting) {
                    alert(i18n.t('mMSIDAlreadyExistsPleaseSelect'));
                    formIsValid = false;
                }
                else {
                    formIsValid = true;
                }
            }
            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('edit')) {
                this.populateValues(e.data);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(selectedRow) {
        try {
            this.setState({
                isEdit: 2,
                selectedRow: selectedRow,
                ID: selectedRow.ID,
                MMSID: selectedRow.MMSID,
                IsActive: selectedRow.IsActive,
                IsDeleted: selectedRow.IsDeleted,
                isUpdateExisting: true,
                prevMMSID: selectedRow.MMSID,
                isUnSaved: true,
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {
        try {
            this.setState({
                IsActive: event.target.checked,
                isUnSaved: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");

            this.setState({
                [attr]: eve.target.value || '',
                isUnSaved: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnCancel(event) {
        try {
            this.setState({
                isEdit: 1,
                errors: '',
                IsActive: true,
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                selectedRow: {},
                ID: 0,
                MMSID: '',
                prevMMSID: "",

            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleClose() {
        let currentGridData = [];
        let lstMMSID = [];

        if (this.state.isUnSaved) {
            currentGridData = this.refs.mmsid_grid.gridOptions.rowData;
            if(currentGridData.length > 0)
            {
                alert(i18n.t('mMSIDHasbeenAddedUpdatedPleaseClicktheSave'));
            }
            lstMMSID = currentGridData.reduce((a, o) => (o.IsActive && a.push(o.MMSID), a), [])
        }
        this.setState({ show: false });
        this.props.closeCallback(lstMMSID.length, lstMMSID.join(','), currentGridData, this.state.isUnSaved);
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value
            });
            if (value) {
                this.saveMMSID(value);
            } else {
                this.saveMMSID();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }

    render() {
        let { t } = this.props;
        var columnDefs = [];
        columnDefs = [
            { headerName: t('edit'), field: '', cellRenderer: CreateEditBox, suppressMovable: true, suppressResize: true, },
            { headerName: t('dealId'), field: 'DealId', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: t('iD'), field: 'ID', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: t('mMSID'), field: 'MMSID', suppressMovable: true, suppressResize: true, },
            { headerName: t('createdBy'), field: 'CreatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: t('createdDate'), field: 'CreatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: t('updatedBy'), field: 'UpdatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: t('updatedDate'), field: 'UpdatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: t('headerIsActive'), field: 'IsActive', valueFormatter: this.formatActive, suppressMovable: true, suppressResize: true, },

        ];

        var MMSIDDataGrid = (
            <div style={{ height: '340px' }} className="ag-theme-material grid-div">
                <AgGridReact ref="mmsid_grid" id="grd_MMSIDgrid"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.MMSIDGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='false'
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />

            </div>
        )

        return (<div>
            <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h4>
                        {this.props.DealName}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="box">
                        <div className="box-in">
                            <div className="row">
                                <div className="col-lg-5 form-horizontal">
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" id="lbl_MMSID">{i18n.t('mMSID')}:<span style={{ color: "red" }}>*</span></label>
                                        <div className="col-sm-8">
                                            <input ref="txt_MMSID" name="MMSID" data-field="MMSID" type="text" className="form-control" placeholder="" value={this.state.MMSID} onChange={this.onInputChange} title="Enter value with prefix MMS followed by 8 digits" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 form-horizontal">
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" id="lbl_isActive">{i18n.t('isActive')} </label>
                                        <div className="col-sm-8">
                                            <div className="commoncheckbox">
                                                <input ref="IsActive" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} onChange={this.ChangeIsActive} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="btn-group-md pull-right">
                                    <input type="button" id="btn_update" className="btn btn-success" value={i18n.t('addUpdate')} onClick={this.saveMMSID} />
                                    <input type="button" id="btn_cancel" className="btn btn-danger" value={i18n.t('clear')} onClick={this.OnCancel} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="box-plain pmm-asmt-tbl">
                                    {MMSIDDataGrid}
                                </div>
                            </div>
                            {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={"MMSID"} editValue={this.state.value}
                                passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-group-md">
                        <input type="button" id="btn_cancel" className="btn btn-info" value={i18n.t('close')} onClick={this.handleClose} />
                    </div>
                </Modal.Footer>
            </Modal>
        </div>)
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}
DealMMSIDMapping =connect(mapStateToProps)( withTranslation()(DealMMSIDMapping));
export default withRouter(DealMMSIDMapping);