import i18n from 'i18next';


export function AgGridLocale() {
    return {       
        first: i18n.t('first'),
        last: i18n.t('last'),
        next: i18n.t('next'),
        previous: i18n.t('previous'),
        filterOoo: i18n.t('filterOoo'),
        page: i18n.t('page'),
        to: i18n.t('to'),
        of: i18n.t('of'),
        nodataavailable: i18n.t('nodataavailable'),
        loadingOoo: i18n.t('loadingOoo'),
        noRowsToShow: i18n.t('noRowsToShow'),
    }
}