import React from 'react';
import TermsOfUsePrivacyStatement from './TermsOfUsePrivacyStatement';
import footerLogo from './images/acc-foot-logo.png';
import IdleTimer from 'react-idle-timer';
import { Modal } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';


class Footer extends React.Component {

    constructor(props) {
        super(props);
        try {
            this.state = {
                popUpTerms: false,
                popupPrivacy: false,
                timeout: 1000 * 1200 * 1,
                showModal: false,
                isTimedOut: false,
                showSessionModal: false,
                sessionModalText: "",
                sessionBtnText: ""

            };
            this.onTermsClick = this.onTermsClick.bind(this);
            this.closeTermsPopUp = this.closeTermsPopUp.bind(this);
            this.onPrivacyClick = this.onPrivacyClick.bind(this);
            this.closePrivacyPopUp = this.closePrivacyPopUp.bind(this);
            this.handleSessionModalClose = this.handleSessionModalClose.bind(this);

            this.idleTimer = null;
            this.onAction = this._onAction.bind(this);
            this.onActive = this._onActive.bind(this);
            this.onIdle = this._onIdle.bind(this);

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onTermsClick(eve) {
        debugger;
        try {
            this.setState({
                popUpTerms: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeTermsPopUp() {
        try {
            this.setState({
                popUpTerms: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onPrivacyClick(eve) {
        debugger;
        try {
            this.setState({
                popupPrivacy: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePrivacyPopUp() {
        try {
            this.setState({
                popupPrivacy: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleSessionModalClose() {
        if (this.state.sessionBtnText === "OK") {


            $("#loader-div").hide();
            // window.location = "https://federation-sts-stage.accenture.com/adfs/ls/?wtrealm=https%3a%2f%2ffederation-sts-stage.accenture.com%2foauth%2fwsfed%2fenterprise%2f&wa=wsignout1.0 ";
            window.location = "https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0";
            this.setState({ showSessionModal: false })
        }

    }

    _onAction(e) {

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {
        this.setState({ isTimedOut: false })
    }

    _onIdle(e) {

        this.setState({ showSessionModal: true, sessionModalText: i18n.t('sessionTimeOut'), sessionBtnText: "OK" })


    }

    render() {
        return (
            <div><footer className="footerPosition"><a><img src={footerLogo} /></a>
                <p>{i18n.t('copyright2001')} <a className="mousePointer" onClick={this.onTermsClick}>{i18n.t('termsofUse')}</a> | <a className="mousePointer" onClick={this.onPrivacyClick}>{i18n.t('privacyStatements')}</a></p>

                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout} />

                <Modal show={this.state.showSessionModal} backdrop="static">
                    <Modal.Header>
                        <Modal.Title>{i18n.t('sessionExpired')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>
                            {this.state.sessionModalText}
                        </label >
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.handleSessionModalClose}>
                            {this.state.sessionBtnText}
                        </button>
                    </Modal.Footer>
                </Modal>

            </footer>
                {this.state.popUpTerms && <TermsOfUsePrivacyStatement show={this.state.popUpTerms} closeCallback={this.closeTermsPopUp} msgType={"Terms Of Use"} />}
                {this.state.popupPrivacy && <TermsOfUsePrivacyStatement show={this.state.popupPrivacy} closeCallback={this.closePrivacyPopUp} msgType={"Privacy Statement"} />}
            </div>
        );
    }
}
Footer = withTranslation()(Footer);
export default withRouter(Footer);
