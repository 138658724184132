import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import langKeys from './Utils/LangKeys'
import { withTranslation } from 'react-i18next';

class TermsOfUsePrivacyStatement extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show, headerName: "", innerContent: ""
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.handleResponse = this.handleResponse.bind(this);
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {
        
        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    componentDidMount() {
       
        try {
            this.setState({
                show: this.props.show
            });
            var pageName = this.props.msgType;
            if (pageName == "Terms Of Use") {
                //window.tfo_event("PMM-Home-8", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
                this.setState({ headerName: i18n.t("termsOfUseForAccenture") });
                this.setState({
                    innerContent: <span>
                        <p id ="accentureBusinessSupport" className="infoContent">{i18n.t('accentureBusinessSupport')}</p>
                    </span>
                })
            }
            else if (pageName == "Privacy Statement") {
                //window.tfo_event("PMM-Home-9", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
                this.setState({ headerName: i18n.t('privacyStatementon')+"pmm.accenture.com" });
                this.setState({
                    innerContent: <span>
                        <p className="infoContent" id="thisWebsiteIsAdminByAcceture">{i18n.t('thisWebsiteIsAdminByAcceture')}</p>
                    </span>
                });
            }
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    closePopUp() {
        try {
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    handleResponse(eve) {
        try {
            let targetText = eve.target.value;
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
           console.log("Error Encountered");
        }
    }

    render() {
        debugger;
        return (
            <div>
                <Modal show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5><b>{this.state.headerName}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.innerContent}
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" className="btn btn-info pull-right" value={i18n.t("close")} onClick={this.handleResponse} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(TermsOfUsePrivacyStatement)
TermsOfUsePrivacyStatement = withTranslation()(TermsOfUsePrivacyStatement);