/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from '../EditConfirmation';
import { withRouter } from 'react-router';
import $ from 'jquery';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect } from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.KPA;

const renderSuggestion = suggestion => <span>{suggestion.KPA}</span>;

var KPAData = []

function CreateEditBox(params) {
    try {
        let eDiv = document.createElement("div");
        let groupId=params.colDef.cellRendererParams.groupId
        if (params.data.GroupID == parseInt(groupId)
            ) {
            eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
        } else {
            eDiv.innerHTML = ``;
        }
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class KPA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            KPAGridData: [],
            isEdit: 1,
            errors: {},
            selectedRow: {},
            value: '',
            suggestions: [],
            KPAName: '',
            KPAId: 0,
            Description: '',
            isEditPopUp: false,
            IsConfirmUpdate: false,
            isUpdateExisting: false,
            IsActive: true
        };
        this.ChangeIsActive = this.ChangeIsActive.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.populateValues = this.populateValues.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.SaveKPADetails = this.SaveKPADetails.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.passValue = this.passValue.bind(this);
        this.formatActive = this.formatActive.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    componentWillMount() {
        try {
            this.setState({ KPAGridData: KPAData });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            $("#loader-div").hide();
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            var objThis = this;

            var SearchedString = "";
            params.api.showLoadingOverlay();
            request("/MasterScreens/GetKPADetails?KPAID=0&GroupID="+this.props.groupId+ "&SearchedString=" + SearchedString + "&LanguageID=" +this.props.languageId,'GET')
                .then(res => {
                    if(res.length!==0){ 
                        params.api.hideOverlay();
                        objThis.setState({ KPAGridData: res });
                        $("#loader-div").hide();
                }
                else{
                    params.api.showNoRowsOverlay();
                }
                   
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('edit')) {
                if (e.data.GroupID == parseInt(this.props.groupId)
                    ) {
                    this.populateValues(e.data);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(kpa) {
        try {
            this.setState({
                isEdit: 2,
                selectedRow: kpa,
                KPAId: kpa.KPAId,
                IsActive: kpa.IsActive,
                IsDeleted: kpa.IsDeleted,
                value: kpa.KPA,
                KPAName: kpa.KPA,
                Description: kpa.Description,
                isUpdateExisting: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {
        try {
            this.setState({ IsActive: event.target.checked });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnCancel() {
        try {
            this.setState({
                isEdit: 1,
                errors: '',
                IsActive: false,
                value: '',
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                KPAName: "",
                Description: "",
                searchKPA: "",
                KPAId: 0,

            });
            this.props.editFun(false);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-MC-7", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])  
            window.addEventListener('resize', this.reSizeGrid, true);
            //this.FetchConnKPAGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.kpa_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async FetchConnKPAGrid() {
        try {
            var objThis = this;

            var SearchedString = "";
            await request("/MasterScreens/GetKPADetails?KPAID=0&GroupID="+this.props.groupId+ "&SearchedString=" + SearchedString + "&LanguageID=" +this.props.languageId,'GET')
                .then(res => {
                    objThis.setState({ KPAGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveKPADetails(eve) {
        try {
            const obj = this;
            let { KPAId, KPA, IsActive, IsDeleted, value, Description } = obj.state;
            let Operation = eve && KPAId != 0 ? 2 : 1;
            let kpavalidation = inputValidator(value)
            let descriptionvalidation = inputValidator(Description)

            if(kpavalidation=== false && descriptionvalidation === false){
            if (obj.IsFormValid()) {
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && KPA != "") {
                    this.setState({
                        isEditPopUp: true
                    })
                    return alert(i18n.t("updateExistingorCreateNew"));
                }

                const requestData = obj.state.selectedRow;
                requestData["UserName"] = this.props.enterpriseId;
                requestData["Operation"] = Operation;
                requestData["KPAID"] = eve ? KPAId : 0;
                requestData["KPA"] = value;
                requestData["Description"] = Description;
                requestData["IsActive"] = IsActive;
                requestData["IsDeleted"] = IsDeleted;
                requestData["GroupID"] = this.props.groupId;
                requestData["LanguageID"] = this.props.languageId;

                request("/MasterScreens/UpsertKPA", 'POST', requestData)
                    .then(res => {
                        if (res.Outputcode != -1) {

                            alert(res["outputmessage"]);
                            obj.FetchConnKPAGrid();
                            obj.OnCancel();
                            obj.props.editFun(false);
                        }
                        else {
                            alert(res["outputmessage"]);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
            }
        }
        else{
            alert(i18n.t("PleaseEntervalidValuesintheFields"));
        }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};
            let reg = /^[A-Za-z\d\s]+$/;
            let regForAlphabets = /^[a-zA-Z]+$/;

            if (!this.refs.KPAName.input.value) {
                errors.KPAName = i18n.t('pleaseEnterKPAName');
                alert(i18n.t('pleaseEnterKPAName'));
                formIsValid = false;
            }

            if (!this.refs.Description.value && formIsValid == true) {
                errors.Description = i18n.t('pleaseEnterKPADescription');
                alert(i18n.t('pleaseEnterKPADescription'));
                formIsValid = false;
            }

            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.KPAGridData.filter(KPA => KPA.GroupID == parseInt(this.props.groupId)
                    );
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.KPAGridData.filter(KPA => regex.test(KPA.KPA) && KPA.GroupID == parseInt(this.props.groupId)
                );
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        try {
            this.setState({
                value: newValue
            });
            $(event.target).attr('type', 'new');
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        try {
            this.setState({
                suggestions: this.getSuggestions(value)
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });

    };

    onSuggestionSelected(event, { suggestion }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event) {
        try {
            let value = event.target.value.toLowerCase();
            this.state.KPAGridData.forEach(kpa => {
                if (kpa.KPA.toLowerCase() == value) {
                    if (kpa.GroupID ==parseInt(this.props.groupId)) {
                        this.populateValues(kpa);
                    }
                }
                return kpa;
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value
            });
            if (value) {
                this.SaveKPADetails(value);
            } else {
                this.SaveKPADetails();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }

    render() {
        let { t } = this.props;
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('edit'), field: '', cellRenderer: CreateEditBox, cellRendererParams:{groupId:this.props.groupId},suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('KPAID'), field: 'KPAId', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('KPAName'), field: 'KPA', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('description'), field: 'Description', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('createdBy'), field: 'CreatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('createdDate'), field: 'CreatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('updatedBy'), field: 'UpdatedBy', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('updatedDate'), field: 'UpdatedDate', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('isActive'), field: 'IsActive', valueFormatter: this.formatActive, suppressMovable: true, suppressResize: true, },

        ];

        var KPADataGrid = (
            <div style={{ height: '400px' }} className="ag-theme-material grid-div">
                <AgGridReact ref="kpa_grid" id="grid_kpa"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.KPAGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='true'
                    quickFilterText={this.state.searchKPA}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        let { value, suggestions } = this.state;

        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur
        }

        return (
            <div>
                <div className="box">
                    <div className="box-in">
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_KPAName" className="control-label col-sm-4">{i18n.t('KPAName')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Autosuggest ref="KPAName"
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps} />


                                </div>
                            </div>
                            {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={'KPAName'} editValue={this.state.KPAName}
                                passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />}
                        </div>
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_Description" className="control-label col-sm-4">{i18n.t('description')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <textarea ref="Description" id="txtArea_Description" name="Description" data-field="Description" rows="1" className="form-control" placeholder="" type="" onChange={this.onInputChange} value={this.state.Description} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_isActive" className="control-label col-sm-2">{i18n.t('isActive')} </label>
                                <div className="col-sm-8">
                                    <div className="commoncheckbox">
                                        <input id="txt_isActive" ref="IsActive" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} defaultChecked={true} onChange={this.ChangeIsActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-group-md fright">
                            <a type="button" className="btn btn-success" onClick={this.SaveKPADetails} >{i18n.t('save')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.OnCancel} >{i18n.t('clear')}</a>
                        </div>
                        <div className="search-div">
                            <h4 className="fleft">{i18n.t('KPADetails')}</h4>
                            <input id="txt_searchKPA" type="text" placeholder={i18n.t('searchKPA')} className="fright searchBox" data-field='searchKPA' value={this.state.searchKPA} onChange={this.onSearch} />
                        </div>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {KPADataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}

KPA =connect(mapStateToProps)(withTranslation()(KPA));
export default withRouter(KPA);