/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import '../../Script/chartjs-plugin-datalabels.min';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import $ from 'jquery';
import PptxGenJS from 'pptxgenjs';
import backArrowIcon from '../../images/icon-back-arrow.png';
import graphIcon from '../../images/icon-graph.png';
import listDefaultIcon from '../../images/icon-list-default.png';
import powerPointIcon from '../../images/powerpoint-icon.png';
import graphListIcon from '../../images/icon-graphlist.png';
import ReferenceDocumentsDetail from '../PMMAssessment/ReferenceDocumentsDetail';
import ReportOutputDocumentDownload from '../PMMAssessment/ReportOutputDocumentDownload';
import downloadGreenIcon from '../../images/icon-download-green.png';
import icondownload from '../../images/icon-download.png';
import request from '../../services/Service';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';


class ProjectPrioritizationView extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                ProjectPrioritizationGridData: [], lstLever: [], LeverValue: "", graph: true, grid: false,
                SCID: this.props.Assessmentdetails.ServiceComponentID,
                popUpReferenceDocuments: false, popUpSavedDocuments: false
            };
            this.toggleGraph = this.toggleGraph.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.onNavigationClick = this.onNavigationClick.bind(this);
            this.closeReferenceDocumentsPopUp = this.closeReferenceDocumentsPopUp.bind(this);
            this.showDownloadReferencePopup = this.showDownloadReferencePopup.bind(this);
            this.closeSavedDocumentsPopUp = this.closeSavedDocumentsPopUp.bind(this);
            this.showSavedDocumentsPopup = this.showSavedDocumentsPopup.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillMount() {

    }

    reSizeGrid() {
        try {
            this.refs.Grd_BOI.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            $("#loader-div").show();
            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {         
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-7", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                var SearchedString = "";
                let dropdownData = {
                    "ID": 0,
                    "DealId": 0,
                   "UserName": this.props.enterpriseId,
			       "GroupID": this.props.groupId,
			       "LanguageID":this.props.languageId

                }
                let gridData = {
                    "PMMProcessID": PMMProcessID,
                    "SearchedString": SearchedString,
                    "UserName": this.props.enterpriseId,
                    "GroupID": this.props.groupId,
                    "LanguageID":this.props.languageId
                }

                Promise.all([request("/Common/DropDownsValue", 'POST', dropdownData),
                request("/PotentialProjects/ViewProjectPrioritizationGrid", 'POST', gridData)])
                    .then(response => {
                        response[0].lever.unshift({ value: 0, label: i18n.t('all') })
                        this.setState({ lstLever: response[0].lever, ProjectPrioritizationGridData: response[1], LeverValue: { value: 0, label: i18n.t('all') } });
                        let graphDataArr = []
                        response[1].map(row => { if (row.ShortlistProject == i18n.t('yes')) { let dataobj = { x: row.TimeMonths, y: row.ImpactOnBOI, name: row.ProjectId, projectDetails: row.ProjectDetails }; graphDataArr.push(dataobj) } })
                        if (graphDataArr.length >= 1) {
                            this.BubbleChart(graphDataArr);
                        }
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }

            if (PMMProcessDraftID > 0) {     
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-14", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                var SearchedString = "";
                let dropdownData = {
                    "ID": 0,
                    "DealId": 0,
                    "UserName": this.props.enterpriseId,
                    "GroupID": this.props.groupId,
                    "LanguageID":this.props.languageId
                }
                let gridData = {
                    "PMMProcessDraftID": PMMProcessDraftID,
                    "SearchedString": SearchedString,
                    "UserName": this.props.enterpriseId,
                    "GroupID": this.props.groupId,
                    "LanguageID":this.props.languageId
                }

                Promise.all([request("/Common/DropDownsValue", 'POST', dropdownData),
                request("/PotentialProjects/GetProjectPrioritizationGrid", 'POST', gridData)])
                    .then(response => {
                        response[0].lever.unshift({ value: 0, label: i18n.t('all') })
                        this.setState({ lstLever: response[0].lever, ProjectPrioritizationGridData: response[1], LeverValue: { value: 0, label: i18n.t('all') } });
                        let graphDataArr = []
                        response[1].map(row => { if (row.ShortlistProject == i18n.t('yes')) { let dataobj = { x: row.TimeMonths, y: row.ImpactOnBOI, name: row.ProjectId, projectDetails: row.ProjectDetails }; graphDataArr.push(dataobj) } })
                        if (graphDataArr.length >= 1) {
                            this.BubbleChart(graphDataArr);
                        }
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectLever(val) {
        try {
            this.setState({ LeverValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ShowTooltip(e) {
        try {
            var eDiv = document.createElement("div");
            if (e.colDef.headerName == i18n.t('projectNamePMM')) {
                var strProjectName = e.data.ProjectName;
                eDiv.textContent = '<span title="' + strProjectName + '">' + strProjectName + '</span>';
            }
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    BubbleChart(rawData) {
        try {
            Chart.defaults.global.elements.point.radius = 15;
            Chart.defaults.global.plugins.datalabels.display = true;

            var xarr = [], yarr = [];
            // Get time and impact array to find the maximum value
            $(rawData).each(function (index, datum) {
                xarr.push(datum.x);
                yarr.push(datum.y);
            });
            var maxTime = Math.max.apply(null, xarr);
            var maxImpact = Math.max.apply(null, yarr);

            //Calculate the levels for differentiating the project points
            var level1, level2;
            level1 = +(((maxImpact / 2) / maxTime).toFixed(2));
            level2 = +((maxImpact / (maxTime / 2)).toFixed(2));
            var bubbleData = {
                datasets: [{
                    backgroundColor: function (context) {
                        var data = context.dataset.data[context.dataIndex];
                        var val = +((data.y / data.x).toFixed(2));
                        return (val <= level1) ? '#E57373' : (val > level1 && val <= level2) ? '#FFEB3B' : '#81C784';
                    },
                    borderColor: function (context) {
                        var data = context.dataset.data[context.dataIndex];
                        var val = +((data.y / data.x).toFixed(2));
                        return (val <= level1) ? '#E57373' : (val > level1 && val <= level2) ? '#FFEB3B' : '#81C784';
                    },
                    label: [i18n.t("TimeVsImpact")],
                    data: rawData
                }]

            };


            if (window.bubbleChart != null) {
                $("#bubbleDiv").empty();
                $("#bubbleDiv").append("<canvas id='bubble' width='400' height='150' ></canvas>");
            }

            //Construction of Charts
            var bubble_ctx = document.getElementById('bubble').getContext('2d');
            window.bubbleChart = new Chart(bubble_ctx, {
                type: 'bubble',
                data: bubbleData,
                options: {
                    plugins: {
                        datalabels: {
                            anchor: 'center',
                            align: 'center',
                            color: 'black',
                            font: {
                                weight: 'bold'
                            },
                            formatter: function (value, context) {
                                return context.dataset.data[context.dataIndex].name;
                            },
                            offset: 2,
                            padding: 0
                        }
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                min: 0,
                                max: maxTime + 1,
                                stepsize: 1
                            },
                            fontColor: "#000",
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("Timemonths"),
                                fontSize: 14,
                                fontStyle: 600,
                                fontColor: "#000",
                                fontFamily: "OpenSans-Regular"
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: maxImpact + 10,
                                stepsize: 10
                            },
                            fontColor: "#000",
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("ImpactonY"),
                                fontSize: 14,
                                fontStyle: 600,
                                fontColor: "#000",
                                fontFamily: "OpenSans-Regular"
                            }
                        }]
                    },

                    legend: false,
                    legendCallback: function (chart) {
                        var customLegend = [];
                        customLegend.push('<ul class="ulStyle">');
                        customLegend.push('<li class="liStyle"><span style="background:#81C784">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><p>' + i18n.t('Priority') + ' 1</p>');
                        customLegend.push('<span style="background:#FFEB3B">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><p>' + i18n.t('Priority') + ' 2</p>');
                        customLegend.push('<span style="background:#E57373">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><p>' + i18n.t('Priority') + ' 3</p></li>');
                        customLegend.push('</ul>');
                        return customLegend.join('');
                    },

                    tooltips: {
                        enabled: true,
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label;
                                label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].projectDetails;
                                return label;
                            }
                        }
                    }
                }
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    toggleGraph(e) {
        try {
            let graph, grid, graphgrid;
            if (e.currentTarget.id == "graph") {
                graph = true
                grid = false
            }
            else if (e.currentTarget.id == "grid") {
                grid = true
                graph = false
                let activeBtn = document.getElementById("graph");
                activeBtn.className = activeBtn.className.replace("active", "");
            }
            else {
                grid = true
                graph = true
            }
            this.setState({ graph, grid })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun(eve) {
        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }



    downloadPDF() {


    }

    closeReferenceDocumentsPopUp() {
        this.setState({
            popUpReferenceDocuments: false
        });
        $("#loader-div").hide();
    }

    showDownloadReferencePopup() {
        this.setState({
            popUpReferenceDocuments: true
        })
    }

    closeSavedDocumentsPopUp() {
        this.setState({
            popUpSavedDocuments: false
        });
        $("#loader-div").hide();
    }
    showSavedDocumentsPopup() {
        this.setState({
            popUpSavedDocuments: true
        })
    }

    async downloadPPT() {
        try {
            //await window.tfo_event("PMM-D-23", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            //For whole page export
            window.scrollTo(0, 0);
            html2canvas(document.getElementById("bubbleCanvasDiv")).then(canvas_whole => {
                let wholePag = canvas_whole.toDataURL();
                let wholePagURI = wholePag.split(':');
                var ppt = new PptxGenJS();
                var slides = ppt.addNewSlide();
                slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.5, w: 9.0, h: 5.0 });
                ppt.save(i18n.t('projectPrioritization'));
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.currentTarget.getAttribute('data-content');
            let url = eve.currentTarget.getAttribute('data-url');
            if (navigationPage == "StoryBoardView" || navigationPage == "PotentialProjectView") {
                this.props.history.push({
                    pathname: url,
                    state: { asToVal: this.props.location.state.asToVal }
                });
            }
            else {
                this.props.history.push(url);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('ProjectID'), field: 'ProjectId', suppressMovable: true, suppressResize: true, tooltipField: 'ProjectID' },
            { headerName: i18n.t('lever'), field: 'LeverName', suppressMovable: true, suppressResize: true, tooltipField: 'Lever' },
            { headerName: i18n.t('projectDetails'), field: 'ProjectDetails', suppressMovable: true, suppressResize: true, tooltipField: 'ProjectName', width: 677, autoHeight: true },
            { headerName: i18n.t('Timemonths'), field: 'TimeMonths', suppressMovable: true, suppressResize: true, tooltipField: 'Time', width: 150 },
            { headerName: i18n.t('ImpactonY'), field: 'ImpactOnBOI', suppressMovable: true, suppressResize: true, tooltipField: 'ImpactOnY', width: 150 },
            { headerName: i18n.t('Remarks'), field: 'Remarks', suppressMovable: true, suppressResize: true, tooltipField: 'Remarks', width: 200, autoHeight: true }
        ];


        var ProjectPrioritizationDataGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_prjctprior" id="prjctprior_grd"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.ProjectPrioritizationGridData}
                    headerHeight="45"
                    enableSorting={false}
                    rowmodeltype="pagination"
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd"><Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back"><img src={backArrowIcon} /> {i18n.t('bACKTOHOME')}</a></Link>
                    <ul className="pagecounter"  >
                        <li><span title={i18n.t("vIEWPreviousassesssment")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page"><Link to="/ViewPreviousAssessmentHome"></Link></span></li>
                        <li><span title={i18n.t("BOIKeyBusinessMetrics")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><Link to="/BOIView"></Link></span></li>
                        <li><span title={i18n.t("lEADINGINDICATOR")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator"><Link to="/LIView"></Link></span></li>
                        <li><span title={i18n.t("pMMASSESSMENT")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM"><Link to="/PMMAssessmentView"></Link></span></li>
                        <li onClick={this.onNavigationClick} data-url="/StoryBoardView" data-content="StoryBoardView"><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="StoryBoardView"><a></a></span></li>
                        <li onClick={this.onNavigationClick} data-url="/PotentialProjectView" data-content="PotentialProjectView"><span title={i18n.t("pOTENTIALPROJECTS")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects"><a></a></span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">...</span></li>
                    </ul>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <h4><div className="pull-left">{i18n.t('pROJECTPRIORITIZATIONVIEW')}</div>
                            <div className="pull-right">
                                <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.Assessmentdetails.DealName}>{this.props.Assessmentdetails.DealName}
                                </span> &nbsp;&nbsp;||<span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.Assessmentdetails.RegionName}>{this.props.Assessmentdetails.RegionName}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.CountryName}>{this.props.Assessmentdetails.CountryName}
                                {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.Assessmentdetails.OGName}>{this.props.Assessmentdetails.OGName} */}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ':' + this.props.Assessmentdetails.IndustryName}>{this.props.Assessmentdetails.IndustryName}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.CapabilityName}>{this.props.Assessmentdetails.CapabilityName}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.TowerName}>{this.props.Assessmentdetails.TowerName}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.SCName}>{this.props.Assessmentdetails.SCName}</span>
                            </div>
                        </h4>

                        <h4>
                            <div className="btn-group-md">
                                <a type="button" title={i18n.t("downloadgraphinppt")} className="btn btn-info pull-left" onClick={this.downloadPPT}><img src={powerPointIcon} /> {i18n.t('downloadasPPT')}</a>

                            </div>
                            <div className="btn-group btn-group-pmmasmt">

                                <button id="graph" type="button" className="btn btn-default btn-graph active" onClick={this.toggleGraph}>
                                    <img src={graphIcon} />
                                </button>
                                <button id="grid" type="button" className="btn btn-default btn-list" onClick={this.toggleGraph}>
                                    <img src={listDefaultIcon} />
                                </button>
                                <button id="graphgrid" type="button" className="btn btn-default btn-graphlist icon2type" onClick={this.toggleGraph}>
                                    <img src={graphListIcon} />
                                </button>
                                <button id="btn_downloadReferenceDocumentsForThisAssessment" type="button" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default" onClick={this.showDownloadReferencePopup}>
                                    <img src={downloadGreenIcon} />
                                </button>
                                {
                                (this.props.groupId != "1") ?
                                <button id="btn_downloadReportOutDocumentsForThisAssessment" type="button" title={i18n.t("downloadReportOutputDocuments")} className="btn btn-default" onClick={this.showSavedDocumentsPopup}>
                                        <img src={icondownload} />
                                    </button>
                                    : null
                                }
                            </div>
                            <div className="btn-group btn-group-pmmasmt">
                            </div>
                        </h4>
                        <div className="box-plain pmm-asmt-tbl">
                            {this.state.grid ? <div className="box">
                                {ProjectPrioritizationDataGrid}
                            </div> : null}

                            <div id="bubbleCanvasDiv" className="row" style={{ display: this.state.graph ? "block" : "none" }}>
                                <div className="col-md-10">
                                    <div id="bubbleDiv">
                                        <canvas id="bubble" width="400" height="150"></canvas>
                                    </div>
                                </div>
                                <div id="bubbleLegend" style={{ "text-align": "center" }} className="col-md-2">
                                    <span style={{ "text-orientation": "sideways", "transform": "rotate(270deg)", "text-align": "center", "line-height": "50px", "writing-mode": "vertical-lr" }} >{i18n.t('Impact')}</span>
                                    <svg height="100" width="150" style={{ border: "1px solid #95a5a6" }}>
                                        <path d="M0 0 L0 100 L80 0 Z" fill="#81C784" stroke="#95a5a6" stroke-width="1" />
                                        <path d="M0 100 L80 0 L150 0 L150 50Z" fill="#FFEB3B" stroke="#95a5a6" stroke-width="1" />
                                        <path d="M150 100 L0 100 L150 50 Z" fill="#E57373" stroke="#95a5a6" stroke-width="1" />
                                        <text x="3" y="30" font-size="10">{i18n.t('Priority')} 1</text>
                                        <text x="80" y="30" font-size="10">{i18n.t('Priority')} 2</text>
                                        <text x="82" y="85" font-size="10">{i18n.t('Priority')} 3</text>
                                        {i18n.t('SorryourbrowsersupportinlineSVG')}.
                                    </svg>
                                    <p className="timeCls">{i18n.t('Time')}</p>
                                </div>
                            </div>


                        </div>
                        <div className="btn-group-md">
                            <Link to="/ViewPreviousAssessmentHome" className="btn btn-success" >{i18n.t('GoBackToHome')}</Link>
                        </div>
                    </div>
                    <div id="ServiceComponent"></div>
                    {this.state.popUpReferenceDocuments && <ReferenceDocumentsDetail show={this.state.popUpReferenceDocuments} closeCallback={this.closeReferenceDocumentsPopUp} selectedMaturity={i18n.t('all')} SCID={this.state.SCID} />}
                    {this.state.popUpSavedDocuments && <ReportOutputDocumentDownload show={this.state.popUpSavedDocuments} closeCallback={this.closeSavedDocumentsPopUp} selectedPMMProcessDraftID={this.props.Assessmentdetails.PMMProcessDraftID} selectedPMMProcessID={this.props.Assessmentdetails.PMMProcessID} />}
                </section>
            </section >
        );
    }
}

function mapStateToProps(state){
    return{
		languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        groupName: state.groupName
    }
}

ProjectPrioritizationView = connect(mapStateToProps)(withRouter(ProjectPrioritizationView))
export default ProjectPrioritizationView;

